import React from 'react'
import { Router, Switch } from 'react-router-dom'
import Route from './route-wrapper';

import Path from './routes-path';

import history from './history';
import LOGIN from '../login/login';
import STAFF_LOGIN from '../staff-login/staff-login';
import FORGOT_PASSWORD from '../forgot-password/forgot-password';
import TenantWorkflow from '../tenant-workflow/tenant-workflow'
import SINGUP from '../signup/signup';
import Layout from '../../../layout/layout'
import PrivacyPolicy from '../terms-and-privacy/privacy-policy';
import ServicesAgreement from '../terms-and-privacy/services-agreement';
import SellerSetupRefresh from '../payment-config/seller/setup-refresh';
import SellerSetupSuccess from '../payment-config/seller/setup-success';
import PaymentSetupResponse from '../payment-subscription/setup-response/setup-response';
import SubscriptionMobileAppRedirect from '../payment-subscription/subscription-mobile-app-redirect';

export default function Routes() {

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={Path.TENANT_WORKFLOW} component={TenantWorkflow} />
        <Route path={Path.STAFF_LOGIN} component={STAFF_LOGIN} />
        <Route exact path={Path.FORGOT_PASSWORD} component={FORGOT_PASSWORD} />
        <Route exact path={Path.SIGN_UP} component={SINGUP} />
        <Route exact path={Path.LOGIN} component={LOGIN} />
        <Route exact path={Path.PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route exact path={Path.SERVICES_AGREEMENT} component={ServicesAgreement} />
        <Route exact path={Path.PAYMENT.sellerSetupRefresh} component={SellerSetupRefresh} />
        <Route exact path={Path.PAYMENT.sellerSetupSuccess} component={SellerSetupSuccess} />
        <Route exact path={Path.SUBSCRIPTION.setupResponse} component={PaymentSetupResponse} />
        <Route exact path={Path.SUBSCRIPTION.mobileAppRedirect} component={SubscriptionMobileAppRedirect} />
        <Route exact path={"*"} component={Layout} />
      </Switch>
    </Router>
  )
}
