import React from "react";
import "./setup-response.scss";

import ImageConstants from "../../../constants/image-constants";
import { getImageSource } from "../../../utility/image-source";
import Loading from "../../common/loading/loading";
import ToasterComponent from "../../common/toaster/toaster";
import Path from "../../routes/routes-path";
import PaymentSubscriptions from "../../../apis/payment-subscriptions";
import AuthenticateAPI from "../../../apis/authenticate";
import SessionCache from "../../routes/session-cache";

class PaymentSetupResponse extends React.Component {
   state = {
      staticText: {},
      isLoading: true,
   }

   componentDidMount() {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const status = params.get('status');
      const isSuccess = status === "success"
      this.setState({ isSuccess });
      if (!isSuccess) {
         this.setState({ isLoading: false });
         return
      }
      const sessionId = params.get('sessionId')
      this.createSubscriptionAfterPaymentSetupSuccess(sessionId);
   }

   createSubscriptionAfterPaymentSetupSuccess = (sessionId) => {
      const payload = {
         sessionId
      };
      PaymentSubscriptions.createSubscriptionAfterPaymentSetupSuccess(payload)
         .then(() => {
            this.setState({ isLoading: false });
         })
         .catch((err) => {
            this.setState({ isLoading: false });
            console.error(err);
         });
   }

   isAuthenticated() {
      this.setState({ isLoading: true })
      AuthenticateAPI.isLogin({}).then((response) => {

         if (response) {
            SessionCache.setUser(response);
         } else {
            this.setState({ isLoading: false })
         }
         this.props.history.push(Path.SUBSCRIPTION.membershipAndBilling)
      }).catch((err) => {
         this.setState({ isLoading: false })
      })
   }

   getStatusText = () => {
      if (this.state.isSuccess) {
         return this.state.staticText?.successText || 'Your Platinum Membership has been activated!'
      }

      return this.state.staticText?.failedText || 'Failed to setup Platinum Membership'
   }

   onClickContinue = () => {
      if (window?.ReactNativeWebView) {
         window.ReactNativeWebView.postMessage("hideWebView")
         return
      }
      this.isAuthenticated()
   }

   render() {

      if (this.state.isLoading) return <Loading />
      return (<div className="setup-response-container">
         <div className="payment-response">
            <div className="container">
               <div className="row  d-flex justify-content-center">
                  <div className="col payment-response-wrapper">
                     <div className="complete">

                        <div className="text-center">
                           <img className="icon-80 animate-image-1" alt="" src={getImageSource(this.state.isSuccess ? ImageConstants.GREEN_TICK : ImageConstants.CLOSE_RED)} />
                        </div>

                        <div className="pt-4 text-center"> {this.getStatusText()} </div>

                        {
                           !this.isLoading ?
                              <div className="mt-5 text-center">
                                 <button className={`app-button-primary `} onClick={this.onClickContinue}>
                                    {this.state.staticText?.continueButtonText || 'Continue'}
                                 </button>
                              </div>
                              : ""
                        }

                     </div>
                  </div>
               </div>
            </div>
         </div>
         {this.state.showToaster ? (
            <ToasterComponent
               type={this.state.toaster?.type}
               message={this.state.toaster?.message}
            />
         ) : (
            ""
         )}
      </div>)

   }
}

export default PaymentSetupResponse