import HttpClient from './http-client';
import URL from './url';

export default class Branch {


    static getBranches(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.BRANCH + "?" + params.join('&') : URL.BRANCH;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getStaffs(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.STAFF + "?" + params.join('&') : URL.STAFF;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getBranchConfig(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.BRANCH_CONFIG + "?" + params.join('&') : URL.BRANCH_CONFIG;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static createBranch(payload) {
        return HttpClient.post(URL.BASE_URL + URL.BRANCH, null, payload);
    }


    static updateBranch(payload) {
        return HttpClient.put(URL.BASE_URL + URL.BRANCH, null, payload);
    }

    static createStaff(payload) {
        return HttpClient.post(URL.BASE_URL + URL.STAFF, null, payload);
    }

    static updateStaff(payload) {
        return HttpClient.put(URL.BASE_URL + URL.STAFF, null, payload);
    }

    static sendInvite(payload) {
        return HttpClient.post(URL.BASE_URL + URL.BRANCH_INVITE, null, payload);
    }

    static sendWelcomEmail(payload) {
        return HttpClient.post(URL.BASE_URL + URL.REGISTER_INVITE, null, payload);
    }

    static updateInvite(payload) {
        return HttpClient.put(URL.BASE_URL + URL.BRANCH_INVITATION, null, payload);
    }

    static updateBranchConfig(payload) {
        return HttpClient.post(URL.BASE_URL + URL.BRANCH_CONFIG, null, payload);
    }
}
