
import React from 'react';
import './toaster.scss';

class ToasterComponent extends React.Component {

    toasterBackground(value) {
        if (value === "success") {
            return "toaster-success"
        } else if (value === "error") {
            return "toaster-error"
        } else if (value === "warning") {
            return "toaster-waning"
        }
        return "toaster-none"
    }

    render() {

        return (
            <div className={"toaster"}>
                <div className={`toaster-wrapper text-center ${this.toasterBackground(this.props.type)}`}>
                    <div className="my-auto"> {this.props.message}</div>
                </div>
            </div>
        );
    }
}

export default ToasterComponent;