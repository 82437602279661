//New Module for Inventory INV000001
import React from 'react';
import "./create-inventory-products.scss";
import inventoryApi from '../../../apis/inventory';                        // INV000001
import { Modal, ModalBody } from "reactstrap";
import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../../utility/image-source'
import Dropdown from '../../common/dropdown/dropdown'
import ToasterComponent from '../../common/toaster/toaster';

import Constants from '../../../constants/constants';
import CommonUtils from '../../../utility/common-utilities';

class CreateInventoryProduct extends React.Component {

    constructor(props) {
        super(props)

        this.getCategories()
        this.getBrands()

        if (this.props.product) {

            let { productName, packageQuantity, images, currentInventory, productCategory, productBrand, productId } = this.props.product

            this.state.name = productName
            this.state.packageQuantity = packageQuantity
            this.state.images = images
            this.state.invQty = currentInventory
            this.state.selectedCategory = productCategory
            this.state.code = productId
            this.state.brand = productBrand
        }
    }
    state = {

        categoryDropdown: [],
        brandDropdown: [],
        isSubmit: false,
        images: [],
        showToaster: false,
        recentlyAdded: [],
        showCategoryDropdown: true,
        showBrandDropdown: true,
        selectedPreview: ''
    }

    imageTyes = ["image/jpeg", "image/png", "image/bmp", "image/gif", "	image/x-icon"]
    audioTypes = ["audio/mpeg", "audio/ogg", "audio/wav"]

    componentDidMount() {
    }

    onChangeHandler(event, key, max) {
        let value = event?.target?.value || ""
        max = max || Constants.INPUT_MAX_LENGTH_1
        if (max < value.length) return
        this.setState({ [key]: value })

        if (key === "code") {
            this.setState({ isDuplicateCode: false })
        }
    }


    getCategories = () => {

        let payload = {
        }

        inventoryApi.getCategories(payload).then((response) => {

            let categoryDropdown = response.response;
            this.setState({ categoryDropdown }, () => {
                this.categoryReload()
            });

        }).catch((err) => {
            alert(err);
        })

    }

    categoryReload = () => {
        this.setState({ showCategoryDropdown: false }, () => this.setState({ showCategoryDropdown: true }))
    }

    brandReload = () => {
        this.setState({ showBrandDropdown: false }, () => this.setState({ showBrandDropdown: true }))
    }


    showToaster(type, message) {
        this.setState({
            showToaster: true,
            toaster: {
                type,
                message,
            }
        }, () => {
            setTimeout(() => this.hideToaster(), 3000)
        })
    }

    hideToaster() {
        this.setState({ showToaster: false })
    }



    getBrands = () => {

        let payload = {
        }

        inventoryApi.getBrands(payload).then((response) => {
            this.setState({ brandDropdown: response.response }, () => {
                this.brandReload()
            })
        }).catch((err) => {
            alert(err);
        })
    }

    uploadFile = (signedUrl, file, callback) => {
        let type = "IMAGE"
        if (file.type.includes('video')) type = "VIDEO"
        else if (file.type.includes('audio')) type = "AUDIO"

        inventoryApi.uploadFileAwsS3(signedUrl, file,
            (progress) => {
                let progressPercentage = ((progress.loaded / progress.total) * 100).toFixed(2);
                this.setProgress(progressPercentage);
            })
            .then(response => {
                let splittedSignedUrl = signedUrl.split("?");
                callback && callback(null, splittedSignedUrl[0])
            })
            .catch(error => {
                callback && callback(error, null)
                console.log(error);
            });
    };

    getSignedURL(file) {

        let payload = {
            fileName: file.name,
            type: Constants.PRODUCTS
        };

        this.setState({ uploadLoading: true })
        inventoryApi.getSignedUrl(payload).then((response) => {

            this.uploadFile(response.signedUrl, file, (error, res) => {

                if (error) {
                    this.setState({ uploadLoading: false })
                    return
                }

                console.log(res)
                let images = this.state.images;
                images.push({ url: res })
                this.setState({ images, uploadLoading: false })
            })
        }).catch((err) => {
        })
    }

    onDrop = event => {
        const { type } = event.dataTransfer.files[0];
        const reader = new FileReader();
        if (type.includes('image')) {
            reader.onload = e => this.setPreview(e.target.result);
        }

        reader.readAsDataURL(event.dataTransfer.files[0]);
        const payload = new FormData();
        payload.append('file', event.dataTransfer.files[0]);
        let file = event.dataTransfer.files[0];
        this.getSignedURL(file);
        //}
        event.preventDefault();
    };

    onDragOver = event => {
        if (this.state.enableDragDrop) {
            this.setState({ status: 'Drop' });
        }
        event.preventDefault();
    };

    onDragLeave = event => {
        if (this.state.enableDragDrop) {
            this.setState({ status: 'Drop Here' });
        }
        event.preventDefault();
    };

    setProgress(percentage) {
        this.setState({ percentage: percentage });
    }

    showProgressBar = (status) => {
        this.setState({
            showProgress: status,
        });
    }

    reset = () => {
        this.setState({
            percentage: 0,
        });
    }

    onDragEnter = event => {
        if (this.state.enableDragDrop) {
            this.setState({ status: 'Image Selected' });
        }
        event.stopPropagation();
        event.preventDefault();
    };


    onImageUpload(event) {
        this.setState({ hideDone: true, isUploading: true })
        let file = event.target.files[0];
        if (!file)
            return;
        let previewImage = URL.createObjectURL(file);
        this.setPreview(previewImage);
        this.getSignedURL(file);
    }

    setPreview = (data) => {
        this.setState({ preview: data });
    }

    validateInputs = () => {

        if (!this.state.name)
            return false

        if (!this.state.code)
            return false

        if (!this.state.packageQuantity)
            return false

        if (this.state.invQty < 0)
            return false

        if (!this.state.selectedCategory)
            return false

        if (!this.state.brand)
            return false

        if (this.state.uploadLoading) return false

        return true

    }

    onClickCreate = () => {

        this.setState({ isSubmit: false })

        let isValid = this.validateInputs()

        if (!isValid) {
            this.setState({ isSubmit: true })
            return
        }

        if (this.props.product) {

            let payload = {
                productObjId: this.props.product._id,
                productName: this.state.name,
                packageQuantity: this.state.packageQuantity,
                images: this.state.images,
                currentInventory: this.state.invQty,
                productCategory: this.state.selectedCategory.value,
                productBrand: this.state.brand,
                productId: this.state.code ? this.state.code.trim() : ""
            }

            this.setState({ loading: true })
            inventoryApi.updateProductInventory(payload).then((response) => {
                this.setState({ loading: false })
                this.showToaster("success", "Product updated successfully.")
            }).catch((err) => {
                this.setState({ loading: false })
                if (err?.response?.data?.code === 11000) {
                    this.setState({ isDuplicateCode: true })
                    this.showToaster("error", err?.response?.data?.message)
                }
                else {
                    this.showToaster("error", "Oops somthing went wrong...")
                }
            })

        } else {
            let payload = {
                products: {
                    productName: this.state.name,
                    packageQuantity: this.state.packageQuantity,
                    images: this.state.images || [],
                    currentInventory: this.state.invQty,
                    productCategory: this.state.selectedCategory.value,
                    productBrand: this.state.brand,
                    productId: this.state.code
                }
            }

            this.setState({ loading: true })
            inventoryApi.createProductInventory(payload).then((response) => {
                this.setState({ loading: false })
                let recentlyAdded = this.state.recentlyAdded;
                recentlyAdded = payload.products
                this.setState({ recentlyAdded }, () => {
                    this.resetCreateInventoryProduct()
                })
                this.showToaster("Success", "Product created successfully.")
            }).catch((err) => {
                this.setState({ loading: false })
                if (err?.response?.data?.code === 11000) {
                    this.setState({ isDuplicateCode: true })
                    this.showToaster("Error", err?.response?.data?.message)
                }
                else {
                    this.showToaster("Error", "Oops, Something went wrong :-(")
                }
            })
        }
    }

    onClickRemove = (index) => {

        let images = this.state.images
        images.splice(index, 1)
        this.setState({ images })
    }

    resetCreateInventoryProduct = () => {
        this.setState({
            name: "",
            packageQuantity: "",
            images: [],
            invQty: 0,
            selectedCategory: "",
            code: "",
            brand: ""
        })

        this.brandReload()
        this.categoryReload()
    }

    renderRecentlyAdded = () => {

        let elements = []

        for (let i = 0; i < this.state.recentlyAdded.length; i++) {

            let item = this.state.recentlyAdded[i]

            elements.push(<div className='product-list mt-3'>
                <div className='row produt-list-alignment'>
                    {/* <div className='col-1'>

                    </div> */}
                    <div className='col-4 image-block'>
                        <div className='d-inline-block pr-2 image-display-left'>
                            <img alt="" src={item.images[0]?.url || getImageSource(ImageConstants.DEFAULT_PRODUCT)} className='added-image'></img>
                        </div>
                        <div className='d-inline-block image-display-right'>
                            <div className='product-name'>{item.name}</div>
                            <div className='row'>
                                {item.code ? <div className='col-4 product-code-border'>
                                    <span className='product-code'>{item.code}</span>
                                </div> : <></>}
                                <div className='col-7'>
                                    <span className='product-quantity'>Qty: {item.packageQuantity.value}{item.packageQuantity.unit}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-1'>
                        <div className='d-inline-block invqty-display'>{CommonUtils.formatAmountStringStrict(item.invQty)}</div>
                    </div>
                    <div className='col-7'>
                        <span className='description-display'>{item.description}</span>
                    </div>
                </div>
            </div >)
        }

        return (
            <div className='recently-added-container'>
                <div className='recently-added-title'>ADDED LIST:</div>
                {elements}
            </div>
        )
    }

    renderImages = () => {

        let elements = []

        for (let i = 0; i < this.state.images.length; i++) {

            let image = this.state.images[i]
            elements.push(<div className='added-image mr-2'>
                <img src={image.url} className='added-image' />
                <img src={getImageSource(ImageConstants.REMOVE_ICON)} onClick={() => this.onClickRemove(i)} className='remove-icon cursor-pointer'></img>
            </div>)
        }

        return elements
    }

    onClickCancel = () => {
        this.props.cancel && this.props.cancel()
    }

    renderCreateInventoryProduct() {

        return (
            <Modal className="create-product-modal" isOpen={true} toggle={this.hideModal} size="lg">
                <ModalBody>
                    <div className='modal-container-product'>
                        <div className='create-product-header'>
                            <div className='product-container product-header-container'>
                                <div className='row vetrical-center m-0'>
                                    <div className='col'>
                                        <span className='add-product-text'>{this.props.product ? "Update Product" : "Add Product"}</span>
                                    </div>
                                    <div className='col text-right pr-0'>
                                        <img src={getImageSource(ImageConstants.POPUP_CLOSE)} className='popup-close-icon cursor-pointer' onClick={() => this.onClickCancel()}></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='app-container mt-5'>
                            <div className='product-container'>
                                {this.state.recentlyAdded.length ? this.renderRecentlyAdded() : <></>}

                                <div className='create-inv-product-content'>
                                    <div className='d-inline-block product-left-container'>

                                        {this.state.uploadLoading ? <div className='image-upload'>
                                            <img src={getImageSource(ImageConstants.LOADING)} className='loading-icon'></img>
                                        </div> :
                                            this.state.images.length >= 5 ? <div className='image-upload-preview'>
                                                <img src={this.state.selectedPreview ? this.state.selectedPreview : this.state.images[0].url} className='preview-image' />
                                            </div> : <div className='image-upload'
                                                onDragOver={this.onDragOver}
                                                onDragLeave={this.onDragEnter}
                                                onDrop={this.onDrop}>
                                                <div className='upload-image'>
                                                    <img src={getImageSource(ImageConstants.UPLOAD_IMAGE)} className='upload-image-size' />
                                                </div>
                                                <br />
                                                <div className='sub-text'>(MAXIMUM  5 IMAGES)</div>

                                                <label className="m-0 ml-1 cursor-pointer" style={{ "cursor": "pointer" }}>
                                                    <input
                                                        type="file" style={{ "display": "none" }}
                                                        accept={this.props.accept || ''}
                                                        onChange={(event) => this.onImageUpload(event)} />    <div className='drop-text pt-2'>
                                                        <span>Drag & Drop or</span>
                                                        <span className='browse-text'> browse </span>
                                                        <span>Images...</span>
                                                    </div>
                                                </label>
                                            </div>}

                                        <div className='upload-image-bottom'>
                                            Supports JPG, PNG, JPEG
                                        </div>

                                        <div className='mt-3 row'>{this.renderImages()}</div>
                                    </div>

                                    <div className='d-inline-block product-right-container'>
                                        <div className='row'>
                                            <div className='col'>
                                                <div className={`${this.state.isSubmit && !this.state.name ? "invalid-input-container" : ""}`}>
                                                    <div className='app-input-label'>Product name <span className='star'>*</span></div>
                                                    <input
                                                        id="product-name"
                                                        className={`app-input`} placeholder='Enter Product name'
                                                        value={this.state.name}
                                                        onChange={(e) => this.onChangeHandler(e, 'name')} />
                                                </div>
                                            </div>

                                            <div className='col'>
                                                <div className={`${(this.state.isSubmit && !this.state.code) || this.state.isDuplicateCode ? "invalid-input-container" : ""}`}>
                                                    <div className='app-input-label'>Product Code <span className='star'>*</span></div>
                                                    <input id="product-code" className={`app-input`} placeholder='Enter product code'
                                                        value={this.state.code}
                                                        onChange={(e) => this.onChangeHandler(e, 'code')} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col'>

                                                <div className='row'>
                                                    <div className='col'>
                                                        <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.packageQuantity ? "invalid-input-container" : ""}`}>
                                                            <div className='app-input-label'>Package Quantity <span className='star'>*</span></div>
                                                            <input id="sell" className={`app-input`} placeholder='Enter Package Quantity'
                                                                value={this.state.packageQuantity}
                                                                onChange={(e) => this.onChangeHandler(e, 'packageQuantity')} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col">
                                                <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.brand ? "invalid-input-container" : ""}`}>
                                                    <div className='app-input-label'>Select Brand <span className='star'>*</span></div>
                                                    <input id="brand" className={`app-input `} placeholder='Enter Brand'
                                                        value={this.state.brand}
                                                        onChange={(e) => this.onChangeHandler(e, 'brand')} />
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className='row'>
                                            <div className="col">
                                                <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.selectedCategory ? "invalid-input-container" : ""}`}>
                                                    <div className='app-input-label'>Product Category <span className='star'>*</span></div>
                                                    {this.state.showCategoryDropdown ? <Dropdown
                                                        options={this.state.categoryDropdown}
                                                        text={"title"}
                                                        onSelect={(item) => { this.setState({ selectedCategory: item}) }}
                                                        value={this.state.selectedCategory}
                                                        placeholder={'Select category'}
                                                    ></Dropdown> : <></>}
                                                </div>
                                            </div>

                                            <div className='col'>
                                                <div className={`bw-spaces-input ${this.state.isSubmit && this.state.invQty < 0 ? "invalid-input-container" : ""}`}>
                                                    <div className='app-input-label'>Current Inventory <span className='star'>*</span></div>
                                                    <input id="product-inventory" type={'number'} className={`app-input inventory-input-spacing`} placeholder='Enter On Hand Qty'
                                                        value={this.state.invQty}
                                                        min={0}
                                                        onChange={(e) => this.onChangeHandler(e, 'invQty')} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='footer-product-create mt-5'>
                            <div className='product-container'>
                                <div className='row button-container'>
                                    <div className='cancel-button mr-2' onClick={() => this.onClickCancel()}>
                                        Cancel
                                    </div>
                                    {this.state.loading ? <img src={getImageSource(ImageConstants.LOADING)} className='loading-icon'></img> :
                                        <div className={`continue-button-disabled ${this.validateInputs() ? 'continue-button-enabled' : ""}`} onClick={() => this.onClickCreate()}>{this.props.product ? "Update" : "Save"}</div>}
                                </div>
                            </div>
                        </div>
                    </div>

                </ModalBody>
            </Modal >
        )
    }

    render() {

        return (
            <div className='create-inventory-product'>
                {this.renderCreateInventoryProduct()}
                {this.state.showToaster ? <ToasterComponent type={this.state.toaster?.type} message={this.state.toaster?.message} /> : ""}
            </div>
        );
    }
}

export default CreateInventoryProduct;