import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Payment from '../../../apis/payment';
import CommonUtils from '../../../utility/common-utilities';
import Path from '../../routes/routes-path';
import "./style.scss";

class SellerSetupSuccess extends React.Component {
   state = {
      staticText: {},
      loading: true
   }

   componentDidMount() {
      const queryParams = CommonUtils.queryStringToJson(window.location.search)
      this.updatePaymentSellerAccount({ refreshId: queryParams?.id })
   }

   updatePaymentSellerAccount(payload = {}) {
      Payment.updateSellerAccount(payload).then((response) => {
         if (window?.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage("hidePaymentAccountOnboarding")
            return
         }
         this.props.history.push(Path.PROFILE)
      }).catch((err) => {
         this.setState({ loading: false })
      })
   }

   render() {
      return (
         <div className="payment-seller-container" style={{
            height: window.innerHeight, width: window.innerWidth,
         }}>
            <div className="p-4 d-flex align-self-center">
               <div className="cp-flex-1 text-center">
                  <FontAwesomeIcon spin size="1x" icon={faCircleNotch} />
               </div>
               <div className="cp-flex-2">
                  <span className="pl-4 semi-bold-text">
                     We are verifying your account. Please don't refresh the page.
                  </span>
               </div>
            </div>
         </div>
      )
   }
}

export default SellerSetupSuccess;