import React from 'react';
import "./product-item-card.scss";
import Utils from '../../../utility/common-utilities'
import { getImageSource } from '../../../utility/image-source'
import ImageConstants from '../../../constants/image-constants';

class ProductItemCard extends React.Component {

    componentDidMount() {

        console.log(this.props);
    }


    onClickPriceSetup = (e) => {
        e.stopPropagation()
        this.props.pirceSetup && this.props.pirceSetup(this.props.item.product)
    }

    onClickProduct = () => {
        this.props.onClickProduct && this.props.onClickProduct(this.props.item.product)
    }

    render() {

        return <div className='product'>
            <div className='card-item cursor-pointer' onClick={() => this.onClickProduct()}>
                <div className='product-item-container'>
                    <div className='product-item-container-left'>
                        <img className='product-img' src={this.props.item.images[0]?.url || getImageSource(ImageConstants.DEFAULT_PRODUCT)}></img>
                    </div>
                    <div className='product-item-container-right'>
                        <div>
                            <span className='card-title'>{this.props.item?.name}</span>
                        </div>
                        <div className=''>
                            <span className='d-inline-block price'><span>{this.props.item?.symbol}{this.props.item?.price}</span></span>
                        </div>
                    </div>
                </div>

                <div className='row mt-4'>
                    <div className='col text-left'>
                        <div className='category-title'>CATEGORY</div>
                        <span className='category-nmae mt-1 pr-2'>{Utils.ellipsis(this.props.item?.category, 30)}</span>
                    </div>

                    <div className='col text-right'>
                        <div className='category-title'>QUANTITY</div>
                        <span className='d-inline-block product-qty'>{this.props.item?.quantity}{this.props.item?.unit}</span>
                    </div>
                </div>

                {/* <div className='center pt-3 price-setup-button-wrapper'>
                    <button className='button price-setup-button' onClick={(e) => this.onClickPriceSetup(e)}>Advance Price setup</button>
                </div> */}
            </div>
        </div>
    }
}

export default ProductItemCard;