import React from 'react';
import "./otp-input.scss";

class OtpInput extends React.Component {
    state = {
        otp: []
    }

    componentDidMount() {
    }

    componentDidUpdate() {
        if (this.props.resetOtp && this.state.otp?.length) {
            this.setState({ otp: [] })
        }
    }

    onChangeHandler(event, key) {
        let otp = this.state.otp
        otp[key] = event?.target?.value
        this.setState({ otp })
        otp = otp.filter(i => i != "")
        this.props.onChange(otp)
    }

    onKeyUpEvent(index, event) {
        const eventOtp = event.which || event.keyCode;
        if (this.getOtpBoxElement(index).value.length === 1) {
            if (index !== 6) {
                this.getOtpBoxElement(index + 1).focus()
                this.getOtpBoxElement(index + 1).select();
            } else {
                this.getOtpBoxElement(index).blur();
            }
        }
        if (eventOtp === 8 && index !== 1) {
            this.getOtpBoxElement(index - 1).focus()
            this.getOtpBoxElement(index - 1).select();
        }
    }

    onFocusEvent(index) {
        if (this.state.isLoginFailed) {
            this.setState({ isLoginFailed: false })
        }
        for (let item = 1; item < index; item++) {
            const currentElement = this.getOtpBoxElement(item);
            if (!currentElement.value) {
                currentElement.focus();
                currentElement.select()
                break;
            }
        }
    }

    getOtpBoxElement(index) {
        return document.getElementById('otpBox' + index);
    }


    render() {
        if (this.props.resetOtp) return <div className="min-width-100">&nbsp; </div>

        return (
            <div className="otp-input-wrapper">
                <input id="otpBox1" key="1" className={`otp-input mr-2 ${this.state.otp[0] ? "input-with-value" : ""}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(1, event)} onFocus={() => this.onFocusEvent(1)} onChange={(e) => this.onChangeHandler(e, 0)} value={this.state.otp[0] || ""} />
                <input id="otpBox2" key="2" className={`otp-input mr-2 ${this.state.otp[1] ? "input-with-value" : ""}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(2, event)} onFocus={() => this.onFocusEvent(2)} onChange={(e) => this.onChangeHandler(e, 1)} value={this.state.otp[1] || ""} />
                <input id="otpBox3" key="3" className={`otp-input mr-2 ${this.state.otp[2] ? "input-with-value" : ""}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(3, event)} onFocus={() => this.onFocusEvent(3)} onChange={(e) => this.onChangeHandler(e, 2)} value={this.state.otp[2] || ""} />
                <input id="otpBox4" key="4" className={`otp-input mr-2 ${this.state.otp[3] ? "input-with-value" : ""}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(4, event)} onFocus={() => this.onFocusEvent(4)} onChange={(e) => this.onChangeHandler(e, 3)} value={this.state.otp[3] || ""} />
                <input id="otpBox5" key="5" className={`otp-input mr-2 ${this.state.otp[4] ? "input-with-value" : ""}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(5, event)} onFocus={() => this.onFocusEvent(5)} onChange={(e) => this.onChangeHandler(e, 4)} value={this.state.otp[4] || ""} />
                <input id="otpBox6" key="6" className={`otp-input mr-2 ${this.state.otp[5] ? "input-with-value" : ""}`} type="text" maxlength="1" onKeyUpCapture={(event) => this.onKeyUpEvent(6, event)} onFocus={() => this.onFocusEvent(6)} onChange={(e) => this.onChangeHandler(e, 5)} value={this.state.otp[5] || ""} />
            </div>
        );
    }

}

export default OtpInput;