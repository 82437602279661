
import React from 'react';
import "./top-sales.scss";

import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../../utility/image-source';

import StaticText from '../../../shared-store/static-text-store';
import SharedStore from '../../../shared-store/shared-store';
import BranchApi from '../../../apis/branch';
import Utils from '../../..//utility//common-utilities';

import AppLoader from '../../common/app-loader/app-loader';
import ReportApi from '../../../apis/report';

import SessionCache from '../../routes/session-cache';
import ActionConstant from '../../../constants/action-constant';
import Path from '../../routes/routes-path';
import CommonUtils from '../../..//utility//common-utilities';

class TopCustomers extends React.Component {
    state = {
        staticText: {},
        sales: [],
        activeStatus: "SALES"
    }

    user = {}
    accessRights = {}
    componentDidMount() {

        this.user = SessionCache.getUser()
        this.accessRights = this.user.accessRights || {}

        let staticText = StaticText.getStaticText("topSales")
        this.setState({ staticText })
        this.getReport()
    }


    getReport = (payload = {}) => {
        payload.type = this.state.activeStatus
        payload.range = this.props.type

        this.setState({ isLoading: true })
        ReportApi.getTopProduct(payload).then((response) => {
            let data = response
            this.setState({ sales: data || {}, isLoading: false })
        }).catch((err) => {
            this.setState({ isLoading: false, sales: [] })
        })
    }


    renderNameBadge(name) {
        let splitName = name.split(" ")
        let tag = ""
        if (splitName.length > 1) {
            tag = `${splitName[0][0] || ""}${splitName[1][0] || ""}`
        } else if (splitName) {
            tag = `${splitName[0][0]}`
        }
        tag = tag ? tag.toUpperCase() : ""
        return (<div className="d-inline-block name-badge p-3 "><span className="">{tag || ""}</span></div>)

    }

    onStatusUpdate(item, status) {
        let payload = {
            invitationId: item._id,
            status
        }
        BranchApi.updateInvite(payload).then((response) => {
            this.getInviataions()
        }).catch((err) => {
        })
    }


    statusToggle(activeStatus) {
        this.setState({ activeStatus }, () => {
            this.getReport()
        })
    }

    onClickAdd = () => {
        this.props.history.push(Path.PRODUCT)
    }

    render() {
        return (
            <div className="top-sales">
                <div className="customers-card card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col">
                                <div className="font-16">{this.state.staticText?.title}</div>
                            </div>
                            {/* <div className="col text-right">
                                <div className="d-inline-block">
                                    <div className='status-switch cursor-pointer row no-gutters '>
                                        <div className={`col status-switch-toggle ${this.state.activeStatus == 'SALES' ? 'status-switch-toggle-active' : ""}`} onClick={() => this.statusToggle("SALES")}>{this.state.staticText?.salesText}</div>
                                        <div className={`col status-switch-toggle ${this.state.activeStatus == 'UNIT' ? 'status-switch-toggle-active' : ""}`} onClick={() => this.statusToggle("UNIT")}>{this.state.staticText?.unitText}</div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    {
                        this.state.sales?.length ?
                            <div className="invitation-list">
                                <div id="table-scoll" className="table-responsive">
                                    <table className="table mb-0 align-items-center  bg-white">
                                        <thead>
                                            <tr>
                                                <th className='border-top-none text-center slno-max-width'>#</th>
                                                <th className='border-top-none name-max-width'>Product</th>
                                                <th className='border-top-none role-max-width text-right'>{this.state.activeStatus == "SALES" ? "Total Price" : "Unit"}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.sales.map((item, index) => (

                                                    <tr key={item._id} className="customer-row">
                                                        <td className="semi-bold-text text-center">{index + 1}</td>
                                                        <td className="semi-bold-text branch-name">{item.name}</td>
                                                        <td className="semi-bold-text text-right">{this.state.activeStatus == "SALES" ? <>{item?.currency?.symbol} {item.totalPrice ? Utils.formatAmountStringStrict(item.totalPrice) : ""} </> : item.count}</td>

                                                    </tr>
                                                ))

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            :
                            <div className="no-sales card">
                                {
                                    this.state.isLoading ? <AppLoader size="2x" />
                                        : <>
                                            <img className="" alt="" src={getImageSource(ImageConstants.NO_PRODUCT)} ></img>
                                            <div>
                                                <div className="pt-4 bold-text font-16">{this.state.staticText?.noRecordText}</div>
                                                {/* <div className="no-sales-description">{this.state.staticText?.noRecordDescription || ""}</div> */}
                                                {/* <div className="pt-4">
                                                    {
                                                        CommonUtils.checkRights(ActionConstant.ADD_CUSTOMER) ?
                                                            <button className="app-button-primary-small" onClick={this.onClickAdd}>
                                                                <span className="">{this.state.staticText?.addButtonText}</span>
                                                            </button>
                                                            : ""
                                                    }
                                                </div> */}
                                            </div>
                                        </>
                                }
                            </div>
                    }
                </div>

            </div>
        );
    }

}

export default TopCustomers;