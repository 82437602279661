import React from 'react';
import "./create-staff.scss";
import BranchApi from '../../../apis/branch'
import Dropdown from '../../common/dropdown/dropdown'
import { ImageConstants } from '../../../constants/constants';
import { getImageSource } from '../../../utility/image-source'
import RolesApi from '../../../apis/role'

import Constants from '../../../constants/constants';
import Utils from '../../../utility/common-utilities';
import StaticText from '../../../shared-store/static-text-store';

class CreateBranch extends React.Component {

    constructor(props) {
        super(props)

        this.getBranches()

        if (this.props.branchId)
            this.state.selectedBranch = this.props.branchId;
    }

    state = {
        activeStep: "STEP1",
        staff: [],
        branchDropdown: [],
        selectedBranch: "",
        rolesDropdown: [],
        countryCode: "+1",
        selectedRoles: []
    }

    componentDidMount() {

        let staticText = StaticText.getStaticText("staffs")
        this.setState({ staticText })
        this.roleDetail()
    }
    roleDetail() {
        let payload = {
            isShow: "true"
        };

        RolesApi.getRoles(payload).then((response) => {
            if (response && response.response) {
                let items = []
                for (let i = 0; i < response.response?.length; i++) {
                    let role = response.response[i]
                    items.push({ title: role.displayTitle, value: role._id })
                }
                this.setState({ rolesDropdown: items, reloadRoleDropdown: true }, () => {
                    this.setState({ reloadRoleDropdown: false })
                })
            }
        }).catch((err) => {
            alert(err);
        })
    }

    onClickCreate = () => {

        if (!this.state.staff.length || this.state.isAddAnother) {
            this.addStaff(() => {
                this.setState({ activeStep: "STEP3" })
            })
        } else
            this.props.onPressCreate && this.props.onPressCreate()
    }

    getRoles = () => {
        let roles = this.state.selectedRoles.map(item => { return { role: item.title, id: item.value } })
        return roles
    }

    validate = (payload) => {

        if (!payload.name)
            return false

        if (!payload.username)
            return false

        if (!payload.mobileNumber)
            return false

        if (!payload.password)
            return false

        if (!payload.email || !Utils.validateEmail(payload.email))
            return false

        if (!payload.roles || !payload.roles.length)
            return false

        return true
    }

    addStaff = (callback) => {

        this.setState({ isSubmit: true })

        let payload = {
            name: this.state.name,
            username: this.state.username,
            mobileNumber: this.state.mobileNumber,
            password: this.state.password,
            countryCode: this.state.countryCode,
            email: this.state.email,
            roles: this.getRoles()
        };

        let isValid = this.validate(payload)

        if (!isValid)
            return

        BranchApi.createStaff(payload).then((response) => {

            let staff = this.state.staff;

            let staffPayload = {
                name: this.state.name,
                username: this.state.username,
                role: this.state.role
            }
            staff.push(staffPayload)

            this.reset()
            this.setState({ staff }, () => {
                callback && callback()
            })
        }).catch((err) => {
            alert(err);
        })


    }

    getBranches = () => {

        let payload = { limit: 100 };

        BranchApi.getBranches(payload).then((response) => {

            if (response && response.response)
                this.buildBranchDropdown(response.response)
        }).catch((err) => {
            alert(err);
        })
    }

    reset = () => {
        this.setState({
            name: "",
            username: "",
            mobileNumber: "",
            selectedRoles: [],
            password: "",
            isAddAnother: false,
            isSubmit: false
        })
    }

    buildBranchDropdown = (items) => {

        let branchDropdown = items.map(item => { return { title: item.businessName, value: item._id } })

        this.setState({ branchDropdown })
    }


    onPressAddAnother = () => {
        this.setState({ activeStep: "STEP2", isAddAnother: true })
    }

    onClickSkip = () => {
        this.props.onPressCreate && this.props.onPressCreate()
    }


    onChangeHandler(event, key, max) {
        let value = event?.target?.value || ""
        max = max || Constants.INPUT_MAX_LENGTH_1
        if (max < value.length) return
        this.setState({ [key]: value })
    }


    onClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }


    renderStaffList = () => {

        let elements = []

        for (let i = 0; i < this.state.staff.length; i++) {

            let staff = this.state.staff[i];

            elements.push(<div className='staff-list-card'>
                <div className='row'>
                    <div className='col-2'>
                        <div className='sl-no'>{i + 1}.</div>
                    </div>
                    <div className='col-10'>
                        <div className='staff-name-spacing'><span className='staff-name'>{staff.name}</span> <span className='pl-2 staff-role-text'>{staff.role}</span></div>
                        <div className='staff-username'>{staff.username}</div>
                    </div>
                </div>
            </div>)
        }

        return (<div className='add-branch-step2'>
            <div className='branch-add-title'>
                Add employees
            </div>

            <div className='input-container input-container-staff-list'>

                <div className='staff-list-title'>
                    MY EMPLOYEE LIST
                </div>

                <div className="pt-3">
                    {elements}
                </div>

                <div>
                    <span className='add-another-text cursor-pointer' onClick={() => this.onPressAddAnother()}>+ Add another employee</span>
                </div>
            </div>

            <div className='button-wrapper-staff mb-4'>
                {!elements?.length ? <div className='app-button-default' onClick={this.onClickSkip}>Skip</div> : ""}
                <div className='app-button-primary' onClick={this.onClickCreate}>{this.state.staff.length ? 'Continue' : 'Add Employee'}</div>
            </div>
        </div>)
    }

    renderCreateStaff = () => {

        return (
            <div className='add-branch-step2'>
                <div className='branch-add-title'>
                    Add employees
                </div>

                <div className='input-container input-container-staff'>

                    <div className="row">
                        <div className="col">
                            <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.name ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>Employee Name <span className='star'>*</span></div>
                                <input className={`app-input`} placeholder='Enter employee name'
                                    value={this.state.name}
                                    onChange={(e) => this.onChangeHandler(e, 'name')} />
                            </div>
                        </div>

                        <div className="col">
                            <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.username ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>Employee Code / User name <span className='star'>*</span></div>
                                <input className={`app-input`} placeholder='Enter employee code / user name'
                                    value={this.state.username}
                                    onChange={(e) => this.onChangeHandler(e, 'username')} />
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col">
                            <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.mobileNumber ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>Phone number <span className='star'>*</span></div>
                                <div className='app-input row p-1 mobile-number-input-wrapper'>
                                    <input placeholder='' className='country-code'
                                        value={this.state.countryCode}
                                        onChange={(e) => this.onChangeHandler(e, 'countryCode', Constants.INPUT_MAX_LENGTH_3)} />

                                    <input className='mobile-number-input' type={'number'} placeholder='Enter Mobile number'
                                        value={this.state.mobileNumber}
                                        onChange={(e) => this.onChangeHandler(e, 'mobileNumber', Constants.INPUT_MAX_LENGTH_2)} />
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.selectedRoles?.length ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>Role Assigned <span className='star'>*</span></div>
                                <Dropdown
                                    placeholder="Select Roles"
                                    options={this.state.rolesDropdown}
                                    text={"title"}
                                    multiSelect
                                    onSelect={(item) => { this.setState({ selectedRoles: item }) }}
                                    invalid={this.state.isSubmit && !this.state.selectedRoles?.length}
                                ></Dropdown>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className={`bw-spaces-input ${this.state.isSubmit && (!this.state.email || !this.state.email.trim() || !Utils.validateEmail(this.state.email)) ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>{this.state?.staticText?.emailText}<span id="email-tool-tip" className='star'>*</span></div>
                                <input className={`app-input`} placeholder={this.state?.staticText?.emailPlaceHolder}
                                    value={this.state.email}
                                    onChange={(e) => this.onChangeHandler(e, 'email')} />

                                {/* <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isSubmit && (!this.state.email || !this.state.email.trim() || !Utils.validateEmail(this.state.email))} target="email-tool-tip">
                                    {this.state.staticText?.emailErrorMessage}
                                </Tooltip> */}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.password ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>Password <span className='star'>*</span></div>
                                <input id="login-password" className={`app-input pr-5`} placeholder='Enter Password'
                                    value={this.state.password}
                                    type={`${this.state.showPassword ? '' : 'password'}`}
                                    onChange={(e) => this.onChangeHandler(e, 'password')} />

                                <span className="password-icon">
                                    <img className="cursor-pointer icon-20" alt=""
                                        src={getImageSource(this.state.showPassword ? ImageConstants.EYE_OPEN : ImageConstants.EYE_CLOSE)}
                                        onClick={this.onClickShowPassword}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='button-wrapper-staff'>
                    <div className='app-button-default' onClick={this.onClickSkip}>Skip</div>
                    <div className='app-button-primary' onClick={this.onClickCreate}>{this.state.staff.length ? 'Continue' : 'Add Employee'}</div>
                </div>
            </div>
        )
    }

    onPressStaffAdd = () => {
        this.setState({ activeStep: "STEP2" })
    }

    render() {

        return (
            <div className="create-branch">
                {this.state.activeStep == "STEP1" ? <div className='add-branch'>
                    <div>
                        <img className='staff-image' src={getImageSource(ImageConstants.NO_STAFF)}></img>
                    </div>

                    <div>
                        <span className='create-branch-title cursor-pointer' onClick={this.onPressStaffAdd}>+Add your Employees</span>
                    </div>

                    <div className='sub-text-branch pt-3'>
                        <ul>
                            <li className="pt-2"> Would you like to add your employees in the system at this time?.</li>
                            <li className="pt-2">This will give them access to the system to place/receive orders.You will be able to control what they can/can't do in the system. </li>
                            <li className="pt-2">If you skip this step now, you can do this later as well.</li>
                        </ul>
                    </div>

                    <div className='pt-4'>
                        <div className='app-button-primary' onClick={this.onPressStaffAdd}>+ Add your Employees</div>
                    </div>
                    <div className='pt-2'>
                        <div className='cancel' onClick={this.onClickSkip}>Skip</div>
                    </div>
                </div> : <></>}

                {this.state.activeStep == "STEP2" ? this.renderCreateStaff() : ""}

                {this.state.activeStep == "STEP3" ? this.renderStaffList() : ""}

            </div>
        );
    }

}

export default CreateBranch;