//New Module for Inventory INV000001
import React from 'react';
import "./inventory-product-mapping.scss";


class ItemInput extends React.Component {
   state = {
      isFocused: false
   }

   render() {
      const { onChangeInput } = this.props
      return <div onClick={() => this.inputRef.focus()} className={`item-input-container ${this.props.isFocused ? "item-focus" : ""}`}>
         <div>
            <input
               ref={(ref) => this.inputRef = ref}
               type="text"
               className="item-tax-input"
               onFocus={this.props.onFocus}
               onBlur={this.props.onBlur}
               onChange={(event) => onChangeInput(event?.target?.value)}
               placeholder={"Product Name"}
               value={`${this.props?.value || ""}`}
            />
         </div>
      </div>
   }
}

export default ItemInput