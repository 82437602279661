import React from 'react';
import "./signup.scss";
import AuthenticateAPI from '../../apis/authenticate'
import SessionCache from '../routes/session-cache';
import Path from '../routes/routes-path';
import ReCAPTCHA from "react-google-recaptcha";

import ImageConstants from "../../constants/image-constants"

import { getImageSource } from '../../utility/image-source';

import Utils from '../../utility/common-utilities';
import Header from '../common/header/header-component';
import OtpInput from '../common/otp-input/otp-input';
import Footer from '../common/footer/footer-component';
import ToasterComponent from '../common/toaster/toaster';

import StaticText from '../../shared-store/static-text-store';
import { Tooltip } from "reactstrap"
import Constants from '../../constants/constants';
import CommonUtils from '../../utility/common-utilities';

class Signup extends React.Component {
    state = {
        businessName: "",
        email: "",
        fullName: "",
        password: "",
        isOtpSend: false,
        isInvalidOtp: true,
        isComplete: false,
        staticText: {}
    }

    componentDidMount() {
        let staticText = StaticText.getStaticText("signup")
        this.setState({ staticText })
    }


    onChangeHandler(event, key, max) {
        let value = event?.target?.value || ""
        max = max || Constants.INPUT_MAX_LENGTH_1
        if (max < value.length) return
        this.setState({ [key]: value }, () => {
            this.validatePayload()
        })
    }


    showToaster(type, message) {
        this.setState({
            showToaster: true,
            toaster: {
                type,
                message,
            }
        }, () => {
            setTimeout(() => this.hideToaster(), 3000)
        })
    }

    hideToaster() {
        this.setState({ showToaster: false })
    }

    validatePayload(showError) {

        let isValidForm = true

        if (!this.state.businessName?.trim()) {
            showError && this.setState({ isInvalidBusiness: true })
            isValidForm = false
        } else this.setState({ isInvalidBusiness: false })

        if (!this.state.email) {
            showError && this.setState({ isInvalidEmail: true })
            isValidForm = false
        } else if (showError && !Utils.validateEmail(this.state.email)) {
            this.setState({ isInvalidEmail: true })
            isValidForm = false
        } else this.setState({ isInvalidEmail: false })

        if (!this.state.firstName?.trim()) {
            showError && this.setState({ isInvalidFirstName: true })
            isValidForm = false
        } else this.setState({ isInvalidFirstName: false })

        if (!this.state.lastName?.trim()) {
            showError && this.setState({ isInvalidLastName: true })
            isValidForm = false
        } else this.setState({ isInvalidLastName: false })

        if (!this.state.password) {
            showError && this.setState({ isInvalidPassword: true })
            isValidForm = false
        } else {
            if (!CommonUtils.validatePassword(this.state.password)) {
                showError && this.setState({ isInvalidPasswordLength: true })
                return false
            } else this.setState({ isInvalidPassword: false, isInvalidPasswordLength: false })
        }

        if (!this.state.isCaptchaVerified) {
            this.setState({ errMessage: "Captcha not Verified" })
            isValidForm = false
        }

        if (!this.state.isAcceptTerms) {
            this.setState({ errMessage: "Please accept Terms & Privacy policy" })
            isValidForm = false
        }

        this.setState({ isValidForm, errMessage: "" })
        return isValidForm
    }

    onClickSendOtp = () => {


        let payload = {
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            token: this.state.token
        };

        let validation = this.validatePayload(true)

        if (!validation) return

        AuthenticateAPI.sendSignupOtp(payload).then((response) => {
            this.setState({ isOtpSend: false, invalidOtp: false, otp: [] }, () => {
                this.setState({ isOtpSend: true })
            })
            this.showToaster("success", "OTP has been sent successfully.")

        }).catch((err) => {
            if (err.response?.data?.code === 11000) {
                this.showToaster("error", "User already exsists")
            } else {
                this.showToaster("error", "Oops!, Something went wrong")
            }
        })
    }

    onVerifyOtp = () => {

        if (this.state.isInvalidOtp) {
            this.setState({ invalidOtp: true })
            return
        }


        let payload = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            password: this.state.password,
            mobileNumber: this.state.mobileNumber,
            countryCode: "+91",
            businessName: this.state.businessName,
            address: {
                city: this.state.city,
                state: this.state.state,
                country: this.state.country
            },
            otp: this.state.otp?.join(""),
            isSearchable: true              /* pramod CHG000001 */
        };

        AuthenticateAPI.signup(payload).then((response) => {
            let userDetails = response.data
            SessionCache.setUser(userDetails)
            this.setState({ isComplete: true })
            // this.props.history.push(Path.BRANCH)
        }).catch((err) => {
            this.setState({ invalidOtp: true, otp: [], refreshOtp: true }, () => {
                this.setState({ refreshOtp: false })
            })
        })
    }

    onChangeCaptcha = (value) => {
        this.setState({ isCaptchaVerified: !!value, token: value }, () => {
            this.validatePayload()
        })

    }

    changeToLogin = () => {
        this.props.history.replace(Path.LOGIN)
    }

    onEnterOtp = (value) => {
        let isInvalidOtp = value?.length !== 6
        this.setState({ otp: value, isInvalidOtp })
    }

    onClickCancel = () => {
        this.setState({ isOtpSend: false })
    }

    onClickContinue = () => {
        this.props.history.replace(Path.TENANT_WORKFLOW)
        // this.props.history.replace(Path.BRANCH)
    }

    onClickTerms = () => {
        this.setState({ isAcceptTerms: !this.state.isAcceptTerms }, () => {
            this.validatePayload()
        })
    }

    onClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }

    renderOtpScreen() {

        return (
            <div className="singup-card p-4">
                <div>
                    <div>
                        <span className="lock-image"> <img className="icon-20" alt="" src={getImageSource(ImageConstants.LOCK)} /> </span>
                    </div>

                    <div className="sub-title d-inline-block pt-5">
                        {this.state.staticText.sentOtpMessage}
                    </div>

                    <div className="pt-4">
                        <div className='bw-spaces-input'>
                            <div className='otp-input-label'>
                                {this.state.staticText.otpTitle}
                            </div>
                            <div id="otp-tooltip" className={`${this.state.invalidOtp ? "invalid-otp" : ""}`}><OtpInput resetOtp={this.state.refreshOtp} key="otp" onChange={this.onEnterOtp} /></div>
                            {
                                !this.state.refreshOtp ? <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.invalidOtp} target="otp-tooltip">
                                    {this.state.staticText.invalidOtp}
                                </Tooltip> : ""
                            }
                        </div>
                    </div>

                    <div className="pt-4 text-center">
                        <button className={`app-button-primary w-100 ${this.state.isInvalidOtp ? "app-button-disabled" : ""}`} onClick={this.onVerifyOtp}>
                            {this.state.staticText.otpButtonText}
                        </button>
                    </div>

                    <div className="pt-4">
                        <div>
                            <span className="cancel" onClick={this.onClickCancel}>{this.state.staticText.cancelButtonText}</span>
                            <span className="resend float-right" onClick={this.onClickSendOtp}>{this.state.staticText.resendButtonText}</span>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    openTerms(path) {
        let url = `${window.location.origin}${path}`
        window.open(url)
    }

    renderSignupCard() {
        return (
            <div className="singup-card">

                <div className="text-center header-text pt-4">
                    {this.state.staticText?.signupHeaderTitle}
                </div>
                <div className='text-center mt-2 sub-header-text'>
                    {this.state.staticText.existingUserText} <span className='click-here bold-text' onClick={this.changeToLogin}>{this.state.staticText.signinHereText}</span>
                </div>
                <div className={`bw-spaces-input ${this.state.isInvalidBusiness ? "invalid-input-container" : ""}`}>
                    <div className='app-input-label'><span id="business-tooltip" >Business Name <span className='star'>*</span> </span></div>
                    <input className={`app-input`} placeholder='Enter Organisation name'
                        value={this.state.businessName}
                        onChange={(e) => this.onChangeHandler(e, 'businessName')} />
                    <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isInvalidBusiness} target="business-tooltip">
                        {this.state.staticText.invalidBusinessName}
                    </Tooltip>
                </div>

                <div className={`bw-spaces-input ${this.state.isInvalidEmail ? "invalid-input-container" : ""}`}>
                    <div className='app-input-label'><span id="email-tooltip" >Email <span className='star'>*</span></span></div>
                    <input className={`app-input`} placeholder='Enter Email address'
                        value={this.state.email}
                        onChange={(e) => this.onChangeHandler(e, 'email')} />
                    <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isInvalidEmail} target="email-tooltip">
                        {this.state.staticText.invalidEmail}
                    </Tooltip>
                </div>

                <div className={`bw-spaces-input ${this.state.isInvalidFirstName ? "invalid-input-container" : ""}`}>
                    <div className='app-input-label'><span id="firstname-tooltip" >First Name <span className='star'>*</span></span></div>
                    <input className={`app-input`} placeholder='Enter your first name'
                        value={this.state.firstName}
                        onChange={(e) => this.onChangeHandler(e, 'firstName')} />
                    <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isInvalidFirstName} target="firstname-tooltip">
                        {this.state.staticText.invalidFirstName}
                    </Tooltip>
                </div>

                <div className={`bw-spaces-input ${this.state.isInvalidLastName ? "invalid-input-container" : ""}`}>
                    <div className='app-input-label'><span id="lastname-tooltip" >Last Name <span className='star'>*</span></span></div>
                    <input className={`app-input`} placeholder='Enter your last name'
                        value={this.state.lastName}
                        onChange={(e) => this.onChangeHandler(e, 'lastName')} />
                    <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isInvalidLastName} target="lastname-tooltip">
                        {this.state.staticText.invalidLastName}
                    </Tooltip>
                </div>

                <div className={`position-relative bw-spaces-input ${this.state.isInvalidPassword || this.state.isInvalidPasswordLength ? "invalid-input-container" : ""}`}>
                    <div className='app-input-label'><span id="password-tooltip" >Password <span className='star'>*</span></span></div>
                    <input className={`app-input`} placeholder='Enter Password'
                        value={this.state.password}
                        type={this.state.showPassword ? "" : "password"}
                        onChange={(e) => this.onChangeHandler(e, 'password')} />
                    <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isInvalidPassword || this.state.isInvalidPasswordLength} target="password-tooltip">
                        {this.state.isInvalidPasswordLength ? this.state.staticText.invalidPasswordLength : this.state.staticText.invalidPassword}
                    </Tooltip>
                    <span className="password-icon">
                        <img className="cursor-pointer icon-20" alt=""
                            src={getImageSource(this.state.showPassword ? ImageConstants.EYE_OPEN : ImageConstants.EYE_CLOSE)}
                            onClick={this.onClickShowPassword}
                        />
                    </span>
                </div>

                <div className="pt-4 text-center">
                    <div className="d-inline-block">
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_ENV === 'production' ? Constants.CAPTCHA_KEY : Constants.DEV_CAPTCHA_KEY} /*CHG000001 */
                            onChange={this.onChangeCaptcha}
                        />
                    </div>
                </div>

                <div className="pt-4">
                    <span className="mr-2">
                        <img className="icon-15 cursor-pointer" alt="" src={getImageSource(this.state.isAcceptTerms ? ImageConstants.CHECKBOX_TICK : ImageConstants.CHECKBOX)} onClick={this.onClickTerms} />
                    </span>
                    <span>
                        <span className="tc cursor-pointer" onClick={() => this.openTerms(Path.SERVICES_AGREEMENT)}>Terms</span>
                        {" & "}
                        <span className="tc cursor-pointer" onClick={() => this.openTerms(Path.PRIVACY_POLICY)}>Privacy policy</span>
                    </span>
                </div>

                <div className="pt-4 text-center">
                    <button className={`app-button-primary w-100 ${!this.state.isValidForm ? "app-button-disabled" : ""}`} onClick={this.onClickSendOtp}>
                        {this.state.staticText.signupTitle}
                    </button>
                </div>

                {/* <div className='mt-3 text-center'>
                    <span className="tc cursor-pointer" onClick={() => this.props.history.push(Path.SERVICES_AGREEMENT)}>Terms</span>
                    {" & "}
                    <span className="tc cursor-pointer" onClick={() => this.props.history.push(Path.PRIVACY_POLICY)}>Privacy policy</span>
                </div> */}
            </div>
        )
    }


    render() {

        return (
            <>
                {/* <Header from="SIGNUP" /> */}
                <div className="">
                    <div className="signup">
                        {
                            !this.state.isComplete ?
                                <div className="signup-container">
                                    {!this.state.isOtpSend ? <div className="left-container">
                                        <div className='left-image'>
                                            <img className="signup-image" alt="" src={getImageSource(ImageConstants.LOGIN_BACKGROUND)} />
                                        </div>
                                    </div> : ""
                                    }
                                    <div className="right-container">
                                        {!this.state.isOtpSend ? this.renderSignupCard() : this.renderOtpScreen()}
                                    </div>

                                </div> :

                                <div className="row display-grid-center">
                                    <div className="col complete text-center">
                                        <div>
                                            <img className="icon-80 animate-image-1" alt="" src={getImageSource(ImageConstants.GREEN_TICK)} />
                                        </div>
                                        <div className="pt-4">
                                            <div>{this.state.staticText.congratulationsText} {this.state.firstName} !</div>
                                            <div>{this.state.staticText.successText}</div>
                                        </div>

                                        <div className="pt-4 text-center">
                                            <button className={`app-button-primary `} onClick={this.onClickContinue}>
                                                {this.state.staticText.continueButtonText}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                {/* <Footer /> */}
                {
                    this.state.showToaster ? <ToasterComponent type={this.state.toaster?.type} message={this.state.toaster?.message} /> : ""
                }
            </>
        );
    }

}

export default Signup;