
import React from 'react';
import "./invitations.scss";

import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../../utility/image-source';
import Utils from '../../../utility/common-utilities';
import StaticText from '../../../shared-store/static-text-store';
import SharedStore from '../../../shared-store/shared-store';
import CustomerApi from '../../../apis/customer';
import BranchApi from '../../../apis/branch';
import AppLoader from '../../common/app-loader/app-loader';

import SessionCache from '../../routes/session-cache';
import ActionConstant from '../../../constants/action-constant';
import Path from '../../routes/routes-path';
import CommonUtils from '../../../utility/common-utilities';
import MyNetwork from '../../../../layout/header/my-network/my-network';
import AcceptInvitation from '../../customers/accept-invitation/accept-invitation';

class Invitations extends React.Component {
    state = {
        staticText: {},
        invitaions: [
        ]
    }

    user = {}
    accessRights = {}
    componentDidMount() {

        this.user = SessionCache.getUser()
        this.accessRights = this.user.accessRights || {}

        let staticText = StaticText.getStaticText("invitation")
        this.setState({ staticText })
        this.getInviataions()
        document.addEventListener('mousedown', this.handleClickEvent, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }

    handleClickEvent = (event) => {
        if (this.containerRef && !this.containerRef.contains(event.target)) {
            if (!this.state.myNetworkAddPopUpVisible) this.closeMyNetWork()
        }
    }

    getInviataions = (payload = {}) => {
        payload.type = "RECEIVED"
        this.setState({ isLoading: true })
        CustomerApi.getInvitations(payload).then((response) => {
            this.setState({ invitaions: response.response || [], isLoading: false })
        }).catch((err) => {
            this.setState({ isLoading: false })
        })
    }

    renderNameBadge(name) {
        let splitName = name.split(" ")
        let tag = ""
        if (splitName.length > 1) {
            tag = `${splitName[0][0] || ""}${splitName[1][0] || ""}`
        } else if (splitName) {
            tag = `${splitName[0][0]}`
        }
        tag = tag ? tag.toUpperCase() : ""
        return (<div className="name-badge p-3 m-auto"><span className="">{tag || ""}</span></div>)

    }

    onStatusUpdate(item, status, acceptedAs) {

        // this.props.history.push(Path.VENDORS)
        let payload = {
            invitationId: item._id,
            status,
            acceptedAs
        }
        BranchApi.updateInvite(payload).then((response) => {
            this.getInviataions()

            if (status == "ACCEPTED" || status == "REJECTED") {
                let store = SharedStore.getStore("header")
                if (store.reduceInvitationBadgeCount) store.reduceInvitationBadgeCount(-1)
            }
            this.onCloseInvitation()
        }).catch((err) => {
        })
    }

    onClickAdd = () => {
        let store = SharedStore.getStore("header")
        if (store.onClickAdd) store.onClickAdd()
    }


    showMyNetworkPopUp = () => {
        this.setState({ showMyNetworkPopUp: true })
    }

    closeMyNetWork = () => {
        this.setState({ showMyNetworkPopUp: false })
    }

    onClickAccept(item, index) {

        this.setState({ selectedItem: item, acceptInvitationIndex: index })
    }

    onConfirmAccept = (type) => {
        this.onStatusUpdate(this.state.selectedItem, "ACCEPTED", type)
    }

    onCloseInvitation = () => {
        this.setState({ acceptInvitationIndex: -1, selectedItem: null })

    }

    render() {
        return (
            <div className="invitations-1">
                {
                    this.state.invitaions?.length ?
                        <div className="invitaions-card card">
                            <div className="card-header">
                                <div className="font-16">{this.state.staticText?.title}</div>
                                <div className="card-description">{this.state.invitaions?.length} {this.state.staticText?.requestText}</div>
                            </div>
                            <div className="invitation-list-1">
                                {
                                    this.state.invitaions.map((item, index) => (
                                        <div className={`row p-2 mt-2 no-gutters ${index === 0 ? "" : "border-top"}`}>
                                            <div className='col-2 my-auto text-center'>
                                                {this.renderNameBadge(item.businessName)}
                                            </div>
                                            <div className='col-5 pl-2'>
                                                <div className=''><span className='branch-name'>{item?.businessName}</span></div>
                                                {/* <div className='staff-username'>{item.email}</div> */}
                                                <div><span className='date'>{Utils.datePipe(item.createdDate, "MMM do, yyyy")}</span> <span className="invitation-status"></span></div>
                                            </div>

                                            <div className='col-5 text-right pr-2 my-auto'>
                                                {
                                                    <div>
                                                        {
                                                            CommonUtils.checkRights(ActionConstant.REJECT_INVITATION) ?
                                                                <span className='app-button-secondary-small mr-2'
                                                                    onClick={() => this.onStatusUpdate(item, "REJECTED")}>
                                                                    <span className='btn-text'>{this.state?.staticText?.rejectText}
                                                                    </span>
                                                                </span>
                                                                : ""
                                                        }

                                                        {
                                                            CommonUtils.checkRights(ActionConstant.ACCEPT_INVITATION) ?
                                                                <div className="d-inline-block">
                                                                    <span className='app-button-primary-small'
                                                                        onClick={() => this.onClickAccept(item, index)}>
                                                                        <span className='btn-text'>{this.state?.staticText?.acceptText}
                                                                        </span>
                                                                    </span>
                                                                    {this.state.acceptInvitationIndex === index ?
                                                                        <div className="accept-wrapper text-left">
                                                                            <AcceptInvitation name={item.businessName} close={this.onCloseInvitation} onAccept={this.onConfirmAccept} />
                                                                        </div>
                                                                        : ""}
                                                                </div> : ""
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    ))
                                }
                            </div>

                        </div> :
                        <div className="no-invitation card">
                            {
                                this.state.isLoading ? <AppLoader size="2x" /> :
                                    <>
                                        <img className="" alt="" src={getImageSource(ImageConstants.NO_INVITATIONS)} ></img>
                                        <div className="pt-4 semi-bold-text">{this.state.staticText?.noInvitationReceivedText}</div>
                                        <div className="pt-4">
                                            <div className="position-relative">
                                                {
                                                    CommonUtils.checkRights(ActionConstant.ADD_CUSTOMER) ?
                                                        <button className="app-button-primary-small" onClick={this.showMyNetworkPopUp}>
                                                            <span className="">{this.state.staticText?.addButtonText}</span>
                                                        </button>

                                                        : ""
                                                }
                                                <div className="pt-1 text-left">
                                                    {this.state.showMyNetworkPopUp ? <div ref={ref => this.containerRef = ref}><MyNetwork {...this.props} close={this.closeMyNetWork} onModalHandler={(value) => this.setState({ myNetworkAddPopUpVisible: value })} /></div> : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }

                        </div>
                }

            </div>
        );
    }

}

export default Invitations;