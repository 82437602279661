// INV000001 - New file for Inventory module

import HttpClient from './http-client';
import URL from './url';
import axios from "axios";



export default class inventoryApi {             //INV000001

    static getInventoryProducts(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.INVENTORY_PRODUCT + "?" + params.join('&') : URL.INVENTORY_PRODUCT;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getMappedInventoryProduct(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.INVENTORY_PRODUCT_MAPPING + "?" + params.join('&') : URL.INVENTORY_PRODUCT_MAPPING;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }


    static getProductsByCustomer(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${encodeURIComponent(payload[key])}`);
            });
        }

        let paramsList = params.length ? URL.INVENTORY_PRODUCTS_BY_CUSTOMER + "?" + params.join('&') : URL.INVENTORY_PRODUCTS_BY_CUSTOMER;

        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }


    static getFrequentlyOrderProducts(payload) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.FREQUENTLY_ORDER_PRODUCTS + "?" + params.join('&') : URL.FREQUENTLY_ORDER_PRODUCTS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getCustomerProductPrice(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.CUSTOMER_PRODUCTS_PRICE + "?" + params.join('&') : URL.CUSTOMER_PRODUCTS_PRICE;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getCustomerByProducts(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.PRICE_BY_CUSTOMER_PRODUCT + "?" + params.join('&') : URL.PRICE_BY_CUSTOMER_PRODUCT;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }


    static getCategories(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.CATEGORIES + "?" + params.join('&') : URL.CATEGORIES;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getInvCategoriesAndBrand(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.INV_CATEGORIES + "?" + params.join('&') : URL.INV_CATEGORIES;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getBrands(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.BRANDS + "?" + params.join('&') : URL.BRANDS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static uploadFileAwsS3(signedUrl, file, progressCallback) {
        let options = {
            headers: {
                "Content-Type": file.type
            },
            onUploadProgress: progressCallback
        };
        return axios.put(signedUrl, file, options);
    }

    static getSignedUrl(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.SIGNED_URL + "?" + params.join('&') : URL.SIGNED_URL;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getVendors(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.VENDOR + "?" + params.join('&') : URL.VENDOR;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static exportInvProducts(payload) {
        return HttpClient.post(URL.BASE_URL + URL.EXPORT_INV_PRODUCTS, null, payload);
    }

    static bulkInactive(payload) {
        return HttpClient.put(URL.BASE_URL + URL.BULK_INACTIVE_PRODUCT, null, payload);
    }

    static createProductInventory(payload) {
        return HttpClient.post(URL.BASE_URL + URL.INVENTORY_PRODUCT, null, payload);
    }

    static updateProductInventory(payload) {
        return HttpClient.put(URL.BASE_URL + URL.INVENTORY_PRODUCT, null, payload);
    }

    static createMappedInventoryProduct(payload) {
        return HttpClient.post(URL.BASE_URL + URL.INVENTORY_PRODUCT_MAPPING, null, payload);
    }

    static updateMappedInventoryProduct(payload) {
        return HttpClient.put(URL.BASE_URL + URL.INVENTORY_PRODUCT_MAPPING, null, payload);
    }

    static deleteMappedInventoryProduct(payload) {
        return HttpClient.delete(URL.BASE_URL + URL.INVENTORY_PRODUCT_MAPPING, null, payload);
    }

    static bulkUploadProduct(payload) {
        return HttpClient.post(URL.BASE_URL + URL.BULK_UPLOAD_INVENTORY_PRODUCT, null, payload);
    }


}
