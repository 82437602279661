import React from 'react';
import "./order-detail.scss";
import orderApi from '../../../../apis/order'
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import SessionCache from '../../../routes/session-cache';

import ImageConstants from '../../../../constants/image-constants';
import { getImageSource } from '../../../../utility/image-source'
import Constants from '../../../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import AppConfirmModal from '../../../common/app-confirm/app-confirm'
import Utils from '../../../../utility/common-utilities'
import Loading from '../../../common/loading/loading';
import StaticText from '../../../../shared-store/static-text-store';
import ActionConstant from '../../../../constants/action-constant';
import CommonUtils from '../../../../utility/common-utilities';

class OrderDetail extends React.Component {
    state = {
        order: this.props.order,
        staticText: {}

    }
    accessRights = {}
    componentDidMount() {

        let user = SessionCache.getUser()
        this.accessRights = user?.accessRights || {}
        let staticText = StaticText.getStaticText("orderDetail")
        this.setState({ staticText })

        let payload = {
            orderId: this.props.order._id
        }

        this.getOrders(payload)
    }

    getOrders(payload = {}) {
        payload.orderId = this.props.order._id
        this.setState({ isLoading: true })
        orderApi.getOrders(payload).then((response) => {
            this.prepareOrders(response?.response[0])
            this.setState({ isLoading: false })

        }).catch((err) => {
            this.setState({ isLoading: false })

        })
    }

    prepareOrders(element) {
        if (element.status == Constants.ORDER_STATUS.NEW) element.deliveryDate = new Date()
        element.items.forEach(item => {
            if (element.orderTotal) element.orderTotal += +item.orderedTotalPrice
            else element.orderTotal = +item.orderedTotalPrice
            if (!element.currency) element.currency = item.currency
            item.deliverableQuantity = (item.orderedQuantity - (item.deliveredQuantity || 0)) || ""
        });

        this.setState({ order: element })
    }

    updateOrder(payload) {
        payload.orderId = this.state.order?._id
        orderApi.updateOrder(payload).then((response) => {
            this.getOrders()
        }).catch((err) => {
        })
    }

    hideModal = () => {
        this.setState({ showOrderDetail: false })
    }

    getImageByStatus() {
        let status = this.state?.order?.status
        if (status == Constants.ORDER_STATUS.REJECTED || status == Constants.ORDER_STATUS.PARTIAL_CLOSED || status == Constants.ORDER_STATUS.CLOSED_BY_VENDOR || status == Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER) return ImageConstants.CUBE_RED
        if (status == Constants.ORDER_STATUS.COMPLETED) return ImageConstants.CUBE_GREEN

        return ImageConstants.CUBE_WHITE
    }

    getClassNameByStatus(status) {
        if (status === Constants.ORDER_STATUS.COMPLETED || status === Constants.ORDER_STATUS.REJECTED) return "tr-complete"
        return ""
    }

    getStatusBackgoundStyle() {
        let status = this.state?.order?.status
        if (status == Constants.ORDER_STATUS.NEW) return "status-new"
        if (status == Constants.ORDER_STATUS.PENDING) return "status-pending"
        if (status == Constants.ORDER_STATUS.PARTIAL) return "status-partial"
        if (status == Constants.ORDER_STATUS.COMPLETED) return "status-complete"
        if (status == Constants.ORDER_STATUS.REJECTED || status == Constants.ORDER_STATUS.PARTIAL_CLOSED || status == Constants.ORDER_STATUS.CLOSED_BY_VENDOR || status == Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER || status == Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER) return "status-reject"
        if (status == Constants.ORDER_STATUS.PAST_DUE) return "status-due"
        return ""
    }


    getNoteBackgoundStyle() {
        let status = this.state?.order?.status
        if (status === Constants.ORDER_STATUS.NEW) return "note-new"
        if (status === Constants.ORDER_STATUS.PENDING) return "note-pending"
        if (status === Constants.ORDER_STATUS.COMPLETED) return "note-complete"
        if (status === Constants.ORDER_STATUS.PAST_DUE) return "note-due"
        if (status == Constants.ORDER_STATUS.CLOSED_BY_VENDOR || status == Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER || status == Constants.ORDER_STATUS.REJECTED || status == Constants.ORDER_STATUS.PARTIAL_CLOSED) return "note-close"
        return ""
    }

    checkInputDisable(item) {
        let status = this.state.order?.status
        let deliverableQuantity = item.orderedQuantity - item.deliveredQuantity
        return status === Constants.ORDER_STATUS.NEW || Utils.checkClosedStatus(status) || deliverableQuantity == 0
    }

    onChangeQuantity(event, item, index) {
        let order = this.state.order

        let value = +event?.target?.value
        if (!value && value != "") return

        let maxQuantityAllow = item.orderedQuantity - item.deliveredQuantity

        if (value > maxQuantityAllow) item.deliverableQuantity = maxQuantityAllow
        else item.deliverableQuantity = value

        order.items[index] = item
        this.setState({ order })
    }


    handleClose = () => {
        this.setState({ showRejectConfirm: false, showAcceptModal: false })
    }

    getDeliverdStatus(item) {
        if (this.state.order.status == Constants.ORDER_STATUS.PENDING || !item.orderedQuantity) return ""
        if (item.orderedQuantity == item.deliveredQuantity) return "Shipped"
        if (!item.deliveredQuantity) return "Pending"
        if (item.orderedQuantity > item.deliveredQuantity) return `Partially Shipped`
    }

    getItemStatus(item) {
        if (this.state.order.status == Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER) return "na"
        if (Utils.checkClosedStatus(this.state.order.status) && (item.orderedQuantity - (+item.deliveredQuantity || 0))) return "not-delivered"
        if (this.state.order.status == Constants.ORDER_STATUS.PENDING) return "na"
        if (item.orderedQuantity == item.deliveredQuantity) return "delivered-item"
        if (!item.deliveredQuantity) return "pending-item"
        if (item.orderedQuantity > item.deliveredQuantity) return `partially-delivered-item`
    }

    showRejectConfirm = () => {
        this.setState({ showRejectConfirm: true })
    }

    handleClose = () => {
        this.setState({ showRejectConfirm: false, showAcceptModal: false })
    }

    onReject = (reason) => {
        let payload = {
            orderId: this.state.order._id,
            status: Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER,
            reason
        }
        this.updateOrder(payload)
        this.handleClose()
    }

    showAcceptModal() {
        return (

            <Modal className="app-confirm-custom-modal" isOpen={true} toggle={this.handleClose}>
                <ModalBody className="" >
                    <div>
                        <img className="icon-20 close-icn cursor-pointer" alt="" src={getImageSource(ImageConstants.CLOSE_GRAY)} onClick={this.handleClose} />
                    </div>
                    <div className="app-confirm mt-5">
                        <div className="content-wrapper py-4">
                            <div className="text-center">
                                <img className="abs-img icon-50 animate-image-1" alt="" src={getImageSource(ImageConstants.CALENDAR_WHITE)} />
                            </div>
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <div className="row m-0 cursor-pointer">
                        <div className="col text-center border-right p-3" onClick={this.handleClose}>
                            No
                        </div>
                        <div className={`col text-center p-3 reject-text`} onClick={this.onConfirmReject}>
                            Cancel Order
                        </div>
                    </div>

                </ModalFooter>

            </Modal>
        )
    }



    getActionButtons() {

        if (!CommonUtils.checkRights(ActionConstant.UPDATE_ORDER)) return ""

        if (this.state.order.status === Constants.ORDER_STATUS.NEW) {
            return (
                <div className="text-right">
                    <button className="app-button-red mr-2" onClick={this.showRejectConfirm}>
                        <FontAwesomeIcon icon={faTimes} />
                        <span className="pl-2">{this.state.staticText?.cencelOrderText}</span>
                    </button>

                </div>
            )
        }

    }


    renderPriceDetail() {
        return (
            <div>
                <div className="price-detail card">
                    <div className="text-center price-summary">{this.state.staticText?.priceSummary}</div>
                    <div className="py-4">
                        <div className="sub-text"> <span>{this.state.staticText?.priceText}</span> <span className="float-right">{this.state.order?.currency?.symbol} {Utils.formatAmountStringStrict(+this.state.order?.totalPrice)}</span></div>
                    </div>
                    <div className="grand-total pt-2">
                        <div>
                            <span className="semi-bold-text">{this.state.staticText?.grandTotalText}</span>
                            <span className="total-amount float-right">{this.state.order?.currency?.symbol} {Utils.formatAmountStringStrict(+this.state.order?.totalPrice)}</span>
                        </div>
                    </div>
                </div>
                {
                    this.state.order?.status != Constants.ORDER_STATUS.NEW && this.state.order?.status != Constants.ORDER_STATUS.REJECTED && this.state.order?.status != Constants.ORDER_STATUS.CLOSED_BY_VENDOR && this.state.order?.status != Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER ?
                        <div className="delivery-date">
                            <div className="delivery-date-title">{this.state.order?.status == Constants.ORDER_STATUS.COMPLETED ? this.state.staticText?.deliveredOnText : this.state.staticText?.deliveryDateText}</div>
                            <div className="date" key="edit-date-1" >
                                {Utils.datePipe(this.state.order?.deliveryDate, Constants.DATE_FORMAT_1)}
                            </div>
                        </div>
                        : ""
                }
            </div >
        )

    }

    renderOrderDetail() {
        let status = this.state.order?.status
        let address = this.state.order?.vendorBranch?.address
        return (
            <div>
                <div className="order-detail-card card">
                    <div className="order-status-wrapper">
                        <div className="row d-flex justify-content-center">
                            <div className={`col ${status === Constants.ORDER_STATUS.COMPLETED ? "pr-0" : "pr-0"}`}>
                                <div className={`order-status py-2 ${this.getStatusBackgoundStyle()}`}>
                                    <div className="mr-2 pl-4">
                                        <img className="icon-20" alt="" src={getImageSource(this.getImageByStatus())} />
                                    </div>
                                    <div>
                                        <div className="status">{Constants.ORDER_STATUS_TEXT[this.state?.order?.status || "-"]}</div>
                                        <div className="date">Order Created Date: {Utils.datePipe(this.state?.order?.createdDate, Constants.DATE_FORMAT_1)}</div>
                                    </div>
                                </div>
                            </div>
                            {
                                !Utils.checkClosedStatus(this.state?.order?.status) || this.state?.order?.status === Constants.ORDER_STATUS.COMPLETED ?
                                    <div className={`col-8 pl-0`}>
                                        <div className={`order-note py-2  ${this.getNoteBackgoundStyle()}`}>
                                            <div className="note pl-4">Note:</div>
                                            <div className="note-sub-text px-2">{this.state?.order?.note || "NA"}</div>
                                        </div>
                                    </div> : ""
                            }
                            {
                                status == Constants.ORDER_STATUS.CLOSED_BY_VENDOR || status == Constants.ORDER_STATUS.REJECTED || status == Constants.ORDER_STATUS.PARTIAL_CLOSED || status == Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER ?
                                    <div className={`col-8 pl-0`}>
                                        <div className={`order-note py-2  ${this.getNoteBackgoundStyle()}`}>
                                            <div className="note pl-4">Reason: </div>
                                            <div className="note-sub-text px-2">{this.state?.order?.reason || "-"}</div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>

                    <div className="ordered-product pt-4">
                        <div className="d-flex align-items-center "><span className="semi-bold-text mr-2 item"> Items </span><span className="count-badge px-2 py-1">{this.state.order?.items?.length < 10 ? "0" : ""}{this.state.order?.items?.length || 0}</span></div>
                        <div id="table-scoll" className="table-responsive pt-2">
                            <table className="table mb-0 align-items-center bg-white">
                                <thead>
                                    <tr>
                                        <th className='icon-40'></th>
                                        <th className='th-product-max-width'>Item</th>
                                        <th className='th-max-width text-right'>Brand</th>
                                        <th className='th-max-width text-right'>Order Qty</th>
                                        <th className='th-max-width text-right'>Unit Price</th>
                                        <th className='th-max-width text-right'>Shipped Qty</th>
                                        {this.state.order?.status != Constants.ORDER_STATUS?.NEW ? <th className='th-max-width text-center'>Status</th> : ""}

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.order?.items?.length ? this.state.order?.items.map((item, index) => (

                                            <tr key={item.code} >
                                                <td className=""><img alt="" className="icon-40" src={item?.images ? item?.images[0]?.url || getImageSource(ImageConstants.DEFAULT_PRODUCT) : getImageSource(ImageConstants.DEFAULT_PRODUCT)} /></td>
                                                <td className="cursor-pointer">
                                                    <div className={`bold-text `}>{item.name}</div>
                                                    <div className="sub-text"> {item.code}</div>
                                                    <div className="sub-text"> Qty: {item.sellQuantity?.value}</div>
                                                </td>
                                                <td className="cursor-pointer text-right semi-bold-text"> {item?.brand?.value}</td>
                                                <td className="cursor-pointer text-right semi-bold-text"> {this.state.order?.status != Constants.ORDER_STATUS?.NEW && this.state.order?.status != Constants.ORDER_STATUS?.CLOSED_BY_CUSTOMER ? item.orderedQuantity : item.customerOrderedQuantity}</td>
                                                <td className="cursor-pointer bold-text text-right"> {item?.currency?.symbol} {Utils.formatAmountStringStrict(+item?.price)} </td>
                                                <td className="cursor-pointer text-right position-relative">{item.deliveredQuantity || 0} </td>

                                                {this.state.order?.status != Constants.ORDER_STATUS?.NEW ? <td className={`item-status text-center ${this.getItemStatus(item)}`}> {this.getDeliverdStatus(item)} </td> : ""}
                                            </tr>
                                        ))
                                            : <tr><td colSpan="10" align='center' className='text-gray'>No Records found !</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="pt-4">{this.getActionButtons()}
                    </div>
                </div>

                <div className="other-detail card">
                    <div className="other-detail-header">{this.state.staticText?.orderDetailText}</div>
                    <div className="row">
                        <div className="col pt-4">
                            <div className="d-inline-block mr-4">
                                <div className="d-flex justify-content-center">
                                    <div className="mr-2"> <img className="icon-50" alt="" src={getImageSource(ImageConstants.PHONE)} /></div>
                                    <div>
                                        <div className="sub-text">{this.state.staticText?.mobileText}</div>
                                        <div className="flex-content">{this.state.order?.vendorBranch?.countryCode} {this.state.order?.vendorBranch?.mobileNumber}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-inline-block mr-4">
                                <div className="d-flex justify-content-center">
                                    <div className="mr-2"> <img className="icon-50" alt="" src={getImageSource(ImageConstants.DELIVERY)} /></div>
                                    <div>
                                        <div className="sub-text">Type</div>
                                        <div className="flex-content">{this.state.order?.deliveryType?.title}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-4  pt-4">
                            <div>
                                <div className="sub-text">{this.state.staticText?.addressText}</div>
                                {address?.addressLine1 ? <div className="flex-content">{address?.addressLine1},</div> : ""}
                                {address?.addressLine1 ? <div className="flex-content">{address.addressLine2},</div> : ""}
                                <div className="flex-content">{address.city}, {address.state}, {address.country} - {address.code}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        )
    }

    render() {

        return (
            <div className="order-detail px-5">
                {this.state.isLoading && <Loading />}
                <div className="position-relative">
                    <div className="d-inline-block back-icon" onClick={this.props.back}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="header-text"> {this.state?.order?.orderNumber}</div>
                    <div className="sub-text"> Order To: {this.state?.order?.vendorBranch?.name}</div>
                </div>
                <div className="order-detail-flex mt-4">
                    <div className="flex-1"> {this.renderOrderDetail()} </div>
                    <div className="flex-2"> {this.renderPriceDetail()}</div>
                </div>
                {
                    this.state.showRejectConfirm ? <AppConfirmModal isCaptureInput img={getImageSource(ImageConstants.CLOSE_RED)} cancelButtonText={this.state.staticText?.noText} okButtonText={this.state.staticText?.yesText} okButtonStyle="reject-text" title={this.state.staticText?.cancelTitle} close={this.handleClose} onOk={this.onReject} /> : ""
                }

            </div >
        );
    }

}

export default OrderDetail;