import React from 'react';
import "./invoice.scss";
import SessionCache from '../../routes/session-cache';
import InvoiceApi from '../../../apis/invoice'

import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../../utility/image-source'
import Utils from '../../../utility/common-utilities'
import Constants from '../../../constants/constants';
import InvoiceDetail from './invoice-detail/invoice-detail'
import Loading from '../../common/loading/loading';
import StaticText from '../../../shared-store/static-text-store';
import InvoiceConst from './const';
import ImportExport from '../../invoice/import-export/import-export';

class Invoice extends React.Component {
    state = {
        invoices: [],
        status: InvoiceConst.tabFilters[InvoiceConst.tabs[1]].map(item => ({ ...item })),
        selectedTab: InvoiceConst.tabs[1],
    }

    accessRights = {}
    page = 1
    totalPages = 1
    limit = 10

    componentDidMount() {
        let user = SessionCache.getUser()

        this.accessRights = user?.accessRights || {}
        this.setState({ type: user?.type }, () => {
            this.getInvoices()
        })

        let staticText = StaticText.getStaticText("invoice")
        this.setState({ staticText })

        document.addEventListener('mousedown', this.handleClickEvent, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }

    handleClickEvent = (event) => {
        if (this.filterRef && !this.filterRef.contains(event.target)) {
            this.setState({ showFilters: false })
        }
        if (this.headerRef && !this.headerRef.contains(event.target)) {
            this.setState({ showProductExpandView: false })
        }

    }


    getInvoices(payload = {}, isLoadMore) {

        payload = this.buildSearchPayload()

        if (!isLoadMore)
            this.page = 1

        payload.page = this.page
        payload.limit = this.limit

        let filters = this.state.status.filter(item => item.active)

        if (filters.length) {
            payload.status = filters.map(item => item.value).join(',')
        }

        this.setState({ isLoading: true })

        InvoiceApi.getInvoices(payload).then((response) => {

            this.totalPages = response?.totalPages || 0;

            this.prepareInvoices(response.response || [], isLoadMore)
            this.setState({ isLoading: false })

        }).catch((err) => {
            this.setState({ isLoading: false })

        })
    }

    prepareInvoices(invoices, isLoadMore) {
        let oldInvoices = isLoadMore ? this.state.invoices : []

        this.setState({ invoices: [...oldInvoices, ...invoices] })

    }

    showInvoiceDetail = (item, forceEdit) => {
        this.setState({ selectedItem: item, viewInvoiceDetail: true })
    }

    openViewMode = (item) => {
        this.setState({ showEditModal: false, viewInvoiceDetail: true, selectedItem: item, })
    }

    handleBack = () => {
        this.setState({ showEditModal: false, viewInvoiceDetail: false }, () => {
            this.page = 1
            this.getInvoices()
        })
    }

    getImageByStatus(item) {
        if (item.status == Constants.INVOICE_STATUS.NEW) return ImageConstants.INVOICE_NEW
        if (item.status == Constants.INVOICE_STATUS.PAST_DUE) return ImageConstants.INOVICE_PAST_DUE
        if (item.status == Constants.INVOICE_STATUS.PENDING_PAYMENT || item.status == Constants.INVOICE_STATUS.PARTIALLY_PAID) return ImageConstants.INVOICE_PENDING
        if (item.status == Constants.INVOICE_STATUS.FULLY_PAID) return ImageConstants.INVOICE_COMPLETED

        return ImageConstants.ORDER_NEW
    }


    getStatusBadge(status) {
        if (status == Constants.INVOICE_STATUS.NEW) return "invoice-new"
        if (status == Constants.INVOICE_STATUS.PAST_DUE) return "invoice-due"
        if (status == Constants.INVOICE_STATUS.PENDING_PAYMENT) return "invoice-pending"
        if (status == Constants.INVOICE_STATUS.PARTIALLY_PAID) return "invoice-partial"
        if (status == Constants.INVOICE_STATUS.FULLY_PAID) return "invoice-complete"

        return ""
    }

    onClickLoadMore = () => {

        this.page += 1
        this.getInvoices({}, true)
    }

    getFilterBadgeCount = () => {

        let status = this.state.status;
        let filters = status.filter(item => item.active)
        return filters.length
    }

    onClickApplyFilter = () => {
        this.page = 1
        this.setState({ showFilters: false })
        this.getInvoices()
    }

    onClickStatus = (i) => {
        let status = this.state.status;
        status[i].active = !status[i].active
        this.setState({ status })
    }

    onClickFilter = () => {
        this.setState({ showFilters: true })
    }

    onChangeHandler(event, key) {
        this.setState({ [key]: event?.target?.value }, () => {
            this.searchInvoice(key)
        })
    }

    searchInvoice(key) {
        let maxLength = this.state.staticText.characterForSearch
        if (this.state[key] && (this.state[key].length < maxLength)) return

        this.getInvoices()
    }

    buildSearchPayload() {

        let maxLength = this.state.staticText.characterForSearch
        let payload = {}

        if (this.state.invoiceNumberSearch && (this.state?.invoiceNumberSearch?.length >= maxLength)) {
            payload.invoiceNumber = this.state.invoiceNumberSearch
        }

        if (this.state.orderNumberSearch && (this.state?.orderNumberSearch?.length >= maxLength)) {
            payload.orderNumber = this.state.orderNumberSearch
        }

        if (this.state.businessUnitSearch && (this.state?.businessUnitSearch?.length >= maxLength)) {
            payload.businessUnitSearch = this.state.businessUnitSearch
        }

        return payload
    }

    onClickImportExport = () => {
        this.setState({ showImportExport: true })
    }

    closeImportExport = () => {
        this.setState({ showImportExport: false })
    }


    renderFilterStatus = () => {

        let elements = []

        for (let i = 0; i < this.state.status.length; i++) {

            let item = this.state.status[i]
            elements.push(<div className='cursor-pointer mb-2' onClick={() => { this.onClickStatus(i) }}>
                <div className="d-flex align-items-center">
                    <img alt="" src={getImageSource(item.active ? ImageConstants.CHECKBOX_ACTIVE : ImageConstants.CHECKBOX_INACTIVE)} className='checkbox'></img>
                    <span className={`categories-name pl-3 ${item.active ? 'categories-name-active' : ""}`}>{item.title}</span>
                </div>
            </div>)
        }

        return elements

    }

    renderFilters = () => {

        return (
            <div className='filterpop-over app-box-shadow' ref={ref => this.filterRef = ref}>
                <div className="arrow-up"></div>
                <div className='filter-header'><span className="pr-2">{"FILTERS"}</span> {this.getFilterBadgeCount() ? <div className="badge-red position-absolute"><i>{this.getFilterBadgeCount()}</i></div> : ""}</div>

                <div className='row m-0'>

                    <div className='col filter-left'>
                        <div className='title-pop-over'>{"Invoice Status"}</div>
                        <div className="category-list mt-2">
                            {this.renderFilterStatus()}
                        </div>
                    </div>
                </div>

                <div className='row m-0 py-2 m-2'>
                    <div className='apply-button' onClick={this.onClickApplyFilter.bind(this)}>
                        {this.state.staticText?.applyText || 'Apply Filter'}
                    </div>
                </div>
            </div>)
    }

    onChangeTab = (selectedTab) => {
        this.setState({ selectedTab, status: InvoiceConst.tabFilters[selectedTab].map(item => ({ ...item })) }, this.onClickApplyFilter)
    }


    render() {

        if (this.state.viewInvoiceDetail) return <InvoiceDetail invoice={this.state.selectedItem} back={this.handleBack} onEdit={this.showInvoiceDetail} />

        return (
            <div className="customer-invoice">

                {this.state.isLoading && <Loading />}
                <div className="">
                    <div className='row'>
                        <div className="col">
                            <span className={`tab mr-4 ${this.state.selectedTab === InvoiceConst.tabs[1] ? 'active-tab' : ''}`} onClick={() => this.onChangeTab(InvoiceConst.tabs[1])}>{InvoiceConst.tabs[1]}</span>
                            <span className={`tab mr-4 ${this.state.selectedTab === InvoiceConst.tabs[2] ? 'active-tab' : ''}`} onClick={() => this.onChangeTab(InvoiceConst.tabs[2])}>{InvoiceConst.tabs[2]}</span>
                            <span className={`tab mr-4 ${this.state.selectedTab === InvoiceConst.tabs[3] ? 'active-tab' : ''}`} onClick={() => this.onChangeTab(InvoiceConst.tabs[3])}>{InvoiceConst.tabs[3]}</span>
                            <span className={`tab mr-4 ${this.state.selectedTab === InvoiceConst.tabs[4] ? 'active-tab' : ''}`} onClick={() => this.onChangeTab(InvoiceConst.tabs[4])}>{InvoiceConst.tabs[4]}</span>
                        </div>
                        <div className="col text-right">
                            <div className='d-inline-block' onClick={this.onClickImportExport}>
                                <img alt="" title='Export Orders' src={getImageSource(ImageConstants.EXPORT_ICON)} className='export-icon'></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div id="table-scoll" className="table-responsive pt-2">
                        <table className="table mb-0 align-items-center bg-white">
                            <thead>
                                <tr>
                                    <th className='border-top-none border-bottom-none'>
                                        <input
                                            placeholder={"Search invoice number..."}
                                            className="app-input"
                                            value={this.state.invoiceNumberSearch}
                                            onChange={(e) => this.onChangeHandler(e, 'invoiceNumberSearch')} />
                                    </th>
                                    <th className='border-top-none border-bottom-none'>
                                        <input
                                            placeholder="Search order number"
                                            className="app-input"
                                            value={this.state.orderNumberSearch}
                                            onChange={(e) => this.onChangeHandler(e, 'orderNumberSearch')} />
                                    </th>
                                    <th className='border-top-none border-bottom-none '>
                                        <input
                                            placeholder="Search Business Unit"
                                            className="app-input"
                                            value={this.state.businessUnitSearch}
                                            onChange={(e) => this.onChangeHandler(e, 'businessUnitSearch')} />
                                    </th>

                                    <th className='border-top-none border-bottom-none '></th>
                                    <th className='border-top-none border-bottom-none '></th>
                                    <th className='border-top-none border-bottom-none '>
                                        <div className="d-inline-block filter-wrapper bg-white">
                                            {this.getFilterBadgeCount() ?
                                                <div className="main-filter-badge position-absolute badge-red"><i>{this.getFilterBadgeCount()}</i></div>
                                                : ""
                                            }
                                            <div onClick={() => this.onClickFilter()}>
                                                <img alt="" className="icon-20" src={getImageSource(ImageConstants.SLIDER)} />
                                                <span className="pl-2 filter-text">{this.state.staticText?.filterByText}</span>
                                            </div>
                                            {this.state.showFilters ? <div className='order-filters'>{this.renderFilters()}</div> : <></>}
                                        </div></th>

                                    <th className='border-top-none border-bottom-none th-fixed-width-13'></th>
                                </tr>

                                <tr>
                                    <th className=''>Invoice #
                                        {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                    </th>
                                    <th className=''>Order ID</th>
                                    <th className=''>Business Unit
                                        {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                    </th>
                                    <th className=''>Invoice Date
                                        {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                    </th>
                                    <th className=''>Due Date
                                        {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                    </th>
                                    <th className=''>Status
                                        {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                    </th>
                                    <th className='text-right pr-4'>Amount
                                        {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.invoices?.length ? this.state.invoices.map((item, index) => (

                                        <tr key={item._id}>
                                            <td className="cursor-pointer" onClick={() => this.showInvoiceDetail(item)}>
                                                <img alt="" className="icon-40" src={getImageSource(this.getImageByStatus(item))} />
                                                <span className={`px-2 invoice-number ${item.status == Constants.INVOICE_STATUS.NEW ? "text-primary" : ""}`}>{item.invoiceNumber}</span>
                                            </td>
                                            <td className=" semi-bold-text"> {item?.orderNumber} </td>

                                            <td className=" semi-bold-text"> {item?.vendor?.name} </td>
                                            <td className=" created-date"> {Utils.datePipe(item.createdDate, Constants.DATE_FORMAT_1)} </td>
                                            <td className=" medium-text"> {!item.dueDate ? "New Invoice" : Utils.datePipe(item.dueDate, Constants.DATE_FORMAT_1) || ""} </td>
                                            <td className=""><span className={`position-relative invoice-status-badge ${this.getStatusBadge(item.status)}`}><div className="d-inline-block draw-dot ">&nbsp;</div> <span className="pl-2">{Constants.INVOICE_STATUS_TEXT[item?.status || "New"]}</span></span> </td>
                                            <td className="text-right bold-text font-14 pr-4">{item?.currency?.symbol} {Utils.formatAmountStringStrict(item?.billingAmount || 0)} </td>
                                        </tr>
                                    ))
                                        : <tr><td colSpan="10" align='center' className='text-gray'>No Records found !</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {this.totalPages && this.totalPages > 1 && this.page < this.totalPages ? <div className='text-center load-more cursor-pointer pt-4' onClick={() => this.onClickLoadMore()}><span>{this.state.staticText.loadMoreText}</span></div> : ""}
                </div>
                {
                    this.state.showImportExport ? <ImportExport closeImportExport={() => this.closeImportExport()} {...this.props} /> : "" //Prdocut Upload Dummy 
                }
            </div >
        );
    }

}

export default Invoice;