//New Module for Inventory INV000001
import React from 'react';
import CommonUtils from '../../../utility/common-utilities';
import "./inventory-product-mapping.scss";


class HandlingUnitInput extends React.Component {
   state = {
      isFocused: false,
      value: this.props?.value
   }

   componentDidUpdate() {
      // if (this.state.value !== this.props?.value) {
      //    this.setState({ value: CommonUtils.formatAmount(this.props?.value || 0) })
      // }
   }

   onChangeInput(value) {
      this.setState({ value })
      this.props.onChangeItemDiscount(value)
   }

   onBlur = () => {
      if (this.state.value) {
         let value = CommonUtils.formatAmount(this.state.value)
         this.setState({ value })
         this.props.onChangeItemDiscount(this.state.value)
      }

      this.props.onBlur()
   }

   render() {
      return <div onClick={() => this.inputRef.focus()} className={`item-tax-input-container ${this.props.isFocused ? "item-focus" : ""}`}>
         <div>
            <input
               ref={(ref) => this.inputRef = ref}
               type="number"
               className="item-tax-input"
               onFocus={this.props.onFocus}
               onBlur={this.onBlur}
               onChange={(event) => this.onChangeInput(event?.target?.value)}
               placeholder={this.props.isFocused ? "" : "0"}
               value={`${this.state?.value || ""}`}
               style={{ textAlign: this.props?.type === "PERCENTAGE" ? "center" : "right" }}
            />
         </div>
         {this.props?.type === "PERCENTAGE" && <span className='discount-input-mode'>%</span>}
      </div>
   }
}

export default HandlingUnitInput