import { getStaticText } from './static-text-modal';

export default class SharedStaticText {

    static staticText = getStaticText(null)

    static setStaticText(staticTextResponse) {
        this.staticText = staticTextResponse;
    }

    static getStaticText(screen) {
        return this.staticText[screen];
    }

}
