import React from 'react';
import './footer-component.scss';
import { Redirect } from 'react-router-dom';
import Path from '../../routes/routes-path';

class Footer extends React.Component {

    state = {
    };

    onClickOption = (path) => {
        this.setState({ navigatePath: path })
    }

    render() {

        return (
            <div className={`app-footer ${this.props.transparent ? '' : 'bg-white'}`}>
                {this.state.navigatePath && <Redirect to={this.state.navigatePath} />}
                <div className="app-container h-100">
                    <div className="d-flex  h-100 align-items-center ">
                        <div className="col text-center my-auto">
                            <div className="" onClick={() => this.onClickOption(Path.SIGN_UP)}>
                                <span className="text-1"> Terms & Conditions </span> <span className='footer-line'>|</span> <span className="text-1"> Privacy </span>
                            </div>
                        </div>
                        <div className="col text-center my-auto">
                            <div className="" onClick={() => this.onClickOption(Path.SIGN_UP)}>
                                <span className="text">Any Help ?</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;