/// <reference types="google.accounts" />
import jwt_decode from 'jwt-decode'
import React, { useEffect } from 'react'
import Constants from '../../constants/constants'
import "./login.scss";

let google = window.google

export const GoogleSignIn = ({
  socialAuthLoading,
  googleSignInLoading,
  // setGoogleSignInLoading,
  onSocialLogin,
}) => {
  useEffect(() => {
    const onSuccess = (data) => {
      // setGoogleSignInLoading(true)
      const basicProfile = jwt_decode(data.credential)
      const googleLoginData = {
        provider: 'google',
        tokenId: data.credential,
        firstName: basicProfile?.given_name ? basicProfile?.given_name : '',
        lastName: basicProfile?.family_name ? basicProfile?.family_name : '',
        googleId: basicProfile.sub,
        profilePicUrl: basicProfile.picture,
      }
      onSocialLogin(googleLoginData)
    }
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_ENV === 'production' ? Constants.GOOGLE_LOGIN_KEY : Constants.DEV_GOOGLE_LOGIN_KEY,     /* CHG000001*/
      // state_cookie_domain: 'mist.com',
      callback: onSuccess,
    })
    
    google.accounts.id.renderButton(
      document.getElementById("google_signin_button"),
      { theme: "outline", size: "large" }  // customization attributes
    );   /* Pramod 7/28/23 Updated to work with the latest Google Auth */
    
    google.accounts.id.prompt()
    
  /*  google.accounts.id.renderButton(document.getElementById('google_signin_button'), {
      type: 'standard',
      theme: 'outline',
      shape: 'rectangular',
      size: 'large',
      text: 'continue_with',
      width: '400',
      logo_alignment: 'center',
    }) Pramod 7/28/23 Updated to work with the latest Google Auth       */
  }, [])

  const onClickSignin = () => {
    document.getElementById('google_signin_button').click()
  }

  return (
    <div className="text-center google-login cursor-pointer" onClick={onClickSignin}>
      <div
        className={`d-flex justify-content-center button-container ${socialAuthLoading ? 'pointer-none' : ''}`}
        data-loading={googleSignInLoading}
      >
        <div id="google_signin_button"></div>
        {/* <div className='ml-2'>GOOGLE</div> */}
      </div>
    </div>
  )
}
