import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import './pagination.scss'

class PaginationComponent extends Component {

    constructor(props) {
        super(props);

        if (this.props.maxPagesToDisplay)
            this.MAX_DISPLAY_PAGES = this.props.maxPagesToDisplay;
    }

    state = {
        totalPages: 1,
        maxDisplay: 20,
        currentSelectedPage: this.props.selectedPage || 1,
        previousSelectedPage: 0
    };
    startIndex = this.props.startIndex || 1;

    MAX_DISPLAY_PAGES = 10;

    buildPagesNew(totalPages) {

        let elements = [];

        let startIndex = this.startIndex;

        let pagesToDisplay = totalPages < (startIndex + this.MAX_DISPLAY_PAGES - 1) ? startIndex + (totalPages % this.MAX_DISPLAY_PAGES) : startIndex + this.MAX_DISPLAY_PAGES;

        for (let i = startIndex; i < pagesToDisplay; i++) {
            elements.push(<li className={i == this.state.currentSelectedPage ? "page-item active" : "page-item disabled"} onClick={this.onPageClick.bind(this, i)} key={"page_" + i}><span className={i == this.state.currentSelectedPage ? "page-link" : "page-link "}>{i}</span></li>);
        }
        return elements;
    }

    nextPageHandler(totalPages) {

        if (this.state.currentSelectedPage == totalPages)
            return;

        let page = this.state.currentSelectedPage == totalPages ? totalPages : this.state.currentSelectedPage + 1;

        if (this.state.currentSelectedPage >= Math.ceil(this.startIndex / this.MAX_DISPLAY_PAGES) * this.MAX_DISPLAY_PAGES) {
            this.startIndex = this.startIndex + this.MAX_DISPLAY_PAGES;
        }

        this.onPageClick(page);

    }

    previousPageHandler(totalPages) {

        if (this.state.currentSelectedPage == 1)
            return;

        let page = this.state.currentSelectedPage == 1 ? 1 : this.state.currentSelectedPage - 1;

        if (this.state.currentSelectedPage == this.startIndex) {
            this.startIndex = this.startIndex - this.MAX_DISPLAY_PAGES;
        }

        this.onPageClick(page);
    }

    setSelectedPage(pageNumber) {
        this.setState({
            previousSelectedPage: this.state.currentSelectedPage,
            currentSelectedPage: pageNumber
        }, () => {
            this.props.onPageSelected(pageNumber, this.startIndex);
        });
    }

    onPageClick(pageNumber) {
        this.setSelectedPage(pageNumber);
    }

    render() {
        let totalPages = this.props.totalPages;

        return (
            <>
                {
                    totalPages ? <ul className="justify-content-end pagination ">
                        <li className="page-item">
                            <span tabIndex="-1" className="page-link" onClick={this.previousPageHandler.bind(this, totalPages)}>
                                <FontAwesomeIcon icon={faAngleLeft} /><span className="sr-only">Previous</span>
                            </span>
                        </li>
                        {this.startIndex > this.MAX_DISPLAY_PAGES && <li className="page-item">....</li>}
                        {
                            totalPages && this.buildPagesNew(totalPages)
                        }
                        {totalPages > (this.startIndex + this.MAX_DISPLAY_PAGES - 1) && <li className="page-item">....</li>}
                        <li className="page-item">
                            <span className="page-link" onClick={this.nextPageHandler.bind(this, totalPages)}>
                                <FontAwesomeIcon icon={faAngleRight} /><span className="sr-only">Previous</span>

                            </span>
                        </li>
                    </ul>
                        : ''}
            </>

        );
    }
}

export default PaginationComponent;