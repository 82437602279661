import React from 'react';
import "./update-tenant.scss";
import AuthenticateAPI from '../../../apis/authenticate'

class Product extends React.Component {
    state = {
    }

    componentDidMount() {
    }

    onChangeHandler(event, key) {
        this.setState({ [key]: event?.target?.value })
    }

    onClickUpdate = () => {

        let payload = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password,
            mobileNumber: this.state.mobileNumber,
            countryCode: "+91",
            businessName: this.state.businessName,
            address: {
                city: this.state.city,
                state: this.state.state,
                country: this.state.country
            },
        }

        if (!payload.businessName) {
            this.setState({ errMessage: "Please Enter Business Name" })
            return
        }

        if (!payload.firstName) {
            this.setState({ errMessage: "Please Enter First Name" })
            return
        }

        if (!payload.password) {
            this.setState({ errMessage: "Please Enter Valid Password" })
            return
        }

        AuthenticateAPI.updateTenant(payload).then((response) => {
            this.props.onSave && this.props.onSave()

        }).catch((err) => {
            alert(err);
        })
    }

    render() {

        return (
            <div className="update-tenant">
                <div className="">

                    <div className='bw-spaces-input'>
                        <div className='cw-input-label'>Business Name <span className='star'>*</span></div>
                        <input className={`cw-input`} placeholder='Enter Business Name'
                            value={this.state.businessName}
                            onChange={(e) => this.onChangeHandler(e, 'businessName')} />
                    </div>

                    <div className='bw-spaces-input'>
                        <div className='cw-input-label'>First Name <span className='star'>*</span></div>
                        <input className={`cw-input`} placeholder='Enter First Name'
                            value={this.state.firstName}
                            onChange={(e) => this.onChangeHandler(e, 'firstName')} />
                    </div>

                    <div className='bw-spaces-input'>
                        <div className='cw-input-label'>Last Name <span className='star'>*</span></div>
                        <input className={`cw-input`} placeholder='Enter Last Name'
                            value={this.state.lastName}
                            onChange={(e) => this.onChangeHandler(e, 'lastName')} />
                    </div>

                    <div className='bw-spaces-input'>
                        <div className='cw-input-label'>City <span className='star'>*</span></div>
                        <input className={`cw-input`} placeholder='Enter City'
                            value={this.state.city}
                            onChange={(e) => this.onChangeHandler(e, 'city')} />
                    </div>

                    <div className='bw-spaces-input'>
                        <div className='cw-input-label'>State <span className='star'>*</span></div>
                        <input className={`cw-input`} placeholder='Enter State'
                            value={this.state.state}
                            onChange={(e) => this.onChangeHandler(e, 'state')} />
                    </div>

                    <div className='bw-spaces-input'>
                        <div className='cw-input-label'>Country <span className='star'>*</span></div>
                        <input className={`cw-input`} placeholder='Enter Country'
                            value={this.state.country}
                            onChange={(e) => this.onChangeHandler(e, 'country')} />
                    </div>

                    <div className='bw-spaces-input'>
                        <div className='cw-input-label'>Mobile Number<span className='star'>*</span></div>
                        <input className={`cw-input`} placeholder='Enter Mobile Number'
                            value={this.state.mobileNumber}
                            onChange={(e) => this.onChangeHandler(e, 'mobileNumber')} />
                    </div>

                    <div className='bw-spaces-input'>
                        <div className='cw-input-label'>Password <span className='star'>*</span></div>
                        <input className={`cw-input`} placeholder='Enter Password'
                            value={this.state.password}
                            type="password"
                            onChange={(e) => this.onChangeHandler(e, 'password')} />
                    </div>

                </div>

                <div className="pt-4 text-center">
                    <button className="cw-button-primary" onClick={this.onClickUpdate}>
                        Update Product
                    </button>

                </div>
            </div>
        );
    }

}

export default Product;