import { faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal, ModalBody } from "reactstrap";
import ModalHeader from 'reactstrap/lib/ModalHeader';
import BranchApi from '../../../home/apis/branch';
import CustomerApi from '../../../home/apis/customer';
import Path from '../../../home/components/routes/routes-path';
import SessionCache from '../../../home/components/routes/session-cache';
import ActionConstant from '../../../home/constants/action-constant';
import Constants from '../../../home/constants/constants';
import ImageConstants from '../../../home/constants/image-constants';
import StaticText from '../../../home/shared-store/static-text-store';
import CommonUtils from '../../../home/utility/common-utilities';
import { getImageSource } from '../../../home/utility/image-source';
import './my-network.scss';

export default class MyNetwork extends React.Component {

   state = {
      showMyNetworkPopUp: false,
      showAddPopUp: false,
      isDisabled: true,
      welcomEmail: ""
   }

   constructor(props) {
      super()
      let user = SessionCache.getUser()
      this.state.routeType = user.type == Constants.USER_TYPE.BOTH ? Constants.USER_TYPE.VENDOR : user.type

      if (user.type === Constants.USER_TYPE.BOTH) {
         if (user.currentUseType) {
            this.state.routeType = user.currentUseType
         } else {
            this.state.routeType = Constants.USER_TYPE.VENDOR
         }
      } else {
         this.state.routeType = user.type
      }
      let staticText = StaticText.getStaticText("header")
      this.state.staticText = staticText
   }

   hideMyNetworkPopUp = () => {
      this.setState({ showMyNetworkPopUp: false })
   }

   onClickMyNetworkItem = (addType) => {
      this.setState({ showAddPopUp: true, addType, }, () => {
         this.props.onModalHandler && this.props.onModalHandler(true)
      })
   }

   goToMyNetwork = () => {
      this.props.history.push(this.state.routeType == Constants.USER_TYPE.VENDOR ? Path.CUSTOMER : Path.VENDORS)
   }

   showAddModal = () => {
      this.setState({ showAddPopUp: true }, () => {
         this.props.onModalHandler && this.props.onModalHandler(true)
      })
   }

   hideAddModal = () => {
      this.props.onModalHandler && this.props.onModalHandler(false)

      this.setState({ showAddPopUp: false }, () => {
         this.props.close && this.props.close()

      })
   }

   onFocusSearch(value) {
      this.setState({ isFocusSearch: value })
   }

   onChangeInput = (event) => {
      //  if (CommonUtils.checkTrialAccess(ActionConstant.ADD_CUSTOMER)) {
      //      SharedStore.getStore("subscription").showSubscriptionModal()
      //  } else {
      this.setState({ search: event?.target.value }, () => {
         this.onSearch()
      })
      //  }
   }

   onChangeEmailInput = (event) => {
      //  if (CommonUtils.checkTrialAccess(ActionConstant.ADD_CUSTOMER)) {
      //      SharedStore.getStore("subscription").showSubscriptionModal()
      //  } else {
      this.setState({ welcomEmail: event?.target.value }, () => {
         this.validateEmail(this.state.welcomEmail)
      })
      //  }
   }

   validateEmail = (email) => {

      this.setState({ isDisabled: true })

      if (email && CommonUtils.validateEmail(email)) {
         this.setState({ isDisabled: false })
      }
   }

   onSearch = () => {
      let value = this.state.search
      if (!value || value.length < 3) {
         this.setState({ customers: [] })
         return
      }

      let payload = {
         cubeId: value
      }

      CustomerApi.getCustomerByBusinessName(payload).then((response) => {
         this.setState({ customers: response })
      }).catch((err) => {
         this.setState({ customers: [] })
      })
   }

   renderNameBadge(name) {
      if (!name) return ""
      let splitName = name.split(" ")
      let tag = ""
      if (splitName.length > 1) {
         tag = `${splitName[0][0] || ""}${splitName[1][0] || ""}`
      } else if (splitName) {
         tag = `${splitName[0][0]}`
      }
      tag = tag ? tag.toUpperCase() : ""
      return (<div className="name-badge-1"><span className="">{tag || ""}</span></div>)

   }

   onClickWelcomeEmail() {

      if (this.state.isSendingEmail) return

      this.setState({ isSendingEmail: true })
      let payload = {
         email: this.state.welcomEmail,
         invitedAs: this.state.addType
      }
      BranchApi.sendWelcomEmail(payload).then((response) => {
         this.setState({ isSendingEmail: false, welcomEmail: "", search: "" })
      }).catch((err) => {
         this.setState({ isSendingEmail: false, welcomEmail: "", search: "" })

      })
   }

   onClickSendInvite = (vendor) => {
      let payload = {
         tenantId: vendor.tenantId,
         branchId: vendor._id,
         invitedAs: this.state.addType
      }
      BranchApi.sendInvite(payload).then((response) => {
         this.onSearch()
      }).catch((err) => {
      })
   }

   checkSendInviteButton() {
      // if (!this.state.search || this.state.customers?.length) return false

      // let [beforeAt, afterAt] = this.state.search.split("@")

      // if (!beforeAt || !afterAt) return false

      // let [beforeDot, AfterDot] = afterAt.split(".")

      // if (!beforeDot || beforeDot.length < 2) return false
      // if (!AfterDot || AfterDot.length < 2) return false


      // return true

      if (this.state.search && this.state.search.length > 2 && this.state.customers?.length == 0)
         return true

      return false
   }

   renderAddModal = () => {
      return (
         <Modal className="add-custom-modal" isOpen={this.state.showAddPopUp} toggle={this.hideAddModal} size="md">
            <ModalHeader>
               <div className="header-text"> {this.state.addType == "BUYER" ? "Add Buyer" : "Add Vendor"}</div>
            </ModalHeader>
            <ModalBody className="" >
               <div>
                  {
                     CommonUtils.checkRights(ActionConstant.ADD_CUSTOMER) ?
                        <div className='add-modal-content'>
                           <div>
                              <div className={`app-search-gray`} ref={ref => this.searchRef = ref}>
                                 <div className="app-search-input">
                                    <div className='search-icon-wrapper'>
                                       <img alt="" src={getImageSource(ImageConstants.SEARCH)} className='search-icon'></img>
                                    </div>
                                    <input
                                       ref={ref => this.inputRef = ref}
                                       className={`${!this.state.isFocusSearch ? "search-input-box-gray" : "search-input-box"}`}
                                       placeholder={this.state.staticText?.searchPlaceHolderBusiness}
                                       value={this.state.search}
                                       onFocus={() => this.onFocusSearch(true)}
                                       // onBlur={() => this.onFocusSearch(false)}
                                       onChange={this.onChangeInput.bind(this)}
                                    />
                                 </div>
                                 {

                                    <div className={`customer-list border-top`} >{
                                       this.state.customers?.length ? this.state.customers?.map(item => (
                                          <div className='row p-2 m-0 '>
                                             <div className='col-2 my-auto text-center'>
                                                {this.renderNameBadge(item.businessName)}
                                             </div>
                                             <div className='col-6 overflow-scroll'>
                                                <div className='staff-name-spacing'><span className='staff-name'>{item?.businessName}</span></div>
                                                <div className='staff-username'>{item?.address?.city}{", "}{item?.address?.state}</div>
                                                <div className='badge p-0'> {this.state.staticText?.cubeIdText} {item?.cubeId}</div>
                                             </div>

                                             <div className='col-4 my-auto text-right m-0'>
                                                {item.status == 'INVITE' ? <button className='app-button-primary cursorxw-pointer' onClick={() => this.onClickSendInvite(item)}>{this.state.staticText?.sendInviteButtonText}</button> : <></>}
                                                {item.status == 'INVITED' ? <button className='app-button-primary-outline'>{this.state.staticText?.invitedText}</button> : <></>}
                                                {item.status == 'ACCEPTED' ? <button className='app-button-primary-outline'>{this.state.staticText?.acceptedText}</button> : <></>}
                                                {item.status == 'INVITE_RECEIVED' ? <div className='invite-text'>{this.state.staticText?.invitedReceivedText}</div> : <></>}
                                             </div>
                                          </div>)) :

                                          <div className="p-4">
                                             <p> {this.state.staticText?.noSearchRecords}</p>

                                             <div className='mt-2'>
                                                {this.checkSendInviteButton() ?
                                                   <><p> {this.state.staticText?.registerRequestText} </p>
                                                      <input
                                                         className={"app-input app-input-override mr-2"}
                                                         placeholder={this.state.staticText?.welcomEmail}
                                                         value={this.state.welcomEmail}
                                                         onChange={this.onChangeEmailInput.bind(this)}

                                                      />
                                                      <button className='app-button-primary cursorxw-pointer app-button-primary-override' onClick={() => this.onClickWelcomeEmail()} disabled={this.state.isDisabled}>
                                                         {
                                                            this.state.isSendingEmail ? <FontAwesomeIcon spin icon={faSpinner} /> :
                                                               <span><FontAwesomeIcon className="cursor-pointer" icon={faPaperPlane} /> {this.state.staticText?.sendRegisterRequestText}</span>
                                                         }

                                                      </button>
                                                   </>
                                                   : ""}
                                             </div>
                                          </div>
                                    }
                                    </div>
                                 }
                              </div>
                           </div>
                        </div> : ""}
               </div>
            </ModalBody>

         </Modal >
      )
   }

   render() {

      return (
         <div className='my-network'>

            <div className="mynetwork-custom-modal" >
               {
                  !this.state.showAddPopUp ?
                     <div className='pop-over-mynetwork'>
                        <div className="arrow-up"></div>

                        <div ref={ref => this.myNetworkRef = ref} className="menu-card my-network-menu cursor-pointer shadow-lg-custom  pt-3">
                           <div className={`my-network-title`}>
                              My Network
                              {/* <img className="float-right cursor-pointer icon-20" alt="" onClick={this.props.close} src={getImageSource(ImageConstants.CLOSE_GRAY)} /> */}
                           </div>

                           <div className="py-2 menu-item cursor-pointer mt-2" onClick={() => this.onClickMyNetworkItem('BUYER')}>
                              <img className="icon-12" alt="" src={getImageSource(ImageConstants.PLUS_BLACK)} />
                              <span className={`my-network-text`}>
                                 Add Buyer
                              </span>
                           </div>

                           <div className="py-2 menu-item cursor-pointer" onClick={() => this.onClickMyNetworkItem('VENDOR')}>
                              <img className="icon-12" alt="" src={getImageSource(ImageConstants.PLUS_BLACK)} />
                              <span className={`my-network-text`}>
                                 Add Vendor
                              </span>
                           </div>
                           {
                              !this.props.isHideMyNetwork ?
                                 <div className="py-2 menu-item cursor-pointer" onClick={this.goToMyNetwork}>
                                    <img className="icon-12" alt="" src={getImageSource(ImageConstants.MY_NETWORK)} />
                                    <span className={`my-network-text`}>
                                       My Network
                                    </span>
                                 </div>
                                 : ""}
                        </div>
                     </div> : ""}
            </div>
            {this.renderAddModal()}
         </div>
      )
   }
}