import React from 'react';
import "./sales-chart.scss";

import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../..//utility/image-source';
import StaticText from '../../../shared-store/static-text-store';
import ReportApi from '../../../apis/report';
import Utils from '../../..//utility//common-utilities';

import AppLoader from '../../common/app-loader/app-loader';

import { LineChart, BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


class SalesCharts extends React.Component {
    state = {
        staticText: {},
        currency: {},
        chartData: []
    }

    user = {}
    componentDidMount() {

        let staticText = StaticText.getStaticText("dashboard")
        this.setState({ staticText })
        this.getReport()
    }


    getReport = (payload = {}) => {

        this.setState({ isLoading: true })
        ReportApi.getSalesChartReport(payload).then((response) => {
            let data = response.data
            this.formatChartDate(data || [])

            this.setState({ totalSales: response.totalSales, currency: response?.currency })
        }).catch((err) => {
            this.setState({ isLoading: false, sales: [] })
        })
    }

    formatChartDate(data) {
        let arr = [], lastMonthValue = null, maxSales = 0           //CHG000001
        data.forEach(element => {
            if (lastMonthValue && !element.totalSales && element.totalSales != 0) {
                arr.push({
                    month: element.month ? element.month.toUpperCase() : "",
                    // "Total Sales": 0
                })
                return
            }
            if (element.totalSales > maxSales){                 //CHG000001
                maxSales = element.totalSales                   //CHG000001
            }                                                   //CHG000001
            arr.push({
                month: element.month ? element.month.toUpperCase() : "",
                "Total Sales": element.totalSales ? Utils.formatAmountStringStrict(element.totalSales) : 0
            })
            if (element.totalSales) lastMonthValue = true
        });

        this.setState({maxSalesValue: Math.ceil(maxSales/100)*100}) //CHG000001
        this.setState({ chartData: arr || [], isLoading: false })
    }

    renderChart() {

        return (

            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={this.state.chartData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis domain={[0, this.state.maxSalesValue]}/>       {/*CHG000001*/}
                    <Tooltip cursor={{ fill: 'transparent' }} />
                    <Bar dataKey="Total Sales" fill="#8884d8" />
                    <Line type="monotone" dataKey="Total Sales" stroke="#8884d8" activeDot={{ r: 6 }} />
                </BarChart>
            </ResponsiveContainer>

        )

    }


    render() {

        return (
            <div className="sales-chart">
                {
                    this.state.chartData?.length ?
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-1">
                                        <img className="icon-50" alt="" src={getImageSource(ImageConstants.SALES_ACTIVITY)} />
                                    </div>
                                    <div className="col">
                                        <div className="cart-title">{this.state.staticText?.overAllSalesText}</div>
                                        <div className=""><span className="bold-text font-18">{this.state?.totalSales ? `${this.state.currency?.symbol} ${Utils.formatAmountStringStrict(this.state?.totalSales)}` : "-"}</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="chart-container">
                                {this.renderChart()}
                            </div>
                        </div>
                        : <div className="card sales-card">
                            <div className="text-center">
                                {
                                    this.state.isLoading ? <AppLoader size="2x" /> :
                                        <>
                                            <img alt="" className="sales-image" src={getImageSource(ImageConstants.SALES)} />
                                            <div className="pt-4 bold-text font-16"> {this.state.staticText?.noSalesTitle}</div>
                                            <div className="no-data-description"> {this.state.staticText?.noSalesDescription}</div>
                                        </>}
                            </div>
                        </div>
                }
            </div>
        );
    }

}

export default SalesCharts;