
export default class SessionCache {

    static user = null;

    static isAuthenticated() {

        if (this.user == null) {
            return null;
        }
        else if (this.user['isAuthorized']) {
            return true;
        }

        return false;
    }

    static isVerified() {

        return false;
    }

    static getName() {
        if (this.user) {
            return this.user.name;
        }

        return "";
    }


    static getAccessRights() {
        return this.user?.accessRights;
    }

    static getUser(obj) {

        return this.user
    }

    static setUser(obj) {

        this.user = obj;

        if (obj.actions && obj.actions?.length) {
            let accessRights = {}
            obj.actions.forEach(element => {
                accessRights[element.name] = true
            });
            this.user.accessRights = accessRights
        }

    }
};