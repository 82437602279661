import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Path from '../routes/routes-path'
import BillSUmmary from './bill-summary/bill-summary'
import MembershipAndBilling from './membership-and-billing/membership-and-billing'
import SubscriptionPage from './subscription-management'

const SubscriptionRoutes = () => {

  return (
    <Suspense>
      <Switch>
        <Route exact path={Path.SUBSCRIPTION.subscribe} component={SubscriptionPage} />
        <Route exact path={Path.SUBSCRIPTION.membershipAndBilling} component={MembershipAndBilling} />
        <Route exact path={Path.SUBSCRIPTION.billSummary} component={BillSUmmary} />
        <Redirect to={Path.SUBSCRIPTION.setupResponse} />
      </Switch>
    </Suspense>
  )
}

export default SubscriptionRoutes
