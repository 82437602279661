import React, { Component } from 'react';
import './loading.scss';

import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../../utility/image-source'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

class Loading extends Component {
    state = {}
    render() {
        return (
            <div className='loading-screen'>
                <div className='loading'>
                    <FontAwesomeIcon spin size="5x" icon={faCircleNotch} />
                </div>
            </div>);
    }
}

export default Loading;