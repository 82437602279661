import React from 'react';
import "./cart-details.scss";
import cartItemApi from '../../apis/cart'
import orderApi from '../../apis/order'
import SharedStore from "../../shared-store/shared-store"
import { Modal, ModalBody, Input } from "reactstrap";
import ImageConstants from '../../constants/image-constants'
import { getImageSource } from '../../utility/image-source'
import Loading from '../common/loading/loading'
import ProductPreview from './product-preview/product-preview'
import AddCartItem from '../common/add-cart-items/add-cart-items';

import SessionCache from '../routes/session-cache';
import ActionConstant from '../../constants/action-constant';
import Utils from '../../utility/common-utilities'


class CartDetails extends React.Component {
    state = {
        deliveryTypes: [
            {
                type: "DELIVERY",
                title: "Delivery"
            },
            {
                type: "PICKUP",
                title: "Pickup"
            }
        ]
    }

    accessRights = {}

    componentDidMount() {

        this.user = SessionCache.getUser()
        this.accessRights = this.user.accessRights || {}
        this.setState({ loading: true })
        this.getCartItem()
        document.addEventListener('mousedown', this.handleClickEvent, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }

    handleClickEvent = (event) => {
        if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
            let groupByProducts = this.state.groupByProducts
            groupByProducts.forEach(element => {
                element.openDeliveryPopup = false
            })
            this.setState({ groupByProducts })
        }

    }

    handleCart = () => {
        cartItemApi.getCartItems({}).then((response) => {
            SharedStore.setStore("cart", response?.response || [])
        }).catch((err) => {
            SharedStore.setStore("cart", [])
        })
    }

    getCartItem(payload = {}) {

        cartItemApi.getCartItems(payload).then((response) => {
            this.setState({
                cartId: response?.response?.id,
                products: response?.response?.products || [],
                groupByProducts: [],
                loading: false
            }, () => {
                this.onClickPlaceOrder()
            })
            SharedStore.setStore("cart", response?.response || {})

        }).catch((err) => {
            this.setState({ products: [], groupByProducts: [], loading: false })
        })
    }

    updateCartItem(product) {
        let payload = {
            cartItemId: product._id,
            quantity: product.quantity,
        }

        // this.setState({ loading: true })

        cartItemApi.updateCartItem(payload).then((response) => {
            // this.setState({ loading: false })
            this.getCartItem()
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }


    removeCartItem(product) {
        let payload = {
            productId: product.productId,
            cartId: this.state.cartId,
        }

        this.setState({ loading: true })
        cartItemApi.removeCartItem(payload).then((response) => {
            this.setState({ loading: false })
            this.getCartItem()
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }


    onConfirmOrder = () => {
        let products = this.state.groupByProducts

        console.log(products)
        let payload = {
            meta: products.map(i => ({ note: i.summary, branchId: i.branchId, deliveryType: i.deliveryType }))
        }

        orderApi.createOrder(payload).then((response) => {
            this.setState({ showOrderSuccess: true, products: [], groupByProducts: [] })
            SharedStore.setStore("cart", {})

        }).catch((err) => {
            alert(err);
        })
    }

    getTotal() {
        let products = this.state.products, total = 0, currency = ""
        products.forEach(element => {
            let price = element.price
            currency = element?.currency?.symbol || "Inr"
            let quantity = element.quantity
            total += (price * quantity)
        });
        return `${currency} ${Utils.formatAmountStringStrict(total)}`
    }

    onChangeHandler(event, index) {
        let products = this.state.products
        products[index].note = event?.target?.value
        this.setState({ products })
    }

    onChangeSummary(e, index) {

        let products = this.state.groupByProducts
        products[index].summary = e?.target?.value
        this.setState({ groupByProducts: products })
    }

    removeCartGroup = (group, groupIndex) => {

        let payload = { cartItemIds: group.products.map((item) => item._id) }

        this.setState({ loading: true })
        cartItemApi.removeCartItem(payload).then((response) => {
            this.setState({ loading: false })
            this.getCartItem()
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }

    onClickPlaceOrder = () => {
        let products = this.state.products
        let hashProduct = {}
        products.forEach(element => {
            let branchId = element?.branch?.id
            if (hashProduct[branchId]) {
                hashProduct[branchId].products.push(element)
                hashProduct[branchId].total += element.quantity * element.price
            } else {
                hashProduct[branchId] = {
                    branchId,
                    name: element?.branch?.name,
                    products: [element],
                    deliveryType: {
                        type: "DELIVERY",
                        title: "Delivery"
                    },
                    currency: element.currency,
                    total: element.quantity * (+element.price)
                }
            }
        })

        let groupByProducts = Object.values(hashProduct)
        this.setState({ showPlaceOrder: true, groupByProducts })
    }

    onClickQuantityEdit = (product, index, groupIndex) => {
        let groupByProducts = this.state.groupByProducts;
        groupByProducts[groupIndex].products[index].isEdit = true
        this.setState({ groupByProducts })
    }

    onChangeQuantity = (e, product, index, groupIndex) => {

        let value = +e.target.value
        if (value < 0) return

        let groupByProducts = this.state.groupByProducts;
        groupByProducts[groupIndex].products[index].quantity = value ? value : 0
        this.setState({ groupByProducts })
    }

    onClickQuantityConfirm = (product, index, groupIndex) => {
        this.updateCartItem(product)
    }

    onClickAdd = (index, groupIndex) => {

        let groupByProducts = this.state.groupByProducts;
        let value = +groupByProducts[groupIndex].products[index].quantity + 1;
        groupByProducts[groupIndex].products[index].quantity = value
        this.onClickQuantityConfirm(groupByProducts[groupIndex].products[index])
    }

    onClickMinus = (index, groupIndex) => {
        let groupByProducts = this.state.groupByProducts;
        let value = +groupByProducts[groupIndex].products[index].quantity - 1 ? +groupByProducts[groupIndex].products[index].quantity - 1 : +groupByProducts[groupIndex].products[index].quantity;
        groupByProducts[groupIndex].products[index].quantity = value
        this.onClickQuantityConfirm(groupByProducts[groupIndex].products[index])
    }

    onClickDeliveryType(value, index) {
        let products = this.state.groupByProducts
        products[index].deliveryType = value
        this.setState({ groupByProducts: products })
    }

    onSelectDeliveryType = (type, index) => {
        let groupByProducts = this.state.groupByProducts
        groupByProducts[index].deliveryType = type
        this.setState({ groupByProducts })
    }

    onClickRenderProducts = (e, product) => {
        e.stopPropagation()
        this.setState({ showProductPreview: true, selectedProduct: product })
    }

    onClickDiliveryTypeSelection = (item, index) => {

        let groupByProducts = this.state.groupByProducts
        groupByProducts[index].openDeliveryPopup = !groupByProducts[index].openDeliveryPopup
        this.setState({ groupByProducts })
    }

    renderProducts = (products, groupIndex) => {

        let productElements = []

        console.log(products)

        for (let i = 0; i < products.length; i++) {

            let product = products[i]

            productElements.push(<div className='cart-card-products cursor-pointer row' onClick={(e) => this.onClickRenderProducts(e, product)}>
                <div className='col-6 '>
                    <div className="left-wrapper">
                        <div className="cart-flex-1">
                            <img alt="" src={getImageSource(ImageConstants.CROSS_ICON_WHITE)} onClick={(e) => { e.stopPropagation(); this.removeCartItem(product) }} className='remove-cart cursor-pointer'></img>
                        </div>
                        <div className="cart-flex-2">
                            {
                                product?.images?.length ?
                                    <div className='d-inline-block product-image-wrapper mr-1'>
                                        <img alt="" className='product-image-wrapper' src={product.images[0]?.url}></img>
                                    </div>
                                    :
                                    <div className='d-inline-block product-image-wrapper mr-1'>
                                        <img alt="" className='product-image-wrapper' src={getImageSource(ImageConstants.DEFAULT_PRODUCT)}></img>
                                    </div>
                            }
                        </div>
                        <div className="cart-flex-3">
                            <div className='product-name-wrapper mr-1'>
                                <div className='product-name'>{product.name}</div>
                                <div className='category-name'>{product?.category?.title}</div>
                            </div>
                        </div>
                        <div className="cart-flex-4">
                            <div className='product-price mr-1'>
                                <div className='dot'></div> Price: {product?.currency?.symbol} {Utils.formatAmountStringStrict(+product?.price)}</div>
                        </div>
                    </div>
                </div>

                <div className='col-2'>
                    {
                        this.user.accessRights[ActionConstant.ADD_TO_CART] ?
                            <AddCartItem onChange={(e) => this.onChangeQuantity(e, product, i, groupIndex)} value={product.quantity} onSubmit={() => this.onClickQuantityConfirm(product, i, groupIndex)} onClickMinus={() => { this.onClickMinus(i, groupIndex) }} onClickAdd={() => this.onClickAdd(i, groupIndex)} />
                            : <div className='category-name'>{product.quantity}</div>
                    }
                </div>

                <div className='col-4 text-right'>
                    <span className='total-text'><span className='total-text-value'>{product.currency.symbol} {Utils.formatAmountStringStrict((+product.price * product.quantity))}</span></span>
                </div>
            </div>)
        }

        return productElements
    }

    onAddQuantity = (quantity) => {
        this.state.selectedProduct.quantity = quantity;
        this.closePreview()
        this.updateCartItem(this.state.selectedProduct)
    }

    closePreview = () => {
        this.setState({ showProductPreview: false })
    }

    handleBack = () => {
        this.props.history.replace("/my-shop")
    }

    gotoMyOrder = () => {
        this.props.history.replace("/my-orders")
    }


    render() {

        if (this.state.loading) {
            return <div className='loading-wrapper'><Loading></Loading></div>
        }
        return (
            this.state.showOrderSuccess ?
                <div className='order-success'>
                    <div className='text-center'>
                        <img className='order-success-image' src={getImageSource(ImageConstants.ORDER_SUCCESS)}></img>
                        <div className='order-placed-title mt-3'>Order Placed Successfully.</div>
                        <div className='order-placed-sub-title mt-3'>Your order has been placed successfully. Please checkout more information under your orders.</div>
                        <div className='app-button-primary mt-3' onClick={this.gotoMyOrder}>Go to my orders</div>
                        <br />
                        <div className='app-outline-button mt-3' onClick={this.handleBack}>+ Create New Order</div>
                    </div>

                </div> :
                <div className='cart-details'>
                    <div className='row'>
                        <div className='col-12'>
                            <img src={getImageSource(ImageConstants.FULL_ARROW_LEFT)} className='arrow-left cursor-pointer' onClick={() => this.handleBack()}></img>
                            <span className='cart-title pl-5 pt-5'>Your  cart</span>
                        </div>
                    </div>


                    {this.state.groupByProducts?.length ? this.state.groupByProducts.map((item, index) => (<div className='mb-5'>
                        <div className='cart-card'>
                            <div className='cart-card-header'>
                                <img src={getImageSource(ImageConstants.CROSS_ICON)} className='remove-cart cursor-pointer' onClick={() => { this.removeCartGroup(item, index) }}></img>
                                <span className='vendor-name-title pl-3'>VENDOR:</span>
                                <span className='vendor-name pl-2'>{item.name}</span>
                            </div>

                            {this.renderProducts(item.products, index)}

                            <div className="row m-0 px-2">
                                <div className='col note-disabled-wrapper'>
                                    <input placeholder={'Special Instructions for the Vendor'} onChange={(e) => this.onChangeSummary(e, index)} className='note-disabled' />
                                </div>
                                <div className='col-4 pt-2 text-right'>
                                    <span className='total-text'>Sub Total: <span className='total-text-value'>{item.currency?.symbol}{Utils.formatAmountStringStrict(item.total)}</span></span>
                                </div>
                            </div>

                            <div className={`cursor-pointer delivery-type ${item.openDeliveryPopup ? 'border-radius-null' : ""}`} onClick={() => this.onClickDiliveryTypeSelection(item, index)} ref={ref => this.dropdownRef = ref}>
                                <span className='delivery-text'>{item?.deliveryType?.title}</span>
                                <span><img className='blue-down-arrow ml-3' src={getImageSource(ImageConstants.BLUE_DOWN_ARROW)}></img></span>

                                {item.openDeliveryPopup ? <div className='type-selection'>
                                    {this.state.deliveryTypes.map((type, indexx) => (
                                        <div className={`type-selection-padding ${indexx < this.state.deliveryTypes.length ? 'type-selection-border' : ""}`} onClick={() => this.onSelectDeliveryType(type, index)}>{type.title}</div>
                                    ))}
                                </div> : <></>}
                            </div>

                        </div>

                    </div>)) : <></>}

                    {this.state.groupByProducts?.length ? <div className='cart-details-footer'>
                        <div className='d-inline-block'>
                            <div className='grand-total-header'>GRAND TOTAL</div>
                            <div className='total-amount'>{this.getTotal()}</div>
                        </div>

                        {
                            this.user.accessRights[ActionConstant.PLACE_ORDER] ?
                                <div className='d-inline-block mr-5 ml-3'>
                                    <div className='app-button-primary' onClick={this.onConfirmOrder}>Place Order</div>
                                </div>
                                : ""}
                    </div> : ""}

                    {this.state.showProductPreview ? <ProductPreview product={this.state.selectedProduct} close={this.closePreview} onAdd={(quantity) => this.onAddQuantity(quantity)} /> : <></>}
                </div>
        );
    }

}

export default CartDetails;