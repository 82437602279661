import HttpClient from './http-client';
import URL from './url';

export default class Cart {


    static getCartItems(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.CART_ITEM + "?" + params.join('&') : URL.CART_ITEM;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static createCartItem(payload) {
        return HttpClient.post(URL.BASE_URL + URL.CART_ITEM, null, payload);
    }

    static updateCartItem(payload) {
        return HttpClient.put(URL.BASE_URL + URL.CART_ITEM, null, payload);
    }
    static removeCartItem(payload) {
        return HttpClient.delete(URL.BASE_URL + URL.CART_ITEM, null, payload);
    }
}
