import { faBuilding, faCheck, faPlus, faSchool, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Payment from '../../home/apis/payment';
import Loading from '../../home/components/common/loading/loading';
import SessionCache from '../../home/components/routes/session-cache';
import ImageConstants from '../../home/constants/image-constants';
import StaticText from '../../home/shared-store/static-text-store';
import { getImageSource } from '../../home/utility/image-source';
import "./profile.scss";
import AppConfirmModal from '../../home/components/common/app-confirm/app-confirm';
import ToasterComponent from '../../home/components/common/toaster/toaster';
import CommonUtils from '../../home/utility/common-utilities';

class PaymentSetting extends React.Component {
   state = {
      staticText: {},
      sellerAccount: null,
      loading: true,
      defaultLoading: true,
      accounts: []
   }

   user = {}
   accessRights = {}


   componentDidMount() {
      document.addEventListener('mousedown', this.handleClickEvent, false);

      let staticText = StaticText.getStaticText("profile")
      this.setState({ staticText })
      this.user = SessionCache.getUser()

      this.accessRights = this.user.accessRights || {}

      this.getSellerAccount()
   }


   componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickEvent, false);
   }

   handleClickEvent = (event) => {
      if (this.listRef && !this.listRef.contains(event.target)) {
         this.hideLinkAccount()
      }
   }

   getSellerAccount(payload = {}) {
      Payment.getSellerAccount(payload).then((response) => {
         if (!response) {
            this.getSellerAccountByTenant()
            this.setState({ sellerAccount: null })
         } else {
            this.setState({ sellerAccount: response, loading: false, defaultLoading: false })
         }
      }).catch((err) => {
         this.setState({ loading: false, defaultLoading: false })
      })
   }

   getSellerAccountByTenant(payload = {}) {
      Payment.getSellerAccountByTenant(payload).then((response) => {
         this.setState({ accounts: response.data || [], loading: false, defaultLoading: false })
      }).catch((err) => {
         this.setState({ loading: false, defaultLoading: false })
      })
   }

   createSellerAccount() {
      this.setState({ loading: true })
      Payment.createSellerAccount({}).then((response) => {
         this.setState({ loading: false })
         window.location.assign(response.data.onboardLink)
      }).catch((err) => {
         this.setState({ loading: false })
         console.error(err)
      })
   }

   onClickLinkAccount() {
      if (!this.state.accounts?.length) this.createSellerAccount()
      else this.setState({ showLinkAccount: true })
   }

   hideLinkAccount() {
      this.setState({ showLinkAccount: false })
   }

   onClickStripeAccount = () => {
      if (this.state?.loading) return
      if (!this.state?.sellerAccount) return this.createSellerAccount()
      if (!this.state?.sellerAccount?.serviceProvider?.isDetailsSubmitted || this.state.sellerAccount?.status == "INACTIVE") return window.location.assign(this.state?.sellerAccount?.onboardLink)

      // window.open('https://dashboard.stripe.com/dashboard', '_blank').focus()
   }

   getStripeAccountAction() {

      if (this.state?.loading) {
         return (
            <div className="d-inline-block ">
               <span className="position-relative dot-wrapper ">
                  <span className="active-text pl-2"><FontAwesomeIcon icon={faSyncAlt} spin /> {this.state.staticText?.loadingText || ' Fetching account info'}</span>
               </span>
            </div>)

      }

      if (!this.state?.sellerAccount) {
         return (
            <button className="app-button-primary mr-2 cursor-pointer" disabled={this.state.loading} onClick={() => this.onClickLinkAccount()}>
               <span className="pl-2">{this.state.staticText?.linkStripeText || 'Link account'}</span>
            </button>
         )
      }

      if (this.state.sellerAccount?.status == "INACTIVE") {
         return (
            <div className="d-inline-block cursor-pointer">
               <span className="continue-button" disabled={this.state.loading} onClick={this.onClickStripeAccount}>
                  <img alt="" className="radio" src={getImageSource(ImageConstants.RADIO_YELLOW)} />
                  <span className="px-2">{this.state.staticText?.completeStripeOnboardingText || 'Continue Setup'}</span>
                  <img alt="" className="arrow-right" src={getImageSource(ImageConstants.RIGHT_ARROW_BLUE)} />
               </span>
            </div>
         )
      }

      if (this.state.sellerAccount?.status == "VERIFICATION_PENDING") {
         return (
            <div className="d-inline-block pt-1">
               <span className="position-relative dot-wrapper ">
                  <div className="draw-dot yellow-dot d-inline-block">&nbsp;</div>
                  <span className="pending-text pl-2">{this.state.staticText?.pendingText || 'Verification Pending'}</span>
               </span>
            </div>
         )
      }

      if (this.state.sellerAccount?.status === "ACTIVE") {
         return (
            <div className="status-container pt-1">
               <span className="position-relative dot-wrapper text-right">
                  <div className="draw-dot d-inline-block">&nbsp;</div>
                  <span className="active-text border-right px-2">{this.state.staticText?.activeText || 'Active'}</span>
                  <span className="remove-text pl-2" onClick={this.onCLickRemove}>{this.state.staticText?.removeText || "Remove"}</span>
               </span>

            </div>
         )
      }

      return ""
   }

   onSelectAccount = (item) => {
      this.setState({ showConfirm: true, selectedItem: item, showLinkAccount: false })
   }

   hideConfirmModal = () => {
      this.setState({ showConfirm: false, selectedItem: null, showLinkAccount: true })
   }

   onCLickRemove = () => {
      this.setState({ showRemoveConfirm: true })
   }

   handleClose = () => {
      this.setState({ showRemoveConfirm: false })
   }

   onRemoveAccount = () => {
      Payment.removeBranchPaymentAccount({}).then((response) => {
         this.setState({ showRemoveConfirm: false, loading: false })
         this.showToaster("success", "Account delinked successfully")
         this.getSellerAccount()
      }).catch((err) => {
         this.showToaster("error", "Something went wrong")
         this.setState({ showRemoveConfirm: false, loading: false })
      })
   }

   onLinkAccount = () => {
      let payload = { accountId: this.state.selectedItem?.id }
      this.setState({ loading: true })
      Payment.updateBranchPaymentAccount(payload).then((response) => {
         this.setState({ showConfirm: false, loading: false })
         this.showToaster("success", "Payment account linked successfully")
         this.getSellerAccount()
      }).catch((err) => {
         this.showToaster("error", "Something went wrong")
         this.setState({ loading: false, showConfirm: false })
      })

   }


   showToaster(type, message) {
      this.setState({
         showToaster: true,
         toaster: {
            type,
            message,
         }
      }, () => {
         setTimeout(() => this.hideToaster(), 3000)
      })
   }

   hideToaster() {
      this.setState({ showToaster: false })
   }

   renderAccount = () => {

      let elements = []

      for (let i = 0; i < this.state.accounts.length; i++) {

         let item = this.state.accounts[i]
         elements.push(<div className={`cursor-pointer account-title-wrapper ${i !== 0 ? "" : ""}`} onClick={() => this.onSelectAccount(item)}>
            <div className="d-flex align-items-center account-name">
               <span className={`px-4 text-left`}>{item.branch?.businessName}</span>
            </div>
         </div>)
      }
      return elements
   }

   renderLinkAccount() {
      return (
         <div className='linkpop-over app-box-shadow' ref={ref => this.listRef = ref}>
            <div className="arrow-up"></div>
            <div className='filter-header'><span className="pr-2">{"Linked Accounts"}</span> </div>

            <div className='row m-0'>
               <div className='col p-0'>
                  <div className="account-list">
                     {this.renderAccount()}
                  </div>
               </div>
            </div>

            <div className='card-footer'>
               <div className='apply-button' onClick={this.onClickStripeAccount.bind(this)}>
                  {this.state.staticText?.addNewAccountText || "Add New Account"}
               </div>
            </div>
         </div>
      )
   }

   render() {

      if ((this.props.from === "INVOICE" && this.state.sellerAccount?.status === "ACTIVE") || (this.props.from === "INVOICE" && this.state.defaultLoading)) return ""
      return (
         <div className="profile payment-card">
            <div className="p-4 d-flex align-items-center">
               <div className="cp-flex-1 text-center">
                  <img className="icon-20" alt="" src={getImageSource(ImageConstants.BANK)} />
               </div>

               <div className="cp-flex-2">
                  <div className="pl-4">
                     <div className="semi-bold-text">
                        {
                           this.state.sellerAccount?.status == "ACTIVE" ? this.state.staticText?.myAccountText :
                              this.state.sellerAccount?.status == "INACTIVE" ?
                                 this.state.staticText?.continuePaymentTitle :
                                 this.state.sellerAccount?.status == "VERIFICATION_PENDING" ? this.state.staticText?.verifyDetailTitle :
                                    this.state.staticText?.paymentTitle
                        }
                     </div>
                     <div>
                        {
                           this.state?.sellerAccount?.bankAccount?.accountNumberLast4 &&
                           <div className='bank-account-number-container text-right'>
                              <span className='bank-account-number-text bank-account-number-text-star'>
                                 ****
                              </span>
                              <span className='bank-account-number-text'>
                                 {this.state?.sellerAccount?.bankAccount?.accountNumberLast4}
                              </span>
                           </div>
                        }
                     </div>
                  </div>
               </div>

               <div className="cp-flex-3 px-4">
                  {this.getStripeAccountAction()}
                  <div>
                     {this.state.showLinkAccount ? this.renderLinkAccount() : ""}
                  </div>
               </div>
            </div>
            {
               this.state.showConfirm ?
                  <AppConfirmModal
                     img={getImageSource(ImageConstants.TICK_MARK)}
                     title={CommonUtils.simpleTemplateEngine(this.state.staticText?.linkAccountTitle, { branchName: this.state?.selectedItem?.branch?.businessName || "" })}
                     close={this.hideConfirmModal}
                     onOk={this.onLinkAccount}
                  />
                  :
                  ""
            }
            {
               this.state.showRemoveConfirm ?
                  <AppConfirmModal
                     img={getImageSource(ImageConstants.CLOSE_RED)}
                     okButtonText={this.state.staticText?.removeText}
                     okButtonStyle="reject-text"
                     title={this.state.staticText?.removeTitle}
                     close={this.handleClose}
                     onOk={this.onRemoveAccount} />
                  : ""
            }
            {this.state.showToaster ? <ToasterComponent type={this.state.toaster?.type} message={this.state.toaster?.message} /> : ""}
         </div >
      )
   }
}

export default PaymentSetting;