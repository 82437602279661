import HttpClient from './http-client';
import URL from './url';

export default class Report {

    static getSalesReport(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SALES_REPORT + "?" + params.join('&') : URL.SALES_REPORT;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getTopCustomer(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.TOP_CUSTOMER + "?" + params.join('&') : URL.TOP_CUSTOMER;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getTopVendor(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.TOP_VENDOR + "?" + params.join('&') : URL.TOP_VENDOR;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getTopPurchedProduct(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.TOP_PURCHASED_PRODUCT + "?" + params.join('&') : URL.TOP_PURCHASED_PRODUCT;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getTopProduct(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.TOP_PRODUCT + "?" + params.join('&') : URL.TOP_PRODUCT;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getSalesChartReport(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SALES_CHART + "?" + params.join('&') : URL.SALES_CHART;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }


    static getCustomerOrderStatictics(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.ORDER_STATICTICS + "?" + params.join('&') : URL.ORDER_STATICTICS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

}
