import React from 'react';
import "./sales-chart.scss";

import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../..//utility/image-source';
import StaticText from '../../../shared-store/static-text-store';
import ReportApi from '../../../apis/report';

import AppLoader from '../../common/app-loader/app-loader';

import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';


class SalesCharts extends React.Component {
    state = {
        staticText: {},
        currency: { symbol: "$" },
        chartData: []
    }

    user = {}
    componentDidMount() {

        let staticText = StaticText.getStaticText("dashboard")
        this.setState({ staticText })
        this.getReport()
    }


    getReport = (payload = {}) => {

        this.setState({ isLoading: true })
        ReportApi.getCustomerOrderStatictics(payload).then((response) => {
            let data = response
            this.formatChartDate(data)

            this.setState({ report: data })
        }).catch((err) => {
            this.setState({ isLoading: false, report: {} })
        })
    }

    formatChartDate(data) {

        let arr = [
            {
                key: "Delivered",
                "value": data?.delivered || 0,
                color: "#1778F2"
            },
            {
                key: "Cancelled",
                "value": data?.cancelled || 0,
                color: "#FF3F6C"
            },
            {
                key: "In Progress",
                "value": data?.inProgress || 0,
                color: "#F2B94A"
            }
        ]


        this.setState({ chartData: arr || [], isLoading: false })
    }

    renderChart() {

        return (

            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={800} height={800} onMouseEnter={this.onPieEnter}>
                    <Pie
                        data={this.state.chartData}
                        innerRadius={80}
                        outerRadius={120}
                        paddingAngle={5}
                        dataKey="value"
                    >
                        {this.state.chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>

        )

    }


    render() {

        return (
            <div className="sales-chart-1">
                {
                    this.state.report ?
                        <div className="card">
                            <div className="chart-wrapper">
                                <div className="chart-container flex-1">
                                    <div className="total-order">
                                        <div className="order-value text-center">{this.state.report?.totalOrder || 0}</div>
                                        <div className="title text-center"> {this.state.staticText?.totalOrderText}</div>

                                    </div>
                                    {this.renderChart()}
                                </div>
                                <div className="flex-1 border-left">
                                    <div className="card-header">
                                        <div className="cart-title">{this.state.staticText?.staticticsText}</div>
                                    </div>

                                    <div className="p-4">

                                        <div className="d-flex pt-4">
                                            <div>
                                                <img alt="" className="icon-50" src={getImageSource(ImageConstants.ORDER_BLUE)} />
                                            </div>
                                            <div className="pl-3">
                                                <div className="order-title">{this.state.staticText?.deliveredText} </div>
                                                <div className="order-count">{this.state.report?.delivered || 0}</div>
                                            </div>
                                        </div>

                                        <div className="d-flex pt-4">
                                            <div>
                                                <img alt="" className="icon-50" src={getImageSource(ImageConstants.ORDER_RED)} />
                                            </div>
                                            <div className="pl-3">
                                                <div className="order-title">{this.state.staticText?.cancelledText} </div>
                                                <div className="order-count">{this.state.report?.cancelled || 0}</div>
                                            </div>
                                        </div>

                                        <div className="d-flex pt-4">
                                            <div>
                                                <img alt="" className="icon-50" src={getImageSource(ImageConstants.ORDER_YELLOW)} />
                                            </div>
                                            <div className="pl-3">
                                                <div className="order-title">{this.state.staticText?.inProgressText} </div>
                                                <div className="order-count">{this.state.report?.inProgress || 0}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        : <div className="card sales-card">
                            <div className="text-center">
                                {
                                    this.state.isLoading ? <AppLoader size="2x" /> :
                                        <>
                                            <img alt="" className="sales-image" src={getImageSource(ImageConstants.SALES)} />
                                            <div className="pt-4 bold-text font-16"> {this.state.staticText?.noSalesTitle}</div>
                                            <div className="no-data-description"> {this.state.staticText?.noSalesDescription}</div>
                                        </>}
                            </div>
                        </div>
                }
            </div>
        );
    }

}

export default SalesCharts;