import HttpClient from './http-client';
import URL from './url';

export default class AuthenticateAPI {

    static isLogin(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.IS_LOGGED_IN + "?" + params.join('&') : URL.IS_LOGGED_IN;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getStaticText(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.CONFIURATIONS + "?" + params.join('&') : URL.CONFIURATIONS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static verifyCubeId(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.VERIFY_CUBE_ID + "?" + params.join('&') : URL.VERIFY_CUBE_ID;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }


    static logout(payload) {
        return HttpClient.post(URL.BASE_URL + URL.LOGOUT, null, payload);
    }

    static login(payload) {
        return HttpClient.post(URL.BASE_URL + URL.LOGIN, null, payload);
    }

    static signup(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SIGNUP, null, payload);
    }

    static loginUsingClient(payload) {
        return HttpClient.post(URL.BASE_URL + URL.LOGIN_CLIENT, null, payload);
    }


    static sendSignupOtp(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SEND_SINGUP_OTP, null, payload);
    }

    static forgortPassword(payload) {
        return HttpClient.post(URL.BASE_URL + URL.SEND_FORGORT_PASSWORD_OTP, null, payload);
    }

    static validateForgortPassword(payload) {
        return HttpClient.post(URL.BASE_URL + URL.VALIDATE_FORGORT_PASSWORD_OTP, null, payload);
    }

    static validateForgortPasswordAndUpdate(payload) {
        return HttpClient.post(URL.BASE_URL + URL.FORGORT_PASSWORD_UPDATE, null, payload);
    }


    static changeBranch(payload) {
        return HttpClient.post(URL.BASE_URL + URL.CHANGE_BRANCH, null, payload);
    }

    static changePassword(payload) {
        return HttpClient.put(URL.BASE_URL + URL.CHANGE_PASSWORD, null, payload);
    }


    static updateTenant(payload) {
        return HttpClient.put(URL.BASE_URL + URL.TENANT, null, payload);
    }

    static updateTenantPassword(payload) {
        return HttpClient.put(URL.BASE_URL + URL.TENANT_PASSWORD, null, payload);
    }

    static staffLogin(payload) {
        let url = `/api/branch/${payload.cubeId}/login`
        return HttpClient.post(URL.BASE_URL + url, null, payload);
    }

    static updateCurrentSessionBranchType(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_SESSION_TYPE, null, payload);
    }

}
