
export default class Service {
    static cart
    static header


    static setCart(item) {
        this.cart = item
    }

    static getCart() {
        return this.cart
    }

    static setHeader(item) {
        this.header = item
    }

    static getHeader() {
        return this.header
    }

}
