import Service from "../service/service"

export default class Store {

    static store = {};

    static setStore(key, value) {

        this.store[key] = value

        if (key == "cart" && Service.getCart()) Service.getCart().refreshCart()
        if (key == "cart" && Service.getHeader()) Service.getHeader().refreshCart()
    }

    static getStore(key) {
        return this.store[key]

    }

};