import React from 'react';
import "./calendar.scss";
import orderApi from '../../../apis/order'

import ImageConstants from '../../../constants/image-constants';
import Utils from '../../../utility/common-utilities'
import { getImageSource } from '../../../utility/image-source'
import { addMonths, subMonths, isSameDay, startOfMonth, format, addDays, startOfWeek, isSameMonth, endOfMonth, endOfWeek, addWeeks, subWeeks } from "date-fns";
import Constants from '../../../constants/constants';
import Path from '../../routes/routes-path';
import OrderDetail from '../orders/order-detail/order-detail';

class Calendar extends React.Component {

    constructor(props) {
        super(props)

        this.getOrdersWithRange()
    }

    state = {
        currentMonth: new Date(),
        // selectedDate: new Date(),
        currenDate: new Date(),
        currentWeek: new Date(),
        activeCalendar: "MONTH",
        selectedOrders: [],
        orders: {
        },
        expandViewList: [
            {
                title: "Orders",
                path: Path.MY_ORDERS
            },
            {
                title: "Order fullfilment calendar",
                isActive: true
            }
        ]
    };

    page = 1
    totalPages = 1

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickEvent, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }

    handleClickEvent = (event) => {
        if (this.headerRef && !this.headerRef.contains(event.target)) {
            this.setState({ showProductExpandView: false })
        }

    }


    getOrders(date, callback) {
        let payload = {
            startDate: date.setHours(0, 0, 0, 0),
            endDate: date.setHours(23, 59, 59, 999),
        }

        if (this.page)
            payload.page = this.page

        this.setState({ loading: true })
        orderApi.getOrders(payload).then((response) => {

            this.setState({ loading: false })

            let orders = []

            if (response.totalPages)
                this.totalPages = response.totalPages;

            for (let i = 0; i < response.response.length; i++) {
                let order = response.response[i]

                let price = order.totalPrice
                orders.push({
                    ...order,
                    name: order.vendorBranch ? order.vendorBranch.name : "",
                    price,
                    currency: order.items && order.items.length ? order.items[0].currency : {}
                })
            }
            callback && callback(orders)
        }).catch((err) => {
            this.setState({ loading: false })
            callback && callback(null)
        })
    }

    onClickLoadMore = () => {

        this.page += 1
        this.getOrders(this.state.selectedDate, (orders) => {
            if (orders) {
                let selectedOrders = this.state.selectedOrders
                selectedOrders = selectedOrders.concat(orders)
                this.setState({ selectedOrders })
            }
        })
    }

    getOrdersWithRange(date) {

        let startDate = startOfMonth(this.state.currentMonth)
        let endDate = endOfMonth(this.state.currentMonth)

        let payload = {
            startDate: startDate.setHours(0, 0, 0, 0),
            endDate: endDate.setHours(23, 59, 59, 999),
            timezone: Utils.getTimeZoneString()//encodeURIComponent(Utils.getTimeZone())
        }

        this.setState({ loading: true })
        orderApi.getOrdersWithRange(payload).then((response) => {
            this.setState({ orders: response, loading: false })
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }


    showProductExpand = () => {
        this.setState({ showProductExpandView: !this.state.showProductExpandView })
    }

    onClickHeaderNavigation = (item) => {

        this.setState({ showProductExpandView: false })

        if (item.isActive || !item.path) {
            return
        }

        this.props.history.push(item.path)
    }


    renderProductExpandView = () => {

        let elements = []

        for (let i = 0; i < this.state.expandViewList.length; i++) {
            let item = this.state.expandViewList[i]
            elements.push(
                <div className={`name-wrapper`} onClick={() => this.onClickHeaderNavigation(item)}>
                    <div className={`name ${item.isActive ? 'name-active' : ""}`}>
                        {item.isActive ? <img alt="" src={getImageSource(ImageConstants.GREEN_TICK_NEW)} className='green-tick-new'></img> : ""}
                        {item.title}
                    </div>
                </div>)

        }

        return <div className='product-expand-view' ref={ref => this.headerRef = ref}>
            {elements}
        </div>
    }



    renderHeader() {
        const dateFormat = "MMMM yyyy";

        return (
            <div>

                <div className='row mb-4 mr-0'>
                    <div className='col-1'>
                        <div className="current-date-month">
                            <span>{format(this.state.activeCalendar == 'WEEK' ? this.state.currentWeek : this.state.currentMonth, dateFormat)}</span>
                        </div>
                    </div>

                    {this.state.activeCalendar == 'MONTH' ? <div className='col-5'>
                        <div className='d-inline-block'>
                            <div className='row'>
                                <div className="col pr-0">
                                    <div className="left-arrow-icon cursor-pointer" onClick={this.prevMonth}>
                                        <img className='arrow-icon-image' src={getImageSource(ImageConstants.ARROW_LEFT)}></img>
                                    </div>
                                </div>
                                <div className="col" onClick={this.nextMonth}>
                                    <div className="left-arrow-icon cursor-pointer">
                                        <img className='arrow-icon-image' src={getImageSource(ImageConstants.ARROW_RIGHT)}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <div className='col-5'></div>}

                    <div className='col-6 text-right'>
                        <div className='calendar-switch cursor-pointer row'>
                            <div className={`col calendar-switch-toggle ${this.state.activeCalendar == 'WEEK' ? 'calendar-switch-toggle-active' : ""}`} onClick={() => this.calendarToggle("WEEK")}>Week</div>
                            <div className={`col calendar-switch-toggle ${this.state.activeCalendar == 'MONTH' ? 'calendar-switch-toggle-active' : ""}`} onClick={() => this.calendarToggle("MONTH")}>Month</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDaysForWeekly() {

        let days = [];
        let day = startOfWeek(this.state.currentWeek);

        for (let i = 0; i < 7; i++) {
            let dateFormat = format(day, "d")
            let isSelected = isSameDay(day, this.state.selectedDate)
            let cloneDay = day
            days.push(
                <div className={`col days-text-weekly cursor-pointer ${isSelected ? "selected-date" : ""} ${i < 6 ? 'days-text-weekly-border' : ""} col-center-weekly`} key={i} onClick={() => { this.onClickWeeklyDate(cloneDay) }}>
                    <div className={`weekly-date-text ${isSelected ? "selected-text" : ""}`}>{dateFormat}</div>
                    <div className={`weekly-date-text-small ${isSelected ? "selected-text" : ""}`}>{format(day, "EEEE")}</div>
                </div>
            );

            day = addDays(day, 1)
        }

        return (<div className="days-weekly row m-0">
            <div className="left-arrow-icon-weekly" onClick={this.prevWeek}>
                <img className='arrow-icon-image' src={getImageSource(ImageConstants.ARROW_LEFT)}></img>
            </div>
            {days}
            <div className="right-arrow-icon-weekly" onClick={this.nextWeek}>
                <img className='arrow-icon-image' src={getImageSource(ImageConstants.ARROW_RIGHT)}></img>
            </div>
        </div>);
    }

    renderDays() {
        const dateFormat = "EEEE";
        const days = [];

        let startDate = startOfWeek(this.state.currentMonth);

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className={`col days-text ${i < 6 ? 'days-text-border' : ""} col-center`} key={i}>
                    {format(addDays(startDate, i), dateFormat).toUpperCase()}
                </div>
            );
        }

        return <div className="days row m-0">{days}</div>;
    }

    renderOrders = () => {

        let orders = []

        for (let i = 0; i < this.state.selectedOrders.length; i++) {

            let order = this.state.selectedOrders[i]

            orders.push(<div className='orders-row row cursor-pointer' onClick={() => this.showOrderDetail(order)}>
                <div className='col-1 pr-0'>
                    <img className='order-left-image' src={getImageSource(this.getImageByStatus(order))}></img>
                </div>
                <div className='col'>
                    <span className='order-number'>{order.orderNumber}</span>
                </div>
                <div className='col'>
                    <span className='business-name'>{order.name}</span>
                </div>
                <div className='col'>
                    <span className='ordered-date'>{Utils.datePipe(order.createdDate, Constants.DATE_FORMAT_1)}</span>
                </div>
                <div className='col'>
                    <span className='delivery-date'>{order.status == Constants.ORDER_STATUS.NEW ? "NEW ORDER" : Utils.datePipe(order.deliveryDate, Constants.DATE_FORMAT_1)}</span>
                </div>
                <div className='col'>
                    <span className={`order-status-badge position-relative ${this.getStatusBadge(order.status)}`}><div className="d-inline-block draw-dot ">&nbsp;</div><span className='pl-2'>{this.getStatusText(order.status)}</span></span>
                </div>

                <div className='col'>
                    <span className='currency'>{order.currency.symbol + '' + Utils.formatAmountStringStrict(+order.price)}</span>
                </div>
            </div>)
        }

        return <div className='orders pt-4 text-center'>{orders}
            {this.totalPages && this.totalPages > 1 && this.page < this.totalPages ? <div className='text-center load-more d-inline-block cursor-pointer' onClick={() => this.onClickLoadMore()}><span>+ LOAD MORE</span></div> : ""}
        </div>
    }

    getStatusText = (status) => {

        return Constants.ORDER_STATUS_TEXT[status] ? Constants.ORDER_STATUS_TEXT[status] : status
    }

    renderOrderForBranch = (date) => {

        let dateString = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();//date.toLocaleDateString()
        let orders = this.state.orders[dateString] ? this.state.orders[dateString].orders : null;
        let totalOrder = this.state.orders[dateString] ? this.state.orders[dateString].totalOrder : 0

        if (!orders)
            return <></>

        let orderElements = []

        for (let i = 0; i < orders.length; i++) {
            let order = orders[i]

            if (i == 2 && totalOrder > 3) {
                orderElements.push(
                    <div className='row'>
                        <div className='col-9 pr-0'>
                            <div className={`${this.getStatusBadge(order.status)} order-badge`}>{Utils.ellipsis(order?.vendorBranch?.name, 8)}</div>)
                        </div>
                        <div className='p-0 col-3 pl-1'>
                            <div className='extra-order' onClick={() => this.onClickExtraOrder(date, orders)}>+{totalOrder - 3}</div>
                        </div>
                    </div >)
            } else
                orderElements.push(<div className={`${this.getStatusBadge(order.status)} order-badge`} onClick={() => this.onClickExtraOrder(date, orders)}>{Utils.ellipsis(order?.vendorBranch?.name, 10)}</div>)

            if (i == 2)
                break;
        }
        return <div className='orders-branch'>{orderElements}</div>
    }

    getStatusBadge(status) {
        if (status == Constants.ORDER_STATUS.NEW) return "order-new"
        if (status == Constants.ORDER_STATUS.PAST_DUE) return "order-due"
        if (status == Constants.ORDER_STATUS.PENDING) return "order-pending"
        if (status == Constants.ORDER_STATUS.PARTIAL) return "order-partial"
        if (status == Constants.ORDER_STATUS.COMPLETED || status == Constants.ORDER_STATUS.REJECTED) return "order-complete"

        return ImageConstants.ORDER_NEW
    }

    onClickExtraOrder = (date, orders) => {
        this.setState({ currentWeek: date, selectedDate: date, selectedOrders: [], activeCalendar: "WEEK" }, () => {
            this.onClickWeeklyDate(date)
        })
    }

    renderCells() {
        let { currentMonth, currenDate } = this.state;
        let monthStart = startOfMonth(currentMonth);
        let monthEnd = endOfMonth(monthStart);
        let startDate = startOfWeek(monthStart);
        let endDate = endOfWeek(monthEnd);

        let dateFormat = "d";
        let rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = "";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                let cloneDay = day;
                days.push(
                    <div
                        className={`col calendar-cell ${i < 6 ? 'calendar-cell-border-right' : ""} ${rows.length < 4 ? 'calendar-cell-border-bottom' : ""} ${!isSameMonth(day, monthStart)
                            ? "disabled-cell" : ""
                            }`}
                        key={day}
                        onClick={() => this.onDateClick(cloneDay)}
                    >
                        <div className={`calendar-number ${isSameDay(day, currenDate) ? "selected" : ""} ${!isSameMonth(day, monthStart) ? 'disabled-cell-number' : ""}`}>{formattedDate}</div>
                        {!isSameMonth(day, monthStart) ? <div className='disabled-bar-wrapper'>
                            {this.renderDiabledBars()}
                        </div> : ""}

                        {isSameMonth(day, monthStart) ? this.renderOrderForBranch(cloneDay) : <></>}
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div className="row m-0" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="calendar-row-wrapper">{rows}</div>;
    }

    onClickWeeklyDate = (date) => {
        // let dateString = date.toLocaleDateString()

        this.totalPages = 1;
        this.page = 1
        this.getOrders(date, (order) => {

            if (!order) {
                this.setState({ selectedDate: date, selectedOrders: [], currentMonth: date, currentWeek: date })
            }

            if (order) {
                this.setState({ selectedDate: date, selectedOrders: order, currentMonth: date, currentWeek: date })
            }
        })

    }

    renderDiabledBars = () => {

        let bars = []
        for (let i = 0; i < 10; i++) {
            bars.push(<div className='disabled-bar'></div>)
        }

        return bars
    }

    onDateClick = day => {
        this.setState({
            selectedDate: day
        });
    };

    nextWeek = () => {
        this.setState({
            currentWeek: addWeeks(this.state.currentWeek, 1)
        }, () => {
            this.setState({ currentMonth: this.state.currentWeek })
        });
    };

    prevWeek = () => {
        this.setState({
            currentWeek: subWeeks(this.state.currentWeek, 1)
        }, () => {
            this.setState({ currentMonth: this.state.currentWeek })
        });
    };

    nextMonth = () => {
        this.setState({
            currentMonth: addMonths(this.state.currentMonth, 1)
        }, () => {
            this.setState({ currentWeek: startOfMonth(this.state.currentMonth) })
            this.getOrdersWithRange()
        });
    };

    prevMonth = () => {
        this.setState({
            currentMonth: subMonths(this.state.currentMonth, 1)
        }, () => {
            this.setState({ currentWeek: startOfMonth(this.state.currentMonth) })
            this.getOrdersWithRange()
        });
    };

    getImageByStatus(item) {
        if (item.status == Constants.ORDER_STATUS.NEW) return ImageConstants.ORDER_NEW
        if (item.status == Constants.ORDER_STATUS.PAST_DUE) return ImageConstants.ORDER_PAST_DUE
        if (item.status == Constants.ORDER_STATUS.PENDING) return ImageConstants.ORDER_PENDING
        if (item.status == Constants.ORDER_STATUS.REJECTED) return ImageConstants.ORDER_REJECT
        if (item.status == Constants.ORDER_STATUS.COMPLETED || item.status == Constants.ORDER_STATUS.PARTIAL) return ImageConstants.ORDER_COMPLETE

        return ImageConstants.ORDER_NEW
    }

    calendarToggle = (activeCalendar) => {
        this.setState({ activeCalendar, selectedOrders: [] }, () => {

            if (activeCalendar == "MONTH") {
                this.getOrdersWithRange()
            } else {
                this.onClickWeeklyDate(new Date())
            }
        })
    }

    showOrderDetail(item) {
        this.setState({ selectedOrder: item, showOrderDetail: true })
    }

    handleBack = () => {
        this.setState({ showOrderDetail: false }, () => {
            this.onClickWeeklyDate(this.state.selectedDate || new Date())
        })
    }

    render() {

        // if (this.state.loading)
        //     return <div className='loading-wrapper'>
        //         <Loading></Loading>
        //     </div>
        if (this.state.showOrderDetail) return <OrderDetail order={this.state.selectedOrder} back={this.handleBack} />

        return (
            <>
                {/* {this.state.loading ? <div className='loading-wrapper'>
                <Loading></Loading>
            </div> : ""} */}
                <div className="calendar">

                    <div className=''>
                        <div className='row'>
                            <div className="col">
                                <span className="tab mr-4" onClick={this.props.onClickBack}>My Orders</span>
                                <span className="tab active-tab">Order Calendar</span></div>
                        </div>

                        <div className="pt-4">

                            {this.state.activeCalendar == 'MONTH' ? <div>
                                {this.renderHeader()}
                                {this.renderDays()}
                                {this.renderCells()}
                            </div> : <></>}

                            {this.state.activeCalendar == 'WEEK' ? <div>
                                {this.renderHeader()}
                                {this.renderDaysForWeekly()}
                                {this.renderOrders()}
                            </div> : <></>}
                        </div>
                    </div>

                </div>
            </>
        );
    }
}


export default Calendar;