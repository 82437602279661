import React from 'react';
import "./price-setup.scss";
import productApi from '../../../apis/product'
import CustomerApi from '../../../apis/customer'
import { Modal, ModalBody } from "reactstrap";
import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../../utility/image-source'
import Dropdown from '../../common/dropdown/dropdown'
import ToasterComponent from '../../common/toaster/toaster';
import StaticText from '../../../shared-store/static-text-store';
import CommonUtils from '../../../utility/common-utilities';


class CreateProduct extends React.Component {

    constructor(props) {
        super(props)
        this.staticText = StaticText.getStaticText("priceSetup")

        if (this.props.product) {
            this.state.product = this.props.product
            this.getAlreadyAddedCustomer()
        }
    }

    totalPages = 1;
    page = 1

    state = {
        product: {},
        productList: [],
        currency: {
            //CHG000002  PB-7                  code: "DOLLER",
            code: "USD",                     //CHG000002  PB-7
            symbol: "$"
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickEvent, false);

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }


    onChangeHandler(event, key) {
        this.setState({ [key]: event?.target?.value }, () => {
        })
    }

    showToaster(type, message) {
        this.setState({
            showToaster: true,
            toaster: {
                type,
                message,
            }
        }, () => {
            setTimeout(() => this.hideToaster(), 3000)
        })
    }

    hideToaster() {
        this.setState({ showToaster: false })
    }

    onClickCreate = () => {

        this.setState({ isSubmit: false })

        if (this.props.product) {

            let payload = {
                productId: this.props.product._id,
                name: this.state.name,
                description: this.state.description,
                sellQuantity: { value: this.state.sellQuantity, unit: this.state.sellUnit },
                images: this.state.images,
                price: this.state.price,
                currency: this.state.currency,
                category: this.state.selectedCategoryObj,
                brand: this.state.selectedBrandObj,
                code: this.state.code
            }

            productApi.updateProduct(payload).then((response) => {
                this.showToaster("success", "Product update successfully.")
            }).catch((err) => {
                this.showToaster("error", "Oops somthing went wrong...")
            })

        } else {
            let payload = {
                products: [{
                    name: this.state.name,
                    description: this.state.description,
                    sellQuantity: { value: this.state.sellQuantity, unit: this.state.sellUnit },
                    images: this.state.images,
                    price: this.state.price,
                    currency: this.state.currency,
                    category: this.state.selectedCategoryObj,
                    brand: this.state.selectedBrandObj,
                    code: this.state.code
                }]
            }

            productApi.createProduct(payload).then((response) => {

                let recentlyAdded = this.state.recentlyAdded;
                recentlyAdded = recentlyAdded.concat(payload.products)
                this.setState({ recentlyAdded }, () => {
                    this.resetCreateProduct()
                })
                this.showToaster("success", "Product create successfully.")
            }).catch((err) => {
                this.showToaster("error", "Oops somthing went wrong...")
            })
        }
    }

    onClickRemove = (index) => {

        let images = this.state.images
        images.splice(index, 1)
        this.setState({ images })
    }

    onClickCancel = () => {
        this.props.cancel && this.props.cancel()
    }

    onFocusSearch(value) {
        this.setState({ isFocusSearch: value })
        // this.getProduct()
    }

    onFocusCustomerSearch(value) {
        this.setState({ isFocusCustomerSearch: value })
        // this.getNewCustomers()
    }


    getNewCustomers = (payload = {}) => {

        if (this.state.customerSearch) {
            payload.search = this.state.customerSearch
        }

        CustomerApi.getCustomers({}).then((response) => {
            this.setState({ customersList: response.response || [] })
        }).catch((err) => {
        })
    }
    onChangeCustomerSearch = (event) => {
        this.setState({ customerSearch: event?.target.value }, () => {

            if (this.state.customerSearch && this.state.customerSearch.length > 3) {
                this.getNewCustomers()
            } else if (!this.state.customerSearch) {
                this.setState({ customersList: [] })
            }
        })
    }


    getProduct(payload = {}) {

        if (this.state.search) {
            payload.search = this.state.search
        }

        productApi.getProducts(payload).then((response) => {
            this.setState({ productList: response.response || [] })
        }).catch((err) => {
        })
    }

    getAlreadyAddedCustomer = (append) => {
        let payload = {
            productId: this.state.product._id,
        }

        payload.page = this.page;

        productApi.getCustomerByProducts(payload).then((response) => {

            if (response.totalPages)
                this.totalPages = response.totalPages;

            this.setState({ customers: append ? this.state.customers.concat(response.response || []) : response.response || [] })
        }).catch((err) => {
        })
    }

    onChangeSearch = (event) => {
        this.setState({ search: event?.target.value }, () => {

            if (this.state.search && this.state.search.length > 3)
                this.getProduct()
            else if (!this.state.search) {
                this.setState({ productList: [] })
            }
        })
    }

    onSelectProduct = (e, product) => {
        e.stopPropagation();
        this.setState({ product, isFocusSearch: false, search: "" }, () => {
            this.getAlreadyAddedCustomer()
        })
    }

    onChangeCustomerPrice = (e) => {

        let selectedCustomer = this.state.selectedCustomer
        selectedCustomer.price = e.target.value
        this.setState({ selectedCustomer })
    }

    onSelectCustomer = (e, customer) => {
        this.setState({ isFocusCustomerSearch: false, customerSearch: "", selectedCustomer: customer })
    }

    onPriceUpdate(item, index, isRowUpdate) {

        this.setState({ isSubmit: true })

        if (!item.price) {
            return
        }

        let payload = {
            productId: this.state.product._id,
            price: Number(item.price),
            currency: this.state.currency,
            customerBranchId: item._id
        }
        productApi.setCustomPrice(payload).then((response) => {

            if (isRowUpdate) {
                let customers = this.state.customers
                customers[index].isEdit = false;
                this.setState({ customers })
            } else {
                this.setState({ selectedCustomer: "" }, () => {
                    this.getAlreadyAddedCustomer()
                })
            }
        }).catch((err) => {
            alert("ERROR")

        })
    }

    onClickLoadMore = () => {
        this.page += 1
        this.getAlreadyAddedCustomer(true)
    }

    onChangeRowPrice = (e, index) => {

        let customers = this.state.customers
        customers[index] = {
            ...customers[index],
            oldPrice: customers[index].price,
            price: e.target.value
        }

        this.setState({ customers })
    }


    handleClickEvent = (event) => {

        if (this.customerSearchRef && !this.customerSearchRef.contains(event.target)) {
            this.setState({ isFocusCustomerSearch: false })
        }


        if (this.productSearchRef && !this.productSearchRef.contains(event.target)) {
            this.setState({ isFocusSearch: false })
        }

    }

    onRowMouseEnter = (item, index, value) => {
        let customers = this.state.customers
        customers[index].focus = value
        this.setState({ customers })
    }

    onClickRemovePrice = (item, index) => {
        let payload = {
            productId: this.state.product._id,
            customerBranchId: item._id
        }
        productApi.removeCustomPrice(payload).then((response) => {
            this.getAlreadyAddedCustomer()
        }).catch((err) => {
            alert("ERROR")

        })
    }

    onClickEdit = (item, index) => {
        let customers = this.state.customers
        customers[index].isEdit = true;
        this.setState({ customers })
    }

    renderPriceSetup() {

        return (
            <Modal className="product-setup-modal" isOpen={true} toggle={this.hideModal} size="lg">
                <ModalBody className="overflow-auto">
                    <div className='create-product-header'>
                        <div className='price-setup-container full-height'>
                            <div className='row align-center'>
                                <div className='col'>
                                    <span className='add-product-text'>{this.staticText.title}</span>
                                </div>
                                <div className='col text-right'>
                                    <img src={getImageSource(ImageConstants.POPUP_CLOSE)} className='popup-close-icon cursor-pointer' onClick={() => this.onClickCancel()}></img>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='app-container'>
                        <div className='price-setup-container'>
                            <div className='row  body-container'>
                                <div className='col-3'>

                                    <div className='app-search-wrapper-container' ref={ref => this.productSearchRef = ref}>
                                        <div className={`${!this.state.isFocusSearch ? "app-search-input-gray" : "app-search-input"} ${this.state.isFocusSearch ? "border-radius-none" : ""} `}>
                                            <div className='search-icon-wrapper'>
                                                <img alt="" src={getImageSource(ImageConstants.SEARCH)} className='search-icon'></img>
                                            </div>
                                            <input
                                                ref={ref => this.inputRef = ref}
                                                className={`${!this.state.isFocusSearch ? "search-input-box-gray" : "search-input-box"}`}
                                                placeholder={this.staticText?.searchPlaceHolder}
                                                value={this.state.search}
                                                onFocus={() => this.onFocusSearch(true)}
                                                onChange={(e) => this.onChangeSearch(e)}
                                            />
                                        </div>

                                        {this.state.isFocusSearch ? <div className='custom-list'>
                                            {this.state.productList.length ? this.state.productList.map((item, index) =>
                                                <div className={`product-name ${index < this.state.productList.length - 1 ? "product-name-border " : ""}`} onClick={(e) => this.onSelectProduct(e, item)}>{item.name}</div>
                                            ) : <div className='text-center pt-2'><small>No Products found</small></div>}
                                        </div> : ""}
                                    </div>

                                    <div className='product'>
                                        <div className='product-container-card'>
                                            <div className='setup-product-image-left'>
                                                <img alt="" src={this.state?.product?.images[0]?.url || getImageSource(ImageConstants.DEFAULT_PRODUCT)} className='product-image'></img>
                                            </div>
                                            <div className='setup-product-image-right'>
                                                <div className='product-name-setup-card'>{this.state.product?.name}</div>
                                                <div>
                                                    <span className='product-code'>#{this.state.product?.code}</span>
                                                    <span className='product-category'>  <div className='small-dot'></div>{this.state.product?.category?.title}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row product-bg m-0'>
                                            <div className='col'><span className='actual-price'>{this.staticText.actualPrice}</span></div>
                                            <div className='col footer-pirce text-center'>{this.state?.currency?.symbol}{CommonUtils.formatAmountStringStrict(this.state.product?.price || 0)} <span className='price-unit'>{this.state.product?.sellQuantity?.unit}</span></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-9'>

                                    <div className='app-search-wrapper-container customer-search' ref={ref => this.customerSearchRef = ref}>
                                        <div className={`${!this.state.isFocusCustomerSearch ? "app-search-input-gray" : "app-search-input"} ${this.state.isFocusCustomerSearch ? "border-radius-none" : ""} `}>
                                            <div className='search-icon-wrapper-customer'>
                                                <img alt="" src={getImageSource(ImageConstants.SEARCH)} className='search-icon'></img>
                                            </div>
                                            <input
                                                ref={ref => this.inputRef = ref}
                                                className={`${!this.state.isFocusCustomerSearch ? "search-input-box-gray" : "search-input-box"}`}
                                                placeholder={this.staticText?.customerSearchPlaceHolder}
                                                value={this.state.customerSearch}
                                                onFocus={() => this.onFocusCustomerSearch(true)}
                                                onChange={(e) => this.onChangeCustomerSearch(e)}
                                            />
                                        </div>

                                        {this.state.isFocusCustomerSearch ? <div className='custom-list customer-custom'>
                                            {this.state.customersList && this.state.customersList.length ? this.state.customersList.map((item, index) =>
                                                <div className={`product-name ${index < this.state.customersList.length - 1 ? "product-name-border " : ""}`} onClick={(e) => this.onSelectCustomer(e, item)}>{item.businessName}</div>
                                            ) : <div className='text-center pt-2'>No Buyers found</div>}
                                        </div> : ""}
                                    </div>

                                    <div className='customer-container'>
                                        <div className='customer-title'>{this.staticText.customerTitle} ({this.state.customers ? this.state.customers.length : 0})</div>

                                        {this.state.selectedCustomer ? <div className='selected-customer row mt-3'>
                                            <div className='col-6'>
                                                <img src={getImageSource(ImageConstants.CROSS_MARK)} className='cross-mark' onClick={() => this.setState({ selectedCustomer: null })}></img>
                                                <span className='selected-name ml-3'>{this.state.selectedCustomer.businessName}</span>
                                            </div>

                                            <div className='col-6 text-right'>
                                                <span className='price-title'>{this.staticText.priceText}</span>
                                                <div className='price-card ml-3'>
                                                    <span className='pl-2 pr-2'>{this.state.currency.symbol}</span>
                                                    <input className='price-card-input' value={this.state.selectedCustomer.price} type='number' onChange={(e) => this.onChangeCustomerPrice(e)}></input>
                                                </div>

                                                <div className='add-button ml-3 cursor-pointer' onClick={() => this.onPriceUpdate(this.state.selectedCustomer)}>{this.staticText.addButton}</div>
                                            </div>
                                        </div> : ""}

                                        <div className='customer-list'>
                                            {this.state.customers && this.state.customers.length ? this.state.customers.map((item, index) => (
                                                <div className={`row-wrapper ${this.state.customers.length - 1 > index ? "border-bottom-row" : ""} row ${item.focus ? "customer-focus" : ""}`} onMouseEnter={() => this.onRowMouseEnter(item, index, true)} onMouseLeave={() => this.onRowMouseEnter(item, index, false)}>
                                                    <div className='col-1 my-auto'>
                                                        <span className='sl-no'>{index + 1 < 10 ? '0' + (index + 1) : index + 1}</span>
                                                    </div>
                                                    <div className='col-3 my-auto'>
                                                        <span className='customer-name'>{item.businessName}</span>
                                                    </div>
                                                    <div className='col text-right my-auto'>
                                                        {
                                                            item.focus && !item.isEdit ?
                                                                <>
                                                                    {
                                                                        item.price ?
                                                                            <span>
                                                                                <img alt="" src={getImageSource(ImageConstants.CROSS_MARK)} className='cross-mark mr-3' onClick={() => this.onClickRemovePrice(item, index)}></img>
                                                                                <img alt="" src={getImageSource(ImageConstants.EDIT_ICON_ACTIVE)} className='cross-mark mr-5' onClick={() => this.onClickEdit(item, index)}></img>
                                                                            </span>
                                                                            :
                                                                            <span>
                                                                                <button className="app-button-primary-small" onClick={() => this.onClickEdit(item, index)}>Add</button>
                                                                            </span>
                                                                    }
                                                                </>
                                                                : ""
                                                        }
                                                        {
                                                            item.isEdit ?
                                                                <div className='selected-customer p-0'>
                                                                    <span className='price-title'>{this.staticText.priceText}</span>
                                                                    <div className='price-card ml-3'>
                                                                        <span className='pl-2 pr-2'>{item?.currency?.symbol || this.state.currency?.symbol}</span>
                                                                        <input className='price-card-input' value={item.price} type='number' onChange={(e) => this.onChangeRowPrice(e, index)}></input>
                                                                    </div>

                                                                    <div className='add-button ml-3 cursor-pointer' onClick={() => this.onPriceUpdate(item, index, true)}>{this.staticText.updateButton}</div>

                                                                </div>
                                                                :
                                                                <span className='customer-price'>{item?.currency?.symbol}{CommonUtils.formatAmountStringStrict(item.price || 0)}</span>
                                                        }
                                                    </div>
                                                </div>
                                            )) : <div className='text-center p-2'>No customer found</div>}

                                        </div>

                                        {this.totalPages > this.page ? <div className='mt-3 text-center mb-3 cursor-pointer' onClick={() => this.onClickLoadMore()}><small>{this.staticText.loadMore}</small></div> : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal >
        )
    }

    render() {

        return (
            <div className='create-product'>
                {this.renderPriceSetup()}
                {this.state.showToaster ? <ToasterComponent type={this.state.toaster?.type} message={this.state.toaster?.message} /> : ""}
            </div>
        );
    }
}

export default CreateProduct;