import React from 'react';
import cartItemApi from '../../apis/cart';
import productApi from '../../apis/product';
import SharedStore from "../../shared-store/shared-store";
import Utils from '../../utility/common-utilities';
import "./my-shop.scss";

import ImageConstants from '../../constants/image-constants';
import { getImageSource } from '../../utility/image-source';
import CartFooter from '../common/cart-footer/cart-footer';

import StaticText from '../../shared-store/static-text-store';
import ProductPreview from '../cart-details/product-preview/product-preview';
import CartAddItem from '../common/add-cart-items/add-cart-items';
import Loading from '../common/loading/loading';

import customerApi from '../../apis/customer';
import ActionConstant from '../../constants/action-constant';
import CommonUtils from '../../utility/common-utilities';
import ImagePreview from '../common/image-preview';
import SessionCache from '../routes/session-cache';

class Product extends React.Component {

    constructor(props) {
        super(props)

        this.getCategories()
        this.getBrands()
        this.getVendors()
    }
    state = {
        staticText: {},
        products: [],
        selectedView: "LIST",
        categories: [
        ],
        vendors: [
        ],
        brands: [
        ],
        selectedProduct: null,
        activeTab: "MARKETPLACE",
        buyerVendorDiscounts: []
    }
    productHash = {}
    totalPages = 1
    page = 1
    filter = {}

    accessRights = {}

    componentDidMount() {

        this.user = SessionCache.getUser()
        this.accessRights = this.user.accessRights || {}
        this.getCart(this.getProduct)
        this.getBuyerVendorDiscounts()
        let staticText = StaticText.getStaticText("myShop")
        this.setState({ staticText })
        document.addEventListener('mousedown', this.handleClickEvent, false);

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }

    getBuyerVendorDiscounts() {
        this.setState({ buyerVendorDiscountsLoading: true })
        customerApi.getBuyerVendorDiscounts({}).then((response) => {
            if (!response) {
                this.setState({ buyerVendorDiscountsLoading: false })
                return
            }
            console.log(response)
            this.setState({ buyerVendorDiscountsLoading: false, buyerVendorDiscounts: response })
        }).catch((err) => {
            this.setState({ buyerVendorDiscountsLoading: false })
            alert(err);
        })
    }

    getCart(callback) {

        cartItemApi.getCartItems({}).then((data) => {
            SharedStore.setStore("cart", data?.response || {})
            this.generateProductHash(data?.response?.products || [])
            if (data?.response?.products?.length) this.setState({ showMinimal: true, isOpenCart: true })
            callback && callback()
        }).catch((err) => {
            SharedStore.setStore("cart", {})
            callback && callback()
        })
    }

    generateProductHash(products) {
        products.forEach(element => {
            this.productHash[element.productId] = element.quantity
        });
    }

    handleClickEvent = (event) => {
        if (this.filterRef && !this.filterRef.contains(event.target)) {
            this.setState({ showFilters: false })
        }
        if (this.vendorPriceDiscountRef && !this.vendorPriceDiscountRef.contains(event.target)) {
            this.setState({ showVendorPriceDiscounts: false })
        }
    }

    onClickLoadMore = () => {
        this.page += 1
        this.getProduct(this.filter || {}, true)
    }

    getProduct = (payload = {}, append) => {

        if (this.state.activeTab == "FREQUENTLY") {
            this.getFrequentlyOrderItem(payload, append)

        } else {
            this.getProductByCustomer(payload, append)
        }
    }

    getProductByCustomer(payload = {}, append) {

        if (!append)
            this.page = 1

        payload.page = this.page;

        this.setState({ isLoading: true })
        productApi.getProductsByCustomer(payload).then((response) => {

            this.totalPages = response?.totalPages || 0

            let products = response.response || []
            this.prepareProductList(products, append)
            this.setState({ isLoading: false })
        }).catch((err) => {
            this.setState({ isLoading: false })
        })
    }

    getFrequentlyOrderItem(payload = {}, append) {
        if (!append)
            this.page = 1

        payload.page = this.page;

        this.setState({ isLoading: true })
        productApi.getFrequentlyOrderProducts(payload).then((response) => {

            this.totalPages = response?.totalPages || 0

            let products = response.response || []
            this.prepareProductList(products, append)
            this.setState({ isLoading: false })
        }).catch((err) => {
            this.setState({ isLoading: false })
        })

    }

    prepareProductList(products, append) {
        products.forEach(element => {
            if (this.productHash[element._id] >= 0) {
                element.quantity = this.productHash[element._id]
                element.quantityState = this.productHash[element._id]
            }
        })
        this.setState({ products: append ? this.state.products.concat(products) : products })
    }

    onChangeHandler(event, key) {
        this.setState({ [key]: event?.target?.value }, () => {
            this.searchProduct(key)
        })
    }

    searchProduct(key) {
        let maxLength = this.state.staticText.characterForSearch
        if (this.state[key] && (this.state[key].length < maxLength)) return

        let payload = this.buildSearchPayload()

        this.getProduct(payload)
    }

    buildSearchPayload() {

        let maxLength = this.state.staticText.characterForSearch
        let payload = {}

        if (this.state.productNameSearch && (this.state?.productNameSearch?.length >= maxLength)) {
            payload.productNameSearch = this.state.productNameSearch
        }
        if (this.state.brandSearch && (this.state?.brandSearch?.length >= maxLength)) {
            payload.brandSearch = this.state.brandSearch
        }
        if (this.state.categorySearch && (this.state?.categorySearch?.length >= maxLength)) {
            payload.categorySearch = this.state.categorySearch
        }
        if (this.state.vendorSearch && (this.state?.vendorSearch?.length >= maxLength)) {
            payload.vendorSearch = this.state.vendorSearch
        }
        this.filter = {
            ...this.filter,
            ...payload
        }
        return payload
    }

    handleCart = () => {
        cartItemApi.getCartItems({}).then((response) => {
            SharedStore.setStore("cart", response?.response || {})
            this.generateProductHash(response?.response?.products || [])
            this.setState({ isOpenCart: true })
        }).catch((err) => {
            SharedStore.setStore("cart", {})
            // this.onCloseCart()
            this.setState({ isOpenCart: false })
        })
    }

    addCartItem(product) {
        let payload = {
            productId: product._id,
            quantity: product.quantity,
        }

        cartItemApi.createCartItem(payload).then((response) => {
            this.handleCart()
        }).catch((err) => {
            alert(err);
        })
    }

    removeCartItem(product) {
        let payload = {
            productId: product._id,
        }

        cartItemApi.removeCartItem(payload).then((response) => {
            this.handleCart()
        }).catch((err) => {
        })
    }


    onChangeQuantity = (event, item, index) => {
        let value = event.target.value ? +event.target.value : ""

        if (value <= 0) {
            value = ""
        }

        // if (!value && value != "") value = 0

        let products = this.state.products

        item.quantityState = value
        products[index] = item

        this.setState({ products })
    }

    onAddQuantityFromPreview = (quantity, nonHide) => {
        let value = +quantity

        if (!value && value != "") value = 0

        let products = this.state.products
        products[this.state.selectedIndex].quantityState = value
        this.setState({ products, showProductPreview: !nonHide ? false : true })

        if (value == 1) {
            this.onAddProduct(products[this.state.selectedIndex], this.state.selectedIndex)
        } else {
            this.onAddQuantity(null, products[this.state.selectedIndex], this.state.selectedIndex)

        }
    }

    onAddProduct(item, index) {
        item.quantity = 1
        item.quantityState = 1
        let products = this.state.products
        products[index] = item
        this.addCartItem(item)
        this.setState({ products })

    }

    onDecreaseQuntity(item, index) {

        item.quantityState = item.quantityState ? item.quantityState - 1 : "";

        if (!item.quantityState)
            item.quantityState = ""

        item.quantity = item.quantityState
        let products = this.state.products
        products[index] = item

        if (!item.quantityState) {
            this.removeCartItem(item)
        } else {
            this.addCartItem(item)
        }

        this.setState({ products })
    }

    onIncreaseQuntity(item, index) {

        item.quantityState = item.quantityState ? item.quantityState + 1 : 1;
        item.quantity = item.quantityState;
        let products = this.state.products
        products[index] = item

        if (!item.quantityState) {
            this.removeCartItem(item)
        } else {
            this.addCartItem(item)
        }

        this.setState({ products })
    }


    onAddQuantity(event, item, index) {
        if (event)
            event.stopPropagation()

        item.quantity = item.quantityState
        let products = this.state.products
        products[index] = item

        if (!item.quantityState) {
            this.removeCartItem(item)
        } else {
            this.addCartItem(item)
        }

        this.setState({ products })
    }

    onRemoveProductFooter = (product) => {
        this.productHash[product.productId] = 0
        let products = this.state.products
        this.prepareProductList(products)
    }

    onCloseCart = () => {
        this.setState({ isManualClose: true })
    }

    viewToggle = (selectedView) => {
        this.setState({ selectedView })
    }

    getCategories = () => {

        let payload = {
        }

        productApi.getCategories(payload).then((response) => {

            let categories = response.response || [];
            this.setState({ backupCategories: [...categories], categories: [...categories] })

        }).catch((err) => {
            alert(err);
        })

    }

    getVendors = () => {

        let payload = {
        }

        productApi.getVendors(payload).then((response) => {
            let vendors = response?.response || []

            let data = []
            vendors.forEach(element => {
                data.push({ title: element?.businessName, _id: element._id })
            })
            this.setState({ vendors: data, backupVendors: [...data] })

        }).catch((err) => {
            alert(err);
        })

    }

    getBrands = () => {

        let payload = {
        }

        productApi.getBrands(payload).then((response) => {

            let brands = response.response || [];
            this.setState({ backupBrands: [...brands], brands: [...brands] })
        }).catch((err) => {
            alert(err);
        })
    }

    onCategorySearch = (e) => {

        let value = e.target.value

        let backupCategories = this.state.backupCategories || []

        if (value) {
            let options = backupCategories.filter(option => option.title.toLowerCase().includes(value.toLowerCase()));
            this.setState({ categories: options, categorySearch: value });
        } else {
            this.setState({ categories: backupCategories, categorySearch: value });
        }
    }

    onBrandSearch = (e) => {

        let value = e.target.value
        let backupBrands = this.state.backupBrands || []
        if (value) {
            let options = backupBrands.filter(option => option.title.toLowerCase().includes(value.toLowerCase()));
            this.setState({ brands: options, brandsSearch: value });
        } else {
            this.setState({ brands: backupBrands, brandsSearch: value });
        }

    }

    onVendorSearch = (e) => {

        let value = e.target.value

        let backupVendors = this.state.backupVendors || []

        if (value) {
            let options = backupVendors.filter(option => option.title.toLowerCase().includes(value.toLowerCase()));
            this.setState({ vendors: options, vendorsSearch: value });
        } else {
            this.setState({ vendors: backupVendors, vendorsSearch: value });
        }
    }

    handleVendorPriceDiscountClickEvent = (event) => {
        if (this.vendorPriceDiscountRef && !this.vendorPriceDiscountRef.contains(event.target)) {
            this.setState({ showVendorPriceDiscounts: false })
        }
    }

    renderVendorPriceDiscount = () => {

        return (
            <div className='price-discount-popover-content' ref={ref => this.vendorPriceDiscountRef = ref}>
                <div className="arrow-up"></div>
                <div className='header'><span className="pr-2"><span className="discount-indicator">*</span><span className='main-text'>{this.state.staticText?.viewVendorPriceDiscountTitle || "Price Discounts"}</span> <span>{this.state.staticText?.viewVendorPriceDiscountTitleSub || "(Already applied to the prices below)"}</span></span></div>
                <div className='discount-table-container'>
                    <table>
                        <thead>
                            <tr>
                                <th>{this.state.staticText?.thVendor || "Vendor"}</th>
                                <th>{this.state.staticText?.thDiscount || "Discount"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.buyerVendorDiscounts.map(item => {
                                return (
                                    <tr>
                                        <td>{item.vendorInfo.businessName}</td>
                                        <td>{Utils.formatAmountStringStrict(item.value)}%</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>)
    }

    renderFilters = () => {

        return (
            <div className='filterpop-over' ref={ref => this.filterRef = ref}>
                <div className="arrow-up"></div>
                <div className='filter-header'><span className="pr-2">{this.state.staticText.filterText}</span> {this.state.filterBadgeCount ? <div className="position-absolute badge-red"><i>{this.state.filterBadgeCount}</i></div> : ""}</div>

                <div className='row m-0'>

                    {/* <div className='col filter-left'>
                    <div className='title-pop-over'>{this.state.staticText.categoryTitle}</div>
                    <div>
                        <div className='filter-search-wrapper mt-1 my-2 p-2'>
                            <img alt="" src={getImageSource(ImageConstants.PRODUCT_LIST_SEARCH)} className='icon-15'></img>
                            <input className='filter-search' value={this.state.categorySearch} placeholder={'Search...'} onChange={this.onCategorySearch} />
                        </div>
                        <div className="category-list">
                            {this.renderCategories()}
                        </div>
                    </div>
                </div> */}
                    <div className='col filter-center'>
                        <div className='title-pop-over'>{this.state.staticText.vendor}</div>
                        <div className='filter-search-wrapper mt-1 my-2 p-2'>
                            <img alt="" src={getImageSource(ImageConstants.PRODUCT_LIST_SEARCH)} className='icon-15'></img>
                            <input className='filter-search' placeholder={'Search...'} value={this.state.vendorsSearch} onChange={this.onVendorSearch} />
                        </div>
                        <div className="vendor-filter">
                            {this.renderVendors()}
                        </div>
                    </div>
                    {/* <div className='col filter-right'>
                    <div className='title-pop-over'>{this.state.staticText.brandTitle}</div>

                    <div className='filter-search-wrapper mt-1 my-2 p-2'>
                        <img alt="" src={getImageSource(ImageConstants.PRODUCT_LIST_SEARCH)} className='icon-15'></img>
                        <input className='filter-search' placeholder={'Search...'} value={this.state.brandsSearch} onChange={this.onBrandSearch} />
                    </div>
                    <div className="category-list">
                        {this.renderBrands()}
                    </div>
                </div> */}
                </div>

                <div className='row button-wrapper py-2'>
                    <div className='reset-button' onClick={(e) => { e.stopPropagation(); this.onClickReset() }}>
                        Reset
                    </div>
                    <div className='apply-button' onClick={(e) => { e.stopPropagation(); this.onClickApplyFilter() }}>
                        Apply Filter
                    </div>
                </div>
            </div>)
    }

    renderCategories = () => {

        let elements = []

        for (let i = 0; i < this.state.categories.length; i++) {

            let item = this.state.categories[i]
            elements.push(<div className='cursor-pointer mb-3' onClick={() => { this.onClickCategories(i) }}>
                <div className="d-flex align-items-center">
                    <img alt="" src={getImageSource(item.active ? ImageConstants.CHECKBOX_ACTIVE : ImageConstants.CHECKBOX_INACTIVE)} className='checkbox'></img>
                    <span className={`categories-name pl-3 ${item.active ? 'categories-name-active' : ""}`}>{item.title}</span>
                </div>
            </div>)
        }

        return elements
    }


    renderVendors = () => {

        let elements = []

        for (let i = 0; i < this.state.vendors.length; i++) {

            let item = this.state.vendors[i]
            elements.push(<div className='cursor-pointer mb-1' onClick={() => { this.onClickVendors(i) }}>
                <img src={getImageSource(item.active ? ImageConstants.CHECKBOX_ACTIVE : ImageConstants.CHECKBOX_INACTIVE)} className='checkbox'></img>
                <span className={`categories-name pl-3 ${item.active ? 'categories-name-active' : ""}`}>{item.title}</span>
            </div>)
        }

        return elements
    }

    onClickCategories = (i) => {
        let categories = this.state.categories;
        categories[i].active = !categories[i].active
        let selectedCategories = this.state.selectedCategories || []

        let filterBadgeCount = this.state.filterBadgeCount || 0

        if (categories[i].active) {
            filterBadgeCount += 1
            selectedCategories.push(categories[i].value)
        }
        else {
            filterBadgeCount -= 1
            let data = selectedCategories.filter(item => item !== categories[i].value)
            selectedCategories = data
        }

        this.setState({ categories, filterBadgeCount, selectedCategories })
    }

    onClickApplyFilter = () => {

        let payload = {}
        this.page = 1
        if (this.state.selectedCategories?.length) {
            payload.categories = this.state.selectedCategories?.join(",")
        }

        if (this.state.selectedBrands?.length) {
            payload.brands = this.state.selectedBrands?.join(",")
        }

        if (this.state.selectedVendors?.length) {
            payload.vendors = this.state.selectedVendors?.join(",")
        }
        this.filter = payload
        this.getProduct(payload)
        this.setState({ showFilters: false, isFilterApplied: true })
    }

    onClickReset = () => {
        this.page = 1
        this.getProduct()
        let categories = this.state.backupCategories
        let brands = this.state.backupBrands
        let vendors = this.state.backupVendors

        this.filter = {}

        categories.forEach(element => {
            element.active = false
        });
        brands.forEach(element => {
            element.active = false
        });

        vendors.forEach(element => {
            element.active = false
        });

        this.setState({ showFilters: false, filterBadgeCount: 0, categories, brands, selectedCategories: [], vendors, selectedBrands: [], isFilterApplied: false })
    }


    onClickVendors = (i) => {
        let vendors = this.state.vendors;
        vendors[i].active = !vendors[i].active
        let selectedVendors = this.state.selectedVendors || []

        let filterBadgeCount = this.state.filterBadgeCount || 0

        if (vendors[i].active) {
            filterBadgeCount += 1
            selectedVendors.push(vendors[i]._id)
        }
        else {
            filterBadgeCount -= 1
            let data = selectedVendors.filter(item => item !== vendors[i]._id)
            selectedVendors = data
        }

        this.setState({ vendors, filterBadgeCount, selectedVendors })
    }

    onClickShowPreview = (item, index) => {
        this.setState({ selectedProduct: item, showProductPreview: true, selectedIndex: index })
    }

    onClickBrands = (i) => {
        let brands = this.state.brands;
        brands[i].active = !brands[i].active

        let filterBadgeCount = this.state.filterBadgeCount || 0
        let selectedBrands = this.state.selectedBrands || []

        if (brands[i].active) {
            filterBadgeCount += 1
            selectedBrands.push(brands[i].value)
        }
        else {
            filterBadgeCount -= 1
            let data = selectedBrands = selectedBrands.filter(item => item !== brands[i].value)
            selectedBrands = data
        }

        this.setState({ brands, filterBadgeCount, selectedBrands })
    }

    closePreview = () => {
        this.setState({ showProductPreview: false })
    }

    renderBrands = () => {

        let elements = []

        for (let i = 0; i < this.state.brands.length; i++) {

            let item = this.state.brands[i]
            elements.push(<div className='cursor-pointer mb-3' onClick={() => { this.onClickBrands(i) }}>
                <div className="d-flex align-items-center">
                    <img alt="" src={getImageSource(item.active ? ImageConstants.CHECKBOX_ACTIVE : ImageConstants.CHECKBOX_INACTIVE)} className='checkbox'></img>
                    <span className={`categories-name pl-3 ${item.active ? 'categories-name-active' : ""}`}>{item.title}</span>
                </div>
            </div>)
        }

        return elements
    }



    renderAddButton(item, index) {

        if (item.quantity) {
            return (
                <CartAddItem value={item.quantityState} onChange={(e) => { this.onChangeQuantity(e, item, index) }} onSubmit={() => this.onAddQuantity(null, item, index)} onClickMinus={() => this.onDecreaseQuntity(item, index)} onClickAdd={() => this.onIncreaseQuntity(item, index)} />
            )
        } else {
            return (
                <button className="app-button-secondary cursor-pointer cart-btn" onClick={(e) => { e.stopPropagation(); this.onAddProduct(item, index) }}>
                    <span>{this.state.staticText.addToCartButtonText}</span>
                </button>)
        }

        // return (
        //     <button className="app-button-secondary no-padding cart-btn add-btn-wrapper">
        //         <div className="row m-0 no-padding">
        //             <div className="col my-auto">
        //                 <input className="cart-input"
        //                     value={item.quantityState}
        //                     onChange={(e) => this.onChangeQuantity(e, item, index)}
        //                     onClick={(e) => e.stopPropagation()}
        //                 />
        //             </div>
        //             <div className="col py-1 text-center add cursor-pointer " onClick={(event) => { event.stopPropagation(); this.onAddQuantity(event, item, index) }}>
        //                 <span className="cw-card remove mr-3">{this.state.staticText.addQuantityButtonText}</span>
        //             </div>
        //         </div>
        //     </button>
        // )
    }

    renderTableView() {
        return (
            <div className="">
                <div id="table-scoll" className="table-responsive pt-2 product-table">
                    <table className="table mb-0 align-items-center  bg-white">
                        <thead>
                            <tr>
                                {/* <th className='border-top-none border-bottom-none th-width-sno text-center'></th> */}
                                <th className='border-top-none border-bottom-none product-max-width'>
                                    <input
                                        placeholder="Search Products"
                                        className="app-input"
                                        value={this.state.productNameSearch}
                                        onChange={(e) => this.onChangeHandler(e, 'productNameSearch')} />
                                </th>
                                <th className='border-top-none border-bottom-none '>
                                    <input
                                        placeholder="Search Brand"
                                        className="app-input"
                                        value={this.state.brandSearch}
                                        onChange={(e) => this.onChangeHandler(e, 'brandSearch')} />
                                </th>
                                <th className='border-top-none border-bottom-none '>
                                    <input
                                        placeholder="Search Category"
                                        className="app-input"
                                        value={this.state.categorySearch}
                                        onChange={(e) => this.onChangeHandler(e, 'categorySearch')} />
                                </th>
                                <th className='border-top-none border-bottom-none '>
                                    <input
                                        placeholder="Search Vendor"
                                        className="app-input"
                                        value={this.state.vendorSearch}
                                        onChange={(e) => this.onChangeHandler(e, 'vendorSearch')} />
                                </th>
                                <th className='border-top-none border-bottom-none '></th>
                                <th className='border-top-none border-bottom-none ' colSpan={2}>
                                    {
                                        this.state?.buyerVendorDiscounts?.length ? <div className="d-inline-block price-discount view-discount-button float-right bg-white" onClick={() => this.setState({ showVendorPriceDiscounts: true })}>
                                            <img alt="" className="price-discount-button-tag" src={getImageSource(ImageConstants.TAG)} />
                                            <span className=""> <span className="discount-indicator-1">*</span> <span className="filter-text pl-2"> {this.state.staticText?.viewVendorPriceDiscount || "View Vendor Price Discounts"} </span></span>
                                            {this.state.showVendorPriceDiscounts ? <div className='price-discount-popover'>{this.renderVendorPriceDiscount()}</div> : ""}
                                        </div> : ""
                                    }
                                </th>
                            </tr>
                            <tr>
                                {/* <th className='border-top-none border-bottom-none th-width-sno text-center'>S.No </th> */}
                                <th className='border-top-none border-bottom-none product-max-width'>Product Name
                                    {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                </th>
                                <th className='border-top-none border-bottom-none fixed-width-8'>Brand
                                    {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                </th>
                                <th className='border-top-none border-bottom-none fixed-width-8'>Category
                                    {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                </th>
                                <th className='border-top-none border-bottom-none fixed-width-8'>Vendor
                                    {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                </th>
                                <th className='border-top-none border-bottom-none fixed-width-8'>Unit
                                    {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                </th>
                                <th className='border-top-none text-right border-bottom-none fixed-width-6'>Price
                                    {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                </th>
                                <th className='border-top-none border-bottom-none th-fixed-width-13'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.products?.length ? this.state.products.map((item, index) => (

                                    <tr key={item._id} className="tr-fixed-5" onClick={() => this.onClickShowPreview(item, index)}>
                                        {/* <td className="cursor-pointer text-center">{index + 1}</td> */}
                                        <td className="">
                                            <div className="d-flex align-items-center">
                                                <div className="pr-4">
                                                    <div className='contains-preview-image'>
                                                        <div className="image-circle-wrapper-50">
                                                            <img alt="" src={item?.images[0]?.url || getImageSource(ImageConstants.DEFAULT_PRODUCT)} />
                                                        </div>
                                                        <ImagePreview isLast={index === this.state.products?.length - 1} src={item?.images[0]?.url || getImageSource(ImageConstants.DEFAULT_PRODUCT)} />
                                                    </div>
                                                </div>
                                                <div className="font-14 bold-text ">{item.name}</div>
                                            </div>
                                        </td>
                                        <td className="sub-text">{item.brand?.title}</td>
                                        <td className="sub-text">{item.category?.title}</td>
                                        <td className=""> {item.branch?.businessName || "-"}</td>
                                        <td className=""> {item?.sellQuantity?.value || "-"}</td>
                                        <td className="bold-text font-14 text-right position-relative">
                                            {item.currency?.symbol || ""} {Utils.formatAmountStringStrict(+item?.price)}
                                            {item.isVendorPriceDiscountEnabled && <span className='discount-indicator'>*</span>}
                                        </td>
                                        <td className="text-right" onClick={(e) => { e.stopPropagation() }}>{CommonUtils.checkRights(ActionConstant.ADD_TO_CART) ? this.renderAddButton(item, index) : ""}</td>
                                    </tr>
                                ))
                                    : <tr><td colSpan="10" align='center' className='text-gray'>No Records found !</td></tr>
                            }
                        </tbody>
                    </table>
                </div>

                {this.totalPages > this.page ? <div className='text-center cursor-pointer mt-3' onClick={this.onClickLoadMore}><span>+ Load More</span></div> : <></>}

            </div>
        )
    }

    renderCard(item, index) {
        return (
            <div className='product-card'>
                <div className='card-item p-3'>
                    <div className="row no-gutters ">
                        <div className="col-4">
                            <img alt="" className='product-img' src={item?.images[0]?.url}></img>
                        </div>
                        <div className="col my-auto text-right"></div>
                    </div>
                    <div className='card-title'>
                        <div className="product-name-max-height font-14 bold-text vertical-line-clamp-2 ">{item.name}</div>
                        <div> <span className='product-description product-name-max-height vertical-line-clamp-2 '>{item.description}</span></div>
                        <div className='row no-gutters pt-2'>
                            <div className="col-8">
                                <span className='product-subtitle'>{item.category?.title}</span>
                                <span className='ml-2 inline-block product-qty'>Qty: {item?.sellQuantity?.value}</span>
                            </div>
                            <div className='col-4 text-right'>
                                <span className='price'>{item?.currency?.symbol}{Utils.formatAmountStringStrict(+item?.price)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="text-center button-wrapper">{CommonUtils.checkRights(ActionConstant.ADD_TO_CART) ? this.renderAddButton(item, index) : ""}</div>
                </div>
            </div>
        )
    }

    renderCardView() {
        return (
            <div className="row">
                {
                    this.state.products?.length ? this.state.products?.map((item, index) => (
                        <div className="col-min-width-card col-lg-2 col-md-4 col-sm-4 mt-2">
                            {this.renderCard(item, index)}
                        </div>
                    )) : ""
                }
            </div>
        )
    }

    onClickTab(tab) {
        this.setState({ activeTab: tab }, () => {
            let payload = this.buildSearchPayload()
            this.getProduct(payload)
        })
    }

    render() {

        return (
            <>
                {this.state.isLoading && <Loading />}
                <div className="my-shop ">
                    <div className="">
                        <div className='row mx-0'>
                            <div className="col no-padding">
                                <span className={`tab mr-4 ${this.state.activeTab === "MARKETPLACE" ? "active-tab" : ""}`} onClick={() => this.onClickTab("MARKETPLACE")}>{this.state.staticText?.title}</span>
                                <span className={`tab ${this.state.activeTab === "FREQUENTLY" ? "active-tab" : ""}`} onClick={() => this.onClickTab("FREQUENTLY")}>{this.state.staticText?.frequentlyText || "Frequently Ordered"}</span>
                            </div>
                            {
                                this.state.products?.length || this.state.search || this.state.isFilterApplied ?
                                    <div className="col text-right">

                                        {/* <div className="d-inline-block mr-2">
                                            <input className={`product-search`} placeholder='Search Products'
                                                value={this.state.search}
                                                onChange={(e) => this.onChangeHandler(e, 'search')} />
                                        </div> */}

                                        <div className="d-inline-block filter-wrapper float-right bg-white" onClick={() => this.setState({ showFilters: true })}>
                                            {this.state.filterBadgeCount ? <div className="main-filter-badge position-absolute badge-red"><i>{this.state.filterBadgeCount}</i></div> : ""}
                                            <img alt="" className="icon-20" src={getImageSource(ImageConstants.SLIDER)} />
                                            <span className="pl-2 filter-text">{this.state.staticText?.filterText}</span>
                                            {this.state.showFilters ? <div className='my-shop-filters'>{this.renderFilters()}</div> : ""}
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>

                    <div className="pt-4">
                        {this.state.selectedView == "LIST" ? this.renderTableView() : this.renderCardView()}
                    </div>
                </div>
                {
                    this.state.isOpenCart && !this.state.isManualClose ?
                        <CartFooter {...this.props} showMinimal={this.state.showMinimal} onRemove={this.onRemoveProductFooter} close={this.onCloseCart} />
                        : ""
                }

                {this.state.showProductPreview ? <ProductPreview product={this.state.selectedProduct} close={this.closePreview} onAdd={(quantity, nonHide) => this.onAddQuantityFromPreview(quantity, nonHide)} /> : <></>}

            </>
        );
    }

}

export default Product;