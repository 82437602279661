import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import ImageConstants from "../../../constants/image-constants";
import { getImageSource } from "../../../utility/image-source";
import Path from "../../routes/routes-path";
import PaymentSubscriptions from "../../../apis/payment-subscriptions";
import './subscription.scss'

class SubscriptionModal extends React.Component {
    state = {
    }

    onClickSubscribe = () => {
        this.setState({ loading: true });
        const payload = {
            successUrl: window.location.origin + Path.SUBSCRIPTION.setupResponse,
            cancelUrl: window.location.origin + Path.SUBSCRIPTION.setupResponse,
        };

        PaymentSubscriptions.createSessionForPaymentSetup(payload)
            .then(({ data }) => {
                window.location.assign(data.url);
            })
            .catch((err) => {
                this.setState({ loading: false });
            });
    };


    render() {
        return (
            <Modal className="subscribe-confirm-custom-modal" isOpen={true} toogle={this.props.subscribeClose}>
                <ModalBody >
                    <div>
                        <img className="icon-20 close-icn cursor-pointer float-right" alt="" src={getImageSource(ImageConstants.CLOSE_GRAY)}
                            onClick={this.props.subscribeClose} />
                    </div>
                    <div className="text-center">

                        <div className="subscribe-confirm">
                            <div className="subscribe-title">
                                <span className="header-text-1">Your trial has ended. Please upgrade  so you can continue to use MyBiZSquare!</span>
                                <div className="text-center mt-4">
                                    <img className="subscribe-img " src={getImageSource(ImageConstants.MEMBERSHIP)} alt="" />
                                </div>
                                <div className="text-center  mt-4">
                                    <span className='app-button-primary' onClick={this.onClickSubscribe}>Upgrade</span>
                                </div>
                                <div>
                                    <label className="note-text mt-4">
                                        * For Membership Benefits and Pricing Info please refer to our <a className="note-text" rel="noopener noreferrer" target="_blank" href="https://www.mybizsquare.com/pricing">Pricing Page</a>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal >
        )
    }
}

export default SubscriptionModal;