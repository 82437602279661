
import React from 'react';
import "./app-loader.scss";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

class AppLoader extends React.Component {
    state = {
    }

    componentDidMount() {
    }


    render() {
        return (
            <div className="app-loader">
                <FontAwesomeIcon icon={faSpinner} spin size={this.props.size || "1x"} />
            </div>
        )
    }

}

export default AppLoader;