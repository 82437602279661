import HttpClient from './http-client';
import URL from './url';

export default class Subscription {


    static getSubscriptionAmount(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SUBSCRIPTION_AMOUNT + "?" + params.join('&') : URL.SUBSCRIPTION_AMOUNT;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }


    static getUsageHistory(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SUBSCRIPTION_USAGE + "?" + params.join('&') : URL.SUBSCRIPTION_USAGE;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getUsageDetail(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.SUBSCRIPTION_USAGE_DETAILS + "?" + params.join('&') : URL.SUBSCRIPTION_USAGE_DETAILS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getMembershipDetail(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.MEMBERSHIP_DETAIL + "?" + params.join('&') : URL.MEMBERSHIP_DETAIL;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

}