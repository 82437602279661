import HttpClient from './http-client';
import URL from './url';
import axios from "axios";



export default class productApi {

    static getProducts(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.PRODUCT + "?" + params.join('&') : URL.PRODUCT;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getProductsByCustomer(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${encodeURIComponent(payload[key])}`);
            });
        }

        let paramsList = params.length ? URL.PRODUCT_BY_CUSTOMER + "?" + params.join('&') : URL.PRODUCT_BY_CUSTOMER;

        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getFrequentlyOrderProducts(payload) {

        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.FREQUENTLY_ORDER_PRODUCTS + "?" + params.join('&') : URL.FREQUENTLY_ORDER_PRODUCTS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getCustomerProductPrice(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.CUSTOMER_PRODUCTS_PRICE + "?" + params.join('&') : URL.CUSTOMER_PRODUCTS_PRICE;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getCustomerByProducts(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.PRICE_BY_CUSTOMER_PRODUCT + "?" + params.join('&') : URL.PRICE_BY_CUSTOMER_PRODUCT;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static createProduct(payload) {
        return HttpClient.post(URL.BASE_URL + URL.PRODUCT, null, payload);
    }

    static bulkUploadProduct(payload) {
        return HttpClient.post(URL.BASE_URL + URL.BULK_UPLOAD_PRODUCT, null, payload);
    }

    static updateProduct(payload) {
        return HttpClient.put(URL.BASE_URL + URL.PRODUCT, null, payload);
    }

    static setCustomPrice(payload) {
        return HttpClient.put(URL.BASE_URL + URL.PRODUCT_CUSTOM_PRICE, null, payload);
    }

    static removeCustomPrice(payload) {
        return HttpClient.delete(URL.BASE_URL + URL.PRODUCT_CUSTOM_PRICE, null, payload);
    }


    static getCategories(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.CATEGORIES + "?" + params.join('&') : URL.CATEGORIES;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getVendorCategories(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.VENDOR_CATEGORIES + "?" + params.join('&') : URL.VENDOR_CATEGORIES;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getBrands(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.BRANDS + "?" + params.join('&') : URL.BRANDS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static uploadFileAwsS3(signedUrl, file, progressCallback) {
        let options = {
            headers: {
                "Content-Type": file.type
            },
            onUploadProgress: progressCallback
        };
        return axios.put(signedUrl, file, options);
    }

    static getSignedUrl(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.SIGNED_URL + "?" + params.join('&') : URL.SIGNED_URL;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getVendors(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.VENDOR + "?" + params.join('&') : URL.VENDOR;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static exportProducts(payload) {
        return HttpClient.post(URL.BASE_URL + URL.EXPORT_PRODUCTS, null, payload);
    }

    static bulkInactive(payload) {
        return HttpClient.put(URL.BASE_URL + URL.BULK_INACTIVE_PRODUCT, null, payload);
    }
}
