let ServerConfigs = {

    url: document.location.origin,
    // url: "http://localhost:4001",
    timeout: 30,
}

export default ServerConfigs;



