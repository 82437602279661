const ActionConstant = {

    "SHOW_ALWAYS": "SHOW_ALWAYS",
    "ADD_PRODUCT": "ADD_PRODUCT",
    "IMPORT_PRODUCT": "IMPORT_PRODUCT",
    "UPDATE_PRODUCT": "UPDATE_PRODUCT",
    "PRICE_SETUP": "PRICE_SETUP",
    "UPDATE_ORDER": "UPDATE_ORDER",

    "REMOVE_CUSTOMER": "REMOVE_CUSTOMER",
    "CUSTOMER_PRICE_SETUP": "CUSTOMER_PRICE_SETUP",
    "ADD_CUSTOMER": "ADD_CUSTOMER",

    "ADD_STAFF": "ADD_STAFF",
    "UPDATE_STAFF": "UPDATE_STAFF",
    "STAFF_CHANGE_PASSWORD": "STAFF_CHANGE_PASSWORD",
    "REMOVE_STAFF": "REMOVE_STAFF",

    "ACCEPT_INVITATION": "ACCEPT_INVITATION",
    "CANCEL_INVITATION": "CANCEL_INVITATION",
    "REJECT_INVITATION": "REJECT_INVITATION",

    "SWITCH_BRANCH": "SWITCH_BRANCH",
    "CHANGE_PASSWORD": "CHANGE_PASSWORD",

    "ADD_BRANCH": "ADD_BRANCH",
    "BRANCH_LIST": "BRANCH_LIST",
    "UPDATE_BRANCH": "UPDATE_BRANCH",

    "ADD_TO_CART": "ADD_TO_CART",
    "PLACE_ORDER": "PLACE_ORDER",
    "SETUP_PAYMENT": "SETUP_PAYMENT",

    "VIEW_DASHBOARD": "VIEW_DASHBOARD",
    "VIEW_MY_SHOP": "VIEW_MY_SHOP",
    "VIEW_MY_ORDER": "VIEW_MY_ORDER",
    "VIEW_VENDOR": "VIEW_VENDOR",
    "VIEW_STAFF": "VIEW_STAFF",
    "VIEW_VENDOR_PORTAL": "VIEW_VENDOR_PORTAL",
    "VIEW_ORDER": "VIEW_ORDER",
    "VIEW_CUSTOMER": "VIEW_CUSTOMER",
    "VIEW_INVITATION": "VIEW_INVITATION",
    "VIEW_INVOICE": "VIEW_INVOICE",
    "ACCEPT_ORDER": "ACCEPT_ORDER",
    "REJECT_ORDER": "REJECT_ORDER",
    "MARK_THE_ORDER_AS_CLOSED": "MARK_THE_ORDER_AS_CLOSED",
    "SEND_INVOICE": "SEND_INVOICE",
    "EDIT_INVOICE": "EDIT_INVOICE",
    "UPDATE_INVOICE": "UPDATE_INVOICE",
    "MARK_AS_PAID_INVOICE": "MARK_AS_PAID_INVOICE",
    "VIEW_CUSTOMER_DETAILS": "VIEW_CUSTOMER_DETAILS",

    "VIEW_ORDER_DETAILS": "VIEW_ORDER_DETAILS",
    "CREATE_MANUAL_INVOICE": "CREATE_MANUAL_INVOICE",


    "TRIAL_VIEW_ORDER_DETAILS": "TRIAL_VIEW_ORDER_DETAILS",
    "TRIAL_ADD_CUSTOMER": "TRIAL_ADD_CUSTOMER",
    "TRIAL_ADD_BRANCH": "TRIAL_ADD_BRANCH",
    "TRIAL_ADD_PRODUCT": "TRIAL_ADD_PRODUCT",
    "TRIAL_PRICE_SETUP": "TRIAL_PRICE_SETUP",
    "TRIAL_IMPORT_PRODUCT": "TRIAL_IMPORT_PRODUCT",
    "TRIAL_ADD_STAFF": "TRIAL_ADD_STAFF",
    "TRIAL_CANCEL_INVITATION": "TRIAL_CANCEL_INVITATION",
    "TRIAL_REJECT_INVITATION": "TRIAL_REJECT_INVITATION",
    "TRIAL_REMOVE_CUSTOMER": "TRIAL_REMOVE_CUSTOMER",
    "TRIAL_CUSTOMER_PRICE_SETUP": "TRIAL_CUSTOMER_PRICE_SETUP",
    "TRIAL_REMOVE_STAFF": "TRIAL_REMOVE_STAFF",
    "TRIAL_UPDATE_STAFF": "TRIAL_UPDATE_STAFF",
    "TRIAL_STAFF_CHANGE_PASSWORD": "TRIAL_STAFF_CHANGE_PASSWORD",
    "TRIAL_CHANGE_PASSWORD": "TRIAL_CHANGE_PASSWORD",
    "TRIAL_UPDATE_BRANCH": "TRIAL_UPDATE_BRANCH",
    "TRIAL_ACCEPT_ORDER": "TRIAL_ACCEPT_ORDER",
    "TRIAL_REJECT_ORDER": "TRIAL_REJECT_ORDER",
    "TRIAL_MARK_THE_ORDER_AS_CLOSED": "TRIAL_MARK_THE_ORDER_AS_CLOSED",
    "TRIAL_UPDATE_ORDER": "TRIAL_UPDATE_ORDER",
    "TRIAL_SEND_INVOICE": "TRIAL_SEND_INVOICE",
    "TRIAL_EDIT_INVOICE": "TRIAL_EDIT_INVOICE",
    "TRIAL_UPDATE_INVOICE": "TRIAL_UPDATE_INVOICE",
    "TRIAL_MARK_AS_PAID_INVOICE": "TRIAL_MARK_AS_PAID_INVOICE",
    "TRIAL_VIEW_CUSTOMER_DETAILS": "TRIAL_VIEW_CUSTOMER_DETAILS",
    "SETUP_SUBSCRIPTION": "SETUP_SUBSCRIPTION",
    "SHOW_PRODUCTS": "SHOW_PRODUCTS",
    "TRIAL_SHOW_PRODUCTS": "TRIAL_SHOW_PRODUCTS",

    "BUYER_INVENTORY": "BUYER_INVENTORY",           //INV000001
    "VENDOR_INVENTORY": "VENDOR_INVENTORY",         //INV000001
    "ADD_INVENTORY": "ADD_INVENTORY",               //INV000001
};

export default ActionConstant
