import React from "react";
import ImageConstants from "../../../constants/image-constants";
import { getImageSource } from "../../../utility/image-source";
import './bill-summary.scss';
import StaticText from "../../../shared-store/static-text-store";
import CommonUtils from '../../../../home/utility/common-utilities';
import Subscription from "../../../apis/subscription";

import Pagination from "../../common/pagination/pagination"
import SessionCache from "../../routes/session-cache";
import Loading from "../../common/loading/loading";

const orderStatuses = [{ title: 'Received Orders', value: "RECEIVED" }, { title: 'Rejected Orders', value: "REJECTED" }, { title: 'Cancelled Orders', value: "CANCELLED" },]

let allowancesKey = {
   subscriptionCharges: "Special Rate",
   subscriptionCap: "Monthly Fee Cap",
   creditAmount: "Credits Applied"
}

class BillSUmmary extends React.Component {
   state = {
      staticText: {},
      loading: true,
      totalPages: 0,
      selectedFilter: orderStatuses[0]
   };

   limit = 10
   currentPage = 1

   componentDidMount() {
      let staticText = StaticText.getStaticText("billSummary");
      this.setState({ staticText });
      document.addEventListener('mousedown', this.handleClickEvent, false);
      this.getUsageDetail()
      this.user = SessionCache.getUser()
   }

   componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickEvent, false);
   }


   getUsageDetail(payload = {}) {
      payload.usageId = this.props.usage._id

      if (!payload.page) payload.page = 1
      if (!payload.limit) payload.limit = this.limit

      if (!payload.type) payload.type = this.state?.selectedFilter?.value

      this.currentPage = payload.page
      this.setState({ isLoading: true })

      Subscription.getUsageDetail(payload).then((data) => {
         this.setState({ totalPages: data.totalPages || 0, orderTotalAmount: data.orderTotalAmount || 0 })
         this.prepareData(data.response || [])
      }).catch((err) => {
         this.setState({ usages: [], isLoading: false })
      })
   }

   prepareData(data = []) {
      this.setState({ orders: data, isLoading: false })
   }

   handleClickEvent = (event) => {
      if (this.listRef && !this.listRef.contains(event.target)) {
         this.hideOrderStatusDropDown()
      }
   }

   onClickOrderStatusDropDown() {
      this.setState({ showOrderStatusDropDown: true })
   }

   hideOrderStatusDropDown() {
      this.setState({ showOrderStatusDropDown: false })
   }

   handleBack = () => {
      this.props.back()
   }

   onPageSelected = (selectedPage, startIndex) => {
      this.startIndex = startIndex
      this.page = selectedPage
      this.getUsageDetail({ page: this.page })
   }

   onSelectFilter(item) {
      this.setState({ selectedFilter: item, showOrderStatusDropDown: false }, () => {
         this.getUsageDetail({ page: this.page })
      })
   }

   renderNameBadge(name, customClass) {
      if (!name) return "";
      let splitName = name.split(" ");
      let tag = "";
      if (splitName.length > 1) {
         tag = `${splitName[0][0] || ""}${splitName[1][0] || ""}`;
      } else if (splitName) {
         tag = `${splitName[0][0]}`;
      }
      tag = tag ? tag.toUpperCase() : "";
      return (
         <div className={`d-inline-block name-badge ${customClass || ""}`}>
            <span className="">{tag || ""}</span>
         </div>
      );
   }

   renderOrderTypeDropDown() {
      return (
         <div className='drop-down-popover app-box-shadow' ref={ref => this.listRef = ref}>
            <div className="arrow-up"></div>

            <div className='row m-0'>
               <div className='col p-0'>
                  <div className="status-list">
                     {
                        orderStatuses.map((item) => <div className={`cursor-pointer status-title-wrapper`} onClick={() => this.onSelectFilter(item)}>
                           <div className="d-flex align-items-center status-name">
                              <span className={`px-4 text-left`}>{item.title}</span>
                           </div>
                        </div>)
                     }
                  </div>
               </div>
            </div>
         </div>
      )
   }

   renderAllowances(allowances) {
      let arr = []
      for (let key in allowances) {
         let item = allowances[key]
         if (item.value) {
            arr.push(
               <div className="row text-primary px-3 py-2">
                  <div className="col">{allowancesKey[key]}</div>
                  <div className="col">{item.type === "AMOUNT" ? "$" : ""}{CommonUtils.formatAmountStringStrict(item.value)}{item.type === "PERCENTAGE" ? "%" : ""} </div>
               </div>
            )
         }
      }
      return arr
   }

   getIsAllowanceApplied(allowances) {
      let result = false
      if (allowances && Object.keys(allowances).length) {
         for (let key in allowances) {
            let item = allowances[key]

            if (!result && item.value) result = true
         }
      }
      return result
   }

   compareActualAmount(grossAmount, billingAmount) {

      if (!grossAmount) return false

      grossAmount = CommonUtils.formatAmount(grossAmount || 0)
      billingAmount = CommonUtils.formatAmount(billingAmount || 0)

      return grossAmount > billingAmount
   }

   render() {

      let totalAmount = +this.props.usage?.totalAmount || 0
      let rejectedAmount = +this.props.usage?.rejectedAmount || 0
      let cancelledAmount = +this.props.usage?.cancelAmount || 0
      let allowances = this.props.usage?.usageRecord?.allowances

      return <div className="bill-summary">
         {this.state.isLoading && <Loading />}

         <div className='row'>
            <div className='col-12'>
               <img src={getImageSource(ImageConstants.FULL_ARROW_LEFT)} alt="" className='arrow-left cursor-pointer' onClick={() => this.handleBack()}></img>
               <b className='header-text pl-5 pt-5'>{this.state.staticText.pageTitle} - {this.props.usage?.period}</b>
            </div>
         </div>

         <div className="bill-info-container row">
            <div className="col-md-4">
               <h3 className="bill-number">{this.state.staticText.billNo} {this.props.usage?.invoice?.invoiceNumber || "Processing"}</h3>
               <p className="sub-text">{this.state.staticText.issuedOn} {this.props.usage?.date}</p>
               <p className="sub-text">{this.state.staticText.dueDate} {this.props.usage?.date}</p>

               <table className="fee-table">
                  <tr>
                     <td className="v-align-base">{this.state.staticText.membershipFee}</td>
                     <td className="text-right">
                        {
                           this.compareActualAmount(this.props.usage?.usageRecord?.actualAmount, this.props.usage?.usageRecord?.amount) ?
                              <div>
                                 <div className="mb-2 strike-text">${CommonUtils.formatAmountStringStrict(this.props.usage?.usageRecord?.actualAmount)}</div>
                                 <div>{this.props.usage?.fee} <span className="text-primary position-absolute">**</span></div>
                              </div>
                              : <div>{this.props.usage?.fee} </div>
                        }
                     </td>
                  </tr>
                  <tr>
                     <td className="v-align-base">{this.state.staticText.salesTax || 'Sales Tax'}({this.props.usage?.invoice?.tax?.percentage || 0}%)</td>
                     <td className="text-right">
                        <div>${CommonUtils.formatAmountStringStrict(this.props.usage?.invoice?.tax?.amount)}</div>
                     </td>
                  </tr>
                  <tr className="half-padding">
                     <td colSpan={2}>
                        <div className="border-top-dark"></div>
                     </td>
                  </tr>
                  <tr className="half-padding">
                     <td className="v-align-base">{this.state.staticText.totalMonthlyPayment || 'Total Monthly Payment'}</td>
                     <td className="text-right">
                        <div>${CommonUtils.formatAmountStringStrict((this.props.usage?.invoice?.tax?.amount || 0) + (+this.props.usage?.feeNumber))}</div>
                     </td>
                  </tr>
                  {/* <tr>
                     <td>{this.state.staticText.paymentMethod}</td>
                     <td className="text-right">{this.state.staticText.autoPay}</td>
                  </tr>
                  <tr>
                     <td></td>
                     <td className="text-right card-number">
                        {CommonUtils.simpleTemplateEngine(this.state.staticText.cardEndText, { last4Number: this.props?.usage?.invoice?.paymentMethod?.last4 || '---' })}
                     </td>
                  </tr> */}
               </table>

               <div className="small-description-container pt-4">
                  <span className="small-description mr-1">*</span>
                  <span className="small-description"> {this.state.staticText.feeCalculationHint}</span>
               </div>
            </div>

            <div className="col-md-4 center-container">
               <div className="gross-order-value">
                  <p className="gross-order-title">{this.state.staticText.grossTitle}</p>

                  <table>
                     <tr>
                        <td>{this.state.staticText.receivedOrderValue}</td>
                        <td>+</td>
                        <td className="text-right">${CommonUtils.formatAmountStringStrict(totalAmount)}</td>
                     </tr>
                     <tr>
                        <td>{this.state.staticText.rejectedOrderValue}</td>
                        <td>-</td>
                        <td className="text-right">${CommonUtils.formatAmountStringStrict(rejectedAmount)}</td>
                     </tr>
                     <tr>
                        <td>{this.state.staticText.cancelledOrderValue}</td>
                        <td>-</td>
                        <td className="text-right">${CommonUtils.formatAmountStringStrict(cancelledAmount)}</td>
                     </tr>
                     <tr>
                        <td>{this.state.staticText.grov}</td>
                        <td>=</td>
                        <td className="text-right">${CommonUtils.formatAmountStringStrict((totalAmount) - (rejectedAmount + cancelledAmount))}</td>
                     </tr>
                  </table>
               </div>
               <table className="payment-method-table">
                  <tr>
                     <td>{this.state.staticText.paymentMethod}</td>
                     <td className="text-right">{this.state.staticText.autoPay}</td>
                  </tr>
                  <tr>
                     <td></td>
                     <td className="text-right card-number">
                        {CommonUtils.simpleTemplateEngine(this.state.staticText.cardEndText, { last4Number: this.props?.usage?.invoice?.paymentMethod?.last4 || '---' })}
                     </td>
                  </tr>
               </table>
            </div>

            <div className="col-md-4">
               <div className="profile-info-container">
                  <div className="profile-info">
                     <div className="pr-2">
                        {this.renderNameBadge(this.user?.businessName)}
                     </div>
                     <div>
                        <p className="line1">{this.user?.businessName}</p>
                        <p className="line2">{this.user?.email}</p>
                     </div>
                  </div>

                  <div>
                     {
                        this.getIsAllowanceApplied(allowances) ?
                           <div>
                              <div className="text-primary">** Allowances Applied</div>
                              {this.renderAllowances(allowances)}
                           </div>
                           : ""
                     }
                  </div>
               </div>
            </div>
         </div>

         <div className='bill-details-container'>
            <b className='cart-title pt-5'>{this.state.staticText.billDetails} - {this.props.usage?.period}</b>
            <div className="order-status-drop-down">
               <div className="selected-order-status" onClick={() => this.onClickOrderStatusDropDown()}>
                  <span>{this.state.selectedFilter?.title}</span>
                  <img alt="" className="drop-down-arrow" src={getImageSource(this.state.showOrderStatusDropDown ? ImageConstants.ARROW_UP : ImageConstants.DOWN_ARROW)} />
               </div>
               <div>
                  {this.state.showOrderStatusDropDown ? this.renderOrderTypeDropDown() : ''}
               </div>
            </div>
            <table className="table mb-0 align-items-center bill-details-table">
               <thead>
                  <th className=''>{this.state.staticText.thBusinessUnit}</th>
                  <th className=''>{this.state.staticText.thOrder}</th>
                  <th className=''>{this.state.staticText.thBuyer}</th>
                  <th className=''>{this.state.staticText.thOrderDate}</th>
                  <th className='text-right'>{this.state.staticText.thOrderTotal}</th>
               </thead>
               <tbody>
                  {
                     this.state.orders?.length ?
                        this.state.orders?.map((item, index) => (
                           <tr key={item._id + index}>
                              <td>{item.vendorBranch?.name}</td>
                              <td>{item.orderNumber}</td>
                              <td>{item.customerBranch?.name}</td>
                              <td>{CommonUtils.datePipe(item.createdDate, "MMM dd, yyyy")}</td>
                              <td className='text-right'>${CommonUtils.formatAmountStringStrict(item.totalPrice)}</td>
                           </tr>
                        ))
                        : <tr className="text-center">
                           <td colSpan="5">No Records Found</td>
                        </tr>
                  }
                  {this.state.orderTotalAmount ?
                     <tr className="border-top">
                        <td colSpan="3"></td>
                        <td className="text-primary">Total {this.state.selectedFilter?.title}  </td>
                        <td className="text-primary text-right">${CommonUtils.formatAmountStringStrict(this.state.orderTotalAmount)}</td>
                     </tr> : ""
                  }
               </tbody>
            </table>

            {
               this.state?.totalPages > 1 ?
                  <div className='card-footer'>
                     <Pagination
                        startIndex={this.startIndex}
                        selectedPage={this.currentPage}
                        totalPages={this.state?.totalPages || 0}
                        onPageSelected={this.onPageSelected.bind(this)}
                     />
                  </div>
                  : ""
            }
         </div>
      </div>
   }
}

export default BillSUmmary