export const privacyPolicy = `<div>
<h3>MyBiZSquare - Privacy Policy</h3>
<h5>Last updated: July 2, 2022</h5>
<br />

<div className='intro'>
   <p>This Privacy Policy includes important information about your personal data as well
      as our Cookie policy. Please read it carefully.</p>
   <p>This Privacy Policy (“Policy”) describes the “Personal Data” that we collect about
      you, how we use it, how we share it, and your rights and choices. This Policy also
      outlines your data subject rights, including the right to object to some uses of your
      Personal Data by us. </p>
   <p>“<b>Personal Data</b>” means any information that relates to an identified or identifiable
      individual.</p>
   <p>“<b>Services</b>” means the services that MyBiZSquare indicates are covered by this Policy.
      Depending on the context, “<b>You</b>” means you, or your buyer/vendor, or a website
      visitor.</p>
</div>

<div>
   <p>1. Personal data that we collect and how we use and share it</p>

   <div>
      <p>1.1 End Users</p>
      <p>How we use and share personal data of End Users</p>

      <ul>
         <li><b>Services.</b> We use your Personal Data to provide the Service to you, including
            delivery, support, personalization and messages related to the Service (e.g.
            communicating Policy updates and information about our Services). The use of
            this Personal Data is subject to the Business Users’ privacy policies.</li>
         <li><b>Advertising.</b> We do not share End User Personal Data with third parties for
            their marketing or advertising. We do not sell the data of End Users.</li>
      </ul>
   </div>

   <div>
      <p>1.2 Representatives</p>

      <p>To provide Business Services, we collect, use and share Personal Information from
         Representatives of our Business Users (e.g. a business owner). </p>

      <div>
         <p>a. Personal data that we collect about Representatives</p>

         <ul>
            <li><b>Registration and Contact Information.</b> If you register for a MyBiZSquare
               account, we collect your name, email address, business/delivery address and
               account log-in credentials. If you register for an event that MyBiZSquare
               organizes or attends or if you sign up for MyBiZSquare communications, we
               collect your registration and profile information. We do not collect or save any
               payment information from you. This will be done by the Payment Processor</li>
         </ul>
      </div>
      <div>
         <p>b. How we use and share personal data of Representatives</p>

         <ul>
            <li><b>Business Services.</b> We share data only with parties directly authorized
               by a Business User to receive Personal Data (e.g. Payment Processors). </li>
         </ul>
      </div>
   </div>

   <div>
      <p>1.3 Visitors (e.g. visitors to MyBiZSquare sites who are not an End User, End
         Customer or Representative)</p>

      <div>
         <p>a. Visitor personal data that we collect</p>
         <p>When you visit our Sites, we generally receive your Personal Data either from you
            providing it to us or through our use of cookies and similar technologies. See our
            Cookie Policy.</p>
         <ul>
            <li><b>Forms.</b> When you choose to fill in a form on the Site or on third party websites
               featuring our advertising (e.g. LinkedIn or Facebook), we will collect the
               information included in the form, usually your contact information and other
               information about your question related to our Services.</li>
         </ul>
      </div>

      <div>
         <p>b. How we use and share visitor personal data</p>

         <ul>
            <li><b>Personalization.</b> We use information about you that we gather from cookies
               and similar technologies to measure engagement with the content on the
               Sites, to improve relevancy and navigation, to personalize your experience and
               to tailor content about MyBiZSquare and our Services to you.</li>
            <li><b>Advertising.</b> We do not share any information with advertisers</li>
         </ul>
      </div>
   </div>
</div>

<div>
   <p>2. More ways we collect, use and share personal data</p>

   <p>In addition to the ways we collect, use and share Personal Data that are described
      above, we also process your Personal Data as follows.</p>

   <div>
      <p><b>a. Personal Data Collection</b></p>

      <ul>
         <li><b>Online Activity.</b> Depending on the Service you use and the Business Users’
            implementation of our Business Services, we will collect information about:
            <ul>
               <li>
                  Devices and browsers across our Sites and third-party websites, apps
                  and other online services (“Third-Party Sites”),
               </li>
               <li>
                  Usage data associated with those devices and browsers, including IP
                  address, plug-ins, language used, time spent on Sites and Third-Party
                  Sites, pages visited, links clicked, and the pages that led or referred you
                  to Sites and Third-Party Sites. For example, activity indicators, like
                  mouse activity indicators, help us detect fraud.
               </li>
            </ul>
         </li>
         <li>
            <b>Communication and Engagement Information.</b> We will collect any
            information you choose to provide to us, for example, through support tickets,
            emails or social media. When you respond to MyBiZSquare emails or surveys,
            we collect your email address, name and any other information you choose to
            include in the body of your email or responses. If you contact us by phone, we
            will collect the phone number you use to call MyBiZSquare, as well as other
            information you may provide during the call. We will also collect your
            engagement data such as your registration for, attendance of, or viewing of
            MyBiZSquare events and other interaction with MyBiZSquare personnel.
         </li>
         <li><b>Forums and Discussion Groups.</b> Where our Sites allow you to post content,
            we will collect Personal Data that you provide in connection with the post.</li>
      </ul>
   </div>

   <div>
      <p><b>b. Personal Data Usage.</b> In addition to the ways described above in which we use
         Personal Data, we use Personal Data in the following ways:</p>

      <ul>
         <li><b>Improving and Developing our Services.</b> We use analytics on our Sites to help
            us analyze your use of our Sites and Services and diagnose technical issues. To
            learn more about the cookies that may be served through our Sites and how
            you can control our use of cookies and third-party analytics, please see our
            Cookie Policy. We also collect and process Personal Data through our different
            Services, whether you are an End User, End Customer, Representative or
            Visitor, to improve our Services, develop new Services and support our efforts
            to make our Services more relevant and more useful to you.</li>
         <li>
            <b>Communications.</b> We will use the contact information we have about you to
            perform the Services, which may include sending codes via SMS/Email to
            authenticate you. If you are an End User, Representative or Visitor, we may
            communicate with you using the contact information we have about you (e.g.
            using email, phone, text message or videoconference) to provide information
            about our Services, invite you to participate in our events or surveys, or
            otherwise communicate with you for our marketing purposes, provided that
            we do so in accordance with applicable law, including any consent or opt-out
            requirements.
         </li>
         <li>
            <b>Social Media and Promotions.</b> If you choose to submit Personal Data to us to
            participate in an offer, program or promotion, we will use the Personal Data
            you submit to administer the offer, program or promotion. Based on your
            permission or opt-out, we will also use that Personal Data and Personal Data
            you make available on social media to market to you.
         </li>
         <li>
            <b>Minors.</b> The Services are not directed to minors, including children under the
            age of 13, and we request that they do not provide Personal Data through the
            Services.
         </li>
      </ul>
   </div>

   <div>
      <p><b>c. Personal Data Sharing.</b> In addition to the ways described above, we share Personal
         Data in the following ways:</p>

      <ul>
         <li>
            <b>Service Providers or Processors.</b> In order to provide Services to our Business
            Users and End Users and to communicate, market and advertise to Visitors,
            Representatives and End Users regarding our Services, we will rely on others
            to provide us services. Service providers (provide a variety of critical services,
            such as hosting (storing and delivering), analytics to assess the speed, accuracy
            and/or security of our Services, payment processing, identity verification,
            customer service, email and auditing. We authorize such service providers to
            use or disclose the Personal Data of our Users that we make available to
            perform services on our behalf and to comply with applicable legal
            requirements. We require such service providers to contractually commit to
            protect the security and confidentiality of Personal Data they process on our
            behalf. Our service providers are predominantly located in the United States of America and India.</li>
         <li>
            <b>Corporate Transactions.</b> In the event that we enter into, or intend to enter
            into, a transaction that alters the structure of our business, such as a
            reorganization, merger, sale, joint venture, assignment, transfer, change of
            control, or other disposition of all or any portion of our business, assets or
            stock, we may share Personal Data with third parties in connection with such
            transaction. Any other entity which buys us or part of our business will have
            the right to continue to use your Personal Data, but subject to the terms of
            this Policy.
         </li>
         <li>
            <b>Compliance and Harm Prevention.</b> We share Personal Data as we believe
            necessary: (i) to comply with applicable law; (ii) to enforce our contractual
            rights; (iii) to secure or protect the Services, rights, privacy, safety and
            property of MyBiZSquare, you or others, including against other malicious or
            fraudulent activity and security incidents; and (iv) to respond to valid legal
            process requests from courts, law enforcement agencies, regulatory agencies,
            and other public and government authorities, which may include authorities
            outside your country of residence.
         </li>
      </ul>
   </div>
</div>

<div>
   <p>3. Legal bases for processing data</p>

   <div>
      <p>
         <b>a. Contractual and Pre-Contractual Business Relationships.</b> We process Personal
         Data for the purpose of entering into business relationships with prospective
         Business Users and End Users and to perform the respective contractual obligations
         that we have with these Users. Activities include:</p>

      <ul>
         <li>Creation and management of MyBiZSquare accounts and account credentials,
            including the evaluation of applications to commence or expand the use of our
            Services;</li>
         <li>Accounting, auditing, and billing activities; and</li>
         <li>Processing of subscription payments, including fraud detection and
            prevention, optimizing valid transactions, communications regarding such
            payments, and related customer service.</li>
      </ul>
   </div>

   <div>
      <p><b>b. Legal Compliance.</b> We process Personal Data to verify the identity of our Users in
         order to comply with fraud monitoring, prevention and detection obligations, laws
         associated with the identification and reporting of illegal and illicit activity, such as
         "Anti-Money Laundering ("AML") and Know-Your-Customer ("KYC")" obligations, and
         financial reporting obligations. These obligations are imposed on us by the operation
         of law, industry standards, and by our financial partners, and may require us to
         report our compliance to third parties, and to submit to third party verification
         audits.</p>
   </div>
   <div>
      <p><b>c. Legitimate Business Interests.</b></p>

      <ul>
         <li>Respond to enquiries, send Service notices and provide customer support;</li>
         <li>Promote, analyze, modify and improve our Services, systems, and tools, and
            develop new products and services, including reliability of the Services;</li>
         <li>Manage, operate and improve the performance of our Sites and Services by
            understanding their effectiveness;</li>
         <li>Conduct aggregate analysis that enables us to make informed decisions, and
            report on the performance of our business;</li>
         <li>Share Personal Data with third party service providers that provide services on
            our behalf and business partners which help us operate and improve our
            business;</li>
      </ul>
   </div>
</div>

<div>
   <p>4. Your rights and choices</p>

   <p>You may have choices regarding our collection, use and disclosure of your Personal
      Data:</p>
   <div>
      <p>a. Opting out of receiving electronic communications from us
         If you no longer want to receive marketing-related emails from us, you may opt-out
         via the unsubscribe link included in such emails. Please note that even if you opt out
         of receiving marketing-related emails from us, we will still send you notifications
         about any changes to our Privacy Policy and/or our Services Agreement.</p>
   </div>
</div>

<div>
   <p>5. Security and retention</p>

   <p>We maintain organizational, technical and administrative measures designed to
      protect Personal Data covered by this Policy against unauthorized access,
      destruction, loss, alteration or misuse. Personal Data is only accessed by a limited
      number of personnel who need access to the information to perform their duties. </p>
   <p>
      To protect your personal data please use a strong password and never share your
      password with anyone or use the same password with other sites or accounts. If you
      feel that the security of your account has been compromised, please change the
      password immediately, and if needed please contact us.

   </p>
   <p>
      We retain your Personal Data as long as we are providing the Services to you or for a
      period during which we reasonably anticipate providing the Services. Even after we
      stop providing Services directly to you or a Business User with which you are doing
      business, we retain your Personal Data in order to comply with our legal and
      regulatory obligations. We may also retain it to allow for fraud monitoring, detection
      and prevention activities. We also keep Personal Data where we are required to
      retain the data by our contractual commitments to our financial partners. In cases
      where we keep Personal Data, we do so in accordance with any limitation periods
      and record retention obligations that are imposed by applicable law.
   </p>
</div>

<div>
   <p>
      6. Updates and notifications
   </p>

   <p>We may change this Policy from time to time to reflect new services, changes in our
      privacy practices or relevant laws. The “Last updated” legend at the top of this Policy
      indicates when this Policy was last revised. Any changes are effective when we post
      the revised Policy on the Services.</p>
   <p>We may provide you with disclosures and alerts regarding the Policy or Personal
      Data collected by posting them on our website and by contacting you through your
      email address and/or the physical address listed in your MyBiZSquare account.</p>
   <p>If applicable law requires that we provide notice in a specified manner prior to
      making any changes to this Policy applicable to you, we will provide such required
      notice.</p>
</div>

<div>
   <p>7. Jurisdiction-specific provisions</p>

   <ul>
      <li>
         United States - California. If you are a consumer located in California, we
         process your personal information in accordance with the California Consumer
         Privacy Act ("CCPA"). You have a right to receive notice of our practices at or
         before the collection of personal information. This section provides additional
         details about the personal information we collect and use for purposes of
         CCPA.

         <ul>
            <li>
               How We Collect, Use, and Disclose your Personal Information. The
               Personal Data We Collect section further describes the personal
               information we may have collected about you, including the categories
               of sources of that information. We collect this information for the
               purposes described in the How We Use Personal Data section. We share
               this information as described in the How We Disclose Personal Data
               section. MyBiZSquare uses cookies as described in our Cookie Policy.
            </li>
            <li>
               Your CCPA Rights and Choices. As a California consumer and subject to
               certain limitations under the CCPA, you have choices regarding our use
               and disclosure of your personal information:

               <ul>
                  <li>Exercising the right to know: You may request that we disclose to
                     you the personal information we have collected about you. You
                     also have a right to request additional information about
                     categories of their personal information collected, sold, or
                     disclosed; purposes for which this personal information was
                     collected or sold; categories of sources of personal information;
                     and categories of third parties with whom we disclosed this
                     personal information.</li>
                  <li>Exercising the right to delete: You may request that we delete
                     the personal information we have collected from you, subject to
                     certain limitations under applicable law.</li>
                  <li>Exercising the right to opt out from a sale: We do not sell
                     Personal Data as defined by the CCPA and have not done so in
                     the past 12 months.</li>
                  <li>
                     Non-discrimination: The CCPA provides that you may not be
                     discriminated against for exercising these rights.
                  </li>
               </ul>
               <li>To submit a request to exercise any of the rights described above,
                  please contact us using the methods described in the Contact Us
                  section below.
                  <ul>
                     <li>We will verify your request by asking you to send it from the
                        email address associated with your account or requiring you to
                        provide information necessary to verify your account.</li>
                     <li>An authorized agent may submit a request on your behalf by
                        contacting us using the methods described in the Contact Us
                        section below. We may still require you to directly verify your
                        identity and confirm that you provided the authorized agent
                        permission to submit the request.</li>
                  </ul>
               </li>
            </li>
         </ul>
      </li>
   </ul>
</div>

<div>
   <p>8. Contact us</p>

   <p>If you have any questions or complaints about this Policy, please contact us. If you
      have any questions on the privacy policies of the Payment Processor, please contact
      them directly.</p>
</div>

<div>
   <h5>Appendix A: Cookie Policy</h5>
   <p>This cookie policy describes how MyBiZSquare LLC (“MyBiZSquare”) uses “cookies”
      and other similar technologies, in connection with our Site and Services. </p>

   <div>
      <p className='blue'>1. What is a Cookie?</p>
      <p>Cookies are small text files that are stored in a computer's browser directory. They
         help site providers with things like understanding how people use a site,
         remembering a user's login details, and storing site preferences.</p>
   </div>

   <div>
      <p className='blue'>2. Does MyBiZSquare use Cookies?</p>
      <p>Yes. We use cookies in accordance with our Privacy Policy to:</p>

      <ul>
         <li>ensure that our services function properly</li>
         <li>detect and prevent fraud,</li>
         <li>understand how visitors use our site, and</li>
         <li>analyze and improve Services.</li>
      </ul>
   </div>

   <div>
      <p className='blue'>3. Who sets cookies when I use MyBiZSquare’s Site?</p>
      <p>There are two main types of cookies that can be set:</p>

      <ul>
         <li>First party cookies: These cookies are placed and read by MyBiZSquare directly
            when you use our Services,</li>
         <li>Third party cookies: These cookies are not set by MyBiZSquare, but by other
            companies, like Google or Facebook, for site analytics purposes.</li>
      </ul>
   </div>

   <div>
      <p className='blue'>4. How MyBiZSquare Uses Cookies</p>
      <p>Below is a description of the commonly used cookie types and the purposes that
         apply to them.</p>
   </div>

   <div>
      <p className='blue'>Necessary Cookies</p>
      <p>Some cookies are essential to the operation of our site and services and make it
         usable and secure by enabling basic functions like page navigation and access to
         secure areas of the Site. We use those cookies in a number of different ways,
         including:</p>

      <ul>
         <li><b>Authentication.</b> To remember your login state so you don't have to log in as
            you navigate through our site and dashboard.</li>
         <li>
            <b>Fraud Prevention and Detection.</b> Cookies and similar technologies that we
            deploy through our site help us learn things about computers and web
            browsers used to access the Services. This information helps us monitor and
            detect potentially harmful or illegal use of our Services. For example, in order
            to process subscription payments for our users, it is necessary for
            MyBiZSquare to collect information about the transaction and the user. To
            help secure these transactions and minimize fraud, we collect additional
            information through the use of cookies and other technologies in helping to
            identify bad actors and prevent them from making fraudulent transactions.
         </li>
         <li><b>Security.</b> To protect user data from unauthorized access.</li>
         <li><li>Functionality.</li> To keep our site and services working correctly, like showing
            you the right information for your selected location.</li>
      </ul>
   </div>

   <div>
      <p className='blue'>Preference Cookies</p>

      <p>Preference cookies are used by MyBiZSquare to remember your preferences and to
         recognize you when you return to our Services</p>
   </div>

   <div>
      <p className='blue'>Analytics Cookies</p>
      <p>Analytics cookies help us understand how visitors interact with our services. We use
         those cookies in a number of different ways, including:</p>

      <ul>
         <li><b>Site Features and Services.</b> To remember how you prefer to use our Services
            so that you don't have to reconfigure your settings each time you log into your
            account.</li>
         <li><b>Analyze and Improve Our Services.</b> To make our Site and Services work better
            for You. Cookies help us understand how people reach our Site and our users'
            sites. They give us insights into improvements or enhancements we need to
            make to our Site and Services.</li>
      </ul>
   </div>

   <div>
      <p className='blue'>5. Can I opt out?</p>

      <p>Yes. Your web browser may allow you to manage your cookie preferences, including
         deleting and disabling MyBiZSquare cookies. You can take a look at the help section
         of your web browser or follow the links below to understand your options. If you
         choose to disable cookies, some features of our site or services may not operate as
         intended.</p>

      <ul className='links'>
         <li>Chrome: <a className='word-break' href='https://support.google.com/chrome/answer/95647?hl=en'>https://support.google.com/chrome/answer/95647?hl=en</a></li>
         <li>Safari: <a className='word-break' href='https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac'>https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></li>
         <li>Firefox: <a className='word-break' href='https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer'>https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer</a></li>
         <li>Edge: <a className='word-break' href='https://support.microsoft.com/en-us/microsoft-edge/view-cookies-in-microsoft-edge-a7d95376-f2cd-8e4a-25dc-1de753474879#:~:text=1%20Open%20Microsoft%20Edge%20and%20then%20select%20Settings,to%20view%20all%20cookies%20saved%20on%20your%20device'>https://support.microsoft.com/en-us/microsoft-edge/view-cookies-in-microsoft-edge-a7d95376-f2cd-8e4a-25dc-1de753474879#:~:text=1%20Open%20Microsoft%20Edge%20and%20then%20select%20Settings,to%20view%20all%20cookies%20saved%20on%20your%20device</a></li>
      </ul>
   </div>
</div>
</div>`

export const servicesAgreement = `<div>
<h3>MyBiZSquare Services Agreement — United States</h3>
<h5>Last updated: July 2, 2022</h5>
<br />
<div className='intro'>
   <p>This MyBiZSquare Services Agreement (“Agreement”) is a legal agreement between
      MyBiZSquare LLC (“MyBiZSquare”, “us”, or “we”) and the entity or person (“you”, “your”, or
      “user”) who registered on the MyBiZSquare Account page to receive the business services that
      are offered by MyBiZSquare (“Service”). This Agreement describes the terms and conditions
      that apply to your use of the Services.</p>
   <p>If you do not understand any of the terms of this Agreement, please contact us before using the
      Services.</p>
   <p>You may not access or use any Services unless you agree to abide by all of the terms and
      conditions in this Agreement.</p>
</div>

<br />
<div className='section'>
   <h4>Section A: General Terms</h4>
   <div>
      <h5>1. Overview of this Agreement</h5>
      <p>This Agreement provides a general description of the Services that MyBiZSquare provides you
         through MyBiZSquare application (“Platform”), which allows you to perform Order
         Management related functions (order tracking, invoices, payments etc, collectively “Business
         Activities”) with other B2B businesses doing business with you (“Your Buyers” or “Your
         Vendors” depending on what business activity they perform with you). </p>
      <p>Before using the Services, you must register with MyBiZSquare and create an account on the
         Platform (a “MyBiZSquare Account”).</p>
      <p>Section A describes the process of registering for and using your MyBiZSquare Account.</p>
      <p>Section B describes MyBiZSquare technology.</p>
      <p>Section C describes how MyBiZSquare provides Payment Processing Services.</p>
      <p>Section D describes the use of data generated during your use of the Services, including your
         Buyers’/Vendors’ data.</p>
      <p>Finally, Section E describes your liability to MyBiZSquare for all losses connected with your
         MyBiZSquare Account, your agreement to resolve all disputes with MyBiZSquare by arbitration
         and not in a lawsuit, and other legal terms that apply to you.</p>
   </div>

   <div>
      <h5>2. Your MyBiZSquare Account</h5>
      <p>Registration and Permitted Activities: Only B2B businesses located in the United States are
         eligible to register for a MyBiZSquare Account to use the Services described in this Agreement.
         To register for a MyBiZSquare Account, you or the person completing the registration (your
         “Representative”) must provide us with your business name, physical address, email, phone
         number, and certain other information about you that we require.</p>
   </div>

   <div>
      <h5>
         3. Your Relationship with Your Buyers/Vendors
      </h5>
      <p>You may only use the Services for legitimate Transactions with your Buyers/Vendors and you
         are responsible for your relationship with them. MyBiZSquare is not responsible for the
         products you sell to your Vendors or you purchase from your Buyers, using the Services. You
         affirm that you are solely responsible for the condition and quality of the products, and for
         delivery, support, refunds, returns, and for any other ancillary services you provide to your
         Buyers/Vendors.</p>
      <p>MyBiZSquare provides Services to you but we do not know if any particular purchase, sale,
         invoice, payment, inventory update or any other transaction (each a “Transaction”) is accurate
         or complete, or typical for your business. You are responsible for knowing whether a
         Transaction initiated by your Buyer/Vendor is erroneous (such as a Buyer purchasing one item
         when they meant to order another, or a Vendor adding incorrect charges on the invoice) or
         suspicious (such as unusual or large purchases or delivery charges). If you are unsure if a
         Transaction is erroneous or suspicious, you agree to research the Transaction and, if necessary,
         contact your Buyer/Vendor before fulfilling or completing the Transaction. You are solely
         responsible for any losses you incur due to erroneous or fraudulent Transactions in connection
         with your use of the Services.</p>
   </div>

   <div>
      <h5>4. Fees and Fines</h5>
      <p>MyBiZSquare will provide the Services to you at the subscription fees (“Subscription Fees”)
         described on the Pricing page, and incorporated into this Agreement. We may revise the
         Subscription Fees at any time. However, we will provide you with at least 30 days’ advance
         notice before revisions become applicable to you (or a longer period of notice if this is required
         by applicable Law). </p>
      <p>MyBiZSquare uses a third party as our payment processor (“Payment Processor”). Our
         Subscription Fees do not include the charges for payment processing (“Payment Fees”) and for
         other events connected with your Payment Processor Account (such as handling a disputed
         charge). To receive and make payments from your Buyers/Vendors you will directly set up a
         connection with Payment Processor from within the MyBiZSquare Platform. MyBiZSquare does
         not have any visibility or access to any information about your Credit Card, ACH or any other
         means of payment or bank account. MyBiZSquare does not add any commission to the
         Payment Fees that Payment Processor charges. Any changes to the Payment Fees in beyond
         our control and you will need to work directly with Payment Processor to resolve any conflicts.</p>
      <p>You are obligated to pay all taxes, customs, duties, fees and other charges imposed by any
         governmental authority (“Taxes”), including any value added tax, goods and services tax,
         provincial sales tax and/or, harmonized sales tax, and/or withholding tax on the Services
         provided under this Agreement. You agree MyBiZSquare is not responsible for assessing,
         collecting, reporting, or remitting any Taxes on any goods that you sell using the Services under
         this Agreement and/or on any payments or payouts you make to any third party through the
         Services.</p>
   </div>

   <div>
      <h5>5. Services and MyBiZSquare Account Support</h5>
      <p>We will provide you with support to resolve general issues relating to your MyBiZSquare
         Account and your use of the Services. This support includes resources and documentation that
         we make available to you through the current versions of MyBiZSquare’s support pages, and
         other pages on our website (collectively, “Documentation”).</p>
      <p>You are solely responsible for providing support to your Buyers/Vendors regarding any of your
         Business Activities using MyBiZSquare’s Services. We are only responsible for providing support
         on the technicality and functionality of the Platform and not on any of your or your
         Buyers’/Vendors’ business activities or products.</p>
   </div>

   <div>
      <h5>6. Service Requirements, Limitations and Restrictions</h5>
      <p>
         <b>a. Compliance with Applicable Laws: </b>You must use the Services in a lawful manner, and must
         obey all laws, rules, and regulations (“Laws”) applicable to your use of the Services and to
         Transactions.
      </p>
      <p><b>b. Restricted Businesses and Activities:</b>You may not use the Services to enable any person
         (including you) to benefit from any activities MyBiZSquare has identified as a restricted business
         or activity (collectively, “Restricted Businesses”). </p>
      <p>Please review the list of Restricted Businesses thoroughly before registering for and opening a
         MyBiZSquare Account. We reserve the right to or update the Restricted Business List at any
         time.</p>
      <p>Refer to Appendix A at the bottom of this Services Agreement for a representative list of
         Restricted and Prohibited Businesses’ list</p>
      <p><b>c. Other Restricted Activities: </b>You may not allow, and may not allow others to: (i) access or
         attempt to access non-public MyBiZSquare systems, programs, data, or services; (ii) copy,
         reproduce, republish, upload, post, transmit, resell, or distribute in any way, any data, content,
         or any part of the Services, Documentation, or our website except as expressly permitted by
         applicable Laws; (iii) transfer any rights granted to you under this Agreement; (iv) work around
         any of the technical limitations of the Services or enable functionality that is disabled or
         prohibited; (v) reverse engineer or attempt to reverse engineer the Services except as expressly
         permitted by Laws; or (vi) perform or attempt to perform any actions that would interfere with
         the normal operation of the Services or affect use of the Services by our other users.</p>
   </div>

   <div>
      <h5>7. Disclosures and Notices; Electronic Signature Consent</h5>
      <p>a. Consent to Electronic Disclosures and Notices: By registering for a MyBiZSquare Account, you
         agree that such registration constitutes your electronic signature, and you consent to electronic
         provision of all disclosures and notices from MyBiZSquare (“Notices”), including those required
         by Law. You also agree that your electronic consent will have the same legal effect as a physical
         signature.</p>
      <p>b. Methods of Delivery: You agree that MyBiZSquare can provide Notices regarding the Services
         to you through our website or by mailing Notices to the email or physical addresses identified in
         your MyBiZSquare Account. Notices may include notifications about your MyBiZSquare
         Account, changes to the Services, or other information we are required to provide to you. You
         also agree that electronic delivery of a Notice has the same legal effect as if we provided you
         with a physical copy. We will consider a Notice to have been received by you within 24 hours of
         the time a Notice is either posted to our website or emailed to you</p>
      <p>d. Requirements for Delivery: You will need a computer or mobile device, internet connectivity,
         and an updated browser to access and review the Notices provided to you.</p>
   </div>

   <div>
      <h5>8. Termination</h5>
      <p>a. Term and Termination: This Agreement is effective upon the date you first access or use the
         Services and continues until terminated by you or MyBiZSquare. You may terminate this
         Agreement by unselecting your consent located on the profile page in the Platform. If you reselect the consent again or register for another MyBiZSquare Account, you are consenting to
         this Agreement. We may terminate this Agreement or close your MyBiZSquare Account at any
         time for any reason (including, but not limited to, for you conducting a Business Activity that is
         in our Restricted Businesses list) by providing you Notice. We may suspend your MyBiZSquare
         Account or terminate this Agreement, if (i) we determine that you are ineligible for the Services
         because of any risks associated with your MyBiZSquare Account; (ii) you use the Services in a
         prohibited manner or otherwise do not comply with any of the provisions of this Agreement;
         (iii) any Law, or Payment Processor requires us to do so; or (iv) we are otherwise entitled to do
         so under this Agreement. Payment Processor may terminate your ability to accept or make
         payments, at any time and for any reason, in which case you will no longer be able to accept
         make/receive payments under this Agreement.</p>
      <p>b. Effects of Termination: Termination does not immediately relieve you of obligations incurred
         by you under this Agreement. Upon termination, you agree to (i) complete all pending
         Transactions, (ii) stop accepting new Transactions, and (iii) immediately remove all
         MyBiZSquare and payment network logos from your website. Your continued or renewed use
         of the Services after all pending Transactions have been processed serves to renew your
         consent to the terms of this Agreement. If you terminate this Agreement, we will pay out any
         remaining funds owed to you.</p>
      <p>In addition, upon termination you understand and agree that (i) all licenses granted to you by
         MyBiZSquare under this Agreement will end; (ii) we reserve the right (but have no obligation) to
         delete all of your information and account data stored on our servers; (iii) we will not be liable
         to you for compensation, reimbursement, or damages related to your use of the Services, or
         any termination or suspension of the Services or deletion of your information or account data;
         and (iv) you are still liable to us for any Fees or fines, or other financial obligation incurred by
         you or through your use of the Services prior to termination.</p>
   </div>
</div>

<br />
<div className='section'>
   <h4>Section B: MyBiZSquare Technology</h4>

   <div>
      <h5>
         1. Ownership of MyBiZSquare Intellectual Property
      </h5>
      <p>MyBiZSquare exclusively owns all rights, title, and interest in the patents, copyrights (including
         rights in derivative works), moral rights, rights of publicity, trademarks or service marks, logos
         and designs, trade secrets, and other intellectual property embodied by, or contained in the
         Services, and Documentation (collectively, “MyBiZSquare Intellectual Property”) or any copies
         thereof. MyBiZSquare Intellectual Property is protected by copyright, trade secret, patent, and
         other intellectual property Laws, and all rights in MyBiZSquare Intellectual Property not
         expressly granted to you in this Agreement are reserved.</p>
      <p>If you submit an idea to us for improving the Platform (“Idea”), we will presume that your
         submission was voluntary, unsolicited by us, and delivered to us without any restrictions on our
         use of the Idea. You also agree that MyBiZSquare has no fiduciary or any other obligation to you
         in connection with any Idea you submit to us, and that we are free to use your Idea without any
         attribution or compensation to you.</p>
   </div>

   <div>
      <h5>2. License</h5>
      <p>You are granted a nonexclusive and nontransferable license to electronically access and use the
         MyBiZSquare Intellectual Property only in the manner described in this Agreement.
         MyBiZSquare does not sell to you, and you do not have the right to sublicense the MyBiZSquare
         Intellectual Property. We may make updates to the MyBiZSquare Intellectual Property or new
         Services available to you automatically as electronically published by MyBiZSquare, but we may
         require action on your part before you may use the MyBiZSquare Intellectual Property or new
         Services (including acceptance of new or additional terms). MyBiZSquare may revoke or
         terminate this licence at any time if you use MyBiZSquare Intellectual Property in a manner
         prohibited by this Agreement.</p>
      <p>You may not: (i) claim or register ownership of MyBiZSquare Intellectual Property on your
         behalf or on behalf of others; (ii) sublicense any rights in MyBiZSquare Intellectual Property
         granted by us; (iii) import or export any MyBiZSquare Intellectual Property to a person or
         country in violation of any country’s export control Laws; (iv) use MyBiZSquare Intellectual
         Property in a manner that violates this Agreement or Laws; or (v) attempt to do any of the
         foregoing.</p>
   </div>

   <div>
      <h5>
         3. References to Our Relationship
      </h5>
      <p>During the term of this Agreement, you may publicly identify us as the provider of the Services
         to you and we may publicly identify you as a MyBiZSquare user. If you do not want us to
         identify you as a user, please contact us. Neither you nor we will imply any untrue sponsorship,
         endorsement, or affiliation between you and MyBiZSquare. Upon termination of your
         MyBiZSquare Account, both you and MyBiZSquare will remove any public references to our
         relationship from our respective websites.</p>
   </div>

   <div>
      <h5>4. Content</h5>
      <p>You may use the Services to upload or publish text, images, and other content (collectively,
         “Content”) to your MyBiZSquare Account but only if you agree to obtain the appropriate
         permissions and, if required, licenses to upload or publish any such Content using the Services.
         You agree to fully reimburse MyBiZSquare for all fees, fines, losses, claims, and any other costs
         we may incur that arise from publishing illegal Content through the Services, or claims that
         Content you published infringes the intellectual property, privacy, or other proprietary rights of
         others.</p>
   </div>
</div>

<br />
<div className='section'>
   <h4>Section C: Payment Processing Services</h4>

   <div>
      <p>MyBiZSquare is not a payment processor. We use a third-party payment processor (“Payment
         Processor”) and You may need to agree to the terms and conditions of Payment Processor prior
         to using their services. It is your responsibility to read and agree to Payment Processor’s
         Agreements</p>
      <p>MyBiZSquare does not have any visibility or access to any information about your credit card,
         ACH, any other means of payment or bank account. MyBiZSquare does not add any commission
         to the Payment Fees that Payment Processor charges.</p>
   </div>
</div>

<br />
<div className='section'>
   <h4>Section D: Data Usage, Privacy, and Security</h4>

   <div>
      <h5>1. Data Usage Overview</h5>
      <p>This section describes our respective obligations (Ours and Yours) when handling and storing
         information connected with the Services. The following terms used in this section relate to data
         provided to MyBiZSquare by you or your Customers/Vendors, or received or accessed by you
         through your use of the Services:</p>
      <p>“Payment Data” means payment method details (account number, expiraton date, CVV etc),
         information communicated to or by Payment Processor, financial information specifically
         regulated by Laws and Payment Method Rules, and any other information used with the
         Payment Processing Services to complete a Transaction.</p>
      <p>“Personal Data” means information that identifies a specific living person (not a company, legal
         entity, or machine) and is transmitted to or accessible through the Services.</p>
      <p>“User Data” means information that describes your business and its operations, your products
         or services, and orders placed by Buyers, invoices raised by Vendors, your inventory details etc.</p>
      <p>The term “Data” used without a modifier means all Personal Data, User Data, and Payment
         Data.</p>
      <p>MyBiZSquare processes, analyzes, and manages Data to: (a) provide Services to you and Your
         Buyers/Vendors; and (b) analyze, develop and improve our system. We do not provide Personal
         Data or User Data to any other parties for any purposes. Payment Data is directly stored in
         Payment Processor’s systems. It is Payment Processor’s responsibility to keep their systems
         compliant with the applicable rules and regulations, and your consent on how Your Payment
         Data is used will be directly with Payment Processor. You understand and consent to
         MyBiZSquare’s use of Data for the purposes and in a manner consistent with this Section D.</p>
   </div>
</div>

<br />
<div className='section'>
   <h4>Section E: Additional Legal Terms</h4>

   <div>
      <h5>
         1. Right to Amend
      </h5>
      <p>We have the right to change or add to the terms of this Agreement at any time, solely with
         prospective effect, and to change, delete, discontinue, or impose conditions on use of the
         Services by posting such changes on our website. We will provide you with Notice of any
         changes via email, or through other reasonable means. If you are an existing MyBiZSquare user,
         the changes will come into effect 2 weeks after we post the changes to our website, and your
         use of the Services more than 2 weeks after we publish any such changes on our website
         constitutes your acceptance of the terms of the modified Agreement. You can access a copy of
         the current terms of this Agreement on the Platform at any time. You can find out when this
         Agreement was last changed by checking the “Last updated” date at the top of the Agreement.</p>

   </div>

   <div>
      <h5>2. Third-Party Services</h5>
      <p>Except as expressly stated in this Agreement, nothing in this Agreement serves to establish a
         partnership, joint venture, or other agency relationship between you and us.</p>
      <p>We may reference or provide access to third-party services, products, and promotions that
         utilize, integrate, or provide ancillary services to the Services (“Third-Party Services”). These
         Third-Party Services are provided for your convenience only and do not constitute our approval,
         endorsement, or recommendation of any such Third-Party Services for you. You access and use
         any Third-Party Service based on your own evaluation and at your own risk. You understand
         that your use of any Third-Party Service is not governed by this Agreement. If you decide to use
         a Third-Party Service, you will be responsible for reviewing, understanding and accepting the
         terms and conditions associated with its use. We expressly disclaim all responsibility and
         liability for your use of any Third-Party Service. Please also remember that when you use a
         Third-Party Service, our Privacy Policy is no longer in effect. Your use of a Third-Party Service,
         including those that have a link on our website, is subject to that Third-Party Service’s own
         terms of use and privacy policies.</p>
   </div>

   <div>
      <h5>3. Force Majeure</h5>
      <p>Neither party will be liable for any delays in processing or other nonperformance caused by
         telecommunications, utility, failures, or equipment failures; labor strife, riots, war, or terrorist
         attacks; nonperformance of our vendors or suppliers, fires or acts of nature; or any other event
         over which the respective party has no reasonable control. However, nothing in this section will
         affect or excuse your liabilities or your obligation to pay Fees, Fines, Disputes, Refunds,
         Reversals, or Returns under this Agreement.</p>

   </div>

   <div>
      <h5>4. Your Liability for Third-Party Claims Against Us</h5>
      <p>Without limiting, and in addition to, any other obligation that you may owe under this
         Agreement, you are at all times responsible for the acts and omissions of your employees,
         contractors and agents, to the extent such persons are acting within the scope of their
         relationship with you.</p>
      <p>You agree to defend MyBiZSquare against any claim, suit, demand, loss, liability, damage,
         action, or proceeding (each, a “Claim”) brought by a third party against MyBiZSquare, and you
         agree to fully reimburse MyBiZSquare for any Claims that results from: (i) your breach of any
         provision of this Agreement; (ii) any Fees, Fines, Disputes, Refunds, Reversals, Returns, or any
         other liability we incur that results from your use of the Services; (iii) your negligent or willful
         misconduct, or the negligent or willful misconduct of your employees, contractors, or agents; or
         (iv) contractual or other relationships between you and Buyers/Customers.</p>
      <p>Important Note for Sole Proprietors: If you are using Services as a sole proprietor, please keep
         in mind that the Law and the terms of this Agreement consider you and your business to be
         legally one and the same. You are personally responsible and liable for your use of the Services,
         payment of Fees, Refunds, Reversals, Fines, losses based on Disputes or fraud, or for any other
         amounts you owe under this Agreement for your failure to use Security Controls, and for all
         other obligations to us and to your Buyers/Vendors. You risk personal financial loss if you fail to
         pay any amounts owed.</p>

   </div>

   <div>
      <h5>5. Representations and Warranties</h5>
      <p>By accepting the terms of this Agreement, you represent and warrant that: (a) you are eligible
         to register and use the Services and have the authority to execute and perform the obligations
         required by this Agreement; (b) any information you provide us about your business, products,
         or services is accurate and complete; (c) you will fulfill all of your obligations to
         Buyers/Customers and will resolve all Disputes with them; (d) you will comply with all Laws
         applicable to your business and use of the Services; (e) your employees, contractors and agents
         will at all times act consistently with the terms of this Agreement; and (f) you will not use the
         Services, directly or indirectly, for any fraudulent or illegal undertaking, or in any manner that
         interferes with the normal operation of the Services.</p>
   </div>

   <div>
      <h5>6. No Warranties</h5>
      <p>We provide the services and MyBiZSquare IP “AS IS” and “AS AVAILABLE”, without any express,
         implied, or statutory warranties of title, merchantability, fitness for a particular purpose,
         noninfringement, or any other type of warranty or guarantee. No data, documentation or any
         other information provided by MyBiZSquare or obtained by you from or through the
         MyBiZSquare services, and whether oral or written – creates or implies any warranty from
         MyBiZSquare to you.</p>
      <p>You affirm that MyBiZSquare does not control the products or services that you offer or sell or
         that your Buyers purchase. You understand that we cannot guarantee and we disclaim any
         knowledge that your Buyers/Vendors possess the authority to make, or will complete, any
         transaction.</p>
      <p>MyBiZSquare disclaim any knowledge of, and does not guarantee: (a) the accuracy, reliability,
         or correctness of any data provided through the services; (b) that the services will meet your
         specific business needs or requirements; (c) that the services will be available at any particular
         time or location, or will function in an uninterrupted manner or be secure; (d) that
         MyBiZSquare will correct any defects or errors in the service, documentation, or data; or (e)
         that the services are free of viruses or other harmful code. Use of data you access or download
         through the services is done at your own risk – you are solely responsible for any damage to
         your property, loss of data, or any other loss that results from such access or download. You
         understand that MyBiZSquare makes no guarantees to you regarding transaction processing
         times or payout schedules.</p>
      <p>Nothing in this agreement operates to exclude, restrict or modify the application of any implied
         condition, warranty or guarantee, or the exercise of any right or remedy, or the imposition of
         any liability under law where to do so would: (a) contravene that law; or (b) cause any term of
         this agreement to be void.</p>
   </div>

   <div>
      <h5>
         7. Limitation of Liability
      </h5>
      <p>Under no circumstances will MyBiZSquare be responsible or liable to you for any indirect,
         punitive, incidental, special, consequential, or exemplary damages resulting from your use or
         inability to use the Services or for the unavailability of the Services, for lost profits, personal
         injury, or property damage, or for any other damages arising out of, in connection with, or
         relating to this Agreement or your use of the Services, even if such damages are foreseeable,
         and whether or not you or MyBiZSquare have been advised of the possibility of such damages.
         MyBiZSquare is not liable, and denies responsibility for, any damages, harm, or losses to you
         arising from or relating to hacking, tampering, or other unauthorized access or use of the
         Services, your MyBiZSquare Account, or Data, or your failure to use or implement anti-fraud
         measures, Security Controls, or any other data security measure. MyBiZSquare further denies
         responsibility for all liability and damages to you or others caused by (a) your access or use of
         the Services inconsistent with the Documentation; (b) any unauthorized access of servers,
         infrastructure, or Data used in connection with the Services; (c) interruptions to or cessation of
         the Services; (d) any bugs, viruses, or other harmful code that may be transmitted to or through
         the Services; (e) any errors, inaccuracies, omissions, or losses in or to any Data provided to us;
         (f) third-party content provided by you; or (g) the defamatory, offensive, or illegal conduct of
         others.</p>
      <p>You agree to limit any additional liability not disclaimed or denied by MyBiZSquare under this
         Agreement to your direct and documented damages; and you further agree that under no
         circumstances will any such liability exceed in the aggregate the amount of Fees paid by you to
         MyBiZSquare during the three-month period immediately preceding the event that gave rise to
         your claim for damages. Since MyBiZSquare processes payments through a third-party Payment
         Processor, you also agree that for any payment related claims you will directly work with the
         Payment Processor following their terms and conditions, and MyBiZSquare does not have any
         responsibility to work on resolving such claims or conflicts.</p>
      <p>These limitations on our liability to you will apply regardless of the legal theory on which your
         claim is based, including contract, tort (including negligence), strict liability, or any other theory
         or basis.</p>
      <p>We provide the Services from facilities in the United States. We do not claim, and we cannot
         guarantee that Services we provide from the United States are or will be appropriate or
         available for any other location or jurisdiction, comply with the Laws of any other location or
         jurisdiction, or comply with Laws governing export, import, or foreign use.</p>
   </div>

   <div>
      <h5>8. Responding to Legal Process</h5>
      <p>MyBiZSquare may respond to and comply with any writ of attachment, lien, levy, subpoena,
         warrant, or other legal order (“Legal Process”) that we believe to be valid. Where permitted by
         Law, we will make reasonable efforts to provide you Notice of such Legal Process by sending a
         copy to the email address we have on file for you. MyBiZSquare is not responsible for any
         losses, whether direct or indirect, that you may incur as a result of our response or compliance
         with a Legal Process.</p>
   </div>

   <div>
      <h5>9. Dispute Resolution; Agreement to Arbitrate</h5>
      <p>a. Binding Arbitration: In the event that there is a dispute, claim or controversy arising out of or
         relating to statutory or common law claims, the breach, termination, enforcement,
         interpretation or validity of any provision of this Agreement, and the determination of the
         scope or applicability of your agreement to arbitrate any dispute, claim or controversy
         originating from this Agreement, but specifically excluding any dispute principally related to
         either party’s intellectual property, will be determined by arbitration in Seattle, WA before a
         single arbitrator. The arbitration will be administered by the American Arbitration Association
         under its Commercial Arbitration Rules. The Expedited Procedures of the American Arbitration
         Association’s Commercial Arbitration Rules will apply for cases in which no disclosed claim or
         counterclaim exceeds $75,000 (exclusive of interest, attorneys’ fees and arbitration fees and
         costs). Where no party’s claim exceeds $25,000 (exclusive of interest, attorneys’ fees and
         arbitration fees and costs), and in other cases in which the parties agree, Section E-6 of the
         Expedited Procedures of the American Arbitration Association’s Commercial Arbitration Rules
         will apply. The arbitrator will apply the substantive law of the State of Washington, exclusive of
         its conflict or choice of law rules. If the American Arbitration Association is no longer in
         business, or refuses or declines to administer any dispute between the parties brought before
         it, either party may petition the United States District Court for the Western District of
         Washington to appoint the arbitrator. Nothing in this paragraph will preclude the parties from
         seeking provisional remedies in aid of arbitration from a court of appropriate jurisdiction. The
         parties acknowledge that this Agreement evidences a transaction involving interstate
         commerce. Notwithstanding the provisions in this paragraph referencing applicable substantive
         law, the Federal Arbitration Act (9 U.S.C. §§ 1-16) will govern any arbitration conducted
         pursuant to the terms of this Agreement</p>
      <p>Either party may commence arbitration by providing to the American Arbitration Association
         and the other party to the dispute a written demand for arbitration, setting forth the subject of
         the dispute and the relief requested.</p>
      <p>b. Service of Process: Each party hereby irrevocably and unconditionally consents to service of
         process through personal service at their corporate headquarters, registered address, or
         primary address (for individuals or sole proprietors). Nothing in this Agreement will affect the
         right of any party to serve process in any other manner permitted by Law.</p>
      <p>c. Class Waiver: To the fullest extent permitted by Law, each of the parties agrees that any
         dispute arising out of or in connection with this Agreement, whether in arbitration or in court,
         will be conducted only on an individual basis and not in a class, consolidated or representative
         action. If for any reason a claim or dispute proceeds in court rather than through arbitration,
         each party knowingly and irrevocably waives any right to trial by jury in any action, proceeding
         or counterclaim arising out of or relating to this Agreement or any of the transactions
         contemplated between the parties.</p>
      <p>d. Provision of an Award: Subject to the limitations of liability identified in this Agreement, the
         appointed arbitrators may award monetary damages and any other remedies allowed by the
         laws of the State of Washington. In making a determination, the arbitrator will not have the
         authority to modify any term or provision of this Agreement. The arbitrator will deliver a
         reasoned written decision with respect to the dispute (the “Award”) to each party, who will
         promptly act in accordance with the Award. Any Award (including interim or final remedies)
         may be confirmed in or enforced by a state or federal court located in Seattle, Washington. The
         decision of the arbitrator will be final and binding on the parties, and will not be subject to
         appeal or review</p>
      <p>e. Fees: Each party will advance one-half of the fees and expenses of the arbitrators, the costs
         of the attendance of the arbitration reporter at the arbitration hearing, and the costs of the
         arbitration facility. In any arbitration arising out of or related to this Agreement, the arbitrators
         will award to the prevailing party, if any, the costs and attorneys’ fees reasonably incurred by
         the prevailing party in connection with those aspects of its claims or defenses on which it
         prevails, and any opposing awards of costs and legal fees awards will be offset.</p>
      <p>f. Confidentiality: The parties will maintain the confidential nature of the arbitration
         proceeding, the hearing and the Award, except (i) as may be necessary to prepare for or
         conduct the arbitration hearing on the merits, (ii) in connection with a court application as
         contemplated above for a preliminary remedy, or confirmation of an Award or its enforcement,
         (iii) our disclosure of the Award in confidential settlement negotiations, or (iv) as otherwise
         required by applicable Laws. The parties, witnesses, and arbitrator will treat as confidential and
         will not disclose to any third person (other than witnesses or experts) any documentary or
         other evidence produced in any arbitration hereunder, except as required by Law or except if
         such evidence was obtained from the public domain or was otherwise obtained independently
         from the arbitration.</p>
      <p>g. Conflict of Rules: In the case of a conflict between the provisions of this Section E.11.g and
         the rules governing arbitration identified in Section E.11.a, the provisions of this Section E.11.g
         will prevail. If any provision of this Agreement to arbitrate is held invalid or unenforceable, it
         will be so held to the minimum extent required by Law and all the other provisions will remain
         valid and enforceable</p>

   </div>

   <div>
      <h5>10. Entire Agreement</h5>
      <p>This Agreement and all policies and procedures that are incorporated by reference constitute
         the entire agreement between you and MyBiZSquare for provision and use of the Services. Except where expressly stated otherwise in a writing executed between you and MyBiZSquare,
         this Agreement will prevail over any conflicting policy or agreement for the provision or use of
         the Services. This Agreement sets forth your exclusive remedies with respect to the Services. If
         any provision or portion of this Agreement is held to be invalid or unenforceable under Law,
         then it will be reformed and interpreted to accomplish the objectives of such provision to the
         greatest extent possible, and all remaining provisions will continue in full force and effect.</p>
   </div>

   <div>
      <h5>11. Cumulative Rights, Construction, Waiver</h5>
      <p>The rights and remedies of the parties under this Agreement are cumulative, and either party
         may enforce any of its rights or remedies under this Agreement, along with all other rights and
         remedies available to it at Law, in equity or under the Payment Method Rules. No provision of
         this Agreement will be construed against any party on the basis of that party being the drafter.
         Unless expressly stated otherwise, the use of the term “including” or “such as” is not to be
         interpreted as limiting the generality of the text preceding the term. The failure of either party
         to enforce any provision of this Agreement will not constitute a waiver of that party’s rights to
         subsequently enforce the provision.</p>
   </div>

   <div>
      <h5>12. Survival</h5>
      <p>All provisions of this Agreement that give rise to a party’s ongoing obligation will survive
         termination of this Agreement.</p>
   </div>
</div>

<br />
<div className='section'>
   <h4>Appendix A: Restricted and Prohibited Businesses</h4>

   <div>
      <h5>Prohibited Businesses</h5>
      <p>You must not use MyBiZSquare‘s services for the selling/purchasing the following
         activities.</p>
   </div>

   <div>
      <h5>Illegal products and services</h5>
      <ul>
         <li>Illegal drugs, substances designed to mimic illegal drugs, and equipment
            designed for making or using drugs</li>
         <li>Fake references or ID-providing services</li>
         <li>Telecommunications manipulation equipment including jamming devices</li>
         <li>Any other products or services that are in violation of law in the jurisdictions
            where your business is located or targeted to</li>
      </ul>
   </div>

   <div>
      <h5>Products and services that infringe intellectual property rights</h5>
      <ul>
         <li>Sales or distribution of music, movies, software, or any other licensed
            materials without appropriate authorization</li>
         <li>Counterfeit goods; illegally imported or exported products</li>
         <li>Unauthorized sale of brand name or designer products or services</li>
         <li>Any other products or services that directly infringe or facilitate infringement
            upon the trademark, patent, copyright, trade secrets, proprietary or privacy
            rights of any third party</li>
      </ul>
   </div>

   <div>
      <h5>Adult content and services</h5>

      <ul>
         <li>Pornography and other mature audience content (including literature,
            imagery and other media) depicting nudity or explicit sexual acts</li>
         <li>Adult services including prostitution, escorts, pay-per view, sexual massages,
            and adult live chat features</li>
         <li>Adult video stores</li>
         <li>Gentleman's clubs, topless bars, and strip clubs</li>
      </ul>
   </div>

   <div>
      <h5>Firearms, explosives and dangerous materials</h5>
      <ul>
         <li>Guns, gunpowder, ammunition, weapons, fireworks and other explosives</li>
         <li>Peptides, research chemicals, and other toxic, flammable and radioactive
            materials</li>
      </ul>
   </div>

   <div>
      <h5>Gambling</h5>
      <ul>
         <li>Lotteries</li>
      </ul>
   </div>

   <div>
      <h5>Marijuana</h5>
      <ul>
         <li>Cannabis products</li>
         <li>Cannabis dispensaries and related businesses</li>
         <li>Products containing any amount of CBD/THC</li>
      </ul>
   </div>

   <div>
      <h5>
         Misuse of MyBiZSquare products
      </h5>

      <ul>
         <li>Use of MyBiZSquare products with false, manipulated, inaccurate, or
            misleading information regarding your identity, business entity, and any other
            information requested by MyBiZSquare (you must inform update immediately
            of any changes to your personal and business information)</li>
         <li>Use of MyBiZSquare intellectual property without prior written consent from
            MyBiZSquare; use of the MyBiZSquare name or logo including use of
            MyBiZSquare trade or service marks inconsistent with the MyBiZSquare Marks
            Usage Agreement, or in a manner that otherwise harms the MyBiZSquare
            brand; any action that implies an untrue endorsement by or affiliation with
            MyBiZSquare.</li>
      </ul>
   </div>

   <br />
   <div>
      <h5>The types of businesses listed above are representative but not exhaustive.</h5>
   </div>
</div>

</div>`