import React from 'react';
import './header.scss';
import { Redirect } from 'react-router-dom';
import Path from '../../home/components/routes/routes-path';
import ImageConstants from '../../home/constants/image-constants';
import Constants from '../../home/constants/constants';
import { getImageSource } from '../../home/utility/image-source';
import SessionCache from '../../home/components/routes/session-cache';
import AuthenticateAPI from '../../home/apis/authenticate'
import CustomerApi from '../../home/apis/customer'
import BranchApi from '../../home/apis/branch'
import SubscriptionApi from '../../home/apis/subscription'
import Utils from '../../home/utility/common-utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faKey, faPaperPlane, faPlus, faShare, faSpinner, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import StaticText from '../../home/shared-store/static-text-store';
import SharedStore from '../../home/shared-store/shared-store';
import Service from '../../home/service/service';
import ChangePassword from '../change-password/change-password';
import cartItemApi from '../../home/apis/cart'
import ActionConstant from '../../home/constants/action-constant';
import CreateBranchModal from '../../home/components/create-branch/create-branch'
import getRoutes from '../../routes/routes'
import CommonUtils from '../../home/utility/common-utilities';
import PaymentSubscriptions from "../../home/apis/payment-subscriptions";
import MyNetwork from './my-network/my-network';



class Header extends React.Component {

    state = {
        isLogout: false,
        isLoading: false,
        activeTab: '',
        activeStatus: "RECEIVED",
        staticText: {},
        inputRef: {},
        branchList: [],
        myNetworkAddPopUpVisible: false
    };
    user = {}
    accessRights = {}

    constructor(props) {
        super(props)
        this.user = SessionCache.getUser()

        this.state.activeTab = ""
        if (this.user.type == Constants.USER_TYPE.BOTH) {
            if (this.user.currentUseType) {
                this.state.activeTab = this.user.currentUseType
            } else {
                this.state.activeTab = Constants.USER_TYPE.VENDOR
            }
        } else {
            this.state.activeTab = this.user.type
        }

        this.getCartItem()
        this.getInviataions({ type: "RECEIVED" }, true)
        Service.setHeader(this)
    }

    menus = [
    ]


    componentDidMount() {

        let staticText = StaticText.getStaticText("header")
        this.setState({ staticText })
        document.addEventListener('mousedown', this.handleClickEvent, false);
        SharedStore.setStore("header", this)
        this.getMembershipDetail()
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }

    getBranch(payload = {}) {
        let user = SessionCache.getUser()
        payload.tenantId = user?.id
        BranchApi.getBranches(payload).then((response) => {
            this.setState({ branchList: response.response || [] })
        }).catch((err) => {
            this.setState({ branchList: [] })
        })
    }

    getMembershipDetail(payload = {}) {

        SubscriptionApi.getMembershipDetail({}).then((response) => {

            if (response?.type == "PREMIUM") {
                this.setState({ membershipType: "PREMIUM", usageAmount: response.amount })
            } else {
                this.setState({ membershipType: response?.type, freeDays: response.freeDays })
            }

        }).catch((err) => {
            this.setState({ branchList: [] })
        })
    }

    handleClickEvent = (event) => {
        if (this.searchRef && !this.searchRef.contains(event.target)) {
            this.setState({ customers: [], search: "", isFocusSearch: false })
        }
        if (this.invitaionsRef && !this.invitaionsRef.contains(event.target)) {
            if (!this.state.invitationModalOpen) this.setState({ showInvitaions: false, showMyNetworkPopUpInInvitationCard: false })
        }
        if (this.menuRef && !this.menuRef.contains(event.target)) {
            this.setState({ openMenu: false, showBranchList: false, showSwitchBranch: false })
        }
        if (this.notificationRef && !this.notificationRef.contains(event.target)) {
            this.onClickCloseNotification()
        }
        if (this.containerRef && !this.containerRef.contains(event.target)) {
            if (!this.state.myNetworkAddPopUpVisible) this.closeMyNetWork()
        }

    }

    onSearch = () => {
        let value = this.state.search
        if (!value || value.length < 3) {
            this.setState({ customers: [] })
            return
        }

        let payload = {
            cubeId: value
        }

        CustomerApi.getCustomerByCubeId(payload).then((response) => {
            this.setState({ customers: response })
        }).catch((err) => {
            this.setState({ customers: [] })
        })
    }

    onChangeInput = (event) => {
        if (CommonUtils.checkTrialAccess(ActionConstant.ADD_CUSTOMER)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ search: event?.target.value }, () => {
                this.onSearch()
            })
        }
    }

    onClickSendInvite = (vendor) => {
        let payload = {
            tenantId: vendor.tenantId,
            branchId: vendor._id
        }
        BranchApi.sendInvite(payload).then((response) => {
            this.onSearch()
        }).catch((err) => {
        })
    }

    changeBranchType = (type) => {
        let payload = {
            type
        }
        AuthenticateAPI.updateCurrentSessionBranchType(payload).then((response) => {
            // window.location.reload()
        }).catch((err) => {
        })
    }


    onChangeType = () => {

        let user = SessionCache.getUser()

        if (this.state.activeTab == Constants.USER_TYPE.CUSTOMER) {
            user.currentUseType = Constants.USER_TYPE.VENDOR
            SessionCache.setUser(user)
            this.setState({ activeTab: Constants.USER_TYPE.VENDOR }, () => {
                this.props.delegate && this.props.delegate.switchRoute(this.state.activeTab, () => {
                    this.changeBranchType(Constants.USER_TYPE.VENDOR)
                    this.redirectToInitialScreen(Constants.USER_TYPE.VENDOR)
                })
            })

        } else if (this.state.activeTab == Constants.USER_TYPE.VENDOR) {
            user.currentUseType = Constants.USER_TYPE.CUSTOMER
            SessionCache.setUser(user)
            this.setState({ activeTab: Constants.USER_TYPE.CUSTOMER }, () => {
                this.props.delegate && this.props.delegate.switchRoute(this.state.activeTab, () => {
                    this.changeBranchType(Constants.USER_TYPE.CUSTOMER)
                    this.redirectToInitialScreen(Constants.USER_TYPE.CUSTOMER)
                })
            })
        }

    }


    validateWithActions(routes) {

        this.accessRights = this.user.accessRights || {}
        let result = []
        routes.forEach(element => {
            if (this.accessRights[element.action] || element.action == ActionConstant.SHOW_ALWAYS) result.push(element)
        });
        return result
    }

    redirectToInitialScreen(routeType) {

        this.user = SessionCache.getUser()

        let routes = this.validateWithActions(getRoutes(routeType) || [])
        if (routes[0]) this.props.history.replace(routes[0]?.path)
        else this.props.history.replace(Path.DASHBOARD)

    }

    onPressLogout = () => {

        AuthenticateAPI.logout({}).then((response) => {
            SessionCache.setUser({})
            if (response?.data?.isAdmin) {
                window.open(window.location.origin + "/admin/dashboard", "_self")
            } else {
                this.props.history.replace(Path.LOGIN)
            }

        }).catch((err) => {
            alert("Invalid credentials");
        })

    }

    openMenu() {
        this.setState({ openMenu: !this.state.openMenu })
    }

    openNotification() {
        this.setState({ openNotification: !this.state.openNotification })
    }

    onClickCloseNotification = () => {
        this.setState({ openNotification: false })
    }

    getUserNameLetter = () => {

        this.user = SessionCache.getUser()

        return this.user?.firstName ? this.user.firstName[0] : "" + this.user?.lastName ? this.user.lastName[0] : ""
    }


    renderNameBadge(name) {
        if (!name) return ""
        let splitName = name.split(" ")
        let tag = ""
        if (splitName.length > 1) {
            tag = `${splitName[0][0] || ""}${splitName[1][0] || ""}`
        } else if (splitName) {
            tag = `${splitName[0][0]}`
        }
        tag = tag ? tag.toUpperCase() : ""
        return (<div className="name-badge-1"><span className="">{tag || ""}</span></div>)

    }


    showInvitaions = () => {
        this.setState({ showInvitaions: true })
        this.getInviataions()
    }

    statusToggle(activeStatus) {
        this.setState({ activeStatus }, () => {
            this.getInviataions()
        })
    }

    getInviataions = (payload = {}, isBadge) => {
        if (!payload.type) payload.type = this.state.activeStatus
        CustomerApi.getInvitations(payload).then((response) => {
            let data = response.response || []
            if (isBadge) this.setState({ invitationBadgeCount: data.length })
            else this.setState({ invitaions: data })
        }).catch((err) => {
        })
    }

    onStatusUpdate(item, status) {
        this.setState({ showInvitaions: false, showMyNetworkPopUpInInvitationCard: false })
        this.props.history.push(this.state.activeTab === "VENDOR" ? Path.CUSTOMER : Path.VENDORS)

        // let payload = {
        //     invitationId: item._id,
        //     status
        // }

        // BranchApi.updateInvite(payload).then((response) => {
        //     this.getInviataions()
        //     if (status === "REJECTED" || status === "ACCEPTED") this.setState({ invitationBadgeCount: this.state.invitationBadgeCount - 1 })
        // }).catch((err) => {
        // })
    }

    onFocusSearch(value) {
        this.setState({ isFocusSearch: value })
    }

    onClickAdd = () => {
        this.setState({ showInvitaions: false, showMyNetworkPopUpInInvitationCard: false })
        this.inputRef.focus()
    }

    onClickSwitchBranch = () => {
        this.setState({ showBranchList: true })
        this.getBranch()
    }

    onPressChangePassword = () => {
        if (CommonUtils.checkTrialAccess(ActionConstant.CHANGE_PASSWORD)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ showChangePassword: true })
        }
    }
    onClickProfile = () => {
        this.setState({ openMenu: false })
        this.props.history.push(Path.PROFILE)
    }

    hideChangePassword = () => {
        this.setState({ showChangePassword: false })
    }

    hideBranchList = () => {
        this.setState({ showBranchList: false, showSwitchBranch: false })
    }

    onClickCartIcon = () => {
        this.props.history.push(Path.CART)
    }

    onSwitchBranch(item) {
        let payload = {
            branchId: item._id
        }

        AuthenticateAPI.changeBranch(payload).then((response) => {
            window.location.reload()
        }).catch((err) => {
        })
    }

    getCartItem(payload = {}) {

        this.setState({ loading: true })

        cartItemApi.getCartItems(payload).then((response) => {
            this.setState({
                numberOfCartItems: response?.response?.products.length || [],
            })
        }).catch((err) => {
        })
    }


    onNavigate(path) {
        this.setState({ navigatePath: "" }, () => {
            this.setState({ navigatePath: path })
        })

    }

    refreshCart() {
        let cart = SharedStore.getStore("cart")
        this.setState({ numberOfCartItems: cart.products?.length })
    }

    onClickAddBranch = () => {

        if (CommonUtils.checkTrialAccess(ActionConstant.ADD_BRANCH)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ showAddBranch: true })
        }
    }

    onCloseAddBranch = (branchId) => {
        this.setState({ showAddBranch: false })
        if (branchId) this.onSwitchBranch({ _id: branchId })
    }

    reduceInvitationBadgeCount = (value) => {
        if (!this.state.invitationBadgeCount && value < 0) return
        let invitationBadgeCount = this.state.invitationBadgeCount + (value)
        this.setState({ invitationBadgeCount })
    }

    onClickBranchList = () => {
        if (!CommonUtils.checkRights(ActionConstant.SWITCH_BRANCH)) return
        this.setState({ showSwitchBranch: true })
        this.getBranch()
    }

    checkSendInviteButton() {
        if (!this.state.search || this.state.customers?.length) return false

        let [beforeAt, afterAt] = this.state.search.split("@")

        if (!beforeAt || !afterAt) return false

        let [beforeDot, AfterDot] = afterAt.split(".")

        if (!beforeDot || beforeDot.length < 2) return false
        if (!AfterDot || AfterDot.length < 2) return false


        return true
    }

    onClickWelcomeEmail() {

        if (this.state.isSendingEmail) return

        this.setState({ isSendingEmail: true })
        let payload = {
            email: this.state.search
        }
        BranchApi.sendWelcomEmail(payload).then((response) => {
            this.setState({ isSendingEmail: false, search: "" })
        }).catch((err) => {
            this.setState({ isSendingEmail: false, search: "" })

        })
    }

    onClickUpgrad = () => {
        this.setState({ loading: true });
        const payload = {
            successUrl: window.location.origin + Path.SUBSCRIPTION.setupResponse,
            cancelUrl: window.location.origin + Path.SUBSCRIPTION.setupResponse,
        };

        PaymentSubscriptions.createSessionForPaymentSetup(payload)
            .then(({ data }) => {
                window.location.assign(data.url);
            })
            .catch((err) => {
                this.setState({ loading: false });
            });
    };

    showMyNetworkPopUp = () => {
        this.setState({ showMyNetworkPopUp: true })
    }

    closeMyNetWork = () => {
        this.setState({ showMyNetworkPopUp: false, showMyNetworkPopUpInInvitationCard: false })
    }

    showMyNetworkPopUpInInvitationCard = () => {
        this.setState({ showMyNetworkPopUpInInvitationCard: true, hideInvitationPopup: true })
    }

    addCustomerModalHandler(value) {
        this.setState({ invitationModalOpen: value, myNetworkAddPopUpVisible: true }, () => {
            if (value) {
                this.setState({ hideInvitationCard: true })
            } else {
                this.setState({ hideInvitationCard: false, showInvitaions: false, myNetworkAddPopUpVisible: false })

            }
        })
    }


    renderInvitaionsCard() {
        return (
            <div className="menu-custom-modal" >
                <div className={`invitaions-card ${this.state.hideInvitationCard ? "display-none" : ""}`} ref={ref => this.invitaionsRef = ref}>
                    {/* <div className="arrow-up-wrapper"></div> */}
                    <div className="arrow-up"></div>
                    <div className="px-2 pb-2 pt-4">
                        <div className='status-switch cursor-pointer row no-gutters '>
                            <div className={`col status-switch-toggle ${this.state.activeStatus == 'SENT' ? 'status-switch-toggle-active' : ""}`} onClick={() => this.statusToggle("SENT")}>Sent</div>
                            <div className={`col status-switch-toggle ${this.state.activeStatus == 'RECEIVED' ? 'status-switch-toggle-active' : ""}`} onClick={() => this.statusToggle("RECEIVED")}>Received</div>
                        </div>
                    </div>

                    <div className="invitation-list">
                        {
                            this.state.invitaions?.length ? this.state.invitaions.map((item, index) => (
                                <div className={`row p-2 no-gutters ${index === 0 ? "" : "border-top"}`}>
                                    <div className='col-2 my-auto text-center'>
                                        {this.renderNameBadge(item.businessName)}
                                    </div>
                                    <div className='col-6 pl-2'>
                                        <div className=''><span className='staff-name'>{item?.businessName}</span></div>
                                        <div className='staff-username'>{item.email}</div>
                                        <div><span className='date'>{Utils.datePipe(item.createdDate, "MMM do, yyyy")}</span> <span className="invitation-status"></span></div>
                                    </div>

                                    <div className='col-4 text-right pr-2 my-auto'>
                                        {
                                            this.state.activeStatus == "SENT" ? <span> {CommonUtils.checkRights(ActionConstant.CANCEL_INVITATION) ? <span className="semi-bold-text cursor-poniter" onClick={() => this.onStatusUpdate(item, "CANCELLED")}>Cancel</span> : ""}</span>
                                                :
                                                <div>
                                                    {CommonUtils.checkRights(ActionConstant.REJECT_INVITATION) ? <span className="semi-bold-text cursor-poniter reject" onClick={() => this.onStatusUpdate(item, "REJECTED")}><FontAwesomeIcon icon={faTimes} /></span> : ""}
                                                    {CommonUtils.checkRights(ActionConstant.ACCEPT_INVITATION) ? <span className="semi-bold-text cursor-poniter accept" onClick={() => this.onStatusUpdate(item, "ACCEPTED")}><FontAwesomeIcon icon={faCheck} /></span> : ""}
                                                </div>
                                        }
                                    </div>
                                </div>
                            )) :
                                <div className="no-invitation text-center">
                                    <img className="" alt="" src={getImageSource(ImageConstants.NO_INVITATIONS)} ></img>
                                    <div className="pt-4 semi-bold-text">{this.state.activeStatus == "SENT" ? this.state.staticText?.noInvitationSentText : this.state.staticText?.noInvitationReceivedText}</div>
                                    <div className="pt-4">
                                        {CommonUtils.checkRights(ActionConstant.ADD_CUSTOMER) ?
                                            <div className="d-inline-block position-relative">
                                                <button className="app-button-primary" onClick={this.showMyNetworkPopUpInInvitationCard}>
                                                    <span className="">{this.state.staticText.addButtonText}</span>
                                                </button>
                                                {this.state.showMyNetworkPopUpInInvitationCard ? <div className="text-left" div ref={ref => this.containerRef = ref}><MyNetwork {...this.props} close={this.closeMyNetWork} onModalHandler={(value) => this.addCustomerModalHandler(value)} /></div> : ""}
                                            </div>
                                            : ""

                                        }
                                    </div>
                                </div>
                        }
                    </div>
                </div >
            </div >
        )
    }

    renderBranchList() {
        return (

            <div className="menu-custom-modal" >
                <div className='pop-over-swich-branch' ref={ref => this.menuRef = ref}>
                    <div className="arrow-up"></div>

                    <div className="menu-card">
                        <div>
                            <div className="text-right pr-2 pt-2 cursor-pointer"> <img alt="" className="icon-20" src={getImageSource(ImageConstants.CLOSE_GRAY)} onClick={this.hideBranchList} /></div>
                            {
                                this.state.branchList?.length ?
                                    this.state.branchList?.map((item, index) => (
                                        <div className={`${index > 0 ? "border-top" : ""} py-3 menu-item`} onClick={() => this.onSwitchBranch(item)}>
                                            <div className="px-4  cursor-pointer">
                                                <FontAwesomeIcon className={`${this.user.branchId == item._id ? "tick" : "display-none"}`} icon={faCheck} />
                                                <span className={`semi-bold-text font-16 ${this.user.branchId == item._id ? "pl-2" : "pl-4"}`}>{item?.businessName?.trim()}</span>
                                            </div>
                                        </div>
                                    ))
                                    : ""
                            }
                            <div className={`border-top py-3 menu-item`} >
                                {
                                    CommonUtils.checkRights(ActionConstant.ADD_BRANCH) ?
                                        <div className="px-4 cursor-pointer" onClick={this.onClickAddBranch}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            <span className="pl-2 font-16 semi-bold-text">{this.state.staticText?.addBranchText}</span>
                                        </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderMenu() {
        return (

            <div className="menu-custom-modal" >
                <div className='pop-over-menu' ref={ref => this.menuRef = ref}>
                    <div className="arrow-up"></div>

                    <div className="menu-card">

                        {
                            !this.state.showBranchList ?
                                <div>
                                    <div className="p-2">
                                        <div className='row no-gutters '>
                                            <div className='col-3 my-auto text-center'>
                                                {this.renderNameBadge(this.user?.businessName)}
                                            </div>
                                            <div className='col my-auto'>
                                                <div className='staff-name-spacing'><span className='staff-name'>{this.user?.businessName}</span></div>
                                                <div className='staff-username'>{this.user?.email}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        CommonUtils.checkRights(ActionConstant.SWITCH_BRANCH) ?
                                            <div className="border-top mt-2 menu-item cursor-pointer" onClick={this.onClickSwitchBranch}>
                                                <div className="px-4 py-3">
                                                    <img className="icon-20" alt="" src={getImageSource(ImageConstants.REFRESH)} />
                                                    <span className="pl-2 semi-bold-text">{this.state.staticText?.swichBranchText}</span>
                                                    <div className="d-inline-block float-right">
                                                        <img className="icon-5" alt="" src={getImageSource(ImageConstants.ARROW_RIGHT_FILL)} />
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    <div className="border-top py-3 menu-item cursor-pointer" onClick={this.onClickProfile}>
                                        <div className="px-4">
                                            <FontAwesomeIcon className="font-gray icon-20" icon={faUser} />
                                            <span className="pl-2 semi-bold-text">{this.state.staticText?.profileText}</span>
                                        </div>
                                    </div>

                                    {
                                        CommonUtils.checkRights(ActionConstant.CHANGE_PASSWORD) ?
                                            <div className="border-top py-3 menu-item cursor-pointer" onClick={this.onPressChangePassword}>
                                                <div className="px-4">
                                                    <FontAwesomeIcon className="font-gray icon-20" icon={faKey} />
                                                    <span className="pl-2 semi-bold-text">{this.state.staticText?.changePasswordText}</span>
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    <div className="border-top py-3 menu-item cursor-pointer" onClick={this.onPressLogout}>
                                        <div className="px-4">
                                            <img className="icon-20" alt="" src={getImageSource(ImageConstants.LOGOUT)} />
                                            <span className="pl-2 semi-bold-text">{this.state.staticText?.logoutText}</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="text-right pr-2 pt-2 cursor-pointer"> <img alt="" className="icon-20" src={getImageSource(ImageConstants.CLOSE_GRAY)} onClick={this.hideBranchList} /></div>
                                    {
                                        this.state.branchList?.length ?
                                            this.state.branchList?.map((item, index) => (
                                                <div className={`${index > 0 ? "border-top" : ""} py-3 menu-item`} onClick={() => this.onSwitchBranch(item)}>
                                                    <div className="px-4  cursor-pointer">
                                                        <FontAwesomeIcon className={`${this.user.branchId == item._id ? "tick" : "display-none"}`} icon={faCheck} />
                                                        <span className={`semi-bold-text font-16 ${this.user.branchId == item._id ? "pl-2" : "pl-4"}`}>{item?.businessName?.trim()}</span>
                                                    </div>
                                                </div>
                                            ))
                                            : ""
                                    }
                                    <div className={`border-top py-3 menu-item`} >
                                        {
                                            CommonUtils.checkRights(ActionConstant.ADD_BRANCH) ?
                                                <div className="px-4 cursor-pointer" onClick={this.onClickAddBranch}>
                                                    <FontAwesomeIcon icon={faPlus} />
                                                    <span className="pl-2 font-16 semi-bold-text">{this.state.staticText?.addBranchText}</span>
                                                </div> : ""
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        )
    }


    renderNotificationList() {
        return (

            <div className="menu-custom-modal">
                <div className='pop-over-notification' ref={ref => this.notificationRef = ref}>
                    <div className="arrow-up"></div>
                    <div className="menu-card">
                        <div className="notification">
                            <div className="p-4 border-bottom">
                                <span className="bold-text font-16">{this.state.staticText?.notificationTitle}</span>
                                <span className="float-right cursor-pointer" onClick={this.onClickCloseNotification}>
                                    <img alt="" className="icon-10" src={getImageSource(ImageConstants.CLOSE)} />
                                </span>
                            </div>

                            <div className="py-4 text-center" >
                                <img className="no-image" alt="" src={getImageSource(ImageConstants.NO_NOTIFICATION)} />
                                <div className="">
                                    <div className="py-4 no-text semi-bold-text">
                                        {this.state.staticText?.noNotificationText}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div></div>
        )
    }

    render() {

        return (
            <>
                <div className={`app-header row no-gutters ${this.props.transparent ? '' : 'bg-white'}`}>
                    {this.state.navigatePath && <Redirect to={this.state.navigatePath} />}
                    <div className="col-1 my-auto cursor-pointer" onClick={() => { this.onNavigate(Path.DASHBOARD) }}>
                        {
                            this.user.image ? <div className="logo-image"  > <img alt="MyBiZSquare." src={this.user.image} /> </div> : <div className="logo-image"  > <img className="mt-2" alt="MyBiZSquare." src={getImageSource(ImageConstants.APP_DEFAULT_LOGO)} /></div>
                        }

                    </div>
                    <div className="col-3 my-auto pl-4">

                        <div className='mr-3'>
                            <span className='toggle-label'>{this.state.activeTab == Constants.USER_TYPE.CUSTOMER ? "BUYER" : "VENDOR"}</span>
                            {this.user.type === Constants.USER_TYPE.BOTH ? <div class={`custom-control custom-switch custom-control-switch  ml-3 d-inline-block`}>
                                <input type="checkbox" class="custom-control-input" id="customSwitches" checked={this.state.activeTab == Constants.USER_TYPE.VENDOR} onChange={this.onChangeType} />
                                <label class="custom-control-label" for="customSwitches"></label>
                            </div> : ""}
                        </div>
                    </div>

                    <div className="branch-name">
                        <div>
                            <div className="cursor-pointer" onClick={this.onClickBranchList}><b><span>{Utils.ellipsis(this.user?.branchName, 30)} </span>
                            </b></div>
                            <div className="text-center cube-id">{this.user?.cubeId || ""}</div>
                        </div>
                        <b className="cursor-pointer" onClick={this.onClickBranchList}>{CommonUtils.checkRights(ActionConstant.SWITCH_BRANCH) ? <span >
                            <img alt="" className="icon-10 ml-2" src={getImageSource(ImageConstants.DOWN_ARROW)} />
                        </span> : ""}</b>
                    </div>

                    {/* <div className="col-3 my-auto">
                        {CommonUtils.checkRights(ActionConstant.ADD_CUSTOMER) ?
                            <div className={`${this.state.isFocusSearch ? "menu-custom-modal" : ""}`}>
                                <div className={`${this.state.isFocusSearch ? "app-search-wrapper" : ""}`}>
                                    <div className={`${!this.state.isFocusSearch ? "app-search" : "app-search-gray"} position-relative`} ref={ref => this.searchRef = ref}>
                                        <div className={`${!this.state.isFocusSearch ? "app-search-input-gray" : "app-search-input"}  ${this.state.customers?.length || this.state.isFocusSearch ? "bottom-border-radius-none" : ""}`}>
                                            <div className='search-icon-wrapper'>
                                                <img alt="" src={getImageSource(ImageConstants.SEARCH)} className='search-icon'></img>
                                            </div>
                                            <input
                                                ref={ref => this.inputRef = ref}
                                                className={`${!this.state.isFocusSearch ? "search-input-box-gray" : "search-input-box"}`}
                                                placeholder={this.state.staticText?.searchPlaceHolder}
                                                value={this.state.search}
                                                onFocus={() => this.onFocusSearch(true)}
                                                // onBlur={() => this.onFocusSearch(false)}
                                                onChange={this.onChangeInput.bind(this)}
                                            />
                                        </div>
                                        {

                                            <div className={`customer-list ${this.state.isFocusSearch ? "border-top" : ""}`} >{
                                                this.state.customers?.length ? this.state.customers?.map(item => (
                                                    <div className='row p-2 m-0 '>
                                                        <div className='col-2 my-auto text-center'>
                                                            {this.renderNameBadge(item.businessName)}
                                                        </div>
                                                        <div className='col-6 overflow-scroll'>
                                                            <div className='staff-name-spacing'><span className='staff-name'>{item?.businessName}</span></div>
                                                            <div className='staff-username'>{item?.email}</div>
                                                            <div className='badge p-0'> {this.state.staticText?.cubeIdText} {item?.cubeId}</div>
                                                        </div>

                                                        <div className='col-4 my-auto text-right m-0'>
                                                            {item.status == 'INVITE' ? <button className='app-button-primary cursorxw-pointer' onClick={() => this.onClickSendInvite(item)}>{this.state.staticText?.sendInviteButtonText}</button> : <></>}
                                                            {item.status == 'INVITED' ? <button className='app-button-primary-outline'>{this.state.staticText?.invitedText}</button> : <></>}
                                                            {item.status == 'ACCEPTED' ? <button className='app-button-primary-outline'>{this.state.staticText?.acceptedText}</button> : <></>}
                                                            {item.status == 'INVITE_RECEIVED' ? <div className='invite-text'>{this.state.staticText?.invitedReceivedText}</div> : <></>}
                                                        </div>
                                                    </div>)) :
                                                    this.state.isFocusSearch ?
                                                        <div className="text-center py-4">
                                                            {this.state.staticText?.noSearchRecords}

                                                            <div className='text-center mt-2'>
                                                                {this.checkSendInviteButton() ?
                                                                    <button className='app-button-primary cursorxw-pointer' onClick={() => this.onClickWelcomeEmail()}>
                                                                        {
                                                                            this.state.isSendingEmail ? <FontAwesomeIcon spin icon={faSpinner} /> :
                                                                                <span><FontAwesomeIcon className="cursor-pointer" icon={faPaperPlane} /> {this.state.staticText?.sendRegisterRequestText}</span>
                                                                        }

                                                                    </button>
                                                                    : ""}
                                                            </div>
                                                        </div> :
                                                        ""
                                            }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div> : ""}
                    </div> */}

                    <div className='col-8 my-auto'>

                        <div className="row actions-wrapper">
                            {/* 
                            <div className='mr-3'>
                                <span className='toggle-label'>{this.state.activeTab == Constants.USER_TYPE.CUSTOMER ? "BUYER" : "VENDOR"}</span>
                                {this.user.type === Constants.USER_TYPE.BOTH ? <div class={`custom-control custom-switch custom-control-switch  ml-3 d-inline-block`}>
                                    <input type="checkbox" class="custom-control-input" id="customSwitches" checked={this.state.activeTab == Constants.USER_TYPE.VENDOR} onChange={this.onChangeType} />
                                    <label class="custom-control-label" for="customSwitches"></label>
                                </div> : ""}
                            </div> */}
                            <div className="">
                                <div className="position-relative cursor-pointer" >
                                    <div className='icon-circle mr-2' onClick={this.showMyNetworkPopUp}>
                                        <img alt="" src={getImageSource(ImageConstants.USER_GROUP)} className='user-group-icon'></img>
                                    </div>
                                    {this.state.showMyNetworkPopUp ? <div ref={ref => this.containerRef = ref}><MyNetwork {...this.props} close={this.closeMyNetWork} onModalHandler={(value) => this.addCustomerModalHandler(value)} /></div> : ""}
                                </div>
                            </div>

                            {this.state.activeTab == Constants.USER_TYPE.CUSTOMER ? <div className="position-relative cursor-pointer" >
                                {this.state.numberOfCartItems > 0 ? <div className='red-badge'>{this.state.numberOfCartItems}</div> : <></>}
                                <div className='icon-circle mr-2' onClick={this.onClickCartIcon}>
                                    <img alt="" src={getImageSource(ImageConstants.CART_ICON)} className='flag-icon'></img>
                                </div>
                            </div> : <></>}
                            {
                                CommonUtils.checkRights(ActionConstant.VIEW_INVITATION) ?
                                    <div className="position-relative cursor-pointer" >
                                        {this.state.invitationBadgeCount > 0 ? <div className='red-badge'>{this.state.invitationBadgeCount}</div> : <></>}
                                        <div className='icon-circle mr-2' onClick={this.showInvitaions}>
                                            <img alt="" src={getImageSource(ImageConstants.FLAG)} className='flag-icon'></img>
                                        </div>
                                        {
                                            this.state.showInvitaions ? this.renderInvitaionsCard() : ""
                                        }
                                    </div> : ""
                            }

                            <div className='icon-circle mr-2 cursor-pointer' onClick={() => this.openNotification()}>
                                <img alt="" className='bell-icon' src={getImageSource(ImageConstants.BELL)}></img>
                            </div>

                            <div className='divider'>|</div>

                            <div className="text-center ml-4">{
                                this.state.membershipType === "PREMIUM" ?
                                    <div className="platinum-wrapper">
                                        <div className="membership-title">Platinum Member</div>
                                        <div className="membership-title membership-sub-title"> Current Month: ${CommonUtils.formatAmountStringStrict(this.state.usageAmount || 0)}</div>
                                    </div> :
                                    <>
                                        <div className="membership-title"> {this.state.freeDays ? "Platinum Member" : "Silver Member"}</div>
                                        <div className="membership-title membership-sub-title">{this.state.freeDays ? <span>Trial Expires in {this.state.freeDays > 9 ? this.state.freeDays : `0${this.state.freeDays}`} Days <span className="upgrad" onClick={this.onClickUpgrad}>(Upgrade) </span></span> : <span> Trial Period Expired <span className="upgrad" onClick={this.onClickUpgrad}>(Upgrade) </span></span>}</div>
                                    </>
                            }
                            </div>


                            <div>
                                <div className='user-circle ml-4 cursor-pointer' onClick={() => { this.openMenu() }}>
                                    <span className='user-name-new'>{this.getUserNameLetter()}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.openMenu ? this.renderMenu() : <></>}
                {this.state.openNotification ? this.renderNotificationList() : <></>}
                {this.state.showSwitchBranch ? this.renderBranchList() : <></>}
                {
                    this.state.showChangePassword ? <ChangePassword close={this.hideChangePassword} /> : ""
                }
                {this.state.showAddBranch ? <CreateBranchModal onPressCreate={(branchId) => this.onCloseAddBranch(branchId)} onCancel={() => this.onCloseAddBranch()} /> : <></>}
            </>
        );
    }
}

export default Header;