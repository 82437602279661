import React from 'react';
import "./header.scss";

import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../..//utility/image-source';
import StaticText from '../../../shared-store/static-text-store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ReportApi from '../../../apis/report';
import CommonUtils from '../../../utility/common-utilities';

class Header extends React.Component {
    state = {
        staticText: {},
    }

    user = {}
    componentDidMount() {

        let staticText = StaticText.getStaticText("dashboard")
        this.setState({ staticText })
        this.getReport()
    }

    getReport = (payload = {}) => {
        payload.range = this.props.type
        payload.type = "CUSTOMER"
        this.setState({ isLoading: true })
        ReportApi.getSalesReport(payload).then((response) => {
            this.setState({ report: response || {}, isLoading: false })
        }).catch((err) => {
            this.setState({ isLoading: false })
        })
    }

    renderLoading() {
        return (
            <div className="">
                <FontAwesomeIcon icon={faSpinner} spin />
            </div>
        )
    }


    render() {
        return (
            <div className="dashboard-header">
                <div className="row">
                    <div className="col-4 mt-2 no-gutters">
                        <div className="card p-4">
                            <div className="d-flex">
                                <div className="flex-1 mr-2">
                                    <img className="icon-50" alt="" src={getImageSource(ImageConstants.DOLLER_BLUE)} />
                                </div>
                                <div className="flex-2">
                                    <div className="cart-title">{this.state.staticText?.purchaseText}</div>

                                    {
                                        !this.state.isLoading ?
                                            <>
                                                <div className=""><span className="bold-text font-18">{this.state.report?.currency?.symbol} {CommonUtils.formatAmountStringStrict(this.state.report?.totalSales) || "-"}</span></div>
                                                {/* <div className=""><span className="bold-text font-18">{this.state.lastYearSales || "-"}</span></div> */}

                                            </>
                                            :
                                            this.renderLoading()
                                    }
                                </div>
                            </div >
                        </div >
                    </div >
                    <div className="col-4 mt-2 no-gutters">
                        <div className="card p-4">
                            <div className="d-flex">
                                <div className="flex-1 mr-2">
                                    <img className="icon-50" alt="" src={getImageSource(ImageConstants.ORDER_YELLOW)} />
                                </div>
                                <div className="flex-2">
                                    <div className="cart-title">{this.state.staticText?.orderText}</div>

                                    {
                                        !this.state.isLoading ?
                                            <>
                                                <div className=""><span className="bold-text font-18">{this.state.report?.totalOrder || "-"}</span></div>

                                            </>
                                            :
                                            this.renderLoading()
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 mt-2 no-gutters">
                        <div className="card p-4">
                            <div className="d-flex">
                                <div className="flex-1 mr-2">
                                    <img className="icon-50" alt="" src={getImageSource(ImageConstants.DOLLER_GREEN)} />
                                </div>
                                <div className="flex-2">
                                    <div className="cart-title">{this.state.staticText?.avgPurchaseText}</div>

                                    {
                                        !this.state.isLoading ?
                                            <>
                                                <div className=""><span className="bold-text font-18">{this.state.report?.currency?.symbol} {CommonUtils.formatAmountStringStrict(this.state.report?.averageSales) || "-"}</span></div>

                                            </>
                                            :
                                            this.renderLoading()
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }

}

export default Header;