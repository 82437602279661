import React from 'react';
import './sub-header.scss';
import getRoutes from '../../../routes/routes'
import ActionConstant from '../../../home/constants/action-constant';
import SessionCache from '../../../home/components/routes/session-cache';
import { getImageSource } from '../../../home/utility/image-source';
import ImageConstants from '../../../home/constants/image-constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';


class SubHeader extends React.Component {

    constructor(props) {
        super(props)
    }

    state = {
        showHelpMenu: false
    };

    menus = [
    ]

    accessRights = {}
    componentDidMount() {

        this.user = SessionCache.getUser()

        this.accessRights = this.user.accessRights || {}
        this.setState({ reload: !this.state.reload })
        document.addEventListener('mousedown', this.handleClickEvent, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }

    handleClickEvent = (event) => {
        if (this.menuRef && !this.menuRef.contains(event.target)) {
            this.setState({ showHelpMenu: false })
        }
    }

    onClickRoute = (route) => {
        this.props.history.push(route.path)
    }

    validateWithActions(routes) {
        let result = []
        routes.forEach(element => {
            if (this.accessRights[element.action] || element.action == ActionConstant.SHOW_ALWAYS) result.push(element)
        });
        return result
    }

    showHelpMenu = () => {
        this.setState({ showHelpMenu: true })
    }

    hideHelpMenu = () => {
        this.setState({ showHelpMenu: false })
    }

    onClickHelp = (link) => {
        window.open(link, "_blank")
        this.hideHelpMenu()
    }

    renderMenu() {
        return (

            <div className="menu-custom-modal" >
                <div className='pop-over-menu' ref={ref => this.menuRef = ref}>
                    <div className="arrow-up"></div>

                    <div className="menu-card help-menu">
                        <div className={`help-title`}>
                            Resources
                        </div>

                        <div className="pt-1 menu-item cursor-pointer" onClick={() => this.onClickHelp('https://www.mybizsquare.com/mybizsquare-library')}>
                            <dic className={`help-text green pr-2`}>
                                Support Knowledgebase
                            </dic>
                            <span><FontAwesomeIcon className="green" icon={faExternalLinkAlt} /></span>
                            {/* <img className="arrow-icon" alt="" src={getImageSource(ImageConstants.ARROW_UP_RIGHT_FROM_SQUARE_GREEN)} /> */}
                        </div>

                        <div className="pt-1 menu-item cursor-pointer" onClick={() => this.onClickHelp('https://www.mybizsquare.com/mybizsquare-library-faq')}>
                            <dic className={`help-text green pr-2`}>
                                FAQ
                            </dic>
                            <span><FontAwesomeIcon className="green" icon={faExternalLinkAlt} /></span>

                            {/* <img className="arrow-icon" alt="" src={getImageSource(ImageConstants.ARROW_UP_RIGHT_FROM_SQUARE_GREEN)} /> */}
                        </div>

                        <div className="pt-1 menu-item cursor-pointer" onClick={() => this.onClickHelp('https://www.mybizsquare.com/knownissues')}>
                            <dic className={`help-text text-primary pr-2`}>
                                Known Issues/Workarounds
                            </dic>
                            <span><FontAwesomeIcon className="text-primary" icon={faExternalLinkAlt} /></span>
                            {/* <img className="arrow-icon" alt="" src={getImageSource(ImageConstants.ARROW_UP_RIGHT_FROM_SQUARE_GREEN)} /> */}
                        </div>

                        <div className="pt-1 menu-item cursor-pointer" onClick={() => this.onClickHelp('https://www.mybizsquare.com/report-issue')}>
                            <dic className={`help-text red pr-2`}>
                                Report an Issue
                            </dic>
                            {/* <img className="arrow-icon" alt="" src={getImageSource(ImageConstants.ARROW_UP_RIGHT_FROM_SQUARE_RED)} /> */}
                            <span><FontAwesomeIcon className="red" icon={faExternalLinkAlt} /></span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderRoutes = () => {

        this.routes = this.validateWithActions(getRoutes(this.props.routeType) || [])

        let routesElement = []
        let activePathName = this.props.location && this.props.location.pathname

        for (let i = 0; i < this.routes.length; i++) {
            let route = this.routes[i]

            if (route.isActive) {
                routesElement.push(
                    <div className={`route-blocks cursor-pointer py-3 ${activePathName == route.path ? 'active-route' : ""}`} onClick={() => this.onClickRoute(route)}>{route.title}
                        {activePathName == route.path ? <div className="active-route-bottom"><div className="active-route-bottom-bg"></div></div> : ""}</div>
                )
            }
        }

        return <div className='row sub-header-row'>{routesElement}</div>
    }

    render() {

        return (
            <div className='app-sub-header'>
                <div></div>
                {this.renderRoutes()}
                <div className={`route-blocks cursor-pointer py-3 help`} onClick={this.showHelpMenu}>
                    <span className='question-mark'>?</span>
                    Help
                </div>
                {this.state.showHelpMenu ? this.renderMenu() : ""}
            </div>
        );
    }
}

export default SubHeader;