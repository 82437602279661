
import ServerConfigs from './server-config';
const Urls = {
    BASE_URL: ServerConfigs.url,

    IS_LOGGED_IN: "/api/isloggedin",
    LOGIN: "/api/login",
    LOGOUT: "/api/logout",
    SIGNUP: "/api/signup",
    LOGIN_CLIENT: "/api/login/client",
    SEND_SINGUP_OTP: "/api/signup/otp",
    SEND_FORGORT_PASSWORD_OTP: "/api/forgotpassword/send",
    VALIDATE_FORGORT_PASSWORD_OTP: "/api/forgotpassword/validate",
    FORGORT_PASSWORD_UPDATE: "/api/forgotpassword/updatepassword",
    CHANGE_BRANCH: "/api/tenant/changebranch",

    TENANT: "/api/tenant",
    TENANT_PASSWORD: "/api/tenant/password",
    BRANCH: "/api/branch",
    STAFF: "/api/staff",
    CUSTOMER: "/api/customer",
    ORDER: "/api/branch/order",
    ORDER_ITEMS: "/api/branch/order/item",
    BRANCH_INVITATION: "/api/branch/invitation",

    BRANCH_LIST: "/api/branch/list",
    PRODUCT: "/api/products",
    BULK_UPLOAD_PRODUCT: "/api/products/bulk",
    CHANGE_PASSWORD: "/api/passwordreset/branchadmin",
    CUSTOMER_BY_CUBE_ID: "/api/branch/search/cubeId",
    CUSTOMER_BY_BUSINESS_NAME: "/api/branch/search/businessName",

    BRANCH_INVITE: "/api/branch/send/invitation",
    PRODUCT_CUSTOM_PRICE: "/api/products/pirce",
    PRODUCT_BY_CUSTOMER: "/api/products/customer",
    FREQUENTLY_ORDER_PRODUCTS: "/api/products/customer/frequent",

    CUSTOMER_PRODUCTS_PRICE: "/api/customer/product/price",
    PRICE_BY_CUSTOMER: "/api/product/customer",
    PRICE_BY_CUSTOMER_PRODUCT: "/api/products/customer/price/product",

    REGISTER_INVITE: "/api/register/invite",

    CART_ITEM: "/api/branch/cartitem",

    CONFIURATIONS: "/api/configurations/list",
    STATE: "/api/state/list",

    ORDER_WITH_RANGE: "/api/branch/order/range",

    UPDATE_SESSION_TYPE: "/api/session",

    CATEGORIES: "/api/categories",
    VENDOR_CATEGORIES: "/api/branch/categoriesandbrand",
    BRANDS: "/api/brands",
    SIGNED_URL: "/api/signedurl",
    VENDOR: "/api/customer",

    SALES_REPORT: "/api/report/sales",
    TOP_CUSTOMER: "/api/report/topcustomer",
    TOP_VENDOR: "/api/report/topvendor",
    TOP_PRODUCT: "/api/report/topproducts",
    SALES_CHART: "/api/report/saleschart",
    TOP_PURCHASED_PRODUCT: "/api/report/toppurchaedproduct",

    ORDER_STATICTICS: "/api/report/orderstatistics",

    ROLES: "/api/roles/list",

    VERIFY_CUBE_ID: '/api/branch/verify',

    INVOICE: "/api/invoice",
    SEND_INVOICE: "/api/invoice/send",
    MARK_AS_PAID_INVOICE: "/api/invoice/paid",

    //payments
    PAYMENT_ACCOUNT: '/api/payments/payment-account',
    PAYMENT_ACCOUNT_BY_TENANT: '/api/payments/account/tenant',
    PAYMENT_ACCOUNT_REFRESH_ONBOARDING: '/api/payments/refresh-onboarding',
    UPDATE_PAYMENT_ACCOUNT: '/api/payments/payment-account/update',
    CREATE_PAYMENT_SESSION: '/api/invoice/initiate-payment-from-web',
    PAYMENT_TERMS: '/api/paymentterms/date',
    BRANCH_CONFIG: "/api/branch/config",

    PAYMENT_RESPONSE: '/api/payments/response',
    PAYMENT_STATUS: "/api/paymentstatus",

    UPDATE_PAYMENT_RESPONSE: "/api/invoice/update-payment-status",
    UPDATE_BRANCH_ACCOUNT: "/api/branch/payment/account",
    PAYMENT_ACCOUNT_REMOVE: "/api/branch/payment/account/remove",

    //subscriptions
    SUBSCRIPTION_PROFILE: '/api/subscription/profile',
    GET_STRIPE_CUSTOMER_PORTAL_SESSION: '/api/subscription/get-customer-portal-session',
    CREATE_SESSION_FOR_PAYMENT_SETUP: '/api/subscription/create-session-for-payment-setup',
    TENANT_SUBSCRIPTION: '/api/subscription/tenant-subscription',
    CREATE_SUBSCRIPTION_AFTER_PAYMENT_SETUP_SUCCESS: '/api/subscription/create-subscription-after-payment-setup',
    UPDATE_SUBSCRIPTION: '/api/subscription/update',

    SUBSCRIPTION_USAGE: "/api/subscription/usage",
    SUBSCRIPTION_USAGE_DETAILS: "/api/subscription/usage/detail",
    GET_SUBSCRIPTION_USAGE_AMOUNT_FOR_CANCEL: '/api/subscription/cancel/amount',
    CANCEL_SUBSCRIPTION: '/api/subscription/cancel',

    MEMBERSHIP_DETAIL: '/api/subscription/membership',
    BUYER_PRICING: "/api/buyer-pricing",
    BUYER_VENDOR_DISCOUNTS: "/api/buyer-vendor-discounts",

    EMAIL_NOTIFICATION: '/api/email/notification',
    UPDATE_EMAIL_NOTIFICATION: '/api/email/updatenotification',

    MANUAL_INVOICE: "/api/invoice/manual",


    EXPORT_PRODUCTS: "/api/product/export",
    BULK_INACTIVE_PRODUCT: "/api/product/inactive",
    EXPORT_ORDERS: "/api/branch/order/export",
    EXPORT_INVOICES: "/api/invoice/export",

    /* INV000001 BEGIN */
    // Inventory
    
    INVENTORY_PRODUCT: '/api/inventory-products',
    INV_CATEGORIES: "/api/branch/invcategoriesandbrand",
    BULK_UPLOAD_INVENTORY_PRODUCT: "/api/inventory/bulk",
    INVENTORY_PRODUCT_MAPPING: '/api/inventory-product-mapping',
    INVENTORY_PRODUCTS_BY_CUSTOMER: '/api/inventory-products/customer',
    EXPORT_INV_PRODUCTS: '/api/inventory/export',



    /* INV000001 END */
};

export default Urls;
