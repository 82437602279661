import React from "react";
import './style.scss'

class ImagePreview extends React.Component {
   componentDidMount() {
   }
   render() {
      return (
         <img className="image-preview" alt="" src={this?.props?.src} style={this.props?.isLast ? { bottom: 0 } : { top: -80 }} />
      );
   }
}

export default ImagePreview
