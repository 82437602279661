import React from 'react';
import "./change-password.scss";
import AuthenticateAPI from '../../home/apis/authenticate'
// import SessionCache from '../routes/session-cache';
import { Modal, ModalBody } from "reactstrap";

import ImageConstants from '../../home/constants/image-constants';
import { getImageSource } from '../../home/utility/image-source';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import StaticText from '../../home/shared-store/static-text-store';
import { Tooltip } from "reactstrap"
import CommonUtils from "../../home/utility/common-utilities"
class ChangePassword extends React.Component {
    state = {
        staticText: {},

    }

    componentDidMount() {

        let staticText = StaticText.getStaticText("changePassword")
        this.setState({ staticText })
    }

    onChangeHandler(event, key) {
        this.setState({ [key]: event?.target?.value }, () => {
            this.validatePayload()
        })
    }

    onClickShowPassword = (key) => {
        this.setState({ [key]: !this.state[key] })
    }

    validatePayload(showError) {

        let isValidForm = true

        if (!this.state.oldPassword) {
            showError && this.setState({ isInvalidOldPassword: true, oldPasswordErrorMessage: this.state.staticText.oldPasswordErrorMessage })
            isValidForm = false
        } else this.setState({ isInvalidOldPassword: false, oldPasswordErrorMessage: "" })

        if (this.state.password && this.state.reEnterPassword) {
            if (!CommonUtils.validatePassword(this.state.password)) {
                showError && this.setState({ isInvalidNewPassword: true, newPasswordErrorMessage: this.state.staticText.invalidPasswordLength })
                isValidForm = false
            } else if ((this.state.password !== this.state.reEnterPassword)) {
                showError && this.setState({ isInvalidReEnterPassword: true, reEnterPasswordErrorMessage: this.state.staticText.misMatchPasswordErrorMessage })
                isValidForm = false
            } else this.setState({ isInvalidReEnterPassword: false, reEnterPasswordErrorMessage: "", isInvalidNewPassword: false, newPasswordErrorMessage: "" })
        } else {
            if (!this.state.password) {
                showError && this.setState({ isInvalidNewPassword: true, newPasswordErrorMessage: this.state.staticText.newPasswordErrorMessage })
                isValidForm = false
            } else if (!CommonUtils.validatePassword(this.state.password)) {
                showError && this.setState({ isInvalidNewPassword: true, newPasswordErrorMessage: this.state.staticText.invalidPasswordLength })
                isValidForm = false
            } else this.setState({ isInvalidNewPassword: false, newPasswordErrorMessage: "" })

            if (!this.state.reEnterPassword) {
                showError && this.setState({ isInvalidReEnterPassword: true, reEnterPasswordErrorMessage: this.state.staticText.reEnterPasswordErrorMessage })
                isValidForm = false
            } else this.setState({ isInvalidReEnterPassword: false, reEnterPasswordErrorMessage: "" })

        }


        this.setState({ isValidForm, errMessage: "" })
        return isValidForm
    }


    onClickUpdatePassword = () => {

        let payload = {
            password: this.state.password,
            oldPassword: this.state.oldPassword,
        }

        let validate = this.validatePayload(true)

        if (!validate) return

        AuthenticateAPI.updateTenantPassword(payload).then((response) => {
            this.props.close()
        }).catch((err) => {

            if (err?.response?.data?.code === 900) {
                this.setState({ isInvalidOldPassword: true, oldPasswordErrorMessage: this.state.staticText.inValidOldPasswordErrorMessage })
            }
        })
    }


    render() {

        return (

            <Modal className="change-password-custom-modal" isOpen={true} toggle={this.props.close} size="md">
                <ModalBody className="" >
                    <div className="change-password">
                        <div className="row">
                            <div className="col text-right">
                                <FontAwesomeIcon className="cursor-pointer" onClick={this.props.close} icon={faTimes} />
                            </div>
                        </div>

                        <div className="m-4 ">
                            <div className="row">
                                <div className="col">
                                    <span className="header-text">{this.state.staticText.title}</span>
                                </div>
                            </div>

                            <div className="pt-4">
                                <div className={`bw-spaces-input ${this.state.isInvalidOldPassword ? "invalid-input-container" : ""} position-relative`}>
                                    <div className='app-input-label'>{this.state.staticText?.oldPasswordLableText}<span className='star' id="old-password-tooltip">*</span></div>
                                    <input id="old-password" className={`app-input`} placeholder={this.state.staticText?.oldPasswordPlaceHolder}
                                        value={this.state.oldPassword}
                                        type={this.state.showOldPassword ? "" : "password"}
                                        onChange={(e) => this.onChangeHandler(e, 'oldPassword')} />
                                    <span className="password-icon">
                                        <img className="cursor-pointer icon-20" alt=""
                                            src={getImageSource(this.state.showOldPassword ? ImageConstants.EYE_OPEN : ImageConstants.EYE_CLOSE)}
                                            onClick={() => this.onClickShowPassword("showOldPassword")}
                                        />
                                    </span>
                                    <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isInvalidOldPassword} target="old-password-tooltip">
                                        {this.state.oldPasswordErrorMessage}
                                    </Tooltip>
                                </div>

                                <div className={`bw-spaces-input ${this.state.isInvalidNewPassword ? "invalid-input-container" : ""} position-relative`}>
                                    <div className='app-input-label'>{this.state.staticText?.newPasswordLableText}<span className='star' id="new-password-tooltip">*</span></div>
                                    <input id="new-password" className={`app-input`} placeholder={this.state.staticText?.newPasswordPlaceHolder}
                                        value={this.state.password}
                                        type={this.state.showNewPassword ? "" : "password"}
                                        onChange={(e) => this.onChangeHandler(e, 'password')} />
                                    <span className="password-icon">
                                        <img className="cursor-pointer icon-20" alt=""
                                            src={getImageSource(this.state.showNewPassword ? ImageConstants.EYE_OPEN : ImageConstants.EYE_CLOSE)}
                                            onClick={() => this.onClickShowPassword("showNewPassword")}
                                        />
                                    </span>

                                    <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isInvalidNewPassword} target="new-password-tooltip">
                                        {this.state.newPasswordErrorMessage}
                                    </Tooltip>
                                </div>

                                <div className={`bw-spaces-input ${this.state.isInvalidReEnterPassword ? "invalid-input-container" : ""} position-relative`}>
                                    <div className='app-input-label'>{this.state.staticText?.reEnterPasswordLableText}<span className='star' id="reenter-password-tooltip" >*</span></div>
                                    <input id="new-password" className={`app-input`} placeholder={this.state.staticText?.reEnterPasswordPlaceHolder}
                                        value={this.state.reEnterPassword}
                                        type={this.state.showReEnterPassword ? "" : "password"}
                                        onChange={(e) => this.onChangeHandler(e, 'reEnterPassword')} />
                                    <span className="password-icon">
                                        <img className="cursor-pointer icon-20" alt=""
                                            src={getImageSource(this.state.showReEnterPassword ? ImageConstants.EYE_OPEN : ImageConstants.EYE_CLOSE)}
                                            onClick={() => this.onClickShowPassword("showReEnterPassword")}
                                        />
                                    </span>

                                    <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isInvalidReEnterPassword} target="reenter-password-tooltip">
                                        {this.state.reEnterPasswordErrorMessage}
                                    </Tooltip>
                                </div>

                                <div className="pt-4 text-right">
                                    <button className={`app-button-secondary mr-2 `} onClick={this.props.close}>
                                        {this.state.staticText.cancelButtonText}
                                    </button>

                                    <button className={`app-button-primary ${this.state.isValidForm ? "" : "app-button-disabled"}`} onClick={this.onClickUpdatePassword}>
                                        {this.state.staticText.changePasswordButtonTitle}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }

}

export default ChangePassword;