import React from 'react';
import { Redirect, Router, Switch } from 'react-router-dom'
import Route from '../home/components/routes/route-wrapper';
import './layout.scss'
import getRoutes from '../routes/routes'
import Header from './header/header'
import RoutePaths from '../home/components/routes/routes-path'
import SessionCache from '../home/components/routes/session-cache';
import SubHeader from './header/sub-header/sub-header'
import Constants from '../home/constants/constants'
import SubscriptionModal from '../home/components/common/subscription-confirm/subscription';
import SharedStore from "../home/shared-store/shared-store"

export default class Layout extends React.Component {

    state = {
        routeType: "",
        subscriptionActive: false
    }

    constructor(props) {
        super()
        let user = SessionCache.getUser()
        this.state.routeType = user.type == Constants.USER_TYPE.BOTH ? Constants.USER_TYPE.VENDOR : user.type

        if (user.type === Constants.USER_TYPE.BOTH) {
            if (user.currentUseType) {
                this.state.routeType = user.currentUseType
            } else {
                this.state.routeType = Constants.USER_TYPE.VENDOR
            }
        } else {
            this.state.routeType = user.type
        }
    }
    componentDidMount() {
        SharedStore.setStore("subscription", this)
    }

    switchRoute = (routeType, callback) => {
        this.setState({ routeType }, callback)
    }

    close = () => {
        this.setState({ subscriptionActive: false })
    }

    showSubscriptionModal = () => {
        this.setState({ subscriptionActive: true })
    }

    registerRoutes = () => {

        this.routes = getRoutes(this.state.routeType)
        let elements = this.routes.map(route => (
            <Route exact={!route.hasChildren} path={route.path} component={route.component} />
        ))
        elements.push(<Route path="*"><Redirect to={this.routes[0]?.path} /> </Route>)
        return (elements)
    }


    render() {

        let activePath = this.props.location && this.props.location.pathname;

        return (
            <div className='layout'>
                <Header delegate={this} {...this.props} />
                {Constants.SUB_HEADER_EXCLUDE_ROUTES[activePath] ? <></> : <SubHeader routes={this.routes} {...this.props} routeType={this.state.routeType}></SubHeader>}
                <div className='main-component'>
                    <div className="app-container">
                        <Switch>
                            {this.registerRoutes()}
                        </Switch>
                    </div>
                </div>
                {this.state.subscriptionActive ? <SubscriptionModal
                    subscribeClose={this.close}
                    onSubscribeOk={this.close}
                /> : ""}
            </div >
        )
    }
}