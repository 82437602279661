import React from 'react';
import "./update-invoice.scss";
import InvoiceApi from '../../../apis/invoice'
import AuthenticateAPI from '../../../apis/authenticate'
import { Modal, ModalBody, Util } from "reactstrap";
import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../../utility/image-source'
import Dropdown from '../../common/dropdown/dropdown'
import ToasterComponent from '../../common/toaster/toaster';
import StaticText from '../../../shared-store/static-text-store';
import Utils from '../../../utility/common-utilities';
import Constants from '../../../constants/constants';
import ItemDiscountInput from './item-discount-input';
import CommonUtils from '../../../utility/common-utilities';
import ActionConstant from '../../../constants/action-constant';
import SharedStore from "../../../shared-store/shared-store"
import ItemTextInput from '../manual-invoice/item-input';
import productApi from '../../../apis/product'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

let currency = {
    //CHG000002  PB-7                  code: "DOLLER",
    code: "USD",                     //CHG000002  PB-7
    symbol: "$"
}

class UpdateInvoice extends React.Component {

    state = {
        product: {},
        products: [],
        currency,
        activeItemSwitch: "PERCENTAGE",
        paymentTermsDropdownItems: [],
        currentDate: new Date(),
        loading: true,
        defaultInvoiceItems: []
    }

    totalPages = 1;
    page = 1

    constructor(props) {
        super(props)
        this.staticText = StaticText.getStaticText("invoice")

        if (this.props.invoice) {

            this.state.invoice = CommonUtils.cloneDeep(this.props.invoice)
            this.state.defaultInvoiceItems = CommonUtils.cloneDeep(this.props.invoice.items)
            this.state.activeItemSwitch = this.props.invoice?.items?.length ? this.props.invoice?.items[0]?.discount.type : ""
            this.state.activeGloableDiscountSwitch = this.props.invoice?.discount?.type
            this.prepareInvoiceData(this.state.invoice)
        }
    }

    prepareInvoiceData(invoice) {

    }


    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickEvent, false);
        this.getPaymentTermsConfig()
    }

    getPaymentTermsConfig() {
        AuthenticateAPI.getStaticText({ type: "PAYMENT_TERMS" }).then((data) => {
            const response = data.response[0]
            this.setState({ paymentTermsDropdownItems: response?.config || [], isDropdownLoading: true }, () => {
                this.setState({ isDropdownLoading: false })
            })

        }).catch((err) => {
            console.log(err)
        })
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }


    onChangeHandler(event, key) {
        this.setState({ [key]: event?.target?.value }, () => {
        })
    }

    showToaster(type, message) {
        this.setState({
            showToaster: true,
            toaster: {
                type,
                message,
            }
        }, () => {
            setTimeout(() => this.hideToaster(), 3000)
        })
    }

    hideToaster() {
        this.setState({ showToaster: false })
    }

    getPaymentTermsDate = () => {

        let invoice = this.state.invoice

        let payload = {
            invoiceId: this.state.invoice._id,
            paymentTerms: invoice.paymentTerms
        }

        InvoiceApi.getPaymentTermsDate(payload).then((response) => {
            invoice.dueDate = response?.data?.date
            this.setState({ invoice })
        }).catch((err) => {
            this.showToaster("error", "Oops somthing went wrong...")
        })

    }

    getProductInfoForUpdate = (product) => {
        // if (!product.isManuallyAdded) return { ...product, id: product?.product._id, discount: product?.discount }

        return {
            ...product,
            id: product?.product._id,
        }
    }

    onClickUpdate = () => {
        if (CommonUtils.checkTrialAccess(ActionConstant.UPDATE_INVOICE)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            let invoice = this.state.invoice

            let payload = {
                invoiceId: invoice._id,
                charges: invoice.charges,
                notes: invoice.notes,
                discount: invoice.discount,
                products: invoice.items?.length ? invoice.items.map(this.getProductInfoForUpdate) : [],
                paymentTerms: invoice.paymentTerms
            }

            let validation = this.validatePayload(payload)

            if (!validation) return

            InvoiceApi.updateInvoice(payload).then((response) => {

                if (payload.paymentTerms.value !== "" && payload?.paymentTerms?.value >= 0) {
                    this.props.openViewMode(invoice)

                } else {
                    this.showToaster("error", 'Please Select "Payment Terms"')
                }

            }).catch((err) => {
                this.showToaster("error", "Oops somthing went wrong...")
            })

        }
    }

    onClickRemove = (index) => {

        let images = this.state.images
        images.splice(index, 1)
        this.setState({ images })
    }

    onClickCancel = () => {
        this.props.cancel && this.props.cancel()
    }


    renderNameBadge(name, customeClass) {
        if (!name) return ""
        let splitName = name.split(" ")
        let tag = ""
        if (splitName.length > 1) {
            tag = `${splitName[0][0] || ""}${splitName[1][0] || ""}`
        } else if (splitName) {
            tag = `${splitName[0][0]}`
        }
        tag = tag ? tag.toUpperCase() : ""
        return (<div className={`d-inline-block name-badge ${customeClass || ""}`}><span className="">{tag || ""}</span></div>)

    }

    getUserAddress(address) {
        let str = [address.addressLine1]

        if (address.addressLine2) str.push(address.addressLine2)
        if (address.city) str.push(address.city)
        if (address.state) str.push(address.state)
        if (address.country) str.push(address.country)
        if (address.code) str.push(address.code)
        return str.join(", ")
    }

    onClickSwitch = (key) => {
        let activeSwitch = this.state[key]

        if (activeSwitch == "PERCENTAGE") {
            activeSwitch = "AMOUNT"
        } else {
            activeSwitch = "PERCENTAGE"
        }

        if (key == "activeItemSwitch") {

            let invoice = this.state.invoice
            let products = invoice.items
            let grossAmount = 0

            products.forEach(element => {
                element.discount = {
                    type: activeSwitch,
                    value: 0
                }
                element.billingAmount = element.grossAmount
                grossAmount += element.grossAmount
            })

            invoice.grossAmount = grossAmount

            this.setState({ [key]: activeSwitch, invoice }, () => {
                this.onChangeDiscount(invoice.discount?.value || 0)
            })
        } else {

            let invoice = this.state.invoice
            let discount = {
                type: activeSwitch,
                value: 0
            }

            invoice.discount = discount

            this.setState({ [key]: activeSwitch, invoice }, () => {
                this.calculateBillingAmount()
            })
        }

    }

    onSelectDropdown(option, key) {
        let invoice = this.state.invoice
        invoice.paymentTerms = option

        this.setState({ invoice }, () => {
            this.getPaymentTermsDate()
        })
    }

    onFocusInput(index, type) {
        this.setState({ onFocusInputIndex: index, onFocusInputType: type })
    }

    onBlurInput = () => {
        this.setState({ onFocusInputIndex: -1 })
    }

    renderInvoiceDetail() {
        let buyer = this.state.invoice?.buyer
        return (

            <div className="invoice-detail-wrapper">
                <div>{this.staticText.invoiceDetails}</div>
                <div className="pt-2">
                    <div className="card p-4">
                        <div className="row">
                            <div className="col">
                                <div className="row no-gutters">
                                    <div className="col-3 pr-0">
                                        {this.renderNameBadge(buyer?.name || "")}
                                    </div>
                                    <div className="col pl-0">
                                        <div className="user-name">{buyer?.name || ""} </div>
                                        <div className="address">{this.getUserAddress(buyer?.address || {})} </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col content-center">
                                <div className="d-inline-block">
                                    <div className="user-name">{this.staticText.invoiceNumber} {this.state.invoice?.invoiceNumber || ""} </div>
                                    <div className="address">
                                        <div>{this.staticText.orderNumber} {this.state.invoice?.orderNumber || ""}</div>
                                        <div>{this.staticText.createdDate} {this.state.invoice?.createdDate ? Utils.datePipe(this.state.invoice?.createdDate, Constants.DATE_FORMAT_1) : "-"}</div>
                                        <div>{this.staticText.dueDate} : {this.state.invoice?.dueDate ? Utils.datePipe(this.state.invoice?.dueDate, Constants.DATE_FORMAT_1) : "-"}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div className='app-input-label'>{this.staticText.paymentTerms} <span className='star'>*</span></div>
                                {
                                    !this.state.isDropdownLoading ?
                                        <Dropdown
                                            placeholder={this.staticText.selectPaymentTerms}
                                            options={this.state.paymentTermsDropdownItems || []}
                                            text={"title"}
                                            onSelect={(item) => this.onSelectDropdown(item, "paymentTerms")}
                                            value={this.state.invoice?.paymentTerms?.value}
                                        />
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    onChangeQuantity(value, index) {

        value = value ? +value : 0

        if (value < 0) return

        let invoice = this.state.invoice
        let products = invoice.items

        let item = products[index]

        item.quantity = value

        let price = item?.product?.price
        let totalPrice = item.quantity * price
        item.billingAmount = totalPrice
        item.grossAmount = totalPrice
        if (item.product) item.product.orderedTotalPrice = totalPrice
        invoice.billingAmount = (invoice.billingAmount || 0) + totalPrice

        if (!item.isManuallyAdded) {
            if (this.state.defaultInvoiceItems[index]?.quantity !== item.quantity)
                item.isEdited = true
        }
        this.setState({ invoice }, () => {
            this.onChangeItemDiscount(item?.discount?.value || 0, index)
        })
    }

    onChangePrice(value, index) {

        value = value ? +value : 0

        if (value < 0) return

        let invoice = this.state.invoice
        let products = invoice.items

        let item = products[index]

        item.product.price = value

        let price = item?.product?.price
        let totalPrice = item.quantity * price
        item.billingAmount = totalPrice
        item.grossAmount = totalPrice
        if (item.product) item.product.orderedTotalPrice = totalPrice
        invoice.billingAmount = (invoice.billingAmount || 0) + totalPrice

        if (!item.isManuallyAdded) {
            if (this.state.defaultInvoiceItems[index]?.product?.price !== item.product?.price)
                item.isEdited = true
        }
        this.setState({ invoice }, () => {
            this.onChangeItemDiscount(item?.discount?.value || 0, index)
        })
    }

    getProduct(search) {
        let payload = {
            search,
            status: Constants.PRODUCT_STATUS.ACTIVE
        }

        this.setState({ loading: true })
        productApi.getProducts(payload).then((response) => {
            let products = response?.response || []
            this.setState({ products })
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }

    onChangeInput(value, index) {

        let invoice = this.state.invoice
        let products = invoice.items
        let productDetail = products[index].product

        products[index].product = { ...productDetail, name: value, currency }

        this.setState({ invoice })

        if (!value) this.setState({ productsDropdown: [] })
        if (value.length >= 3) this.getProduct(value)
    }

    onClickAddItem = () => {
        let invoice = this.state.invoice
        let items = invoice.items
        items.push({
            billingAmount: 0,
            grossAmount: 0,
            isManuallyAdded: true,
            product: {
                currency
            },
            discount: {
                type: this.state.activeItemSwitch,
                value: 0
            }
        })
        this.setState({ items })
    }

    validatePayload(payload) {
        const items = payload.products

        if (!items || !items.length) {
            this.setState({ errMsg: "Please add at least one item" })
            return false
        }

        if (items) {

            for (let i = 0; i < items.length; i++) {
                let element = items[i]
                if (element.isManuallyAdded && (!element?.product?.name || !element?.product?.name?.trim())) {
                    this.setState({ errMsg: `Please add valid product name at row no. ${i + 1}` })
                    return false
                }
                if (!element.quantity || (+element.quantity) < 0) {
                    this.setState({ errMsg: `Please add valid quantity at row no. ${i + 1}` })
                    return false
                }

                if (!element.product.price || (+element.product.price) <= 0) {
                    this.setState({ errMsg: `Please add valid price at row no. ${i + 1}` })
                    return false
                }
            }
        }
        return true
    }

    onClickRemoveItem = (index) => {
        let invoice = this.state.invoice
        let items = invoice.items
        items.splice(index, 1)
        invoice.items = items
        this.setState({ invoice }, () => {
            this.onChangeItemDiscount(items[0]?.discount?.value || 0, 0)
        })
    }

    onSelectProduct(item, index) {
        let invoice = this.state.invoice
        let items = invoice.items
        let price = +item.price
        item.orderTotalPrice = +item.price
        items[index].product = item
        items[index].quantity = 1
        items[index].billingAmount = price
        items[index].grossAmount = price
        this.setState({ invoice, onFocusInputIndex: -1 }, () => {
            this.onChangeItemDiscount(items[0]?.discount?.value || 0, 0)
        })
    }

    renderProductDropdown(index) {
        return (
            <div className="product-drop-down-popover">
                {
                    this.state.products?.map(item => (
                        <div className={`cursor-pointer product-title-wrapper`} onClick={() => this.onSelectProduct(item, index)}>
                            <div className="d-flex align-items-center status-name">
                                <span className={`text-left`}>{item.name}</span>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    renderManualInvoiceItem = (item, index) => {
        let products = this.state?.invoice?.items
        return (
            <tr key={item.code} >
                <td className={`product-item sub-text ${this.state.onFocusInputIndex === index && this.state.onFocusInputType === "product" ? "p-0" : ""}`}>
                    <ItemTextInput isFocused={this.state.onFocusInputIndex === index && this.state.onFocusInputType === "product"} value={item?.product?.name} onFocus={() => this.onFocusInput(index, "product")} item={item} onChangeInput={(value) => this.onChangeInput(value, index)} />
                    {this.state.onFocusInputIndex === index && this.state.onFocusInputType === "product" && this.state.products?.length ? this.renderProductDropdown(index) : ""}
                </td>
                <td className={`sub-text text-center ${this.state.onFocusInputIndex === index && this.state.onFocusInputType === "qty" ? "p-0" : ""}`}>
                    <ItemDiscountInput isFocused={this.state.onFocusInputIndex === index && this.state.onFocusInputType === "qty"} value={CommonUtils.formatAmount(item?.quantity || 0)} onFocus={() => this.onFocusInput(index, "qty")} onBlur={this.onBlurInput} item={item} onChangeItemDiscount={(value) => this.onChangeQuantity(value, index)} />
                </td>
                <td className={`sub-text text-center ${this.state.onFocusInputIndex === index && this.state.onFocusInputType === "productPrice" ? "p-0" : ""}`}>
                    <ItemDiscountInput isFocused={this.state.onFocusInputIndex === index && this.state.onFocusInputType === "productPrice"} type={"AMOUNT"} value={CommonUtils.formatAmount(item?.product?.price || 0)} onFocus={() => this.onFocusInput(index, "productPrice")} onBlur={this.onBlurInput} onChangeItemDiscount={(value) => this.onChangePrice(value, index)} />
                </td>
                <td className={`cursor-pointer sub-text text-center ${this.state.onFocusInputIndex === index && this.state.onFocusInputType === "discount" ? "p-0" : ""}`}>
                    <ItemDiscountInput isFocused={this.state.onFocusInputIndex === index && this.state.onFocusInputType === "discount"} type={item?.discount?.type} value={CommonUtils.formatAmount(item?.discount?.value || 0)} onFocus={() => this.onFocusInput(index, "discount")} onBlur={this.onBlurInput} onChangeItemDiscount={(value) => this.onChangeItemDiscount(value, index)} />
                </td>
                <td className="sub-text text-right">
                    {item?.product?.currency?.symbol} {Utils.formatAmountStringStrict(item.billingAmount >= 0 ? item.billingAmount : item.grossAmount)}
                    <span className="action-icon">
                        {
                            products?.length - 1 === index ?
                                <>
                                    {products?.length > 1 ? <img className="icon-15 mr-3" onClick={() => this.onClickRemoveItem(index)} alt="" src={getImageSource(ImageConstants.CROSS_RED)} /> : ""}
                                    <img className="icon-15 " onClick={this.onClickAddItem} alt="" src={getImageSource(ImageConstants.ADD)} />
                                </>
                                :
                                <>
                                    <img className="icon-15 " onClick={() => this.onClickRemoveItem(index)} alt="" src={getImageSource(ImageConstants.CROSS_RED)} />
                                </>

                        }
                    </span>
                </td>
            </tr>
        )
    }

    renderItemDetail() {
        let products = this.state.invoice?.items
        return (

            <div className="invoice-detail-wrapper">
                <div>{this.staticText.itemDetails}</div>

                <div className="ordered-product pt-4">
                    <div id="table-scoll" className="table-responsive pt-2">
                        <table className="table mb-0 align-items-center bg-white">
                            <thead>
                                <tr>
                                    <th className='th-product-max-width'>Item</th>
                                    <th className='th-max-width text-center'>Invoiced Qty</th>
                                    <th className='th-max-width text-center'>Unit Price</th>
                                    <th className='th-max-width text-center'>Discount <span onClick={() => this.onClickSwitch("activeItemSwitch")}>{this.renderSwitch(this.state.activeItemSwitch)}</span></th>
                                    <th className='th-max-width text-right'> Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    products?.length ? products?.map((item, index) => {
                                        if (item.isManuallyAdded) return this.renderManualInvoiceItem(item, index)
                                        return <tr key={item.code} >
                                            <td className="cursor-pointer">
                                                <div className={`user-name`}>{item.product?.name}</div>
                                            </td>
                                            <td className={`sub-text text-center ${this.state.onFocusInputIndex === index && this.state.onFocusInputType === "qty" ? "p-0" : ""}`}>
                                                <ItemDiscountInput isFocused={this.state.onFocusInputIndex === index && this.state.onFocusInputType === "qty"} value={CommonUtils.formatAmount(item?.quantity || 0)} onFocus={() => this.onFocusInput(index, "qty")} onBlur={this.onBlurInput} item={item} onChangeItemDiscount={(value) => this.onChangeQuantity(value, index)} />
                                            </td>
                                            <td className={`sub-text text-center ${this.state.onFocusInputIndex === index && this.state.onFocusInputType === "productPrice" ? "p-0" : ""}`}>
                                                <ItemDiscountInput isFocused={this.state.onFocusInputIndex === index && this.state.onFocusInputType === "productPrice"} type={"AMOUNT"} value={CommonUtils.formatAmount(item?.product?.price || 0)} onFocus={() => this.onFocusInput(index, "productPrice")} onBlur={this.onBlurInput} onChangeItemDiscount={(value) => this.onChangePrice(value, index)} />
                                            </td>
                                            <td className={`cursor-pointer sub-text text-center ${this.state.onFocusInputIndex === index && this.state.onFocusInputType === "discount" ? "p-0" : ""}`}>
                                                <ItemDiscountInput isFocused={this.state.onFocusInputIndex === index && this.state.onFocusInputType === "discount"} type={item?.discount?.type} value={CommonUtils.formatAmount(item?.discount?.value || 0)} onFocus={() => this.onFocusInput(index, "discount")} onBlur={this.onBlurInput} item={item} onChangeItemDiscount={(value) => this.onChangeItemDiscount(value, index)} />
                                            </td>
                                            <td className=" sub-text text-right">
                                                {item?.product?.currency?.symbol} {Utils.formatAmountStringStrict(item.billingAmount >= 0 ? item.billingAmount : item.grossAmount)}
                                                <span className="action-icon">
                                                    {
                                                        products?.length - 1 === index ?
                                                            <>
                                                                {products?.length > 1 ? <img className="icon-15 mr-3" onClick={() => this.onClickRemoveItem(index)} alt="" src={getImageSource(ImageConstants.CROSS_RED)} /> : ""}
                                                                <img className="icon-15 " onClick={this.onClickAddItem} alt="" src={getImageSource(ImageConstants.ADD)} />
                                                            </>
                                                            :
                                                            <>
                                                                <img className="icon-15 " onClick={() => this.onClickRemoveItem(index)} alt="" src={getImageSource(ImageConstants.CROSS_RED)} />
                                                            </>

                                                    }
                                                </span>
                                            </td>
                                        </tr>
                                    })
                                        : <tr><td colSpan="6" align='center' className='text-gray'>No Records found !</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>

        )
    }

    getTaxAmount(percentage, totalAmount) {
        if (!percentage) return totalAmount

        return (percentage / totalAmount) * 100
    }

    onChangeItemDiscount(value, index) {


        value = value ? +value : 0

        if (value < 0) return

        let invoice = this.state.invoice
        let products = invoice.items

        let grossAmount = products[index].grossAmount

        if (value > 100 && this.state.activeItemSwitch === "PERCENTAGE") return
        else if (value > grossAmount && this.state.activeItemSwitch === "AMOUNT") return

        let discount = {
            type: this.state.activeItemSwitch,
            value: value ? +value : 0
        }
        let discountedAmount = this.calculatePercentageAmount(discount, grossAmount)

        products[index].discount = discount
        products[index].billingAmount = Number(Utils.formatAmount(grossAmount - discountedAmount))


        this.setState({ invoice }, () => {
            this.onChangeDiscount(invoice?.discount?.value || 0)
        })
    }

    onChangeSalesTax(value) {

        value = value ? +value : 0

        if (value < 0) return
        let invoice = this.state.invoice

        invoice.charges.tax = {
            "type": "PERCENTAGE",
            "value": value
        }

        let grossAmount = this.getProductGrossAmount(invoice.items)

        grossAmount -= invoice.discount.calculatedAmount || 0
        grossAmount += invoice.charges?.additionalCharges.value || 0

        let salesTaxAmount = this.calculatePercentageAmount(invoice.charges.tax, grossAmount)

        invoice.charges.tax.calculatedAmount = salesTaxAmount

        this.setState({ invoice }, () => {
            this.calculateBillingAmount()
        })

    }

    onChangeDiscount(value) {

        value = value ? +value : 0

        if (value < 0) return
        let invoice = this.state.invoice

        let discount = {
            "type": this.state.activeGloableDiscountSwitch,
            "value": value ? +value : 0
        }

        if (value > 100 && discount.type === "PERCENTAGE") return
        else if (value > invoice.grossAmount && discount.type === "AMOUNT") return

        let grossAmount = this.getProductGrossAmount(invoice.items)

        let calculatedAmount = this.calculatePercentageAmount(discount, grossAmount)
        discount.calculatedAmount = calculatedAmount

        invoice.discount = discount

        this.setState({ invoice }, () => {
            this.calculateBillingAmount()
            this.onChangeSalesTax(invoice.charges.tax?.value || 0)
        })

    }

    getProductGrossAmount(products) {
        let amount = 0
        products.forEach(element => {
            amount += element.billingAmount
        })

        return Utils.formatAmount(amount)
    }

    onChangeDeliveryCharge = (value) => {

        value = value ? +value : 0

        if (value < 0) return
        let invoice = this.state.invoice

        invoice.charges.additionalCharges = {
            "type": "AMOUNT",
            "value": value ? +value : 0
        }
        this.setState({ invoice }, () => {
            this.calculateBillingAmount()
            this.onChangeDiscount(invoice?.discount?.value || 0)
        })
    }

    calculateBillingAmount() {
        let invoice = this.state.invoice

        let charges = invoice.charges

        let products = invoice.items

        let grandTotal = 0

        products.forEach(element => {
            grandTotal += element.billingAmount
        })

        grandTotal += charges?.tax?.calculatedAmount || 0
        grandTotal += charges?.additionalCharges?.value || 0

        invoice.grossAmount = Utils.formatAmount(grandTotal)
        invoice.billingAmount = Utils.formatAmount(grandTotal - (invoice?.discount?.calculatedAmount || 0))

        this.setState({ invoice })

    }

    onChangeNotes = (event) => {

        let invoice = this.state.invoice

        invoice.notes = event.target?.value

        this.setState({ invoice })

    }


    calculatePercentageAmount(discount, total) {
        if (discount.value) {
            if (discount.type === "PERCENTAGE") return Utils.calculatePercentage(discount.value, total)
            else return discount.value > total ? total : discount.value
        } else {
            return 0
        }
    }


    renderDiscountDetail() {
        let invoice = this.state.invoice
        return (
            <div className="invoice-detail-wrapper">
                <div className="row">
                    <div className="col my-auto">
                        <div className="">
                            <div className="add-notes-wrapper">
                                <img alt="" className="icon-20" src={getImageSource(ImageConstants.FILE_PLUS)} />
                                <span className="pl-2"><input className="" placeholder={this.staticText.notePlaceHolder} onChange={this.onChangeNotes} value={this.state.invoice?.notes} /></span>
                            </div>
                        </div>
                    </div>

                    <div className="col text-right">

                        <div className="">
                            <span className="discount-text pr-4">{this.staticText?.totalAmount} </span>
                            <div className="d-inline-block min-width-sm discount-text pr-4">{invoice?.currency?.symbol} {Utils.formatAmountStringStrict(this.getProductGrossAmount(invoice?.items || []))}</div>
                        </div>

                        <div className="pt-4">
                            <span className="discount-text pr-4">{this.staticText.discountText} <span onClick={() => this.onClickSwitch("activeGloableDiscountSwitch")}>{this.renderSwitch(this.state.activeGloableDiscountSwitch)}</span></span>
                            <span className="pr-4"><input type="number" placeholder="0" className="tax-input" onChange={(event) => this.onChangeDiscount(event.target.value)} value={`${invoice?.discount?.value || ""}`} /></span>
                            <div className="d-inline-block min-width-sm discount-text pr-4"> {invoice?.discount.value ? `- ${invoice?.currency?.symbol}` : ""}{Utils.formatAmountStringStrict(invoice?.discount?.calculatedAmount || 0)}</div>
                        </div>

                        <div className="pt-4">
                            <div className="d-inline-block discount-text pr-4">
                                <span>{this.staticText.deliveryCharges} {this.staticText.currency}</span><br />
                                <span className='pallet-charges'>{this.staticText?.palletCharges}</span>
                            </div>
                            <span className="pr-4"><input type="number" className="tax-input" placeholder="0" onChange={(event) => this.onChangeDeliveryCharge(event.target.value)} value={`${invoice?.charges?.additionalCharges?.value || ""}`} /></span>

                            <div className="d-inline-block min-width-sm discount-text pr-4"> {invoice?.charges?.additionalCharges?.value ? invoice?.currency?.symbol : ""}{Utils.formatAmountStringStrict((invoice?.charges?.additionalCharges?.value))}</div>
                        </div>

                        <div className="pt-4">
                            <span className="discount-text pr-4">{this.staticText.salesTax} {this.staticText.percentage}</span>
                            <span className="pr-4"><input type="number" className="tax-input" placeholder="0" onChange={(event) => this.onChangeSalesTax(event.target.value)} value={`${invoice?.charges?.tax?.value || ""}`} /></span>
                            <div className="d-inline-block min-width-sm discount-text pr-4"> {invoice?.charges?.tax?.value ? invoice?.currency?.symbol : ""} {Utils.formatAmountStringStrict(invoice?.charges?.tax?.calculatedAmount || 0)}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderSwitch(activeTab) {
        return (
            <div className="switch-wrapper d-inline-block">
                <div className="switch-body">
                    <div className={`switch ${activeTab == "PERCENTAGE" ? "active-switch" : "in-active-switch"}`}> % </div>
                    <div className={`switch px-1 ${activeTab != "PERCENTAGE" ? "active-switch" : "in-active-switch"}`}> $ </div>
                </div>
            </div>
        )
    }

    renderUpdateInvoice() {


        return (
            <Modal className="update-invoice-modal" isOpen={true} toggle={this.hideModal} size="lg">
                <ModalBody className="overflow-auto">
                    <div className='create-product-header'>
                        <div className='edit-invoice-container full-height'>
                            <div className='row align-center'>
                                <div className='col'>
                                    <span className='add-product-text'>{this.staticText.editInvoice}</span>
                                </div>
                                <div className='col text-right'>
                                    <img alt="" src={getImageSource(ImageConstants.POPUP_CLOSE)} className='popup-close-icon cursor-pointer' onClick={() => this.onClickCancel()}></img>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='app-container'>
                        <div className='edit-invoice-container'>
                            <div className='body-container pt-4'>
                                <div>
                                    {this.renderInvoiceDetail()}
                                </div>
                                <div className="pt-4">
                                    {this.renderItemDetail()}
                                </div>
                                <div className="pt-4">
                                    {this.renderDiscountDetail()}
                                </div>
                                <div className="pt-4">
                                    <div className="grand-total-wrapper">
                                        <span className="user-name">{this.staticText.invoiceAmount}</span>
                                        <span className="user-name float-right">{this.state?.invoice?.currency?.symbol} {Utils.formatAmountStringStrict(this.state?.invoice?.billingAmount)}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="custom-footer py-4 mt-4">
                                {/* <button className="app-button-success mr-2" onClick={this.showOrderUpdateModal}>
                                    <FontAwesomeIcon icon={faCheck} />
                                    <span className="pl-2">{this.staticText?.markAsPaidButtonText}</span>
                                </button> */}
                                <div className='float-right mr-2'>
                                    <span className='mr-2'> {this.state.errMsg ? <b className="text-danger"><i>{this.state.errMsg}</i></b> : ""}</span>
                                    <button className="app-button-primary" onClick={this.onClickUpdate}>
                                        <span className="pl-2">{CommonUtils.checkRights(ActionConstant.UPDATE_INVOICE) ? this.staticText?.updateInvoiceButtonText : ""}</span>
                                    </button>
                                </div>

                                {/* <button className="app-button-secondary float-right mr-2" onClick={this.showOrderUpdateModal}>
                                    <span className="pl-2">{this.staticText?.previewButtonText}</span>
                                </button> */}

                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal >
        )
    }

    render() {

        return (
            <div className='update-invoice'>
                {this.renderUpdateInvoice()}
                {this.state.showToaster ? <ToasterComponent type={this.state.toaster?.type} message={this.state.toaster?.message} /> : ""}
            </div>
        );
    }
}

export default UpdateInvoice;


