import React from 'react';
import "./staff-login.scss";
import AuthenticateAPI from '../../apis/authenticate'
import SessionCache from '../routes/session-cache';
import Path from '../routes/routes-path';
import ReCAPTCHA from "react-google-recaptcha";
import Constants from '../../constants/constants';

import ImageConstants from "../../constants/image-constants"

import { getImageSource } from '../../utility/image-source';
import ToasterComponent from '../common/toaster/toaster';

import Header from '../common/header/header-component';
import Footer from '../common/footer/footer-component';

import StaticText from '../../shared-store/static-text-store';
import ActionConstant from '../../constants/action-constant';
import getRoutes from '../../../routes/routes'


class Login extends React.Component {
    state = {
        staticText: {}
    }

    constructor() {
        super()
        let url = window.location.href
        url = url.split("/")

        let lastUrl = url[url.length - 1]
        let lastBrforeUrl = url[url.length - 2]
        if (lastBrforeUrl == "staff-login" && lastUrl) {
            this.state.cubeId = lastUrl
            this.verifyCubeId()
        }
    }

    componentDidMount() {
        let staticText = StaticText.getStaticText("staffLogin")
        this.setState({ staticText })
    }

    onChangeHandler(event, key) {
        this.setState({ [key]: event?.target?.value }, () => {
            this.validatePayload()
        })
    }


    verifyCubeId(payload, redirect) {
        payload = { cubeId: this.state.cubeId }
        AuthenticateAPI.verifyCubeId(payload).then((response) => {
            this.setState({ isCubeIdCaptured: true })
            if (redirect) {
                let url = `${Path.STAFF_LOGIN}/${this.state.cubeId}`
                this.props.history.push(url)
            }
        }).catch((err) => {
            this.showToaster("error", this.state.staticText?.invalidCubeId)
        })
    }

    validatePayload(showError) {

        let isValidForm = true
        if (!this.state.username) {
            showError && this.setState({ isInvalidUserName: true })
            isValidForm = false
        } else this.setState({ isInvalidUserName: false })

        if (!this.state.password) {
            showError && this.setState({ isInvalidPassword: true })
            isValidForm = false
        } else this.setState({ isInvalidPassword: false })

        if (!this.state.isCaptchaVerified) {
            showError && this.setState({ errMessage: "Captcha not Verified" })
            isValidForm = false
        }

        this.setState({ isValidForm, errMessage: "" })
        return isValidForm
    }

    onClickLogin = () => {
        if (this.state.isSubmit) return
        let payload = {
            username: this.state.username,
            password: this.state.password,
            token: this.state.token,
            cubeId: this.state.cubeId
        };

        let validation = this.validatePayload(true)
        if (!validation) return

        this.setState({ isSubmit: true });

        AuthenticateAPI.staffLogin(payload).then((response) => {
            let userDetails = response.data
            SessionCache.setUser(userDetails)
            this.redirectToInitialScreen(userDetails)

        }).catch((err) => {
            this.setState({ isSubmit: false });
            alert(err, "Invalid credentials");
        })


    }

    validateWithActions(routes) {
        this.user = SessionCache.getUser()

        this.accessRights = this.user.accessRights || {}
        let result = []
        routes.forEach(element => {
            if (this.accessRights[element.action] || element.action == ActionConstant.SHOW_ALWAYS) result.push(element)
        });
        return result
    }

    redirectToInitialScreen(user) {
        let routeType = user.type === Constants.USER_TYPE.BOTH ? Constants.USER_TYPE.VENDOR : user.type

        if (user.type === Constants.USER_TYPE.BOTH) {
            if (user.currentUseType) {
                routeType = user.currentUseType
            } else {
                routeType = Constants.USER_TYPE.VENDOR
            }
        } else {
            routeType = user.type
        }

        let routes = this.validateWithActions(getRoutes(routeType) || [])
        if (routes[0]) this.props.history.replace(routes[0]?.path)
        else this.props.history.replace(Path.DASHBOARD)

    }

    onClickNext = () => {
        if (!this.state.cubeId) return
        this.verifyCubeId({ cubeId: this.state.cubeId }, true)
    }

    onChangeCaptcha = (value) => {
        this.setState({ isCaptchaVerified: !!value, token: value }, () => {
            this.validatePayload()
        })

    }

    showToaster(type, message) {
        this.setState({
            showToaster: true,
            toaster: {
                type,
                message,
            }
        }, () => {
            setTimeout(() => this.hideToaster(), 3000)
        })
    }

    hideToaster() {
        this.setState({ showToaster: false })
    }


    onClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }

    renderLoginCard() {

        return (
            <div className="">
                <div className="text-center header-text p-4">
                    {this.state.staticText?.loginHeaderTitle}
                </div>

                <div className="login-card bg-white">
                    <div className="p-4">

                        <div className="text-center">
                            <img alt="" className="icon-100" src={getImageSource(ImageConstants.STAFF_LOGIN)} />
                        </div>

                        <div className="pt-4">

                            {
                                this.state.isCubeIdCaptured ?
                                    <div className="">
                                        <div className={`bw-spaces-input ${this.state.isInvalidUserName ? "invalid-input-container" : ""}`}>
                                            {/* <div className='app-input-label'>Email <span className='star'>*</span></div> */}
                                            <input
                                                id="login-username" className={`app-input`}
                                                placeholder={this.state.staticText?.userNamePlaceHolder}
                                                value={this.state.username}
                                                onChange={(e) => this.onChangeHandler(e, 'username')}
                                                onBlur={(e) => this.validatePayload()}
                                            />
                                        </div>

                                        <div className={`bw-spaces-input ${this.state.isInvalidPassword ? "invalid-input-container" : ""} position-relative`}>
                                            {/* <div className='app-input-label'>Password <span className='star'>*</span></div> */}
                                            <input id="login-password" className={`app-input`} placeholder={this.state.staticText?.passwordPlaceHolder}
                                                value={this.state.password}
                                                type={this.state.showPassword ? "" : "password"}
                                                onChange={(e) => this.onChangeHandler(e, 'password')} />
                                            <span className="password-icon">
                                                <img className="cursor-pointer icon-20" alt=""
                                                    src={getImageSource(this.state.showPassword ? ImageConstants.EYE_OPEN : ImageConstants.EYE_CLOSE)}
                                                    onClick={this.onClickShowPassword}
                                                />
                                            </span>
                                        </div>

                                        <div className="p-4 text-center">
                                            <div className="d-inline-block">
                                                <ReCAPTCHA
                                                    sitekey={process.env.REACT_APP_ENV === 'production' ? Constants.CAPTCHA_KEY : Constants.DEV_CAPTCHA_KEY} /*CHG000001 */
                                                    onChange={this.onChangeCaptcha}
                                                />
                                            </div>
                                        </div>

                                        <div className="pt-4 text-center">
                                            <button className={`app-button-primary w-100 ${this.state.isValidForm ? "" : "app-button-disabled"}`} onClick={this.onClickLogin}>
                                                {this.state.staticText.loginTitle}
                                            </button>
                                        </div>

                                    </div> :
                                    <div>

                                        <div className={`bw-spaces-input ${this.state.isInvalidCubeId ? "invalid-input-container" : ""}`}>
                                            <input
                                                id="cubeId"
                                                key="cubeId"
                                                className={`app-input`}
                                                placeholder={this.state.staticText?.cubeIdPlaceHolder}
                                                value={this.state.cubeId}
                                                onChange={(e) => this.onChangeHandler(e, 'cubeId')}
                                                onBlur={(e) => this.validatePayload()}
                                            />
                                        </div>

                                        <div className="pt-4 text-center">
                                            <button className={`app-button-primary w-100 ${this.state.cubeId ? "" : "app-button-disabled"}`} onClick={this.onClickNext}>
                                                {this.state.staticText?.continueText}
                                            </button>
                                        </div>
                                    </div>
                            }

                        </div>
                    </div>

                </div>
            </div>
        )
    }

    render() {

        return (
            <>
                <Header from="STAFF_LOGIN" />
                <div className="">
                    <div className="staff-login justify-content-center">
                        <div className="d-inline-block">
                            <div className="row d-flex justify-content-center">
                                <div className="col">
                                    <div>{this.renderLoginCard()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <Footer transparent />
                {
                    this.state.showToaster ? <ToasterComponent type={this.state.toaster?.type} message={this.state.toaster?.message} /> : ""
                }
            </>
        );
    }

}

export default Login;