import React from "react";
import ImageConstants from "../../../../constants/image-constants";
import { getImageSource } from "../../../../utility/image-source";
import './billing.scss'
import Subscription from "../../../../apis/subscription";
import CommonUtils from "../../../../utility/common-utilities";
import Pagination from "../../../common/pagination/pagination"
import Loading from "../../../common/loading/loading";

class Billing extends React.Component {
   state = {
      isLoading: true,
      usages: [],
      totalPages: 0
   }

   limit = 10
   currentPage = 1

   componentDidMount() {
      this.getUsage()
   }

   getUsage(payload = {}) {

      if (!payload.page) payload.page = 1
      if (!payload.limit) payload.limit = this.limit

      this.currentPage = payload.page

      if (this.currentPage === 1) this.startIndex = 1
      this.setState({ isLoading: true })
      Subscription.getUsageHistory(payload).then((data) => {
         this.setState({ totalPages: data?.totalPages || 0 })
         this.prepareData(data.response || [])
      }).catch((err) => {
         this.setState({ usages: [], isLoading: false })
      })
   }

   prepareData(data = []) {
      let usages = data.map(item => {

         let billingDate = new Date(item.usageRecord?.updatedDate)

         let periodDiff = CommonUtils.getDateDiff(item.date, item.endDate)

         let obj = {
            ...item,
            month: CommonUtils.datePipe(item.date, "MMMM yyyy"),
            date: CommonUtils.datePipe(billingDate, "MMM dd, yyyy"),
            period: periodDiff > 1 ? `${CommonUtils.datePipe(item.date, "MMM dd, yyyy")} - ${CommonUtils.datePipe(item.endDate, "MMM dd, yyyy")}` : CommonUtils.datePipe(item.endDate, "MMM dd, yyyy"),
            fee: `$${CommonUtils.formatAmountStringStrict(item.usageRecord?.amount || 0)}`,
            feeNumber: item.usageRecord?.amount || 0
         }

         return obj
      })

      this.setState({ usages, isLoading: false })
   }

   onPageSelected = (selectedPage, startIndex) => {
      this.startIndex = startIndex
      this.page = selectedPage
      this.getUsage({ page: this.page })
   }

   render() {
      return (
         <div className="billing">
            {this.state.isLoading && <Loading />}
            <div id="table-scoll" className="table-responsive pt-2">
               <table className="table mb-0 align-items-center bg-white bill-summary-table">
                  <thead>
                     <th className=''>Usage Month</th>
                     <th className=''>Billing Date</th>
                     <th className=''>Usage Period</th>
                     <th className='text-right'>Membership Fee</th>
                     <th></th>
                  </thead>
                  <tbody>
                     {
                        this.state.usages?.length ?
                           this.state.usages.map((item, index) => (
                              <tr key={item._id} onClick={() => this.props.onSelect(item)}>
                                 <td>{item.month}</td>
                                 <td>{item.date}</td>
                                 <td>{item.period}</td>
                                 <td className='text-right'>{item.fee}</td>
                                 <td className="text-center">
                                    <img alt="" className="right-icon" src={getImageSource(ImageConstants.ARROW_RIGHT_BLACK)} />
                                 </td>
                              </tr>
                           ))
                           :
                           <tr className="text-center"> <td colSpan="6">No Records Found</td></tr>
                     }
                  </tbody>
               </table>
            </div>
            {
               this.state?.totalPages > 1 ?
                  <div className='card-footer'>
                     <Pagination
                        startIndex={this.startIndex}
                        selectedPage={this.currentPage}
                        totalPages={this.state?.totalPages || 0}
                        onPageSelected={this.onPageSelected.bind(this)}
                     />
                  </div>
                  : ""
            }
         </div>
      )
   }
}

export default Billing