import Constants from "../../../constants/constants"

const tabDropDownText = {
   pendingShipMent: "Pending Shipment",
   pastDue: "Past Due",
   dueToday: "Due Today",
   dueTomorrow: "Due Tomorrow"
}

const tabs = {
   1: "All Active",
   2: "New",
   3: [tabDropDownText.pendingShipMent, tabDropDownText.pastDue, tabDropDownText.dueToday, tabDropDownText.dueTomorrow],
   4: "Closed"
}
const orderStatuses = Constants.ORDER_STATUS
const filterStatuses = {
   [orderStatuses.NEW]: {
      title: "Waiting for confirmation from vendor",
      value: "NEW",
      active: true
   },
   [orderStatuses.PENDING]: {
      title: "Pending Shipment",
      value: "PENDING_FULFILLMENT",
      active: true
   },
   [orderStatuses.PAST_DUE]: {
      title: "Past Due",
      value: "PAST_DUE",
      active: true
   },
   [orderStatuses.PARTIAL]: {
      title: "Partially Shipped",
      value: "PARTIAL_FULFILLMENT",
      active: true
   },
   [orderStatuses.PARTIAL_CLOSED]: {
      title: "Partial Closed",
      value: "PARTIAL_CLOSED",
      active: true
   },
   [orderStatuses.COMPLETED]: {
      title: "Completely Shipped",
      value: "ORDER_FULLFILLED",
      active: true
   },
   [orderStatuses.REJECTED]: {
      title: "Rejected",
      value: "ORDER_REJECTED",
      active: true
   },
   [orderStatuses.CLOSED_BY_CUSTOMER]: {
      title: "Closed by Customer",
      value: "CLOSED_BY_CUSTOMER",
      active: true
   },
   [orderStatuses.CLOSED_BY_VENDOR]: {
      title: "Closed by Vendor",
      value: "CLOSED_BY_VENDOR",
      active: true
   },
   dueToday: {
      title: "Due Today",
      value: "DUE_TODAY",
      active: true
   },
   dueTomorrow: {
      title: "Due Tomorrow",
      value: "DUE_TOMORROW",
      active: true
   },
}
const tabFilters = {
   [tabs[1]]: [filterStatuses[orderStatuses.NEW], filterStatuses[orderStatuses.PENDING], filterStatuses[orderStatuses.PAST_DUE], filterStatuses[orderStatuses.PARTIAL]],
   [tabs[2]]: [filterStatuses[orderStatuses.NEW]],
   [tabDropDownText.pendingShipMent]: [filterStatuses[orderStatuses.PENDING]],
   [tabDropDownText.pastDue]: [filterStatuses[orderStatuses.PAST_DUE]],
   [tabDropDownText.dueToday]: [filterStatuses.dueToday],
   [tabDropDownText.dueTomorrow]: [filterStatuses.dueTomorrow],
   [tabs[4]]: [filterStatuses[orderStatuses.PARTIAL_CLOSED], filterStatuses[orderStatuses.REJECTED], filterStatuses[orderStatuses.CLOSED_BY_CUSTOMER], filterStatuses[orderStatuses.CLOSED_BY_VENDOR], filterStatuses[orderStatuses.COMPLETED]]
}

export default {
   tabs,
   tabFilters,
   tabDropDownText
}
