import React from 'react';
import "./add-vendors.scss";
import AuthenticateAPI from '../../../apis/authenticate'
import BranchApi from '../../../apis/branch'
import SessionCache from '../../routes/session-cache';
import { Modal, ModalBody } from "reactstrap";
import Dropdown from '../../common/dropdown-v2/dropdown'
import Path from '../../routes/routes-path';
import { ImageConstants } from '../../../constants/constants';
import { getImageSource } from '../../../utility/image-source'
import Header from '../../common/header/header-component';
import Footer from '../../common/footer/footer-component';
import CustomerApi from '../../../apis/customer'


class AddVendors extends React.Component {

    state = {
        activeStep: "STEP1",
        vendors: [
            // { name: "Praveen Raj", email: "rajn@gmail.com", id: "ID: MI_456" }
        ],
        searchText: ""
    }

    componentDidMount() {
    }

    onPressAddVendor = () => {
        this.setState({ activeStep: "STEP2" })
    }


    onClickSearch = () => {

        if (!this.state.searchText || !this.state.searchText.trim())
            return

        let payload = {
            cubeId: this.state.searchText
        }
        CustomerApi.getCustomerByCubeId(payload).then((response) => {
            this.setState({ vendors: response })
        }).catch((err) => {
        })
    }

    onClickSendInvite = (vendor) => {
        let payload = {
            tenantId: vendor.tenantId,
            branchId: vendor._id
        }
        BranchApi.sendInvite(payload).then((response) => {
            this.onClickSearch()
        }).catch((err) => {
        })
    }

    onClickCreate = () => {
        this.props.onPressCreate && this.props.onPressCreate()
    }

    onClickSkip = () => {
        this.props.onPressCreate && this.props.onPressCreate()
    }

    onChangeHandler(event, key) {
        this.setState({ [key]: event?.target?.value }, () => {
        })
    }

    renderVendorList = () => {

        let elements = []

        for (let i = 0; i < this.state.vendors.length; i++) {

            let vendor = this.state.vendors[i];

            elements.push(<div className='vendor-list-card'>
                <div className='row'>
                    <div className='col-2'>
                        <div className='sl-no-vendor'>{i + 1}.</div>
                    </div>
                    <div className='col-7'>
                        <div className='staff-name-spacing'><span className='staff-name'>{vendor.businessName}</span></div>
                        <div className='staff-username'>{vendor.email}</div>
                        <div className='badge'>{vendor._id}</div>
                    </div>

                    <div className='col-3'>
                        {vendor.status == 'INVITE' ? <div className='invite-text cursor-pointer' onClick={() => this.onClickSendInvite(vendor)}>+ INVITE</div> : <></>}
                        {vendor.status == 'INVITED' ? <div className='invite-text'>INVITED</div> : <></>}
                        {vendor.status == 'INVITE_RECEIVED' ? <div className='invite-text'>INVITE RECEIVED</div> : <></>}
                    </div>
                </div>
            </div>)
        }

        return (<div className='add-branch-step2'>
            <div className='branch-add-title'>
                Search Vendor/Buyer

                <div className='row mt-2'>
                    <div className='col-9'>
                        <input className={`app-input`} placeholder='Search by Email / Cube Id'
                            value={this.state.searchText}
                            onChange={(e) => this.onChangeHandler(e, 'searchText')} />
                    </div>

                    <div className='col-3'>
                        <div className='app-button-primary-outline' onClick={this.onClickSearch}>Search</div>
                    </div>
                </div>
            </div>

            <div className='input-container input-container-vendor'>

                {this.state.vendors.length ? <div className='staff-list-title'>
                    SEARCH RESULTS ({this.state.vendors.length})
                </div> : <></>}

                <div className="pt-3">
                    {elements}
                </div>
            </div>

            <div className='button-wrapper-vendor mb-4'>
                <div className='app-button-default' onClick={this.onClickSkip}>Skip</div>
                {this.state.vendors.length ? <div className='app-button-primary' onClick={this.onClickCreate}>{'Complete'}</div> : <></>}
            </div>
        </div>)
    }

    render() {

        return (
            <div className="create-branch">

                {this.state.activeStep == "STEP1" ? <div className='add-branch'>
                    <div>
                        <img className='no-vendor-image' src={getImageSource(ImageConstants.NO_VENDOR)}></img>
                    </div>

                    <div>
                        <span className='create-branch-title cursor-pointer' onClick={this.onPressStaffAdd}>+ Add Vendors/Buyers</span>
                    </div>

                    <div className='sub-text-branch pt-3'>Would you like to add your business partners at this time? If you skip this step you can do this later as well.</div>

                    <div className='pt-3'>
                        <div className='app-button-primary' onClick={this.onPressAddVendor}>+ Vendor/Buyer</div>
                    </div>
                    <div className='pt-2'>
                        <div className='cancel' onClick={this.onClickSkip}>Skip</div>
                    </div>
                </div> : <></>}

                {this.state.activeStep == "STEP2" ? this.renderVendorList() : <></>}
            </div>
        );
    }

}

export default AddVendors;