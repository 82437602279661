import React from 'react';
import './header-component.scss';
import { Redirect } from 'react-router-dom';
import Path from '../../routes/routes-path';
import AuthenticateAPI from '../../../apis/authenticate'
import SharedStore from '../../../shared-store/shared-store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Services from '../../../service/service'
import cartItemApi from '../../../apis/cart'
import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../../utility/image-source';
import SessionCache from '../../routes/session-cache';
import StaticText from '../../../shared-store/static-text-store';



class Header extends React.Component {

    constructor(props) {
        super(props)
        this.handleClickEvent = this.handleClickEvent.bind(this);
    }

    state = {
        isLogout: false,
        isLoading: false
    };

    menus = [
    ]


    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickEvent);
        // Services.setCart(this)
        // this.handleCart()
        this.userInfo = SessionCache.getUser()

        let staticText = StaticText.getStaticText("signup")
        this.setState({ staticText })
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent);
    }


    handleCart() {
        if (SharedStore.getStore("cart")) {
            this.refreshCart()
            return
        }
        cartItemApi.getCartItems({}).then((response) => {
            SharedStore.setStore("cart", response?.response || [])
            this.refreshCart()
        }).catch((err) => {
            SharedStore.setStore("cart", [])
            this.refreshCart()
        })
    }

    handleClickEvent = (event) => {
        if (this.menuRef && !this.menuRef.contains(event.target)) {
            if (this.state.openMenu) {
                this.closeMenu();
            }
        }
    }


    onClickOption(path) {
        this.setState({ navigatePath: path })
    }


    openMenu() {
        this.setState({ openMenu: !this.state.openMenu })
    }

    onPressLogout = () => {

        AuthenticateAPI.logout({}).then((response) => {
            SessionCache.setUser({})
            this.props.history.push(Path.LOGIN)
            this.setState({ showPopOver: false })

        }).catch((err) => {
            alert("Invalid credentials");
        })

    }

    closeMenu() {

        this.setState({ openMenu: false })
    }

    refreshCart() {
        let cart = SharedStore.getStore("cart")
        this.setState({ cartCount: cart?.products?.length || 0 })
    }

    onClickCart = () => {
        this.props.history.push(Path.CART)
    }

    renderHeaderActions = () => {

        if (this.props.from == "LOGIN") {
            return (
                <>

                    <div className="p-2 pt-3 float-right" onClick={() => this.onClickOption(Path.SIGN_UP)}>
                        <span className="text-1"> Not a user?</span><span className="text">Sign up now</span>
                    </div>
                    <div className="p-2 pt-3 float-right" onClick={() => this.onClickOption(Path.STAFF_LOGIN)}>
                        <span className="text">Employee Login</span>
                    </div>
                </>
            )
        }

        if (this.props.from == "SIGNUP") {
            return (
                <>
                    <div className="p-2 pt-3 float-right" onClick={() => this.onClickOption(Path.LOGIN)}>
                        <span className="text-1"> Already registered?</span><span className="text">Sign in</span>
                    </div>

                    <div className="p-2 pt-3 float-right" onClick={() => this.onClickOption(Path.STAFF_LOGIN)}>
                        <span className="text">Employee Login</span>
                    </div>
                </>)
        }

        if (this.props.from == "DASHBOARD") {
            return (
                <div className="p-2 pt-2 cursor-pointer float-right user-container row" onClick={() => { this.openMenu() }}>
                    <div className='user-circle'>
                        <img alt="" src={getImageSource(ImageConstants.USER)} className='user-image'></img>
                    </div>
                    <div className='user-name'>
                        <span>{this.userInfo ? this.userInfo.firstName + ' ' + this.userInfo.lastName : ""}</span>
                        <img alt="" src={getImageSource(ImageConstants.DOWN_ARROW)} className='down-arrow-header'></img>
                    </div>

                    {this.state.openMenu ?
                        <div className='pop-over-logout' ref={ref => this.menuRef = ref}>
                            <div className='text-center cursor-pointer pt-3' onClick={this.onPressLogout}>Logout</div>
                        </div> : <></>}
                </div>)
        }
    }

    render() {

        return (
            <div className={`app-header ${this.props.transparent ? '' : 'bg-white'}`}>
                {this.state.navigatePath && <Redirect to={this.state.navigatePath} />}
                <div className="row ">
                    <div className={`col-1 my-auto cursor-pointer ${this.props.from == "STAFF_LOGIN" ? "pt-3" : ""}`} onClick={() => { this.onClickOption(Path.LOGIN) }}>
                        {
                            <div className="logo-image"  > <img className="mt-1" alt="MyBiZSquare." src={getImageSource(ImageConstants.APP_DEFAULT_LOGO)} /></div>
                        }
                    </div>
                    <div className="col">
                        {this.props.from != "STAFF_LOGIN" ? this.renderHeaderActions() : ""}
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;