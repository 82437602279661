import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../../utility/image-source';
import "./accept-invitation.scss";

class AcceptInvitation extends React.Component {

    state = {
    }
    accessRights = {}

    constructor(props) {
        super(props)
    }

    onClickCheckBox(selectedType) {
        this.setState({ selectedType })
    }

    render() {

        return (
            <div className='accept-invitation shadow-lg-custom'>
                <div className="card-header p-2">
                    <span className="font-16"><b> New Connection </b></span>
                    <img className="float-right cursor-pointer icon-20" alt="" src={getImageSource(ImageConstants.CLOSE_GRAY)} onClick={this.props.close} />
                </div>
                <div className="text-left px-4 py-4">
                    <div className="d-flex align-items-center">
                        <div className="pr-2">
                            <b>{`Is ${this.props.name} your`}</b>
                            <div className="d-inline-block pl-2 cursor-pointer" onClick={() => this.onClickCheckBox("VENDOR")}><Checkbox className="d-inline-block check-box-wrapper" checked={this.state.selectedType == "VENDOR"} /> <span className="pl-3"><b>Vendor </b></span></div>
                            <div className="d-inline-block pl-2 cursor-pointer" onClick={() => this.onClickCheckBox("BUYER")}><Checkbox className="d-inline-block check-box-wrapper" checked={this.state.selectedType == "BUYER"} /><span className="pl-3"><b>Buyer ?</b></span></div>
                        </div>
                    </div>
                </div>

                <div className="mb-4 text-center">
                    {
                        this.state.selectedType ?
                            <button className="app-button-primary-small" onClick={() => this.props.onAccept(this.state.selectedType)}>ACCEPT</button>
                            : <button className="app-button-disabled-small" >ACCEPT</button>
                    }
                </div>
            </div>
        );
    }
}


export default AcceptInvitation;