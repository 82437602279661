import { getImageSource } from '../home/utility/image-source'
import { ImageConstants, Constants } from '../home/constants/constants'
import Orders from '../home/components/orders/orders'
import myShop from '../home/components/my-shop/my-shop'
import Path from '../home/components/routes/routes-path'
import Calendar from '../home/components/calendar/calendar'
import Cart from '../home/components/cart-details/cart-details'
import Products from '../home/components/product/product-v2'
import CustomerOrders from '../home/components/customers/orders/orders'
import CustomerCalander from '../home/components/customers/calendar/calendar'
import Vendors from '../home/components/customers/vendors/vendors'
import Customers from '../home/components/customers/customers'
import Prolfile from '../layout/profile/profile'
import Dashboard from '../home/components/my-dashboard/my-dashboard'
import CustomerDashboard from '../home/components/customer-dashboard/customer-dashboard'
import Staffs from '../home/components/staff/staff'
import Invoice from '../home/components/invoice/invoice'
import CustomerInvoice from '../home/components/customers/invoice/invoice'
import ActionConstant from '../home/constants/action-constant'
import PaymentRoutes from '../home/components/payment-config'
import PaymentResponse from '../home/components/payment-config/payment-response'
import SubscriptionRoutes from '../home/components/payment-subscription'
import EmailNotification from '../home/components/email-notification-setting/email-notification-setting'
import Inventory from '../home/components/inventory/inventory'      //INV000001

let routes = [
    {
        title: 'My Dashboard',
        path: Path.DASHBOARD,
        component: Dashboard,
        isActive: true,
        action: ActionConstant.VIEW_DASHBOARD,
    },
    {
        title: 'My Products',
        path: Path.PRODUCT,
        component: Products,
        isActive: true,
        action: ActionConstant.VIEW_VENDOR_PORTAL,
    },
    {
        title: 'My Orders',
        path: Path.ORDER,
        component: Orders,
        isActive: true,
        action: ActionConstant.VIEW_ORDER,
    },
    {
        title: 'Order Calendar',
        path: Path.CALENDAR,
        component: Calendar,
        isActive: false,
        action: ActionConstant.VIEW_ORDER,
    },
    {
        title: ' My Invoices',
        path: Path.INVOICE,
        component: Invoice,
        isActive: true,
        action: ActionConstant.VIEW_INVOICE,
    },
    {
        title: 'My Inventory',
        path: Path.INVENTORY,
        component: Inventory,
        isActive: true,
        action: ActionConstant.VIEW_VENDOR_PORTAL,
    }, //INV000001
    {
        title: 'My Network',
        path: Path.CUSTOMER,
        component: Customers,
        isActive: true,
        action: ActionConstant.VIEW_CUSTOMER,
    },
    {
        title: "My Employees",
        path: Path.STAFF,
        component: Staffs,
        isActive: true,
        action: ActionConstant.VIEW_STAFF,
    },
    {
        title: 'Profile',
        path: Path.PROFILE,
        component: Prolfile,
        isActive: false,
        action: ActionConstant.SHOW_ALWAYS
    },
    {
        title: "Payment",
        path: Path.PAYMENT.baseUrl,
        component: PaymentRoutes,
        isActive: false,
        action: ActionConstant.SHOW_ALWAYS,
        hasChildren: true
    },
    {
        title: "Subscription",
        path: Path.SUBSCRIPTION.baseUrl,
        component: SubscriptionRoutes,
        isActive: false,
        action: ActionConstant.SHOW_ALWAYS,
        hasChildren: true
    },
    {
        title: 'Email Notification',
        path: Path.EMAIL_NOTIFICATION,
        component: EmailNotification,
        isActive: false,
        action: ActionConstant.SHOW_ALWAYS
    },
]

let customerRoutes = [
    {
        title: 'My Dashboard',
        path: Path.DASHBOARD,
        component: CustomerDashboard,
        isActive: true,
        action: ActionConstant.VIEW_DASHBOARD
    },
    {
        title: 'My Marketplace',
        path: Path.MY_SHOP,
        component: myShop,
        isActive: true,
        action: ActionConstant.VIEW_MY_SHOP
    },
    {
        title: 'My Orders',
        path: Path.MY_ORDERS,
        component: CustomerOrders,
        isActive: true,
        action: ActionConstant.VIEW_MY_ORDER
    },
    {
        title: 'Order Calendar',
        path: Path.CALENDAR,
        component: CustomerCalander,
        isActive: false,
        action: ActionConstant.VIEW_MY_ORDER
    },
    {
        title: 'My Invoices',
        path: Path.INVOICE,
        component: CustomerInvoice,
        isActive: true,
        action: ActionConstant.VIEW_INVOICE,
    },
    {
        title: 'My Inventory',
        path: Path.INVENTORY,
        component: Inventory,
        isActive: true,
        action: ActionConstant.VIEW_MY_SHOP
    }, //INV000001
    {
        title: 'My Network',
        path: Path.VENDORS,
        component: Vendors,
        isActive: true,
        action: ActionConstant.VIEW_VENDOR
    },

    {
        title: 'My Cart',
        path: Path.CART,
        component: Cart,
        isActive: false,
        action: ActionConstant.VIEW_MY_SHOP
    },

    {
        title: 'Profile',
        path: Path.PROFILE,
        component: Prolfile,
        isActive: false,
        action: ActionConstant.SHOW_ALWAYS
    },

    {
        title: "My Employees",
        path: Path.STAFF,
        component: Staffs,
        isActive: true,
        action: ActionConstant.VIEW_STAFF
    },
    {
        title: 'Payment',
        path: Path.PAYMENT_RESPONSE,
        component: PaymentResponse,
        isActive: false,
        action: ActionConstant.SHOW_ALWAYS
    },
    {
        title: "Subscription",
        path: Path.SUBSCRIPTION.baseUrl,
        component: SubscriptionRoutes,
        isActive: false,
        action: ActionConstant.SHOW_ALWAYS,
        hasChildren: true
    }, {
        title: 'Email Notification',
        path: Path.EMAIL_NOTIFICATION,
        component: EmailNotification,
        isActive: false,
        action: ActionConstant.SHOW_ALWAYS
    },
]

let defaultRoute = [
    {
        title: 'Dashboard',
        path: "",
        component: Dashboard,
        isActive: true
    }
]


function getRoutes(type) {

    if (type == 'CUSTOMER') {
        return customerRoutes
    }

    if (type == 'VENDOR') {
        return routes
    }

    return defaultRoute
}

export default getRoutes