import React from "react";
import ImageConstants from "../../../constants/image-constants";
import StaticText from "../../../shared-store/static-text-store";
import { getImageSource } from "../../../utility/image-source";
import Path from "../../routes/routes-path";
import BillSUmmary from "../bill-summary/bill-summary";
import Billing from "./billing/billing";
import './membership-and-billing.scss';
import Membership from "./membership/membership";

class MembershipAndBilling extends React.Component {
   state = {
      staticText: {},
      loading: true,
   };

   componentDidMount() {
      let staticText = StaticText.getStaticText("membershipAndBilling");
      this.setState({ staticText });
   }

   handleBack = () => {
      this.props.history.replace(Path.PROFILE)
   }

   onSelectUsage = (usage) => {
      this.setState({ showBilling: true, selectedUsage: usage })
   }

   onClickBack = () => {
      this.setState({ showBilling: false })
   }

   render() {
      if (this.state.showBilling) return <BillSUmmary usage={this.state.selectedUsage} back={this.onClickBack} />
      return (
         <div className="membership-and-billing">
            <div className='row'>
               <div className='col-12'>
                  <img src={getImageSource(ImageConstants.FULL_ARROW_LEFT)} alt="" className='arrow-left cursor-pointer' onClick={() => this.handleBack()}></img>
                  <b className='header-text pl-5 pt-5'>{this.state.staticText.pageTitle}</b>
               </div>
            </div>

            <Membership {...this.props} />

            <div className='bill-summary-container'>
               <b className='cart-title pt-5'>{this.state.staticText.billSummary}</b>

               <Billing onSelect={this.onSelectUsage} />
            </div>
         </div>)
   }
}

export default MembershipAndBilling