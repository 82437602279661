import React from "react";
import PaymentSubscriptions from "../../../../apis/payment-subscriptions";
import StaticText from "../../../../shared-store/static-text-store";
import Loading from "../../../common/loading/loading";
import Path from "../../../routes/routes-path";

class PlatinumMembership extends React.Component {
   state = {
      staticText: {},
      loading: false,
      existingSubscription: {},
   };

   componentDidMount() {
      let staticText = StaticText.getStaticText("membershipAndBilling");
      this.setState({ staticText });
   }

   renderNameBadge(name, customClass) {
      if (!name) return "";
      let splitName = name.split(" ");
      let tag = "";
      if (splitName.length > 1) {
         tag = `${splitName[0][0] || ""}${splitName[1][0] || ""}`;
      } else if (splitName) {
         tag = `${splitName[0][0]}`;
      }
      tag = tag ? tag.toUpperCase() : "";
      return (
         <div className={`d-inline-block name-badge ${customClass || ""}`}>
            <span className="">{tag || ""}</span>
         </div>
      );
   }

   onClickMembershipCard = () => {
      if (this.props.existingSubscription?._id) return this.onClickManageSubscription()

      this.onClickSubscribe()
   }

   onClickSubscribe = () => {
      this.setState({ loading: true });
      const payload = {
         successUrl: window.location.origin + Path.SUBSCRIPTION.setupResponse,
         cancelUrl: window.location.origin + Path.SUBSCRIPTION.setupResponse,
      };
      PaymentSubscriptions.createSessionForPaymentSetup(payload)
         .then(({ data }) => {
            window.location.assign(data.url);
         })
         .catch((err) => {
            this.setState({ loading: false });
            this.showToaster(
               "error",
               err.response?.data?.message || "Something went wrong"
            );
            console.error(err);
         });
   };

   onClickManageSubscription = () => {
      this.setState({ loading: true })
      const payload = {
         returnUrl: window.location.origin + Path.SUBSCRIPTION.membershipAndBilling,
      };
      PaymentSubscriptions.getStripeCustomerPortalSession(payload)
         .then(({ data }) => {
            // this.setState({ loading: false });
            window.location.assign(data.url);
         })
         .catch((err) => {
            this.setState({ loading: false });
         });
   }

   render() {
      return (
         <div className="membership-section">
            {this.state.loading && <Loading />}
            <p className={`section-title ${this.props.existingSubscription?._id ? 'text-center' : ''}`}>
               {this.props.existingSubscription?._id ? this.state.staticText.currentMembershipTitle : this.state.staticText.upgradeMembershipTitle}
            </p>
            <div className="membership-card cursor-pointer" onClick={() => this.onClickMembershipCard()}>
               <div className="profile-info">
                  <div className="pr-2">
                     {this.renderNameBadge(this.props.profileLine1)}
                  </div>
                  <div>
                     <p className="line1">{this.props.profileLine1}</p>
                     <p className="line2">{this.props.profileLine2}</p>
                     <p className="line3">{this.props.profileLine3}</p>
                  </div>
               </div>

               <p className="membership-name">{this.state.staticText.platinumMembership}</p>
               <p className="membership-type">{this.state.staticText.premiumMembership}</p>
            </div>
         </div>
      );
   }
}

export default PlatinumMembership;
