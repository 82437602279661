import HttpClient from './http-client';
import URL from './url';

export default class Payment {
   static getSellerAccount(payload) {
      let params = [];
      if (payload) {
         Object.keys(payload).forEach(key => {
            params.push(`${key}=${payload[key]}`);
         });
      }
      let paramsList = params.length ? URL.PAYMENT_ACCOUNT + "?" + params.join('&') : URL.PAYMENT_ACCOUNT;
      return HttpClient.get(URL.BASE_URL + paramsList, payload);
   }

   static getSellerAccountByTenant(payload) {
      let params = [];
      if (payload) {
         Object.keys(payload).forEach(key => {
            params.push(`${key}=${payload[key]}`);
         });
      }
      let paramsList = params.length ? URL.PAYMENT_ACCOUNT_BY_TENANT + "?" + params.join('&') : URL.PAYMENT_ACCOUNT_BY_TENANT;
      return HttpClient.get(URL.BASE_URL + paramsList, payload);
   }

   static refreshPaymentAccountOnboarding(payload) {
      let params = [];
      if (payload) {
         Object.keys(payload).forEach(key => {
            params.push(`${key}=${payload[key]}`);
         });
      }
      let paramsList = params.length ? URL.PAYMENT_ACCOUNT_REFRESH_ONBOARDING + "?" + params.join('&') : URL.PAYMENT_ACCOUNT_REFRESH_ONBOARDING;
      return HttpClient.get(URL.BASE_URL + paramsList, payload);
   }

   static createSellerAccount(payload) {
      return HttpClient.post(URL.BASE_URL + URL.PAYMENT_ACCOUNT, null, payload);
   }

   static updateSellerAccount(payload) {
      return HttpClient.post(URL.BASE_URL + URL.UPDATE_PAYMENT_ACCOUNT, null, payload);
   }

   static createPaymentSession(payload) {
      return HttpClient.post(URL.BASE_URL + URL.CREATE_PAYMENT_SESSION, null, payload);
   }

   static updateBranchPaymentAccount(payload) {
      return HttpClient.put(URL.BASE_URL + URL.UPDATE_BRANCH_ACCOUNT, null, payload);
   }

   static removeBranchPaymentAccount(payload) {
      return HttpClient.put(URL.BASE_URL + URL.PAYMENT_ACCOUNT_REMOVE, null, payload);
   }

}
