import HttpClient from './http-client';
import URL from './url';

export default class PaymentSubscriptions {
    static getSubscriptionProfile(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.SUBSCRIPTION_PROFILE + "?" + params.join('&') : URL.SUBSCRIPTION_PROFILE;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getStripeCustomerPortalSession(payload) {
        return HttpClient.post(URL.BASE_URL + URL.GET_STRIPE_CUSTOMER_PORTAL_SESSION, null, payload);
    }

    static getTenantSubscription(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.TENANT_SUBSCRIPTION + "?" + params.join('&') : URL.TENANT_SUBSCRIPTION;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static createSessionForPaymentSetup(payload) {
        return HttpClient.post(URL.BASE_URL + URL.CREATE_SESSION_FOR_PAYMENT_SETUP, null, payload);
    }

    static createSubscriptionAfterPaymentSetupSuccess(payload) {
        return HttpClient.post(URL.BASE_URL + URL.CREATE_SUBSCRIPTION_AFTER_PAYMENT_SETUP_SUCCESS, null, payload);
    }

    static getSubscriptionUsageAmountForCancel(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.GET_SUBSCRIPTION_USAGE_AMOUNT_FOR_CANCEL + "?" + params.join('&') : URL.GET_SUBSCRIPTION_USAGE_AMOUNT_FOR_CANCEL;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static cancelSubscription(payload) {
        return HttpClient.post(URL.BASE_URL + URL.CANCEL_SUBSCRIPTION, null, payload);
    }
}
