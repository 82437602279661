import HttpClient from './http-client';
import URL from './url';

export default class customer {

    static getCustomers(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.CUSTOMER + "?" + params.join('&') : URL.CUSTOMER;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getCustomerByCubeId(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.CUSTOMER_BY_CUBE_ID + "?" + params.join('&') : URL.CUSTOMER_BY_CUBE_ID;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getCustomerByBusinessName(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.CUSTOMER_BY_BUSINESS_NAME + "?" + params.join('&') : URL.CUSTOMER_BY_BUSINESS_NAME;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getInvitations(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.BRANCH_INVITATION + "?" + params.join('&') : URL.BRANCH_INVITATION;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }



    static createCustomer(payload) {
        return HttpClient.post(URL.BASE_URL + URL.CUSTOMER, null, payload);
    }

    static updateCustomer(payload) {
        return HttpClient.put(URL.BASE_URL + URL.CUSTOMER, null, payload);
    }


    static removeCustomer(payload) {
        return HttpClient.delete(URL.BASE_URL + URL.CUSTOMER, null, payload);
    }


    static createBuyerPricing(payload) {
        return HttpClient.post(URL.BASE_URL + URL.BUYER_PRICING, null, payload);
    }

    static getBuyerPricing(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.BUYER_PRICING + "?" + params.join('&') : URL.BUYER_PRICING;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getBuyerVendorDiscounts(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.BUYER_VENDOR_DISCOUNTS + "?" + params.join('&') : URL.BUYER_VENDOR_DISCOUNTS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }
}
