import React from 'react';
import "./create-branch.scss";
import BranchApi from '../../../apis/branch'
import SessionCache from '../../routes/session-cache';
import Dropdown from '../../common/dropdown/dropdown'
import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../../utility/image-source'
import Utils from '../../../utility/common-utilities'
import CommonApi from '../../../apis/common'
import ProductApi from '../../../apis/product'

import Constants from '../../../constants/constants';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
class CreateBranch extends React.Component {

    state = {
        activeStep: "STEP1",
        branchTypes: [
            {
                type: "VENDOR",
                title: "Vendor",
                isActive: true
            },
            {
                type: "CUSTOMER",
                title: "Customer"
            }
        ],
        countryCode: "+1",
        isStateDropdownLoading: true,
        country: "United States",
        countryDropdown: [{ title: "United States", value: "United States" }]
    }

    constructor(props) {
        super(props)
        this.getStates()
    }

    componentDidMount() {
        let user = SessionCache.getUser()
        this.setState({
            businessName: user.businessName,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
        })
    }


    getStates() {
        CommonApi.getState({}).then((response) => {

            let states = response.response || []
            let items = []
            states.forEach(element => {
                items.push({ title: `${element.name} (${element.code})`, value: element.name })
            })

            this.setState({ isStateDropdownLoading: false, stateDropdownItems: items })

        }).catch((err) => {
            this.setState({ isStateDropdownLoading: false, stateDropdownItems: [] })

        })
    }


    onClickCreate = () => {

        this.setState({ isSubmit: true })

        let payload = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            mobileNumber: this.state.mobileNumber,
            countryCode: this.state.countryCode,
            businessName: this.state.businessName,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            code: this.state.pinCode,
            type: "BOTH",
            addressLine1: this.state.addressLine1,
            addressLine2: this.state.addressLine2,
            image: this.state.image,
            isFirstBranch: true,
            isSearchable: true /* pramod CHG000001 */
        };

        let isValid = this.validatePayload(payload)

        if (!isValid)
            return

        BranchApi.createBranch(payload).then((response) => {
            console.log(response)
            this.props.onPressCreate && this.props.onPressCreate(response && response.data && response.data.branchId)
        }).catch((err) => {
            alert(err);
        })
    }

    getBranchType = () => {

        let activeBranch = []

        for (let i = 0; i < this.state.branchTypes.length; i++) {
            if (this.state.branchTypes[i].isActive) {
                activeBranch.push(this.state.branchTypes[i])
            }
        }

        if (activeBranch.length == 1) {
            return activeBranch[0].type
        }

        if (activeBranch.length == 2) {
            return "BOTH"
        }
    }

    validatePayload = (payload) => {

        if (!payload.city)
            return false

        if (!payload.state)
            return false

        if (!payload.country)
            return false

        if (!payload.code)
            return false

        if (!payload.addressLine1)
            return false

        if (this.state.invalidEmail)
            return false

        return true
    }

    renderBranchTypes = () => {

        let elements = []

        for (let i = 0; i < this.state.branchTypes.length; i++) {

            let item = this.state.branchTypes[i]

            if (item.isActive) {
                elements.push(
                    <div className='selection-card-active' onClick={() => this.onSelectBranchType(item, i)}>
                        {item.title}
                    </div>
                )
            } else {
                elements.push(
                    <div className='selection-card cursor-pointer' onClick={() => this.onSelectBranchType(item, i)}>
                        {item.title}
                    </div>
                )
            }
        }

        return <div className='selection-card-wrapper'>{elements}</div>
    }

    validateEmail = () => {

        this.setState({ invalidEmail: false })

        if (!Utils.validateEmail(this.state.email)) {
            this.setState({ invalidEmail: true })
        }
    }

    onChangeHandler(event, key, max) {
        let value = event?.target?.value || ""
        max = max || Constants.INPUT_MAX_LENGTH_1
        if (max < value.length) return
        this.setState({ [key]: value })
    }

    onSelectBranchType = (item, i) => {

        let branchTypes = this.state.branchTypes;
        branchTypes[i].isActive = !branchTypes[i].isActive

        this.setState({ branchTypes })
    }

    onSelectDropdown(option, key) {
        this.setState({ [key]: option?.value })
    }

    uploadFile = (signedUrl, file, callback) => {
        let type = "IMAGE"
        if (file.type.includes('video')) type = "VIDEO"
        else if (file.type.includes('audio')) type = "AUDIO"

        ProductApi.uploadFileAwsS3(signedUrl, file,
            (progress) => {
                let progressPercentage = ((progress.loaded / progress.total) * 100).toFixed(2);
                this.setProgress(progressPercentage);
            })
            .then(response => {
                let splittedSignedUrl = signedUrl.split("?");
                callback && callback(null, splittedSignedUrl[0])
            })
            .catch(error => {
                callback && callback(error, null)
                console.log(error);
            });
    };

    getSignedURL(file) {

        let payload = {
            fileName: file.name,
            type: Constants.PROFILE
        };

        this.setState({ uploadLoading: true })
        ProductApi.getSignedUrl(payload).then((response) => {

            this.uploadFile(response.signedUrl, file, (error, res) => {

                if (error) {
                    this.setState({ uploadLoading: false })
                    return
                }
                this.setState({ image: res, uploadLoading: false })
            })
        }).catch((err) => {
        })
    }

    onDrop = event => {
        const { type } = event.dataTransfer.files[0];
        const reader = new FileReader();
        if (type.includes('image')) {
            reader.onload = e => this.setPreview(e.target.result);
        }

        reader.readAsDataURL(event.dataTransfer.files[0]);
        const payload = new FormData();
        payload.append('file', event.dataTransfer.files[0]);
        let file = event.dataTransfer.files[0];
        this.getSignedURL(file);
        //}
        event.preventDefault();
    };

    onDragOver = event => {
        if (this.state.enableDragDrop) {
            this.setState({ status: 'Drop' });
        }
        event.preventDefault();
    };

    onDragLeave = event => {
        if (this.state.enableDragDrop) {
            this.setState({ status: 'Drop Here' });
        }
        event.preventDefault();
    };

    setProgress(percentage) {
        this.setState({ percentage: percentage });
    }

    showProgressBar = (status) => {
        this.setState({
            showProgress: status,
        });
    }

    reset = () => {
        this.setState({
            percentage: 0,
        });
    }

    onDragEnter = event => {
        if (this.state.enableDragDrop) {
            this.setState({ status: 'Image Selected' });
        }
        event.stopPropagation();
        event.preventDefault();
    };


    onImageUpload(event) {
        this.setState({ hideDone: true, isUploading: true })
        let file = event.target.files[0];
        if (!file)
            return;
        let previewImage = URL.createObjectURL(file);
        this.setPreview(previewImage);
        this.getSignedURL(file);
    }

    setPreview = (data) => {
        this.setState({ preview: data });
    }

    onClickRemoveImage = () => {
        this.setState({ image: "", preview: "" })
    }


    renderCreateBrach = () => {

        return (
            <div className='add-branch-step2'>
                <div className='branch-add-title'>
                    Create new Business Unit
                </div>

                <div className='input-container'>

                    {/* <div>
                        <div className='app-input-label'>I am creating for <span className='star'>*</span></div>
                        {this.renderBranchTypes()}
                    </div> */}

                    <div className="row">
                        <div className="col">
                            <div className={`bw-spaces-input`}>
                                <div className='app-input-label'>Business name</div>
                                <input className={`app-input`} placeholder='Enter business name'
                                    value={this.state.businessName}
                                    onChange={(e) => this.onChangeHandler(e, 'businessName')} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className={`bw-spaces-input`}>
                                <div className='app-input-label'>First name</div>
                                <input className={`app-input`} placeholder='Enter First name'
                                    value={this.state.firstName}
                                    onChange={(e) => this.onChangeHandler(e, 'firstName')} />
                            </div>
                        </div>

                        <div className="col">
                            <div className={`bw-spaces-input`}>
                                <div className='app-input-label'>Last name</div>
                                <input className={`app-input`} placeholder='Enter Last name'
                                    value={this.state.lastName}
                                    onChange={(e) => this.onChangeHandler(e, 'lastName')} />
                            </div>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col">
                            <div className={`bw-spaces-input`}>
                                <div className='app-input-label'>Mobile number</div>
                                <div className='app-input row p-1 mobile-number-input-wrapper'>
                                    <input placeholder='' className='country-code'
                                        value={this.state.countryCode}
                                        onChange={(e) => this.onChangeHandler(e, 'countryCode', Constants.INPUT_MAX_LENGTH_3)} />

                                    <input className='mobile-number-input' placeholder='Enter Mobile number'
                                        value={this.state.mobileNumber}
                                        onChange={(e) => this.onChangeHandler(e, 'mobileNumber', Constants.INPUT_MAX_LENGTH_2)}
                                        type={'number'} />
                                </div>
                            </div>
                        </div>

                        {/* <div className="col">
                            <div className={`bw-spaces-input ${this.state.invalidEmail && this.state.email ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>Email</div>
                                <input id="login-password" className={`app-input`} placeholder='Enter email'
                                    value={this.state.email}
                                    onFocus={() => this.setState({ invalidEmail: false })}
                                    onBlur={() => { this.validateEmail() }}
                                    onChange={(e) => this.onChangeHandler(e, 'email')} />
                            </div>
                        </div> */}


                        <div className="col">
                            <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.addressLine1 ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>Delivery Address Line 1 <span className='star'>*</span></div>
                                <input className={`app-input`} placeholder='Enter address line 1'
                                    value={this.state.addressLine1}
                                    onChange={(e) => this.onChangeHandler(e, 'addressLine1')} />
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col">
                            <div className={`bw-spaces-input`}>
                                <div className='app-input-label'>Delivery Address Line 2</div>
                                <input id="login-password" className={`app-input`} placeholder='Enter address line 2'
                                    value={this.state.addressLine2}
                                    onChange={(e) => this.onChangeHandler(e, 'addressLine2')} />
                            </div>
                        </div>
                        <div className="col">
                            <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.city ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>City <span className='star'>*</span></div>
                                <input className={`app-input`} placeholder='Enter city'
                                    value={this.state.city}
                                    onChange={(e) => this.onChangeHandler(e, 'city')} />
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col">
                            <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.state ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>State <span className='star'>*</span></div>
                                {
                                    !this.state.isStateDropdownLoading ?
                                        <Dropdown
                                            placeholder="Select State"
                                            options={this.state.stateDropdownItems || []}
                                            text={"title"}
                                            onSelect={(item) => this.onSelectDropdown(item, "state")}
                                            value={this.state.state}
                                        />
                                        : ""
                                }
                            </div>
                        </div>
                        <div className="col">
                            <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.country ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>Country <span className='star'>*</span></div>
                                <Dropdown
                                    placeholder="Select Country"
                                    options={this.state.countryDropdown}
                                    text={"title"}
                                    onSelect={(item) => this.onSelectDropdown(item, "country")}
                                    value={this.state.country}
                                />

                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.pinCode ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>Zipcode <span className='star'>*</span></div>
                                <input id="login-password" className={`app-input`} placeholder='Enter zipcode'
                                    value={this.state.pinCode}
                                    onChange={(e) => this.onChangeHandler(e, 'pinCode', Constants.INPUT_MAX_LENGTH_4)} />
                            </div>
                        </div>
                        <div className="col">
                            <div className={`bw-spaces-input `}>
                                <div className='app-input-label'>Profile Image</div>
                                {
                                    this.state.uploadLoading ?
                                        <div className='image-upload'>
                                            <img alt="" src={getImageSource(ImageConstants.LOADING)} className='loading-icon'></img>
                                        </div>
                                        :
                                        this.state.preview ?
                                            <div className='image-upload-preview'>
                                                <img alt="" src={this.state.preview ? this.state.preview : this.state.image} className='preview-image' />
                                                <FontAwesomeIcon className="remove-image cursor-pointer" color="red" icon={faTimes} onClick={this.onClickRemoveImage} />
                                            </div>
                                            :
                                            <div className='image-upload'
                                                onDragOver={this.onDragOver}
                                                onDragLeave={this.onDragEnter}
                                                onDrop={this.onDrop}>
                                                <div className='upload-image'>
                                                    <img alt="" src={getImageSource(ImageConstants.UPLOAD_IMAGE)} className='upload-image-size' />
                                                </div>
                                                <label className="m-0 ml-1 cursor-pointer" style={{ "cursor": "pointer" }}>
                                                    <input
                                                        type="file" style={{ "display": "none" }}
                                                        accept={this.props.accept || ''}
                                                        onChange={(event) => this.onImageUpload(event)} />    <div className='drop-text pt-2'>
                                                        <span>Drag & Drop or</span>
                                                        <span className='browse-text'> browse </span>
                                                        <span>Profile Image</span>
                                                    </div>
                                                </label>
                                            </div>
                                }
                            </div>
                        </div>
                    </div>

                </div>

                <div className='text-right pb-3 create-branch-wrapper'>
                    <div className='app-button-primary d-inline-block' onClick={this.onClickCreate}>Create</div>
                </div>
            </div>
        )
    }

    onPressBranchAdd = () => {
        this.setState({ activeStep: "STEP2" })
    }

    render() {

        return (
            <div className="create-branch">
                {this.state.activeStep == "STEP1" ? <div className='add-branch'>
                    <div>
                        <img alt="" className='vendor-image' src={getImageSource(ImageConstants.VENDOR)}></img>
                    </div>

                    <div className='pt-5'>
                        <span className='create-branch-title cursor-pointer' onClick={this.onPressBranchAdd}>+ Create a new Business Unit</span>
                    </div>

                    <div className='sub-text-branch pt-3'>Please create your first Business Unit</div>

                    <div className='pt-4'>
                        <div className='app-button-primary' onClick={this.onPressBranchAdd}>+ Add Business Unit</div>
                    </div>
                </div> : <></>}

                {this.state.activeStep == "STEP2" ? this.renderCreateBrach() : ""}
            </div>
        );
    }

}

export default CreateBranch;