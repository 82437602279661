import React from 'react';
import "./order-detail.scss";
import orderApi from '../../../apis/order'
import SessionCache from '../../routes/session-cache';
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import Path from '../../routes/routes-path'

import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../../utility/image-source'
import Constants from '../../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCheck, faEdit, faTimes, faAngleRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import AppConfirmModal from '../../common/app-confirm/app-confirm'
import Utils from '../../../utility/common-utilities'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Loading from '../../common/loading/loading';
import StaticText from '../../../shared-store/static-text-store';
import ActionConstant from '../../../constants/action-constant';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import CommonUtils from '../../../utility/common-utilities';
import SharedStore from '../../../shared-store/shared-store'

class OrderDetail extends React.Component {
    state = {
        order: this.props.order,
        staticText: {}
    }
    accessRights = {}

    componentDidMount() {
        let payload = {
            orderId: this.props.order._id
        }
        this.getOrders(payload)

        let user = SessionCache.getUser()
        this.accessRights = user?.accessRights || {}
        let staticText = StaticText.getStaticText("orderDetail")
        this.setState({ staticText })
    }

    getOrders(payload = {}) {
        payload.isVendor = true
        payload.orderId = this.props.order._id
        this.setState({ isLoading: true })
        orderApi.getOrders(payload).then((response) => {
            this.prepareOrders(response?.response[0])
            this.setState({ isLoading: false })

        }).catch((err) => {
            this.setState({ isLoading: false })

        })
    }

    prepareOrders(element) {
        if (element.status == Constants.ORDER_STATUS.NEW) element.deliveryDate = new Date()
        element.items.forEach(item => {
            if (element.orderTotal) element.orderTotal += +item.orderedTotalPrice
            else element.orderTotal = +item.orderedTotalPrice
            if (!element.currency) element.currency = item.currency

            let qty = element.status == Constants.ORDER_STATUS.NEW ? item.customerOrderedQuantity : item.orderedQuantity
            item.deliverableQuantity = element.status == Constants.ORDER_STATUS.NEW ? qty : 0
        });

        this.setState({ order: element })
    }

    updateOrder(payload) {
        payload.orderId = this.state.order?._id
        orderApi.updateOrder(payload).then((response) => {
            this.getOrders()
        }).catch((err) => {
        })
    }

    updateOrderItem(payload, status) {

        orderApi.updateOrderItem(payload).then((response) => {
            if (status === Constants.ORDER_STATUS.PENDING || status === Constants.ORDER_STATUS.REJECTED) {
                SharedStore.getStore("header").getMembershipDetail()
                this.props.back && this.props.back()
            } else {
                this.getOrders()
            }

            if (response.data.invoiceNumber) {
                this.setState({ updateMessage: `Shipping update completed and Invoice generated. #${response.data.invoiceNumber}` })
            }

            this.handleClose()

        }).catch((err) => {
        })
    }

    hideModal = () => {
        this.setState({ showOrderDetail: false })
    }

    getImageByStatus() {
        let status = this.state?.order?.status
        if (status == Constants.ORDER_STATUS.REJECTED || status == Constants.ORDER_STATUS.PARTIAL_CLOSED || status == Constants.ORDER_STATUS.CLOSED_BY_VENDOR || status == Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER) return ImageConstants.CUBE_RED
        if (status == Constants.ORDER_STATUS.COMPLETED) return ImageConstants.CUBE_GREEN

        return ImageConstants.CUBE_WHITE
    }

    getClassNameByStatus(status) {
        if (status === Constants.ORDER_STATUS.COMPLETED || status === Constants.ORDER_STATUS.REJECTED) return "tr-complete"
        return ""
    }

    getStatusBackgoundStyle() {
        let status = this.state?.order?.status
        if (status == Constants.ORDER_STATUS.NEW) return "status-new"
        if (status == Constants.ORDER_STATUS.PENDING) return "status-pending"
        if (status == Constants.ORDER_STATUS.PARTIAL) return "status-partial"
        if (status == Constants.ORDER_STATUS.COMPLETED) return "status-complete"
        if (status == Constants.ORDER_STATUS.REJECTED || status == Constants.ORDER_STATUS.PARTIAL_CLOSED || status == Constants.ORDER_STATUS.CLOSED_BY_VENDOR || status == Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER || status == Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER) return "status-reject"
        if (status == Constants.ORDER_STATUS.PAST_DUE) return "status-due"
        return ""
    }


    getNoteBackgoundStyle() {
        let status = this.state?.order?.status
        if (status == Constants.ORDER_STATUS.NEW) return "note-new"
        if (status == Constants.ORDER_STATUS.PENDING) return "note-pending"
        if (status == Constants.ORDER_STATUS.COMPLETED) return "note-complete"
        if (status == Constants.ORDER_STATUS.PAST_DUE) return "note-due"
        if (status == Constants.ORDER_STATUS.CLOSED_BY_VENDOR || status == Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER || status == Constants.ORDER_STATUS.REJECTED || status == Constants.ORDER_STATUS.PARTIAL_CLOSED) return "note-close"
        return ""
    }

    checkInputDisable(item) {
        let status = this.state.order?.status
        let deliverableQuantity = item.orderedQuantity - item.deliveredQuantity
        return status === Constants.ORDER_STATUS.NEW || Utils.checkClosedStatus(status) || deliverableQuantity == 0 || !item.orderedQuantity
    }

    onChangeQuantity(event, item, index) {
        let order = this.state.order

        let value = +event?.target?.value
        if (!value && value != "") return

        let maxQuantityAllow = item.orderedQuantity - (item.deliveredQuantity || 0)

        if (value > maxQuantityAllow) item.deliverableQuantity = maxQuantityAllow
        else item.deliverableQuantity = value

        order.items[index] = item
        this.setState({ order })
    }


    onChangeOrderStatus(status) {
        let payload = {
            orderId: this.state.order._id,
        }
        if (status === Constants.ORDER_STATUS.PARTIAL_CLOSED) {
            if (this.state.order.status == Constants.ORDER_STATUS.PENDING) {
                payload.status = Constants.ORDER_STATUS.CLOSED_BY_VENDOR
                this.updateOrder(payload)
            } else {
                payload.status = status
                this.updateOrderItem(payload, status)
            }
        } else if (status === Constants.ORDER_STATUS.PARTIAL) {

            let products = this.state.order.items
            let productItem = []
            products.forEach(item => {
                if (!item.deliverableQuantity) return
                let deliverableQuantity = item.deliverableQuantity

                productItem.push({ id: item.productId, quantity: deliverableQuantity })
            })

            payload.products = productItem

            if (!payload.products?.length) return
            this.updateOrderItem(payload, status)
        } else if (status === Constants.ORDER_STATUS.COMPLETED) {
            let products = this.state.order.items
            let productItem = []
            products.forEach(item => {
                let deliverableQuantity = item.orderedQuantity - (item.deliveredQuantity || 0)
                if (!deliverableQuantity) return
                productItem.push({ id: item.productId, quantity: deliverableQuantity })
            })

            payload.products = productItem

            if (!payload.products?.length) return
            this.updateOrderItem(payload, status)
        }
    }

    onReject = (reason) => {
        let payload = {
            orderId: this.state.order._id,
            status: Constants.ORDER_STATUS.REJECTED,
            reason
        }
        this.updateOrderItem(payload, payload.status)
        this.handleClose()
    }

    onCloseOrder = (reason) => {
        let payload = {
            orderId: this.state.order._id,
            reason
        }
        if (this.state.order.status == Constants.ORDER_STATUS.PENDING) {
            payload.status = Constants.ORDER_STATUS.CLOSED_BY_VENDOR
            this.updateOrder(payload)
        } else {
            payload.status = Constants.ORDER_STATUS.PARTIAL_CLOSED
            this.updateOrderItem(payload, payload.status)
        }
        this.handleClose()
    }

    onConfirmAccept = () => {
        let items = this.state.order.items?.map(item => {
            return {
                id: item._id,
                quantity: item.deliverableQuantity
            }
        })

        let payload = {
            orderId: this.state.order._id,
            status: Constants.ORDER_STATUS.PENDING,
            deliveryDate: this.state.order.deliveryDate,
            items
        }
        this.updateOrderItem(payload, payload.status)
        this.handleClose()
    }

    showAcceptOrderModal = () => {
        if (CommonUtils.checkTrialAccess(ActionConstant.ACCEPT_ORDER)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ showAcceptModal: true })
        }
    }

    showRejectConfirm = () => {
        if (CommonUtils.checkTrialAccess(ActionConstant.REJECT_ORDER)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ showRejectConfirm: true })
        }
    }

    showCloseConfirm = () => {
        if (CommonUtils.checkTrialAccess(ActionConstant.MARK_THE_ORDER_AS_CLOSED)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ showCloseConfirm: true })
        }
    }

    handleClose = () => {
        this.setState({ showRejectConfirm: false, showAcceptModal: false, showCloseConfirm: false, showUpdateModal: false })
    }

    getDeliverdStatus(item) {
        if (this.state.order.status == Constants.ORDER_STATUS.PENDING || !item.orderedQuantity) return ""
        if (item.orderedQuantity == item.deliveredQuantity) return "Shipped"
        if (!item.deliveredQuantity) return "Pending"
        if (item.orderedQuantity > item.deliveredQuantity) return `Partially Shipped`
    }

    getItemStatus(item) {
        if (this.state.order.status == Constants.ORDER_STATUS.PENDING || !item.orderedQuantity) return "na"
        if (item.orderedQuantity == item.deliveredQuantity) return "delivered-item"
        if (!item.deliveredQuantity) return "pending-item"
        if (item.orderedQuantity > item.deliveredQuantity) return `partially-delivered-item`
    }

    onClickEditDate = () => {
        this.setState({ showCalender: true })
    }

    hideCalenderView = () => {
        this.setState({ showCalender: false })
    }

    onSelectDate = (date, updateOrder) => {
        let deliveryDate = new Date(date).toISOString()
        let order = this.state.order
        order.deliveryDate = deliveryDate
        this.setState({ order }, () => {
            if (updateOrder) this.updateOrder({ deliveryDate })
        })
    }

    handleDateEvent = (picker, updateOrder, event) => {
        this.startDate = picker.startDate
        this.onSelectDate(this.startDate, updateOrder)
    }

    renderDatePicker(updateOrder) {
        return (<DateRangePicker
            onHide={(event, picker) => this.handleDateEvent(picker, updateOrder, event)}
            alwaysShowCalendars={true}
            initialSettings={{ singleDatePicker: true, autoApply: true, startDate: this.startDate, minDate: new Date(), autoUpdateInput: false }}
            locale={{
                'format': 'DD/MM/YYYY'
            }}

        ><button className="edit-date-button"><FontAwesomeIcon className="text-primary" icon={faEdit} /></button>
        </DateRangePicker>)
    }


    onChangeQuantityForAccept(event, item, index) {
        let order = this.state.order

        let value = +event?.target?.value
        if (!value && value != "") return

        if (item.customerOrderedQuantity < value) item.deliverableQuantity = item.customerOrderedQuantity
        else item.deliverableQuantity = value

        order.items[index] = item
        this.setState({ order })
    }

    showAcceptModal() {
        return (

            <Modal className="app-confirm-custom-modal" isOpen={true} toggle={this.handleClose}>
                <ModalBody className="" >
                    <div>
                        <img className="icon-20 close-icn cursor-pointer" alt="" src={getImageSource(ImageConstants.CLOSE_GRAY)} onClick={this.handleClose} />
                    </div>
                    <div className="app-confirm mt-5">
                        <div className="content-wrapper py-4">
                            <div className="text-center">
                                <img className="abs-img icon-50 animate-image-1" alt="" src={getImageSource(ImageConstants.CALENDAR_WHITE)} />
                            </div>
                            <div className="text-center pt-2 px-4">
                                <div className="date">{this.state.staticText?.deliveryDateText}</div>
                                <div className="header-text cursor-pointer">
                                    {Utils.datePipe(this.state.order?.deliveryDate, Constants.DATE_FORMAT_1)}
                                    {this.renderDatePicker()}
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <div className="row m-0 cursor-pointer">
                        <div className="col text-center border-right p-3" onClick={this.handleClose}>
                            {this.state.staticText?.cencelText}
                        </div>
                        <div className={`col text-center p-3 ${this.props.okButtonStyle || "text-primary"}`} onClick={this.onConfirmAccept}>
                            {this.state.staticText?.confirmText}
                        </div>
                    </div>

                </ModalFooter>

            </Modal>
        )
    }

    checkUpdateButtonStatus() {
        let items = this.state.order?.items || []

        for (let i = 0; i < items.length; i++) {
            let item = items[i]

            let qty = (+item.deliveredQuantity || 0) + (item.deliverableQuantity)

            if (qty != item.orderedQuantity) {
                return true
            }

        }
        return false
    }

    onOrderConfirm = () => {
        this.setState({ showAcceptModal: false })
        this.getOrders()
    }

    showOrderUpdateModal = () => {
        if (CommonUtils.checkTrialAccess(ActionConstant.UPDATE_ORDER)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ showUpdateModal: !this.state.showUpdateModal })
        }
    }
    getUpdateActionButtons() {
        return (
            <div>
                {
                    this.checkUpdateButtonStatus() ?

                        <button className="app-button-secondary partial-button mr-2 float-right" onClick={() => this.onChangeOrderStatus(Constants.ORDER_STATUS.PARTIAL)}>
                            <span >{this.state.staticText?.updatePartialText}</span>
                        </button>
                        :
                        <button className="app-button-primary float-right" onClick={() => this.onChangeOrderStatus(Constants.ORDER_STATUS.COMPLETED)}>
                            <FontAwesomeIcon icon={faCheck} />
                            <span className="pl-2">{this.state.staticText?.completeText}</span>
                        </button>
                }

                <button className="app-button-secondary float-right mr-2" onClick={this.showOrderUpdateModal}>
                    <span className="pl-2">{this.state.staticText?.cencelText}</span>
                </button>

            </div>
        )
    }

    onClickRightArrow(item, index) {
        let order = this.state.order
        item.deliverableQuantity = item.orderedQuantity - (item.deliveredQuantity || 0)
        order.items[index] = item
        this.setState({ order })
    }

    onClickDoubleRightArrow() {
        let order = this.state.order
        order.items.forEach(element => {
            element.deliverableQuantity = element.orderedQuantity - (element.deliveredQuantity || 0)
        })
        this.setState({ order })
    }

    getActionButtons() {

        if (!CommonUtils.checkRights(ActionConstant.UPDATE_ORDER)) return ""

        if (this.state.order.status === Constants.ORDER_STATUS.NEW) {
            return (
                <div className="text-right">
                    <button className="app-button-red mr-2" onClick={this.showRejectConfirm}>
                        <FontAwesomeIcon icon={faTimes} />
                        <span className="pl-2"> {CommonUtils.checkRights(ActionConstant.REJECT_ORDER) ?
                            this.state.staticText?.rejectText : ""}</span>
                    </button>

                    <button className="app-button-primary" onClick={this.showAcceptOrderModal}>
                        <FontAwesomeIcon icon={faCheck} />
                        <span className="pl-2">{CommonUtils.checkRights(ActionConstant.ACCEPT_ORDER) ?
                            this.state.staticText?.acceptText : ""} </span>
                    </button>
                </div>
            )
        }

        if (!Utils.checkClosedStatus(this.state.order.status)) {
            return (
                <div className="button-wrappers">
                    {this.state.updateMessage ? <div div className="d-inline-block update-msg"> {this.state.updateMessage || `Shipping update completed and Invoice generated. #12312313`}</div> : ""}
                    <button className="app-button-secondary partial-button mr-2 float-right" onClick={this.showOrderUpdateModal}>
                        <span >{this.state.staticText?.updateOrderText || "Update Order"}</span>
                    </button>
                </div >
            )
        }
        if (this.state.updateMessage) {
            return (
                <div className="button-wrappers">
                    <div div className="d-inline-block update-msg"> {this.state.updateMessage || `Shipping update completed and Invoice generated. #12312313`}</div>
                </div>)
        }
    }


    renderPriceDetail() {
        return (
            <div>
                <div className="price-detail card">
                    <div className="text-center price-summary">{this.state.staticText?.priceSummary}</div>
                    <div className="py-4">
                        <div className="sub-text"> <span>{this.state.staticText?.priceText}</span> <span className="float-right">{this.state.order?.currency?.symbol} {Utils.formatAmountStringStrict(+this.state.order?.totalPrice)}</span></div>
                    </div>
                    <div className="grand-total pt-2">
                        <div>
                            <span className="semi-bold-text">{this.state.staticText?.grandTotalText}</span>
                            <span className="total-amount float-right">{this.state.order?.currency?.symbol} {Utils.formatAmountStringStrict(+this.state.order?.totalPrice)}</span>
                        </div>
                    </div>
                </div>
                {
                    this.state.order?.status != Constants.ORDER_STATUS.NEW && this.state.order?.status != Constants.ORDER_STATUS.REJECTED && this.state.order?.status != Constants.ORDER_STATUS.CLOSED_BY_VENDOR && this.state.order?.status != Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER && this.state.order?.status != Constants.ORDER_STATUS.PARTIAL_CLOSED ?
                        <div className="delivery-date">
                            <div className="delivery-date-title">{this.state.order?.status == Constants.ORDER_STATUS.COMPLETED ? this.state.staticText?.deliveredOnText : this.state.staticText?.deliveryDateText}</div>
                            <div className="date" key="edit-date-1" >
                                {Utils.datePipe(this.state.order?.deliveryDate, Constants.DATE_FORMAT_1)}
                                {(this.state.order?.status != Constants.ORDER_STATUS.COMPLETED) && CommonUtils.checkRights(ActionConstant.UPDATE_ORDER) ? this.renderDatePicker(true) : ""}</div>
                        </div>
                        : ""
                }
                {
                    !Utils.checkClosedStatus(this.state.order.status) && Constants.ORDER_STATUS.NEW != this.state.order.status ?
                        <div className="pt-4 p-2 text-center">
                            <button className="app-button-red mr-2 w-100 float-right" onClick={() => this.showCloseConfirm(Constants.ORDER_STATUS.PARTIAL_CLOSED)}>
                                <span >{CommonUtils.checkRights(ActionConstant.MARK_THE_ORDER_AS_CLOSED) ?
                                    this.state.staticText?.closeText : ""}</span>
                            </button>
                        </div>
                        : ""
                }
            </div>


        )

    }

    getStatus(value) {

        switch (value) {

            case "NEW":
            case "New": return "Waiting for confirmation from Vendor";
            default: return value;
        }

    }

    renderOrderDetail() {
        let status = this.state?.order?.status
        let address = this.state.order?.customerBranch?.address
        return (
            <div>
                <div className="order-detail-card card">
                    <div className="order-status-wrapper">
                        <div className="row d-flex justify-content-center">
                            <div className={`col ${status === Constants.ORDER_STATUS.COMPLETED ? "pr-0" : "pr-0"}`}>
                                <div className={`order-status py-2 ${this.getStatusBackgoundStyle()}`}>
                                    <div className="mr-2 pl-4">
                                        <img className="icon-20" alt="" src={getImageSource(this.getImageByStatus())} />
                                    </div>
                                    <div>
                                        <div className="status">{Constants.VENDOR_ORDER_STATUS_TEXT[this.state?.order?.status || "New"]}</div>
                                        <div className="date">Order Created Date: {Utils.datePipe(this.state?.order?.createdDate, Constants.DATE_FORMAT_1)}</div>
                                    </div>
                                </div>
                            </div>
                            {
                                !Utils.checkClosedStatus(this.state?.order?.status) || this.state?.order?.status === Constants.ORDER_STATUS.COMPLETED ?
                                    <div className={`col-8 pl-0`}>
                                        <div className={`order-note py-2  ${this.getNoteBackgoundStyle()}`}>
                                            <div className="note pl-4">Buyer Note: </div>
                                            <div className="note-sub-text px-2">{this.state?.order?.note || "NA"}</div>
                                        </div>
                                    </div> : ""
                            }
                            {
                                status == Constants.ORDER_STATUS.CLOSED_BY_VENDOR || status == Constants.ORDER_STATUS.REJECTED || status == Constants.ORDER_STATUS.PARTIAL_CLOSED || status == Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER ?
                                    <div className={`col-8 pl-0`}>
                                        <div className={`order-note py-2  ${this.getNoteBackgoundStyle()}`}>
                                            <div className="note pl-4">Reason: </div>
                                            <div className="note-sub-text px-2">{this.state?.order?.reason || "-"}</div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>

                    <div className="ordered-product pt-4">
                        <div className="d-flex align-items-center "><span className="semi-bold-text mr-2 item"> Items </span><span className="count-badge px-2 py-1">{this.state.order?.items?.length < 10 ? "0" : ""}{this.state.order?.items?.length || 0}</span></div>
                        <div id="table-scoll" className="table-responsive pt-2">
                            <table className="table mb-0 align-items-center bg-white">
                                <thead>
                                    <tr>
                                        <th className='icon-40'></th>
                                        <th className='th-product-max-width'>Item</th>
                                        <th className='th-max-width text-right'>Brand</th>
                                        <th className='th-max-width text-right'>Unit Price</th>
                                        <th className='th-max-width text-right'>Order Qty</th>
                                        {/* {this.state.order?.status != Constants.ORDER_STATUS?.NEW ? <th className='th-max-width text-right'></th> : ""} */}
                                        {this.state.order?.status != Constants.ORDER_STATUS?.NEW ? <th className='th-max-width text-center'>Already Shipped</th> : ""}
                                        {this.state.order?.status != Constants.ORDER_STATUS?.NEW ? <th className='th-max-width text-center'>Status</th> : ""}
                                        {this.state.order?.status == Constants.ORDER_STATUS?.NEW ? <th className='th-max-width text-right'>Qty That Can Be Shipped</th> : ""}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.order?.items?.length ? this.state.order?.items.map((item, index) => (

                                            <tr key={item.code} >
                                                <td className=""><img alt="" className="icon-40" src={item?.images ? item?.images[0]?.url || getImageSource(ImageConstants.DEFAULT_PRODUCT) : getImageSource(ImageConstants.DEFAULT_PRODUCT)} /></td>
                                                <td className="cursor-pointer">
                                                    <div className={`bold-text`}>{item.name}</div>
                                                    <div className="sub-text"> {item.code}</div>
                                                    <div className="sub-text">Qty: {item.sellQuantity?.value} </div>
                                                </td>
                                                <td className="cursor-pointer sub-text text-right">{item.brand?.value}</td>
                                                <td className="cursor-pointer bold-text text-right"> {item?.currency?.symbol} {Utils.formatAmountStringStrict(+item?.price)} </td>
                                                {this.state.order?.status == Constants.ORDER_STATUS?.NEW || this.state.order?.status == Constants.ORDER_STATUS?.REJECTED ? <td className="cursor-pointer text-right semi-bold-text"> {item.customerOrderedQuantity}</td> : ""}
                                                {this.state.order?.status != Constants.ORDER_STATUS?.NEW && this.state.order?.status != Constants.ORDER_STATUS?.REJECTED ? <td className="cursor-pointer text-right semi-bold-text"> {item.orderedQuantity}</td> : ""}
                                                {this.state.order?.status != Constants.ORDER_STATUS?.NEW ? <td className="cursor-pointer text-center semi-bold-text"> {item.deliveredQuantity || 0}</td> : ""}
                                                {this.state.order?.status != Constants.ORDER_STATUS?.NEW ? <td className={`item-status text-center ${this.getItemStatus(item)}`}> {this.getDeliverdStatus(item)} </td> : ""}
                                                {
                                                    this.state.order?.status == Constants.ORDER_STATUS?.NEW ? <td className="cursor-pointer text-right position-relative">
                                                        <span className="deliverable-input-wrapper">
                                                            <input className=""
                                                                onChange={(e) => this.onChangeQuantityForAccept(e, item, index)}
                                                                value={item.deliverableQuantity} /> </span>
                                                    </td> : ""
                                                }
                                            </tr>
                                        ))
                                            : <tr><td colSpan="10" align='center' className='text-gray'>No Records found !</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="pt-4">{this.getActionButtons()}</div>
                </div>

                <div className="other-detail card">
                    <div className="other-detail-header">{this.state.staticText?.orderDetailText}</div>
                    <div className="row no-gutters">
                        <div className="col pt-4">
                            <div className="d-inline-block mr-4">
                                <div className="d-flex justify-content-center">
                                    <div className="mr-2"> <img className="icon-50" alt="" src={getImageSource(ImageConstants.PHONE)} /></div>
                                    <div>
                                        <div className="sub-text">{this.state.staticText?.mobileText}</div>
                                        <div className="flex-content">{this.state.order?.customerBranch?.countryCode} {this.state.order?.customerBranch?.mobileNumber}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-inline-block mr-4">
                                <div className="d-flex justify-content-center">
                                    <div className="mr-2"> <img className="icon-50" alt="" src={getImageSource(ImageConstants.DELIVERY)} /></div>
                                    <div>
                                        <div className="sub-text">{this.state.staticText?.typeText}</div>
                                        <div className="flex-content">{this.state.order?.deliveryType?.title}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-4  pt-4">
                            <div>
                                <div className="sub-text">{this.state.staticText?.customerAddressText || "ADDRESS"}</div>
                                {address?.addressLine1 ? <div className="flex-content">{address?.addressLine1},</div> : ""}
                                {address?.addressLine1 ? <div className="flex-content">{address.addressLine2},</div> : ""}
                                <div className="flex-content">{address.city}, {address.state}, {address.country} - {address.code}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        )
    }


    renderTableRows() {
        let arr = []

        if (!this.state.order?.items?.length) return <tr><td colSpan="10" align='center' className='text-gray'>No Records found !</td></tr>

        for (let i = 0; i < this.state.order?.items?.length || 0; i++) {

            let item = this.state.order?.items[i]
            let index = i

            if ((item.orderedQuantity - (item.deliveredQuantity || 0)) == 0) continue

            arr.push(<tr key={item.code} >
                <td className=""><img alt="" className="icon-40" src={item?.images ? item?.images[0]?.url || getImageSource(ImageConstants.DEFAULT_PRODUCT) : getImageSource(ImageConstants.DEFAULT_PRODUCT)} /></td>
                <td className="cursor-pointer">
                    <div className={`bold-text`}>{item.name}</div>
                    <div className="sub-text"> {item.code}</div>
                    <div className="sub-text">Qty: {item.sellQuantity?.value} </div>
                </td>
                <td className="cursor-pointer sub-text text-right">{item.brand?.value}</td>
                {this.state.order?.status == Constants.ORDER_STATUS?.NEW || this.state.order?.status == Constants.ORDER_STATUS?.REJECTED ? <td className="cursor-pointer text-right semi-bold-text"> {item.customerOrderedQuantity}</td> : ""}
                {this.state.order?.status != Constants.ORDER_STATUS?.NEW && this.state.order?.status != Constants.ORDER_STATUS?.REJECTED ? <td className="cursor-pointer text-right semi-bold-text"> {item.orderedQuantity - (item.deliveredQuantity || 0)}</td> : ""}
                {this.state.order?.status != Constants.ORDER_STATUS?.NEW ? <td className="cursor-pointer text-right semi-bold-text"> <span className="p-2" onClick={() => this.onClickRightArrow(item, index)} > <FontAwesomeIcon className="cursor-pointer" icon={faAngleRight} /> </span></td> : ""}
                {this.state.order?.status != Constants.ORDER_STATUS?.NEW ? <td className="cursor-pointer text-right position-relative">
                    <div className="">
                        <span className={`deliverable-input-wrapper`}>
                            <input className=""
                                onChange={(e) => this.onChangeQuantity(e, item, index)}
                                value={item.deliverableQuantity || 0} disabled={this.checkInputDisable(item)} />

                        </span>
                    </div>

                </td> : ""}

            </tr>
            )
        }

        return arr
    }

    renderUpdateModal() {
        return (
            <Modal className="order-update-custom-modal" isOpen={true} toggle={this.handleClose} size="lg">
                <ModalHeader toggle={this.handleClose}><div className="row">
                    <div className="col-5"><span>Update Order #{this.state?.order?.orderNumber}</span></div>
                    <div className="col "> <span className="text-center">{this.state?.order?.customerBranch?.name}</span> </div>
                </div>
                </ModalHeader>
                <ModalBody className="" >
                    <div className="order-detail">
                        <div className="ordered-product pt-4">
                            <div className="d-flex align-items-center "><span className="semi-bold-text mr-2 item"> Items </span><span className="count-badge px-2 py-1">{this.state.order?.items?.length < 10 ? "0" : ""}{this.state.order?.items?.length || 0}</span></div>
                            <div id="table-scoll" className="table-responsive pt-2">
                                <table className="table mb-0 align-items-center bg-white">
                                    <thead>
                                        <tr>
                                            <th className='icon-40'></th>
                                            <th className='th-product-max-width'>Item</th>
                                            <th className='th-max-width text-right'>Brand</th>
                                            {/* <th className='th-max-width text-right'>Unit Price</th> */}
                                            <th className='th-max-width text-right'>Open Qty</th>
                                            <th className='th-max-width text-right'><span className="p-2" onClick={() => this.onClickDoubleRightArrow()} > <FontAwesomeIcon className="cursor-pointer" icon={faAngleDoubleRight} /></span> </th>
                                            <th className='th-max-width text-right'>Current Shipment</th>
                                            {/* {this.state.order?.status != Constants.ORDER_STATUS?.NEW ? <th className='th-max-width text-center'>Status</th> : ""} */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderTableRows()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="pt-4">{this.getUpdateActionButtons()}</div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    render() {

        return (
            <div className="order-detail px-5">
                {this.state.isLoading && <Loading />}
                <div className="position-relative">
                    <div className="d-inline-block back-icon" onClick={this.props.back}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="header-text"> {this.state?.order?.orderNumber}</div>
                    <div className="sub-text"> Customer Name: {this.state?.order?.customerBranch?.name}</div>
                </div>
                <div className="order-detail-flex mt-4">
                    <div className="flex-1"> {this.renderOrderDetail()} </div>
                    <div className="flex-2"> {this.renderPriceDetail()}</div>
                </div>
                {
                    this.state.showRejectConfirm ? <AppConfirmModal isCaptureInput img={getImageSource(ImageConstants.CLOSE_RED)} okButtonText={this.state.staticText?.rejectText} okButtonStyle="reject-text" title={this.state.staticText?.rejectTitle} close={this.handleClose} onOk={this.onReject} /> : ""
                }
                {
                    this.state.showCloseConfirm ? <AppConfirmModal isCaptureInput img={getImageSource(ImageConstants.CLOSE_RED)} okButtonText={this.state.staticText?.closeAlertButtonText} okButtonStyle="reject-text" title={this.state.staticText?.closeAlertText} close={this.handleClose} onOk={this.onCloseOrder} /> : ""
                }
                {this.state.showAcceptModal ? this.showAcceptModal() : ""}
                {this.state.showUpdateModal ? this.renderUpdateModal() : ""}
            </div >
        );
    }

}

export default OrderDetail;