import React, { Component } from 'react';
import * as XLSX from "xlsx";
import './excel-to-json.scss';

class ExcelToJson extends Component {
    state = {}

    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this);
        this.fileUploaderRef = React.createRef();   //INV000001 Replacing deprecated function this.refs
    }

    handleClick(e) {
        //this.refs.fileUploader.click(); //INV000001 Replacing deprecated function this.refs
        if (this.fileUploaderRef.current) {             //INV000001 Replacing deprecated function this.refs
            this.fileUploaderRef.current.click();       //INV000001 Replacing deprecated function this.refs
          }                                             //INV000001 Replacing deprecated function this.refs

    }

    filePathset(e) {
        e.stopPropagation();
        e.preventDefault();
        let file = e.target.files[0];
        this.setState({ file }, () => {
            this.readFile()
        });
    }

    readFile() {
        let f = this.state.file;
        if (!f) {
            return
        }
        let name = f.name;
        const reader = new FileReader();

        reader.onload = (evt) => {
            try {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws, {
                    header: 1,
                    blankrows: false,
                    raw: false
                });
                let json = this.convertToJson(data)
                if (this.props.onSuccess) {
                    this.props.onSuccess({ data: json, fileName: name })
                }
            } catch (e) {
                if (this.props.onFailure) {
                    this.props.onSuccess({ error: { message: "Something went wrong" }, err: e })
                }
            }
        };

        reader.readAsBinaryString(f);
    }

    convertToJson(arrayOfArrays) {
        let result = []
        const headerIndex = 0
        const headers = arrayOfArrays[headerIndex]
        for (let i = headerIndex + 1; i < arrayOfArrays.length; i++) {
            const rowObject = {}
            headers.forEach((key, index) => rowObject[key] = arrayOfArrays[i][index]?.toString())
            result = [...result, rowObject]
        }
        return result
    }



    render() {
        //return (<input className="excel-to-json" accept=".xlsx,.csv,.xlx" type="file" id="file" ref="fileUploader" onClick={() => this.handleClick()} onChange={this.filePathset.bind(this)} />)  //INV000001 Replacing deprecated function this.refs
        return (<input className="excel-to-json" accept=".xlsx,.csv,.xlx" type="file" id="file" ref="this.fileUploaderRef" onClick={() => this.handleClick()} onChange={this.filePathset.bind(this)} />)    //INV000001 Replacing deprecated function this.refs
    }
}

export default ExcelToJson;