import HttpClient from './http-client';
import URL from './url';

export default class Roles {

    static getRoles(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.ROLES + "?" + params.join('&') : URL.ROLES;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

}
