const INVOICE_STATUS = {
    NEW: "NEW",
    PAST_DUE: "PAST_DUE",
    PENDING_PAYMENT: "PENDING_PAYMENT",
    PARTIALLY_PAID: "PARTIALLY_PAID",
    FULLY_PAID: "FULLY_PAID",
}
const INVOICE_STATUS_TEXT = {
    NEW: "New",
    PAST_DUE: "Past Due",
    PENDING_PAYMENT: "Pending",
    PARTIALLY_PAID: "Partially Paid",
    FULLY_PAID: "Completed",
}
const Constants = {
    INPUT_MAX_LENGTH: 500,
    INPUT_MAX_LENGTH_1: 250,
    INPUT_MAX_LENGTH_2: 12,
    INPUT_MAX_LENGTH_3: 4,
    INPUT_MAX_LENGTH_4: 7,
    PASSWORD_MIN_LENGTH: 8,

    /* CHG000001 Pramod added Dev config */
    CAPTCHA_KEY: "6LcoTFAgAAAAAO6yedOPe-06LOV2w7_VXgh7h44D",
    DEV_CAPTCHA_KEY: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",    /* CHG000001 DEV Config*/
    GOOGLE_LOGIN_KEY: "117794212481-qmif0v6v3jt993voq98mcod19msutulu.apps.googleusercontent.com",
    DEV_GOOGLE_LOGIN_KEY: "880484759908-kb3g9q7hviu1fkp4jbd02ak68tmbg61l.apps.googleusercontent.com", /* CHG000001 DEV Config*/
    FB_LOGIN_KEY: "741400710348161",
    DEV_FB_LOGIN_KEY: "920039488671071",        /* CHG000001 DEV Config*/


    PRODUCT_UPLOAD_FORMAT: "https://mybizsquare-assets-prod.s3.us-west-2.amazonaws.com/Product_Import_Template.xlsx",
    INVENTORY_UPLOAD_FORMAT: "https://mybizsquare-assets-prod.s3.us-west-2.amazonaws.com/Inventory_Import_Template.xlsx",   //INV000001 

    PRODUCTS: "products",
    PROFILE: "profile",

    ORDER_STATUS: {
        NEW: "NEW",
        PAST_DUE: "PAST_DUE",
        PENDING: "PENDING_FULFILLMENT",
        PARTIAL: "PARTIAL_FULFILLMENT",
        COMPLETED: "ORDER_FULLFILLED",
        REJECTED: "ORDER_REJECTED",
        PARTIAL_CLOSED: "PARTIAL_CLOSED",
        CLOSED_BY_CUSTOMER: "CLOSED_BY_CUSTOMER",
        CLOSED_BY_VENDOR: "CLOSED_BY_VENDOR",
    },

    ORDER_STATUS_TEXT: {
        NEW: "Waiting for confirmation from vendor",
        PAST_DUE: "Past Due",
        PENDING_FULFILLMENT: "Pending Shipment",
        PARTIAL_FULFILLMENT: "Partially Shipped",
        ORDER_FULLFILLED: "Completely Shipped",
        ORDER_REJECTED: "Rejected",
        PARTIAL_CLOSED: "Closed",
        CLOSED_BY_CUSTOMER: "Cancelled",
        CLOSED_BY_VENDOR: "Closed by Vendor",
    },

    VENDOR_ORDER_STATUS_TEXT: {
        NEW: "New Order",
        PAST_DUE: "Past Due",
        PENDING_FULFILLMENT: "Pending Shipment",
        PARTIAL_FULFILLMENT: "Partially Shipped",
        ORDER_FULLFILLED: "Completely Shipped",
        ORDER_REJECTED: "Rejected",
        PARTIAL_CLOSED: "Closed",
        CLOSED_BY_CUSTOMER: "Cancelled",
        CLOSED_BY_VENDOR: "Closed by Vendor",
    },

    PRODUCT_STATUS: {
        ACTIVE: "ACTIVE",
        INACTIVE: "INACTIVE",
    },

    /* INV000001 BEGIN */

    INVENTORY_PRODUCT_STATUS: {
        ACTIVE: "ACTIVE",
        INACTIVE: "INACTIVE",
    },

    /* INV000001 END */ 
    
    INVOICE_STATUS: INVOICE_STATUS,

    INVOICE_STATUS_TEXT: INVOICE_STATUS_TEXT,

    PAYMENT_STATUS: {
        SUCCESS: "SUCCESS",
        FAILED: "FAILED"
    },

    PAYMENT_STATUS_TEXT: {
        SUCCESS: "Paid",
        FAILED: "Payment Failed"
    },

    USER_TYPE: {
        BOTH: "BOTH",
        VENDOR: "VENDOR",
        CUSTOMER: "CUSTOMER",
    },

    LOGIN_AS: {
        TENANT: "TENANT",
        STAFF: "STAFF"
    },

    DATE_FORMAT_1: "MMMM dd, yyyy",

    INVITATION_STATUS: {
        "INVITE": "INVITE",
        "INVITED": "INVITED",
        "INVITE_RECEIVED": "INVITE_RECEIVED"
    },

    SUB_HEADER_EXCLUDE_ROUTES: {
        "/cart": true,
        "/payment/response": true,
    },

    ORDER_STATUS_FILTER: [
        {
            title: "Waiting for confirmation from vendor",
            value: "NEW",
            active: true
        },
        {
            title: "Pending Shipment",
            value: "PENDING_FULFILLMENT",
            active: true
        },
        {
            title: "Past Due",
            value: "PAST_DUE",
            active: true
        },
        {
            title: "Partially Shipped",
            value: "PARTIAL_FULFILLMENT",
            active: true
        },
        {
            title: "Partial Closed",
            value: "PARTIAL_CLOSED"
        },
        {
            title: "Completely Shipped",
            value: "ORDER_FULLFILLED"
        },
        {
            title: "Rejected",
            value: "ORDER_REJECTED"
        },
        {
            title: "Closed by Customer",
            value: "CLOSED_BY_CUSTOMER"
        },
        {
            title: "Closed by Vendor",
            value: "CLOSED_BY_VENDOR"
        }
    ],

    INVOICE_STATUS_FILTER: [
        {
            value: INVOICE_STATUS.NEW,
            title: INVOICE_STATUS_TEXT.NEW,
            active: true
        },
        {
            value: INVOICE_STATUS.PENDING_PAYMENT,
            title: INVOICE_STATUS_TEXT.PENDING_PAYMENT,
            active: true
        },
        {
            value: INVOICE_STATUS.PAST_DUE,
            title: INVOICE_STATUS_TEXT.PAST_DUE,
            active: true
        },
        {
            value: INVOICE_STATUS.PARTIALLY_PAID,
            title: INVOICE_STATUS_TEXT.PARTIALLY_PAID,
            active: true

        },
        {
            value: INVOICE_STATUS.FULLY_PAID,
            title: INVOICE_STATUS_TEXT.FULLY_PAID,
        }
    ],

    BuyerPricingType: {
        DISCOUNT: "DISCOUNT",
        MARKUP: "MARKUP"
    },
    DiscountType: {
        PERCENT: "PERCENT",
        FLAT: "FLAT"
    }
};


export const ImageConstants = {
    FACEBOOK: "facebook",
    USER: "user",
    ADD_USER_IN_ACTIVE: "add-user-inactive",
    ADD_VENDOR_IN_ACTIVE: "add-vendor-inactive",
    DOWN_ARROW: "down-arrow",
    HOME_ACTIVE: "home-active",
    VENDOR: "vendor",
    ADD_USER_ACTIVE: "add-user-active",
    ADD_VENDOR_ACTIVE: "add-vendor-active",
    NO_STAFF: "no-staff",
    NO_VENDOR: "no-vendor",
    TICK_MARK: "tick-mark",
    GREEN_TICK_FILLED: "green-tick-filled",
    GREEN_TICK: "green-tick",
    EYE_OPEN: "eye-open",
    EYE_CLOSE: "eye-close",
}

export default Constants
