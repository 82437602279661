import React from 'react';
import AuthenticateAPI from '../../apis/authenticate';
import { servicesAgreement } from './const';
import './style.scss'

class ServicesAgreement extends React.Component {
   state = {
      loading: true
   }

   componentDidMount() {
      const appMainClass = document.getElementById('app-main-class')
      appMainClass.className = ""
      this.getServicesAgreement()
   }
   componentWillUnmount() {
      const appMainClass = document.getElementById('app-main-class')
      appMainClass.className = "app-main-class"
   }

   setServicesAgreement(content) {
      document.getElementById('services-agreement-content').innerHTML = content
      this.setState({ loading: false })
   }

   getServicesAgreement() {
      AuthenticateAPI.getStaticText({ type: "SERVICES_AGREEMENT" }).then((data) => {
         const response = data.response || []
         this.setServicesAgreement(response[0]?.htmlContent || servicesAgreement)
      }).catch((err) => {
         console.log(err)
      })
   }

   render() {

      return (
         <div>
            <div className='row' style={{ margin: 10 }}>
               <div className='col-md-1 col-sm-0'></div>
               <div className='col-md-10 col-sm-12 mt-4'>
                  {this.state.loading && <p className='text-center'>Loading...</p>}
                  <div id="services-agreement-content"></div>
               </div>
            </div>
         </div>
      )
   }
}

export default ServicesAgreement
