import React from 'react';
import "./customer-dashboard.scss";
import StaticText from '../../shared-store/static-text-store';
import SalesCharts from './sales-chart/sales-chart';
import Invitations from './invitations/invitations';
import TopCustomers from './top-vendors/top-vendors';
import TopSales from './top-product/top-product';
import Header from './header/header';

import SessionCache from '../routes/session-cache';
import ActionConstant from '../../constants/action-constant';
import CommonUtils from '../../utility/common-utilities';

class Dashboard extends React.Component {
    state = {
        staticText: {},
        activeStatus: "WEEK"
    }

    accessRights = {}
    componentDidMount() {
        this.user = SessionCache.getUser()
        this.accessRights = this.user.accessRights || {}

        let staticText = StaticText.getStaticText("dashboard")
        this.setState({ staticText })
    }

    statusToggle(activeStatus) {
        this.setState({ activeStatus, reload: true }, () => {
            this.setState({ reload: false })
        })
    }

    render() {

        if (!CommonUtils.checkRights(ActionConstant.VIEW_DASHBOARD)) return ""
        return (
            <div className="customer-dashboard">
                <div className="">
                    <div className='row mx-0'>
                        <div className="col-3 px-0 mx-0">
                            <strong className="active-title"> {this.state.staticText?.title}</strong>
                        </div>

                        <div className="col text-right">
                            <div className="d-inline-block">
                                <div className='status-switch cursor-pointer row no-gutters '>
                                    <div className={`col status-switch-toggle ${this.state.activeStatus == 'WEEK' ? 'status-switch-toggle-active' : ""}`} onClick={() => this.statusToggle("WEEK")}>{this.state.staticText?.weekText}</div>
                                    <div className={`col status-switch-toggle ${this.state.activeStatus == 'MONTH' ? 'status-switch-toggle-active' : ""}`} onClick={() => this.statusToggle("MONTH")}>{this.state.staticText?.monthText}</div>
                                    <div className={`col status-switch-toggle ${this.state.activeStatus == 'YEAR' ? 'status-switch-toggle-active' : ""}`} onClick={() => this.statusToggle("YEAR")}>{this.state.staticText?.yearText}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        {!this.state.reload ? <Header type={this.state.activeStatus} /> : ""}
                    </div>

                    <div className="d-flex pt-4">
                        <div className="flex-1 mr-2">
                            <SalesCharts type={this.state.activeStatus} />
                        </div>
                        <div className="flex-2">
                            <Invitations {...this.props} />
                        </div>

                    </div>

                    <div className="d-flex pt-4">
                        <div className="flex-3 mr-2">
                            {!this.state.reload ? <TopCustomers type={this.state.activeStatus} /> : ""}
                        </div>
                        <div className="flex-3">
                            {!this.state.reload ? <TopSales type={this.state.activeStatus} history={this.props.history} /> : ""}
                        </div>

                    </div>

                </div>
            </div >
        );
    }

}

export default Dashboard;