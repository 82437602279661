import axios from "axios";


const METHOD = {
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE',
    POST: 'POST'
};

const APPLICATION_JSON = 'application/json';

const httpClient = {

    call: (url, parameters) => {
        const finalUrl = `${url}`;
        const options = {
            ...parameters,
            url: finalUrl
        };

        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            // if (401 === error.response.status && error.config && !error.config.__isRetryRequest) {
            //     error.config.__isRetryRequest = true;
            //     window.location = '/login';
            //     return Promise.reject(
            //         error
            //     );
            // } else {
            return Promise.reject(error);
            // }
        });

        return axios(options);
    },

    parameters: (cookie, method = METHOD.GET, body = {}) => {

        const withBody = [METHOD.PUT, METHOD.POST, METHOD.DELETE];

        if (!cookie)
            cookie = "";

        const params = {
            method,
            headers: {
                'content-type': APPLICATION_JSON,
                //'token': cookie,
                //'Access-Control-Allow-Origin': '*',
                //'authorization': cookie,
                'X-Requested-With': 'XMLHttpRequest'
            }
        };

        if (withBody.indexOf(method) !== -1) {
            params.data = JSON.stringify(body);
        }

        return params;
    },

    get: (url, cookie) => {
        return httpClient.call(url, httpClient.parameters(cookie), METHOD.GET).then(response => {
            // returning the data here allows the caller to get it through another .then(...)
            return response.data
                ;
        });
    },

    post: (url, cookie, body = {}) => {
        return httpClient.call(url, httpClient.parameters(cookie, METHOD.POST, body));
    },

    put: (url, cookie, body = {}) => {
        return httpClient.call(url, httpClient.parameters(cookie, METHOD.PUT, body));
    },

    delete: (url, cookie, body = {}) => {
        return httpClient.call(url, httpClient.parameters(cookie, METHOD.DELETE, body));
    }

};

export default httpClient;