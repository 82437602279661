import React from 'react';
import "./orders.scss";
import orderApi from '../../apis/order'
import SessionCache from '../routes/session-cache';
import Calender from '../calendar/calendar'

import ImageConstants from '../../constants/image-constants';
import { getImageSource } from '../../utility/image-source'
import Utils from '../../utility/common-utilities'
import Constants from '../../constants/constants';
import OrderDetail from './order-detail/order-detail'
import Loading from '../common/loading/loading';
import StaticText from '../../shared-store/static-text-store';
import Path from '../routes/routes-path';
import ActionConstant from '../../constants/action-constant';
import CommonUtils from '../../utility/common-utilities';
import SharedStore from '../../shared-store/shared-store'
import OrderConst from './const';
import ImportExport from './import-export/import-export';

class Order extends React.Component {
    state = {
        orders: [],
        staticText: {},
        status: OrderConst.tabFilters[OrderConst.tabs[1]].map(item => ({ ...item })),
        selectedTab: OrderConst.tabs[1]
    }

    accessRights = {}
    page = 1
    totalPages = 1
    limit = 10

    componentDidMount() {
        let user = SessionCache.getUser()

        this.accessRights = user?.accessRights || {}
        this.setState({ type: user?.type }, () => {
            this.getOrders()
        })

        let staticText = StaticText.getStaticText("orders")
        this.setState({ staticText })

        document.addEventListener('mousedown', this.handleClickEvent, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }

    handleClickEvent = (event) => {
        if (this.filterRef && !this.filterRef.contains(event.target)) {
            this.setState({ showFilters: false })
        }
        if (this.headerRef && !this.headerRef.contains(event.target)) {
            this.setState({ showProductExpandView: false })
        }
        if (this.tabDropDownStatusRef && !this.tabDropDownStatusRef.contains(event.target)) {
            this.setState({ showDropDownTabStatus: false })
        }

    }


    getOrders(payload = {}, isLoadMore) {
        payload.isVendor = true

        payload.page = this.page
        payload.limit = this.limit

        let filters = this.state.status.filter(item => item.active)

        if (this.state.searchText && this.state.searchText.length > 3) {
            payload.orderNumber = this.state.searchText
        }
        
        payload.timezone = Utils.getTimeZoneString();
        payload.dts = new Date().setHours(0,0,0,0);
        payload.dte = new Date().setHours(23,59,59,999);

        if (filters.length) {
            payload.status = filters.map(item => item.value).join(',')
        }

        this.setState({ isLoading: true })

        orderApi.getOrders(payload).then((response) => {

            this.totalPages = response?.totalPages || 0;

            this.prepareOrders(response.response || [], isLoadMore)
            this.setState({ isLoading: false })

        }).catch((err) => {
            this.setState({ isLoading: false })

        })
    }

    prepareOrders(orders, isLoadMore) {
        let oldOrders = isLoadMore ? this.state.orders : []
        orders.forEach(element => {
            if (element.status == Constants.ORDER_STATUS.NEW) element.deliveryDate = new Date()
            if (element.items) return
            element.items.forEach(item => {
                item.deliverableQuantity = (item.orderedQuantity - (item.deliveredQuantity || 0)) || ""
            })
        });

        this.setState({ orders: [...oldOrders, ...orders] })

    }

    onClickMenu = (item) => {
        this.props.history.push(item.path)
    }

    onCreateStaff = () => {
        this.setState({ showCreateModal: true })
    }

    showOrderDetail(item) {
        if (CommonUtils.checkTrialAccess(ActionConstant.VIEW_ORDER_DETAILS)) {
            let checkSubscription = SharedStore.getStore("subscription")
            checkSubscription.showSubscriptionModal()
        } else if (CommonUtils.checkRights(ActionConstant.VIEW_ORDER_DETAILS)) {
            this.setState({ selectedOrder: item, showOrderDetail: true })
        }
        else return ""

    }


    handleBack = () => {
        this.setState({ showOrderDetail: false }, () => {
            this.page = 1
            this.getOrders()
        })
    }

    getImageByStatus(item) {
        if (item.status == Constants.ORDER_STATUS.NEW) return ImageConstants.ORDER_NEW
        if (item.status == Constants.ORDER_STATUS.PAST_DUE) return ImageConstants.ORDER_PAST_DUE
        if (item.status == Constants.ORDER_STATUS.PENDING) return ImageConstants.ORDER_PENDING
        if (item.status == Constants.ORDER_STATUS.REJECTED || item.status == Constants.ORDER_STATUS.PARTIAL_CLOSED || item.status == Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER || item.status == Constants.ORDER_STATUS.CLOSED_BY_VENDOR) return ImageConstants.ORDER_REJECT
        if (item.status == Constants.ORDER_STATUS.COMPLETED || item.status == Constants.ORDER_STATUS.PARTIAL) return ImageConstants.ORDER_COMPLETE

        return ImageConstants.ORDER_NEW
    }

    getClassNameByStatus(status) {
        if (status === Constants.ORDER_STATUS.COMPLETED || status === Constants.ORDER_STATUS.REJECTED) return "tr-complete"
        return ""
    }

    getStatusBadge(status) {
        if (status == Constants.ORDER_STATUS.NEW) return "order-new"
        if (status == Constants.ORDER_STATUS.PAST_DUE) return "order-due"
        if (status == Constants.ORDER_STATUS.PENDING) return "order-pending"
        if (status == Constants.ORDER_STATUS.PARTIAL) return "order-partial"
        if (status == Constants.ORDER_STATUS.COMPLETED || status == Constants.ORDER_STATUS.REJECTED || status == Constants.ORDER_STATUS.PARTIAL_CLOSED || status == Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER || status == Constants.ORDER_STATUS.CLOSED_BY_VENDOR) return "order-complete"

        return ""
    }

    onClickLoadMore = () => {

        this.page += 1
        this.getOrders({}, true)
    }

    getFilterBadgeCount = () => {

        let status = this.state.status;
        let filters = status.filter(item => item.active)
        return filters.length
    }

    onClickApplyFilter = () => {
        this.page = 1
        this.setState({ showFilters: false })
        this.getOrders()
    }

    onClickStatus = (i) => {
        let status = this.state.status;
        status[i].active = !status[i].active
        this.setState({ status })
    }

    onClickFilter = () => {
        this.setState({ showFilters: true })
    }

    showProductExpand = () => {
        this.setState({ showProductExpandView: !this.state.showProductExpandView })
    }

    onClickHeaderNavigation = (item) => {

        this.setState({ showProductExpandView: false })

        if (item.isActive || !item.path) {
            return
        }

        this.props.history.push(item.path)
    }

    onSearch = (e) => {
        this.setState({ searchText: e.target.value }, () => {
            this.getOrders()
        })
    }

    renderFilterStatus = () => {

        let elements = []

        for (let i = 0; i < this.state.status.length; i++) {

            let item = this.state.status[i]
            elements.push(<div className='cursor-pointer mb-2' onClick={() => { this.onClickStatus(i) }}>
                <div className="d-flex align-items-center">
                    <img alt="" src={getImageSource(item.active ? ImageConstants.CHECKBOX_ACTIVE : ImageConstants.CHECKBOX_INACTIVE)} className='checkbox'></img>
                    <span className={`categories-name pl-3 ${item.active ? 'categories-name-active' : ""}`}>{item.title}</span>
                </div>
            </div>)
        }

        return elements

    }

    renderFilters = () => {

        return (
            <div className='filterpop-over app-box-shadow' ref={ref => this.filterRef = ref}>
                <div className="arrow-up"></div>
                <div className='filter-header'><span className="pr-2">{"FILTERS"}</span> {this.getFilterBadgeCount() ? <div className="badge-red position-absolute"><i>{this.getFilterBadgeCount()}</i></div> : ""}</div>

                <div className='row m-0'>

                    <div className='col filter-left'>
                        <div className='title-pop-over'>{"Order Status"}</div>
                        <div className="category-list mt-2">
                            {this.renderFilterStatus()}
                        </div>
                    </div>
                </div>

                <div className='row m-0 py-2 m-2'>
                    <div className='apply-button' onClick={this.onClickApplyFilter.bind(this)}>
                        {this.state.staticText?.applyText}
                    </div>
                </div>
            </div>)
    }

    handleBackToOrder = () => {
        this.setState({ showCalander: false })
    }

    showCalander = () => {
        this.setState({ showCalander: true })
    }

    onChangeTab = (selectedTab) => {
        this.setState({ selectedTab, status: OrderConst.tabFilters[selectedTab].map(item => ({ ...item })) }, this.onClickApplyFilter)
    }

    onChangeTabDropDownStatus = (status) => {
        this.onChangeTab(status)
        setTimeout(() => {
            this.setState({ showDropDownTabStatus: false })
        }, 100)
    }

    onClickImportExport = () => {

        // if (CommonUtils.checkTrialAccess(ActionConstant.IMPORT_PRODUCT)) {
        //     SharedStore.getStore("subscription").showSubscriptionModal()
        // } else {
        this.setState({ showImportExport: true })
        // }
    }

    closeImportExport = () => {
        this.setState({ showImportExport: false })
    }

    renderDropDownTabStatus = (values) => {
        return (
            <div className='drop-down-tab-pop-over app-box-shadow' ref={ref => this.tabDropDownStatusRef = ref}>
                <div className="arrow-up"></div>
                <div className='row m-0'>
                    <div className='col p-0'>
                        <div className="account-list">
                            {values.map(value => {
                                return <div className={`cursor-pointer account-title-wrapper border-bottom`} onClick={() => this.onChangeTabDropDownStatus(value)}>
                                    <div className={`d-flex align-items-center account-name ${this.state.selectedTab === value ? 'selected' : ''}`}>
                                        <span className={`px-4 text-left`}>{value}</span>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    render() {

        if (this.state.showOrderDetail) return <OrderDetail order={this.state.selectedOrder} back={this.handleBack} />

        if (this.state.showCalander) return <div className="orders"><Calender onClickBack={this.handleBackToOrder} /></div>

        return (
            <div className="orders">
                {this.state.isLoading && <Loading />}
                <div className="">
                    {/* <div className='row'>
                        <div className='col-12 d-flex justify-content-end mb-4'>
                            <div className='app-button-primary add-product-button ml-2' onClick={this.onClickImportExport}>{this.staticText?.importExportButtonTitle || 'Import / Export / Mass Update'}</div>
                        </div>
                    </div> */}
                    <div className='row'>
                        <div className="col">
                            <span className={`tab mr-4 ${this.state.selectedTab === OrderConst.tabs[1] ? 'active-tab' : ''}`} onClick={() => this.onChangeTab(OrderConst.tabs[1])}>{OrderConst.tabs[1]}</span>
                            <span className={`tab mr-4 ${this.state.selectedTab === OrderConst.tabs[2] ? 'active-tab' : ''}`} onClick={() => this.onChangeTab(OrderConst.tabs[2])}>{OrderConst.tabs[2]}</span>
                            <div className={`tab mr-4 tab-drop-down ${OrderConst.tabs[3].includes(this.state.selectedTab) ? 'active-tab' : ''}`} onClick={() => this.setState({ showDropDownTabStatus: true })}>
                                <div>
                                    {OrderConst.tabs[3].includes(this.state.selectedTab) ? this.state.selectedTab : OrderConst.tabDropDownText.pendingShipMent}
                                    <img className='down-arrow' alt='' src={getImageSource(ImageConstants.DOWN_ARROW)} />
                                </div>
                                {this.state.showDropDownTabStatus ? this.renderDropDownTabStatus(OrderConst.tabs[3]) : ""}
                            </div>
                            <span className={`tab mr-4 ${this.state.selectedTab === OrderConst.tabs[4] ? 'active-tab' : ''}`} onClick={() => this.onChangeTab(OrderConst.tabs[4])}>{OrderConst.tabs[4]}</span>
                            {/* <span className="tab" onClick={this.showCalander}>{this.state.staticText?.calanderHeaderText}</span> */}
                        </div>
                        <div className="col-8 tab-row-right">
                            <div className="d-inline-block filter-wrapper bg-white">
                                {this.getFilterBadgeCount() ? <div className="main-filter-badge position-absolute badge-red"><i>{this.getFilterBadgeCount()}</i></div> : ""}
                                <div onClick={() => this.onClickFilter()}>
                                    <img alt="" className="icon-20" src={getImageSource(ImageConstants.SLIDER)} />
                                    <span className="pl-2 filter-text">{this.state.staticText?.filterByText}</span>
                                </div>
                                {this.state.showFilters ? <div className='order-filters'>{this.renderFilters()}</div> : <></>}
                            </div>
                            <div className='d-inline-block' onClick={this.showCalander}>
                                <img alt="" title='Order Calendar' src={getImageSource(ImageConstants.CALENDAR_WHITE)} className='calendar-icon'></img>
                            </div>
                            <div className='d-inline-block'>
                                <div className='search-input-order'>
                                    <img alt="" src={getImageSource(ImageConstants.PRODUCT_LIST_SEARCH)} className='product-list-search ml-3'></img>
                                    <input className='search-input-input' value={this.state.searchText} placeholder={"Search order number..."} onChange={(e) => this.onSearch(e)} />
                                </div>
                            </div>
                            <div className='d-inline-block' onClick={this.onClickImportExport}>
                                <img alt="" title='Export Orders' src={getImageSource(ImageConstants.EXPORT_ICON)} className='export-icon'></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div id="table-scoll" className="table-responsive pt-2">
                        <table className="table mb-0 align-items-center bg-white">
                            <thead>
                                <tr>
                                    <th className=''>#Order
                                        {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                    </th>
                                    <th className=''>Buyer</th>
                                    <th className=''>Order Date
                                        {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                    </th>
                                    <th className=''>Delivery Date
                                        {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                    </th>
                                    <th className=''>Order status
                                        {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                    </th>
                                    <th className='text-right pr-4'>Order Total
                                        {/* <img alt="" className="ml-1 icon-8" src={getImageSource(ImageConstants.SORT)} /> */}
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.orders?.length ? this.state.orders.map((item, index) => (

                                        <tr key={item._id} className={`${this.getClassNameByStatus(item?.status)}`}>
                                            <td className="cursor-pointer" onClick={() => this.showOrderDetail(item)}>
                                                <img alt="" className="icon-40" src={getImageSource(this.getImageByStatus(item))} />
                                                <span className={`px-2 order-number ${item.status == Constants.ORDER_STATUS.NEW ? "text-primary" : ""}`}>{item.orderNumber}</span>
                                            </td>
                                            <td className=" semi-bold-text"> {item?.customerBranch?.name} </td>
                                            <td className=" created-date"> {Utils.datePipe(item.createdDate, Constants.DATE_FORMAT_1)} </td>
                                            <td className=" medium-text"> {item.status == Constants.ORDER_STATUS.NEW ? "New Order" : Utils.datePipe(item.deliveryDate, Constants.DATE_FORMAT_1) || ""} </td>
                                            <td className=""><span className={`position-relative order-status-badge ${this.getStatusBadge(item.status)}`}><div className="d-inline-block draw-dot ">&nbsp;</div> <span className="pl-2">{Constants.VENDOR_ORDER_STATUS_TEXT[item?.status || "New"]}</span></span> </td>
                                            <td className="text-right bold-text font-14 pr-4">{item?.currency?.symbol} {Utils.formatAmountStringStrict(item?.totalPrice || 0)} </td>
                                        </tr>
                                    ))
                                        : <tr><td colSpan="10" align='center' className='text-gray'>No Records found !</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                {
                    this.state.showImportExport ? <ImportExport isVendor={true} closeImportExport={() => this.closeImportExport()} showToaster={(type, message) => this.showToaster(type, message)} {...this.props} /> : "" //Prdocut Upload Dummy 
                }
                {this.totalPages && this.totalPages > 1 && this.page < this.totalPages ? <div className='text-center load-more cursor-pointer pt-4' onClick={() => this.onClickLoadMore()}><span>{this.state.staticText.loadMoreText}</span></div> : ""}
            </div >
        );
    }

}

export default Order;