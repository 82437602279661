import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import SessionCache from './session-cache';
import AuthenticateRoute from './authenticate-route';
import Path from './routes-path';
import Login from '../login/login';

let public_routes = [
    Path.LOGIN,
    Path.SIGN_UP,
    Path.FORGOT_PASSWORD,
    Path.STAFF_LOGIN,
    Path.PRIVACY_POLICY,
    Path.SERVICES_AGREEMENT,
    Path.PAYMENT.sellerSetupRefresh,
    Path.PAYMENT.sellerSetupSuccess,
    Path.SUBSCRIPTION.setupResponse,
    Path.SUBSCRIPTION.mobileAppRedirect,
];

export default function RouteWrapper({
    component: Component,
    isPrivate,
    ...rest
}) {

    if (public_routes.indexOf(rest.path) != -1) {
        return <Route {...rest} component={Component} />;
    }

    let status = SessionCache.isAuthenticated();
    // let status = true
    if (!status) {
        return <Route {...rest} render={(props) => {
            return <AuthenticateRoute {...rest} component={Component}></AuthenticateRoute>
        }} />
    }
    if (status) {
        return <Route {...rest} component={Component} />;
    }

    return <Route path={Path.LOGIN} component={Login} />
}

RouteWrapper.propTypes = {

    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {

    isPrivate: false
}