import React from 'react';
import "./create-staff.scss";
import BranchApi from '../../../apis/branch'
import StaffApi from '../../../apis/staff'
import RolesApi from '../../../apis/role'
import Dropdown from '../../common/dropdown/dropdown'
import Constants from '../../../constants/constants';

import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../../utility/image-source'

import StaticText from '../../../shared-store/static-text-store';
import SessionCache from '../..//routes/session-cache';
import { Tooltip } from "reactstrap"
import Utils from '../../../utility/common-utilities';


class CreateStaff extends React.Component {

    state = {
        branchDropdown: [],
        rolesDropdown: [],
        countryCode: "+1",
        selectedRoles: [],
        staticText: {},
        username: ""
    }


    constructor(props) {
        super(props)
        this.user = SessionCache.getUser()
        this.getBranches()

        if (this.props.mode === "EDIT") {
            this.state.name = this.props.staff?.name
            this.state.username = this.props.staff?.username
            this.state.mobileNumber = this.props.staff?.mobileNumber
            this.state.email = this.props.staff?.email
            this.state.countryCode = this.props.staff?.countryCode
            this.state.selectedRoles = this.buildSelectedRoles(this.props.staff?.roles || [])
        }

    }

    componentDidMount() {

        let staticText = StaticText.getStaticText("staffs")
        this.setState({ staticText })
        this.roleDetail()
    }

    roleDetail() {
        let payload = {
            isShow: "true"
        };

        RolesApi.getRoles(payload).then((response) => {
            if (response && response.response) {
                let items = []
                for (let i = 0; i < response.response?.length; i++) {
                    let role = response.response[i]
                    items.push({ title: role.displayTitle, value: role._id })
                }
                this.setState({ rolesDropdown: items, reloadRoleDropdown: true }, () => {
                    this.setState({ reloadRoleDropdown: false })
                })
            }
        }).catch((err) => {
            alert(err);
        })
    }


    getRoles = () => {
        let selectedRoles = this.state.selectedRoles
        let result = []
        selectedRoles.forEach(element => {
            result.push({ role: element.title, id: element.value })
        })

        return result
    }

    buildSelectedRoles(roles) {
        let result = []
        roles.forEach(element => {
            if (element.role.toLowerCase() != "trial") result.push({ title: element.role, value: element.id })
        })

        return result
    }

    validate = (payload) => {
        let result = true

        if (!payload.name)
            result = false

        if (!payload.username || !payload.username.trim()) {

            this.setState({ isInvalidUserName: false })
            result = false
        }

        if (!payload.email || !payload.email.trim() || !Utils.validateEmail(this.state.email)) {

            this.setState({ isInvalidEmail: true })
            result = false
        } else {
            this.setState({ isInvalidEmail: false })
        }

        if (!payload.countryCode || !payload.mobileNumber)
            result = false

        if (this.props.mode !== "EDIT") {
            if (!payload.password)
                result = false
        }

        if (!payload.roles || !payload.roles.length)
            result = false

        return result
    }

    onClickCreate = () => {

        if (this.props.mode === "EDIT") {
            this.updateStaff()
        } else {
            this.createStaff()
        }
    }

    createStaff() {

        this.setState({ isSubmit: true })

        let payload = {
            name: this.state.name,
            username: this.state.username,
            mobileNumber: this.state.mobileNumber,
            email: this.state.email,
            password: this.state.password,
            countryCode: "+1",
            roles: this.getRoles()
        };

        let isValid = this.validate(payload)

        if (!isValid) return

        StaffApi.createStaff(payload).then((response) => {
            this.props.onSave && this.props.onSave()
        }).catch((err) => {
            if (err?.response?.data?.code === 11000) {
                this.setState({ isInvalidUserName: true, userNameErrorMessage: this.state.staticText?.usernameExistsErrorMessage })
            } else if (err?.response?.data?.code === 900) {
                this.setState({ isInvalidUserName: true, userNameErrorMessage: this.state.staticText?.invalidUserNameErrorMessage })
            }
        })

    }

    updateStaff() {

        this.setState({ isSubmit: true })

        let payload = {
            id: this.props.staff._id,
            name: this.state.name,
            username: this.state.username,
            mobileNumber: this.state.mobileNumber,
            email: this.state.email,
            countryCode: "+1",
            roles: this.getRoles()
        };

        let isValid = this.validate(payload)

        if (!isValid) return

        StaffApi.updateStaff(payload).then((response) => {
            this.props.onSave && this.props.onSave()
        }).catch((err) => {
            if (err?.response?.data?.code === 11000) {
                this.setState({ isInvalidUserName: true, userNameErrorMessage: this.state.staticText?.usernameExistsErrorMessage })
            } else if (err?.response?.data?.code === 900) {
                this.setState({ isInvalidUserName: true, userNameErrorMessage: this.state.staticText?.invalidUserNameErrorMessage })
            }
        })

    }

    getBranches = () => {

        let payload = { limit: 100 };

        BranchApi.getBranches(payload).then((response) => {

            if (response && response.response)
                this.buildBranchDropdown(response.response)
        }).catch((err) => {
            alert(err);
        })
    }

    buildBranchDropdown = (items) => {

        let branchDropdown = []
        let self = this;
        items.forEach(item => {
            if (self.user?.branchId === item._id) {
                branchDropdown.push({ title: item.businessName, value: item._id })
            }
        })

        this.setState({ branchDropdown, selectedBranch: branchDropdown[0].value, relaodBranch: true }, () => {
            this.setState({ relaodBranch: false })
        })
    }


    onChangeHandler(event, key, max) {
        let value = event?.target?.value || ""
        max = max || Constants.INPUT_MAX_LENGTH_1
        if (max < value.length) return
        this.setState({ [key]: value })
        if (key == "email") this.setState({ isInvalidEmail: false })
    }

    onClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }

    onSelectRole = (option) => {
        this.setState({ selectedRoles: option || [] })

    }

    onSelectBranch = (option) => {
        this.setState({ selectedBranch: option?.value })

    }

    checkEmailValidation(validateRegex) {
        if (validateRegex && !Utils.validateEmail(this.state.email)) {
            return false
        }

        return (!this.state.email || !this.state.email.trim()) ? false : true
    }

    render() {

        return (
            <div className="create-staff">

                <div className='bold-text font-16 p-4 border-bottom'>
                    {this.props.mode === "EDIT" ? this.state?.staticText?.editStaffModalHeaderText : this.state?.staticText?.addStaffModalHeaderText}
                </div>

                <div className='px-4'>

                    <div className="row">
                        <div className="col">
                            <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.name ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>{this.state?.staticText?.employeeNameText}<span id="name-tool-tip" className='star'>*</span></div>
                                <input className={`app-input`} placeholder={this.state?.staticText?.employeeNamePlaceHolder}
                                    value={this.state.name}
                                    onChange={(e) => this.onChangeHandler(e, 'name')} />
                            </div>

                            <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isSubmit && !this.state.name} target="name-tool-tip">
                                {this.state.staticText?.nameErrorMessage}
                            </Tooltip>
                        </div>

                        <div className="col">
                            <div className={`bw-spaces-input ${this.state.isSubmit && (!this.state.username || !this.state.username.trim() || this.state.isInvalidUserName) ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>{this.state?.staticText?.employeeCodeText}<span id="username-tool-tip" className='star'>*</span></div>
                                <input className={`app-input`} placeholder={this.state?.staticText?.employeeCodePlaceHolder}
                                    value={this.state.username}
                                    onChange={(e) => this.onChangeHandler(e, 'username')} />

                                <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isSubmit && (!this.state.username || !this.state.username.trim() || this.state.isInvalidUserName)} target="username-tool-tip">
                                    {this.state.isInvalidUserName ? this.state.userNameErrorMessage : this.state.staticText?.usernameErrorMessage}
                                </Tooltip>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col">
                            <div className={`bw-spaces-input ${this.state.isSubmit && (!this.state.mobileNumber || !this.state.countryCode) ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>{this.state?.staticText?.phoneNumberText}<span id="number-tool-tip" className='star'>*</span></div>
                                <div className='app-input row p-1 mobile-number-input-wrapper'>
                                    <input placeholder='' className='country-code'
                                        value={this.state.countryCode}
                                        onChange={(e) => this.onChangeHandler(e, 'countryCode', 4)} />

                                    <input className='mobile-number-input' type={'number'} placeholder={this.state?.staticText?.mobileNumberPlaceHolder}
                                        value={this.state.mobileNumber}
                                        onChange={(e) => this.onChangeHandler(e, 'mobileNumber', Constants.INPUT_MAX_LENGTH_2)} />
                                </div>

                                <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isSubmit && (!this.state.mobileNumber || !this.state.countryCode)} target="number-tool-tip">
                                    {this.state.staticText?.mobileNumberErrorMessage}
                                </Tooltip>
                            </div>
                        </div>


                        <div className="col">
                            <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.selectedRoles?.length ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>{this.state.staticText?.roleText}<span id="role-tool-tip" className='star'>*</span></div>
                                {
                                    !this.state.reloadRoleDropdown ?
                                        <Dropdown
                                            placeholder={this.state.staticText?.rolePlaceHolder}
                                            options={this.state.rolesDropdown}
                                            text={"title"}
                                            multiSelect
                                            value={this.state.selectedRoles}
                                            onSelect={this.onSelectRole}
                                        ></Dropdown> : ""}

                                <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isSubmit && !this.state.selectedRoles?.length} target="role-tool-tip">
                                    {this.state.staticText?.roleErrorMessage}
                                </Tooltip>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className={`bw-spaces-input ${this.state.isSubmit && this.state.isInvalidEmail ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>{this.state?.staticText?.emailText}<span id="email-tool-tip" className='star'>*</span></div>
                                <input className={`app-input`} placeholder={this.state?.staticText?.emailPlaceHolder}
                                    value={this.state.email}
                                    onChange={(e) => this.onChangeHandler(e, 'email')} />

                                <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isSubmit && this.state.isInvalidEmail} target="email-tool-tip">
                                    {this.state.staticText?.emailErrorMessage}
                                </Tooltip>
                            </div>
                        </div>

                        <div className="col-6">
                            {this.props.mode !== "EDIT" ? <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.password ? "invalid-input-container" : ""}`}>
                                <div className='app-input-label'>{this.state.staticText?.passwordText} <span id="password-tool-tip" className='star'>*</span></div>
                                <input id="login-password" className={`app-input pr-5`}
                                    placeholder={this.state.staticText?.passwordPlaceholder}
                                    value={this.state.password}
                                    type={`${this.state.showPassword ? '' : 'password'}`}
                                    onChange={(e) => this.onChangeHandler(e, 'password')} />

                                <span className="password-icon">
                                    <img className="cursor-pointer icon-20" alt=""
                                        src={getImageSource(this.state.showPassword ? ImageConstants.EYE_OPEN : ImageConstants.EYE_CLOSE)}
                                        onClick={this.onClickShowPassword}
                                    />
                                </span>

                                <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isSubmit && !this.state.password} target="password-tool-tip">
                                    {this.state.staticText?.passwordErrorMessage}
                                </Tooltip>
                            </div> : ""}
                        </div>
                    </div>

                </div>

                <div className='button-wrapper-staff p-4'>
                    <div className='app-button-default' onClick={this.props.close}>{this.state.staticText?.cancelText}</div>
                    <div className='app-button-primary' onClick={this.onClickCreate}>{this.props.mode == "EDIT" ? this.state.staticText?.updateButtonText : this.state.staticText?.createButtonText}</div>
                </div>

            </div >
        );
    }

}

export default CreateStaff;