import React from 'react';
import './cart-footer.scss';

import { getImageSource } from '../../../utility/image-source';
import { Redirect } from 'react-router-dom';
import ImageConstants from '../../../constants/image-constants';

import SharedStore from '../../../shared-store/shared-store';
import cartItemApi from '../../../apis/cart'
import orderApi from '../../../apis/order'

import StaticText from '../../../shared-store/static-text-store';
import Services from '../../../service/service'
import Path from '../../routes/routes-path';

class CartFooter extends React.Component {

    state = {
        staticText: {},
        products: [],
        renderSimpleView: this.props.showMinimal
    };

    url = "https://cobrandfundfina.s3.ap-south-1.amazonaws.com/veg.png"

    componentDidMount() {
        this.handleCart()
        let staticText = StaticText.getStaticText("myCart")
        this.setState({ staticText })
        Services.setCart(this)
    }

    removeCartItem(product) {
        let payload = {
            productId: product.productId,
            cartId: product.cartId,
        }
        this.refreshCartStore(product)
        cartItemApi.removeCartItem(payload).then((response) => {
            this.props.onRemove && this.props.onRemove(product)
        }).catch((err) => {
        })
    }

    refreshCartStore(product) {
        let cart = SharedStore.getStore("cart")
        let products = this.state.cart?.products || []

        products = products.filter(i => i.productId != product.productId)
        cart.products = products
        SharedStore.setStore("cart", cart)
        this.setState({ cart })
    }

    handleCart() {
        if (SharedStore.getStore("cart")) {
            this.refreshCart()
            return
        }
        cartItemApi.getCartItems({}).then((response) => {
            SharedStore.setStore("cart", response?.response || [])
            this.refreshCart()
        }).catch((err) => {
            SharedStore.setStore("cart", [])
            this.refreshCart()
        })
    }

    refreshCart() {
        let cart = SharedStore.getStore("cart")
        this.setState({ cart })
    }

    goToCart = () => {
        this.props.history.push(Path.CART)
    }

    renderSimpleView = () => {
        this.setState({ renderSimpleView: !this.state.renderSimpleView })
    }


    render() {

        return (
            <div className={`cart-footer p-4 ${this.props.transparent ? '' : 'bg-white'}`}>
                {!this.state.renderSimpleView ?
                    <div>
                        <div className="row no-gutters">
                            <div className="col">
                                <span className="app-header-title-18 pr-2">{this.state.staticText?.title}</span>
                                <span className="no-of-item">( {this.state.cart?.products?.length} {this.state.staticText?.itemAdded} )</span>
                            </div>
                            <div className="col text-right">
                                <img className="icon-20 cursor-pointer" alt="" src={getImageSource(ImageConstants.DOWN_ARROW)} onClick={this.renderSimpleView} />
                            </div>
                        </div>
                        <div className="row no-gutters pt-4">
                            <div className="col-9 cart-item-wrapper pb-2">
                                <div className="row no-gutters">
                                    {
                                        this.state.cart?.products?.length ? this.state.cart?.products?.map(item => (
                                            <div className=" col-lg-3 col-md-4 col-sm-6 mt-2">
                                                <div className="d-flex align-items-center cart-item mr-4 p-2">
                                                    <div className="pr-2 flex-1">
                                                        <div className="image-circle-wrapper-50">
                                                            <img alt="" src={item?.images[0]?.url || getImageSource(ImageConstants.DEFAULT_PRODUCT)} />
                                                        </div>
                                                    </div>
                                                    <div className="flex-2 pr-1">
                                                        <div className="font-14 bold-text">{item.name}</div>
                                                        <div className="qty font-14"> {this.state.staticText?.quantityText} {item.quantity}</div>
                                                    </div>
                                                    <div className="flex-3 remove-qty float-right">
                                                        <img className="icon-20 cursor-pointer" alt="" src={getImageSource(ImageConstants.CLOSE_WHITE)} onClick={() => this.removeCartItem(item)} />
                                                    </div>
                                                </div>
                                            </div>
                                        )) : <div className="col text-center"> No Items Available in your Cart</div>
                                    }
                                </div>
                            </div>
                            <div className="col my-auto text-right">
                                <button className="app-button-primary" onClick={this.goToCart}>
                                    <img alt="" className="icon-20" src={getImageSource(ImageConstants.SHOPPING_CART)} /> <span className="pl-2">{this.state.staticText.goToCartText}</span>
                                </button>
                            </div>
                        </div>
                    </div> :
                    <div className="row no-gutters">
                        <div className="col my-auto">
                            <span className="app-header-title-18 pr-2">{this.state.staticText?.title}</span>
                            <span className="no-of-item">( {this.state.cart?.products?.length} {this.state.staticText?.itemAdded} )</span>
                        </div>
                        <div className="col my-auto text-right">
                            <button className="app-button-primary" onClick={this.goToCart}>
                                <img alt="" className="icon-20" src={getImageSource(ImageConstants.SHOPPING_CART)} /> <span className="pl-2">{this.state.staticText.goToCartText}</span>
                            </button>
                        </div>
                        <div className="col-1 text-right my-auto">
                            <img className="icon-20 cursor-pointer" alt="" src={getImageSource(ImageConstants.ARROW_UP)} onClick={this.renderSimpleView} />
                        </div>
                    </div>

                }
            </div>
        );
    }
}

export default CartFooter;