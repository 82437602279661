import React from 'react';
import "./customer-detail.scss";

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthenticateAPI from '../../apis/authenticate';
import BranchApi from '../../apis/branch';
import customerApi from '../../apis/customer';
import ActionConstant from '../../constants/action-constant';
import Constants from '../../constants/constants';
import ImageConstants from '../../constants/image-constants';
import SharedStore from '../../shared-store/shared-store';
import StaticText from '../../shared-store/static-text-store';
import CommonUtils from '../../utility/common-utilities';
import { getImageSource } from '../../utility/image-source';
import AppConfirmModal from '../common/app-confirm/app-confirm';
import Dropdown from '../common/dropdown/dropdown';
import Loading from '../common/loading/loading';
import ToasterComponent from '../common/toaster/toaster';
import SessionCache from '../routes/session-cache';
import PriceSetup from "./price-setup/price-setup";
import AcceptInvitation from './accept-invitation/accept-invitation';

class CustomerDetail extends React.Component {

    state = {
        previousBuyerPricingValue: null,
        previousBuyerPricingType: Constants.BuyerPricingType.MARKUP,
        selectedBuyerPricingType: Constants.BuyerPricingType.MARKUP,
        buyerPricingValue: null,
    }
    accessRights = {}
    constructor(props) {
        super(props)

        this.staticText = StaticText.getStaticText("branchConfig");
    }

    componentDidMount() {

        this.getBranchConfig()

        this.user = SessionCache.getUser();
        this.accessRights = this.user.accessRights || {}
        this.getBuyerPricing()
    }

    getBuyerPricing = () => {
        const payload = {
            buyerBranchId: this.props.businessUnit?._id,
        }
        this.setState({ isLoading: true })
        customerApi.getBuyerPricing(payload).then((response) => {
            console.log(response)
            if (!response) {
                this.setState({ isLoading: false })
                return
            }
            this.setState({ isLoading: false, selectedBuyerPricingType: response.type, buyerPricingValue: response.value, previousBuyerPricingType: response.type, previousBuyerPricingValue: response.value })
        }).catch((err) => {
            this.setState({ isLoading: false })
            alert(err);
        })
    }


    getBranchConfig() {

        let payload = {
            buyerId: this.props.businessUnit?._id,
            type: "PAYMENT_TERMS"
        }

        BranchApi.getBranchConfig(payload).then((data) => {

            let response = data[0]
            this.setState({ paymentTerms: response.paymentTerms })
            this.getPaymentTermsConfig();
        }).catch((err) => {
            this.getPaymentTermsConfig();

        })
    }

    getPaymentTermsConfig() {
        AuthenticateAPI.getStaticText({ type: "PAYMENT_TERMS" }).then((data) => {
            const response = data.response[0]
            this.setState({ paymentTermsDropdownItems: response?.config || [], isDropdownLoading: true }, () => {
                this.setState({ isDropdownLoading: false })
            })

        }).catch((err) => {
            console.log(err)
        })
    }

    showToaster(type, message) {
        this.setState({
            showToaster: true,
            toaster: {
                type,
                message,
            }
        }, () => {
            setTimeout(() => this.hideToaster(), 3000)
        })
    }

    hideToaster() {
        this.setState({ showToaster: false })
    }

    onSelectDropdown(option, key) {
        let payload = {
            buyerId: this.props.businessUnit?._id,
            paymentTerms: option,
            type: "PAYMENT_TERMS"
        }
        BranchApi.updateBranchConfig(payload).then((data) => {

        }).catch((err) => {
            console.log(err)
        })

    }

    onClickPriceSetup = () => {
        if (CommonUtils.checkTrialAccess(ActionConstant.CUSTOMER_PRICE_SETUP)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {

            this.setState({ showPriceSetup: true })
        }
    }
    closePriceSetup = () => {
        this.setState({ showPriceSetup: false }, () => {
        })
    }
    onClickDelete(event) {
        event.stopPropagation();
        if (CommonUtils.checkTrialAccess(ActionConstant.REMOVE_CUSTOMER)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ showDeleteConfirm: true })
        }
    }
    handleCloseConfirm = () => {
        this.setState({ deleteItem: null, showDeleteConfirm: false })
    }

    onDelete = () => {
        let payload = {
            id: this.props.businessUnit?._id,
        }
        customerApi.removeCustomer(payload).then((response) => {
            this.handleCloseConfirm()
            this.props.back(this.props.businessUnit?._id)
        }).catch((err) => {
            alert(err);
        })
    }
    getCustomer(payload = {}) {
        this.setState({ isLoading: true })
        payload.type = [Constants.USER_TYPE.BOTH, Constants.USER_TYPE.CUSTOMER].join(",")
        payload.showAll = true
        customerApi.getCustomers(payload).then((response) => {
            this.setState({ customers: response.response || [], isLoading: false })
        }).catch((err) => {
            this.setState({ customers: [], isLoading: false })
        })
    }

    onStatusUpdate(event, status, acceptedAs) {
        if (event) event.stopPropagation();
        if (CommonUtils.checkTrialAccess(ActionConstant.CANCEL_INVITATION)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            let payload = {
                invitationId: this.props.businessUnit?.invitationId,
                status,
                acceptedAs
            }

            BranchApi.updateInvite(payload).then((response) => {
                this.getCustomer()
                if (CommonUtils.checkTrialAccess(ActionConstant.ACCEPT_INVITATION) || CommonUtils.checkTrialAccess(ActionConstant.REJECT_INVITATION)) {
                    SharedStore.getStore("subscription").showSubscriptionModal()
                } else {
                    if (status == "ACCEPTED" || status == "REJECTED") {
                        let store = SharedStore.getStore("header")
                        if (store.reduceInvitationBadgeCount) store.reduceInvitationBadgeCount(-1)
                        this.props.back(this.props.businessUnit?._id, true)

                    } else {
                        this.props.back(this.props.businessUnit?._id)

                    }

                    this.onCloseInvitation()
                }
            }).catch((err) => {
            })
        }
    }
    renderNameBadge(name, customeClass) {
        if (!name) return ""
        let splitName = name.split(" ")
        let tag = ""
        if (splitName.length > 1) {
            tag = `${splitName[0][0] || ""}${splitName[1][0] || ""}`
        } else if (splitName) {
            tag = `${splitName[0][0]}`
        }
        tag = tag ? tag.toUpperCase() : ""
        return (<div className={`d-inline-block name-badge ${customeClass || ""}`}><span className="">{tag || ""}</span></div>)

    }

    getUserAddress(address) {
        let str = [address.addressLine1]

        if (address.addressLine2) str.push(address.addressLine2)
        if (address.city) str.push(address.city)
        if (address.state) str.push(address.state)
        if (address.country) str.push(address.country)
        if (address.code) str.push(address.code)
        return str.join(", ")
    }
    showCustomerDetail = () => {
        this.setState({ viewCustomerDetail: true })
    }

    handleBack = () => {
        this.setState({ viewCustomerDetail: false })
    }

    onClickBuyerPricingTypeToggle = () => {
        if (this.state.selectedBuyerPricingType === Constants.BuyerPricingType.DISCOUNT) {
            this.setState({ selectedBuyerPricingType: Constants.BuyerPricingType.MARKUP })
            return
        }
        this.setState({ selectedBuyerPricingType: Constants.BuyerPricingType.DISCOUNT })
    }

    onChangeBuyerPricingValue = (event) => {
        const value = Number(event.target.value)
        if (value < 0 || value > 100) return

        this.setState({ buyerPricingValue: value })
    }

    onClickSaveBuyerPricing = () => {
        const payload = {
            type: this.state.selectedBuyerPricingType,
            discountType: Constants.DiscountType.PERCENT,
            value: this.state.buyerPricingValue,
            buyer: {
                tenantId: this.props.businessUnit?.tenantId,
                branchId: this.props.businessUnit?._id
            },
        }
        this.setState({ isLoading: true })
        customerApi.createBuyerPricing(payload).then((response) => {
            this.setState({ isLoading: false, previousBuyerPricingType: this.state.selectedBuyerPricingType, previousBuyerPricingValue: this.state.buyerPricingValue })
        }).catch((err) => {
            this.setState({ isLoading: false })
            alert(err);
        })
    }

    onClickAccept() {

        this.setState({ showAcceptInvitation: true })
    }

    onConfirmAccept = (type) => {
        this.onStatusUpdate(null, "ACCEPTED", type)
    }

    onCloseInvitation = () => {
        this.setState({ showAcceptInvitation: false })

    }

    renderDetail() {
        let vendor = this.props.businessUnit?.vendor
        let buyer = this.props.businessUnit
        let showBuyerPricingSaveButton = false
        if ((this.state.previousBuyerPricingValue !== this.state.buyerPricingValue) || (this.state.previousBuyerPricingType !== this.state.selectedBuyerPricingType)) {
            showBuyerPricingSaveButton = true
        }
        return (
            <div className='customer-detail-wrapper-card pt-4 px-4'>

                <div className=''>
                    <div className='row'>
                        <div className='col'>
                            <div className="row no-gutters">
                                <div className="d-inline-block pr-4">
                                    {this.renderNameBadge(buyer?.businessName || "")}
                                </div>
                                <div className="d-inline-block">
                                    <div className="user-name"> {buyer?.businessName || ""} </div>
                                    <div className='staff-code text-secondary'>{buyer?.cubeId}</div>
                                    <div className="address">{this.getUserAddress(buyer?.address || {})} </div>

                                </div>
                            </div>
                        </div>
                        <div className='float-right mr-5'>
                            {
                                CommonUtils.checkRights(ActionConstant.REMOVE_CUSTOMER) && this.props.businessUnit?.status === "ACCEPTED" ?
                                    <span className='app-button-red-small float-right text-white font-weight-bold' onClick={(event) => this.onClickDelete(event)} >
                                        <span>{this.staticText.deleteText}</span>
                                    </span> : ""

                            }
                        </div>
                    </div>
                </div>
                <div className=''>
                    <div className="row">
                        {/* <div className="col">
                            <span className='app-button-secondary-small mr-2' onClick={() => this.props.onDelete(this.props.businessUnit)}> Delete</span>
                        </div> */}
                    </div>
                    <div className="pt-4">
                        {/* {this.renderItemDetail()} */}
                    </div>
                    <div className="pt-4">
                        {/* {this.renderDiscountDetail()} */}
                    </div>
                </div >

                <div className="custom-footer py-4 mt-4">
                    {
                        this.props.businessUnit?.status === "PENDING" ?
                            <>
                                {
                                    this.props.businessUnit.invitedByBranchId === this.user?.branchId ?
                                        <span> {CommonUtils.checkRights(ActionConstant.CANCEL_INVITATION) ?
                                            <span className='app-button-red-small float-right' onClick={(event) => this.onStatusUpdate(event, "CANCELLED")}>
                                                <span className='btn-text'>{this.staticText.cancelText}</span>
                                            </span>
                                            : ""}
                                        </span> : <span className="text-center">

                                            {
                                                CommonUtils.checkRights(ActionConstant.REJECT_INVITATION) ?
                                                    <span className='app-button-secondary-small float-right'
                                                        onClick={(event) => this.onStatusUpdate(event, "REJECTED")}>
                                                        <span className='btn-text'>{this.state?.staticText?.rejectText || "Reject"}
                                                        </span>
                                                    </span> : ""
                                            }

                                            {
                                                CommonUtils.checkRights(ActionConstant.ACCEPT_INVITATION) ?
                                                    <div className="d-inline-block">
                                                        <span className='app-button-primary-small float-right mr-2'
                                                            onClick={(event) => this.onClickAccept()}>
                                                            <span className='btn-text'>{this.state?.staticText?.acceptText || "Accept"}
                                                            </span>
                                                        </span>
                                                        {
                                                            this.state.showAcceptInvitation ?
                                                                <div className="accept-wrapper text-left">
                                                                    <AcceptInvitation name={this.props.businessUnit.businessName} close={this.onCloseInvitation} onAccept={this.onConfirmAccept} />
                                                                </div> : ""
                                                        }
                                                    </div>

                                                    : ""

                                            }
                                        </span>
                                }
                            </> :
                            <div className='row'>
                                <div className='col-md-3 payment-terms-col'>
                                    <div className='app-input-label'>{this.staticText.paymentTerms} <span className='star'>*</span></div>
                                    {
                                        !this.state.isDropdownLoading ?
                                            <Dropdown
                                                placeholder={this.staticText.selectPaymentTerms}
                                                options={this.state.paymentTermsDropdownItems || []}
                                                text={"title"}
                                                onSelect={(item) => this.onSelectDropdown(item, "paymentTerms")}
                                                value={this.state.paymentTerms?.value}
                                            />
                                            : ""
                                    }
                                </div>
                                {
                                    CommonUtils.checkRights(ActionConstant.CUSTOMER_PRICE_SETUP) ?
                                        <div className='col-md-7'>
                                            <div className='float-right'>
                                                <div className='app-input-label'>{this.staticText.discountOrMarkup}</div>
                                                <div className='d-flex align-items-center'>
                                                    <div className='toggle-label'>{this.state.selectedBuyerPricingType === Constants.BuyerPricingType.DISCOUNT ? Constants.BuyerPricingType.DISCOUNT : Constants.BuyerPricingType.MARKUP}</div>
                                                    <div class={`custom-control custom-switch custom-control-switch  ml-3 d-inline-block`}>
                                                        <input type="checkbox" class="custom-control-input" id="buyerPricingSwitch" checked={this.state.selectedBuyerPricingType === Constants.BuyerPricingType.DISCOUNT} onChange={this.onClickBuyerPricingTypeToggle} />
                                                        <label class={`custom-control-label on-${this.state.selectedBuyerPricingType === Constants.BuyerPricingType.MARKUP ? 'left' : 'right'}`} for="buyerPricingSwitch"></label>
                                                    </div>
                                                    <div className='price-card ml-3'>
                                                        <span className='pl-2 pr-2'>
                                                            {
                                                                this.state.selectedBuyerPricingType === Constants.BuyerPricingType.DISCOUNT ?
                                                                    '-' : '+'
                                                            }
                                                        </span>
                                                        <input className='price-card-input' placeholder='0' value={this.state.buyerPricingValue || null} type='number' onChange={this.onChangeBuyerPricingValue}></input>
                                                        <span className='pl-2 pr-2'>{'%'}</span>
                                                    </div>
                                                    {showBuyerPricingSaveButton ? <div className='save-pricing-button ml-3 cursor-pointer' onClick={this.onClickSaveBuyerPricing}>{this.staticText.savePricingButton || 'Save'}</div>
                                                        : <div className='save-pricing-button-empty ml-3'></div>}
                                                </div>
                                            </div>
                                        </div> : ""
                                }
                                <div className='col-md-1'></div>
                                {/* {
                                    CommonUtils.checkRights(ActionConstant.CUSTOMER_PRICE_SETUP) ?
                                        <div className='col-md-9'>
                                            <span className='app-button-secondary-small  mr-2' onClick={this.onClickPriceSetup}>
                                                <img alt="" className='icon-15 mr-2' src={getImageSource(this.isActive ? ImageConstants.EDIT_ICON_ACTIVE : ImageConstants.EDIT_ICON_IN_ACTIVE)} />
                                                <span className='btn-text'>{this.staticText.priceSetupText}</span>
                                            </span>
                                        </div> : ""
                                } */}
                            </div>

                    }
                </div>
            </div >
        )
    }

    render() {

        return (
            <>
                {this.state.isLoading && <Loading />}
                <div className='app-container customer-detail'>

                    <div className="position-relative">
                        <div className="d-inline-block back-icon" onClick={this.props.back}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <div className="header-text"> {this.props.businessUnit.businessName}</div>
                    </div>

                    <div className="pt-4">
                        {this.renderDetail()}
                    </div>
                </div>
                {
                    this.state.showDeleteConfirm ? <AppConfirmModal
                        img={getImageSource(ImageConstants.CLOSE_RED)}
                        okButtonText={this.state.staticText?.deleteText}
                        okButtonStyle="reject-text"
                        title={this.staticText?.deleteTitle}
                        close={this.handleCloseConfirm}
                        onOk={this.onDelete}
                    /> : ""
                }
                {this.state.showToaster ? <ToasterComponent type={this.state.toaster?.type} message={this.state.toaster?.message} /> : ""}
                {this.state.showPriceSetup ? <PriceSetup cancel={() => this.closePriceSetup()} customer={this.props.businessUnit} /> : <></>}

            </>
        );
    }
}


export default CustomerDetail;