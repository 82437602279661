import HttpClient from './http-client';
import URL from './url';

export default class Order {

    static getOrders(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.ORDER + "?" + params.join('&') : URL.ORDER;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static createOrder(payload) {
        return HttpClient.post(URL.BASE_URL + URL.ORDER, null, payload);
    }

    static getOrdersWithRange(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.ORDER_WITH_RANGE + "?" + params.join('&') : URL.ORDER_WITH_RANGE;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static updateOrder(payload) {
        return HttpClient.put(URL.BASE_URL + URL.ORDER, null, payload);
    }

    static updateOrderItem(payload) {
        return HttpClient.put(URL.BASE_URL + URL.ORDER_ITEMS, null, payload);
    }

    static removeCartItem(payload) {
        return HttpClient.delete(URL.BASE_URL + URL.ORDER, null, payload);
    }

    static exportOrders(payload) {
        return HttpClient.post(URL.BASE_URL + URL.EXPORT_ORDERS, null, payload);
    }

}
