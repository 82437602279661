import { faCalendarAlt, faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { endOfDay, endOfMonth, startOfMonth, subMonths } from "date-fns";
import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Modal, ModalBody } from "reactstrap";
import orderApi from '../../../apis/order';
import ImageConstants from '../../../constants/image-constants';
import StaticText from '../../../shared-store/static-text-store';
import CommonUtils from '../../../utility/common-utilities';
import { getImageSource } from '../../../utility/image-source';
import "./import-export.scss";

const dateRangeOptions = {
   currentMonth: 'Current Month',
   lastMonth: 'Last Month',
   dateRange: 'Date Range',
}
const dateFormatter = "MM/dd/yyyy"
const currentDate = new Date()

class ImportExport extends React.Component {

   state = {
      downloadLoading: false,
      includeOrderDetails: false,
      includeCancelledAndRejectedOrders: false,
      selectedDateRangeOption: dateRangeOptions.currentMonth,
      startDate: startOfMonth(currentDate),
      endDate: endOfMonth(currentDate),
   }

   constructor(props) {
      super(props)
      this.staticText = StaticText.getStaticText("productList")
   }


   componentDidMount() {
      document.addEventListener('mousedown', this.handleClickEvent, false);
   }

   componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickEvent, false);
   }

   showToaster(type, message) {
      this.setState({
         showToaster: true,
         toaster: {
            type,
            message,
         }
      }, () => {
         setTimeout(() => this.hideToaster(), 3000)
      })
   }

   hideToaster() {
      this.setState({ showToaster: false })
   }

   onClickDownloadOrders() {
      const payload = {
         includeOrderDetails: this.state.includeOrderDetails,
         includeCancelledAndRejectedOrders: this.state.includeCancelledAndRejectedOrders,
         startDate: this.state.startDate,
         endDate: endOfDay(this.state.endDate)
      }

      if (this.props.isVendor) payload.isVendor = true

      this.setState({ downloadLoading: true })
      orderApi.exportOrders(payload).then((response) => {
         this.setState({ downloadLoading: false })
         this.downloadCsv(response.data.downloadUrl)
      }).catch((err) => {
         console.error(err)
         this.setState({ downloadLoading: false })
      })
   }

   downloadCsv = (url) => {
      const link = document.createElement('a');
      link.href = url
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   }

   onChangeIncludeOrderDetails = () => {
      if (this.state.includeOrderDetails) {
         this.setState({ includeOrderDetails: false })
         return
      }
      this.setState({ includeOrderDetails: true })
   }

   onChangeIncludedCancelledAndRejectedOrders = () => {
      if (this.state.includeCancelledAndRejectedOrders) {
         this.setState({ includeCancelledAndRejectedOrders: false })
         return
      }
      this.setState({ includeCancelledAndRejectedOrders: true })
   }

   onChangeDateRangeOption = (event, selectedDateRangeOption) => {

      let startDate = startOfMonth(currentDate)
      let endDate = endOfMonth(currentDate)
      if (selectedDateRangeOption === dateRangeOptions.currentMonth) {
         this.setState({ selectedDateRangeOption, startDate, endDate })
         return
      }
      if (selectedDateRangeOption === dateRangeOptions.lastMonth) {
         const prevMonth = subMonths(new Date(), 1)
         startDate = startOfMonth(prevMonth)
         endDate = endOfMonth(prevMonth)
         this.setState({ selectedDateRangeOption, startDate, endDate })
         return
      }
      this.setState({ selectedDateRangeOption, startDate, endDate })
   }

   handleDateEvent = (picker, updateOrder, event) => {
      this.setState({ startDate: picker.startDate.toDate(), endDate: picker.endDate.toDate() })
   }

   renderDatePicker(updateOrder) {
      return (<DateRangePicker
         onHide={(event, picker) => this.handleDateEvent(picker, updateOrder, event)}
         alwaysShowCalendars={true}
         initialSettings={{ autoApply: true, startDate: this.state.startDate, endDate: this.state.endDate, autoUpdateInput: false, maxSpan: { "days": 30 } }}
         locale={{
            'format': 'DD/MM/YYYY'
         }}

      >
         <button className="date-range-button">
            {
               this.state.startDate ? `${CommonUtils.datePipe(this.state.startDate, dateFormatter)} to ${CommonUtils.datePipe(this.state.endDate, dateFormatter)}` : "dd/mm/yyyy"
            }
            <FontAwesomeIcon className="text-primary icon" icon={faCalendarAlt} /></button>
      </DateRangePicker>)
   }

   render() {

      return (
         <Modal className="custom-modal-import-export" isOpen={true} toggle={this.props.closeImportExport} size={'lg'}>

            <ModalBody>
               <div className="import-export-modal-body">
                  <div className="row">
                     <div className="col text-right">
                        <FontAwesomeIcon className="cursor-pointer" onClick={this.props.closeImportExport} icon={faTimes} />
                     </div>
                  </div>

                  <div className="header-text">
                     <span>{this.staticText?.exportTitle || 'Export Order Data'}</span>
                  </div>

                  <div className='content'>
                     <div className="checkbox-container">
                        <div className='cursor-pointer mb-3' onClick={this.onChangeIncludeOrderDetails}>
                           <div className="d-flex align-items-center">
                              <img alt="" src={getImageSource(this.state.includeOrderDetails ? ImageConstants.CHECKBOX_ACTIVE : ImageConstants.CHECKBOX_INACTIVE)} className='checkbox'></img>
                              <span className={`categories-name pl-2 ${this.state.includeOrderDetails ? 'categories-name-active' : ""}`}>{'Include Order Details'}</span>
                           </div>
                        </div>

                        <div className='cursor-pointer mb-3' onClick={this.onChangeIncludedCancelledAndRejectedOrders}>
                           <div className="d-flex align-items-center">
                              <img alt="" src={getImageSource(this.state.includeCancelledAndRejectedOrders ? ImageConstants.CHECKBOX_ACTIVE : ImageConstants.CHECKBOX_INACTIVE)} className='checkbox'></img>
                              <span className={`categories-name pl-2 ${this.state.includeCancelledAndRejectedOrders ? 'categories-name-active' : ""}`}>{'Include Cancelled and Rejected orders'}</span>
                           </div>
                        </div>
                     </div>

                     <div className='separator'></div>

                     <div className='date-container'>
                        <div className='date-header'>Date Range</div>

                        <div className='radio-buttons'>
                           {Object.keys(dateRangeOptions).map(key => <div className='button-container'>
                              <input
                                 className='radio-button'
                                 type="radio"
                                 checked={dateRangeOptions[key] === this.state.selectedDateRangeOption}
                                 id={key}
                                 name={'dateRangeOption'}
                                 value={dateRangeOptions[key]}
                                 onClick={(event) => this.onChangeDateRangeOption(event, dateRangeOptions[key])}
                              />
                              <label for={key}>{dateRangeOptions[key]}</label><br />
                           </div>)}
                        </div>

                        {this.state.selectedDateRangeOption === dateRangeOptions.dateRange ?
                           <div className='date-range-container'>
                              {this.renderDatePicker()}
                           </div> : ""}
                     </div>
                  </div>

                  <div className="text-center mt-3">
                     {this.state.errorMessage ? <div className="text-danger pb-4 px-4"><strong>{this.state.errorMessage} </strong></div> : ""}
                     <button className="app-button-primary mb-4" disabled={this.state.downloadLoading} onClick={() => this.onClickDownloadOrders()}>
                        {!this.state.downloadLoading ? "Download" : <FontAwesomeIcon icon={faSpinner} spin />}
                     </button>
                  </div>
               </div>
            </ModalBody>

         </Modal >
      );
   }
}

export default ImportExport;


