
const ImageConstants = {
    APP_DEFAULT_LOGO: "app-default-logo",
    FACEBOOK: "facebook",
    USER: "user",
    LOCK: "lock",
    GREEN_TICK: "green-tick",
    ADD_USER_IN_ACTIVE: "add-user-inactive",
    ADD_VENDOR_IN_ACTIVE: "add-vendor-inactive",
    DOWN_ARROW: "down-arrow",
    HOME_ACTIVE: "home-active",
    VENDOR: "vendor",
    ADD_USER_ACTIVE: "add-user-active",
    ADD_VENDOR_ACTIVE: "add-vendor-active",
    NO_STAFF: "no-staff",
    SIGNUP: "signup",
    CHECKBOX_TICK: "checkbox-tick",
    CHECKBOX: "checkbox",
    NO_VENDOR: "no-vendor",
    TICK_MARK: "tick-mark",
    GREEN_TICK_FILLED: "green-tick-filled",
    EYE_CLOSE: "eye-close",
    EYE_OPEN: "eye-open",
    SLIDER: "slider",
    ORDER_NEW: "order-new",
    ORDER_PENDING: "order-pending",
    ORDER_REJECT: "order-reject",
    ORDER_COMPLETE: "order-complete",
    ORDER_PAST_DUE: "order-past-due",
    SORT: "sort",
    CUBE_WHITE: "cube-white",
    DELIVERY: "delivery",
    PHONE: "phone",
    CUBE_GREEN: "cube-green",
    CUBE_RED: "cube-red",
    CLOSE_RED: "close-red",
    CLOSE_GRAY: "close-gray",
    ARROW_LEFT: "arrow-left",
    ARROW_RIGHT: "arrow-right",
    ARROW_RIGHT_BLACK: "arrow-right-black",
    EXPORT_ICON: "export-icon",
    SHOPPING_CART: "shopping-cart",
    CLOSE_WHITE: "close-white",

    BELL: "bell",
    FLAG: "flag",
    SEARCH: "search",
    CROSS_ICON: "cross-icon",
    CROSS_ICON_WHITE: "cross-icon-white",
    EDIT_ICON: "edit-icon",
    FULL_ARROW_LEFT: "full-arrow-left",

    LIST_VIEW: "list-view",
    CARD_VIEW: "card-view",
    BLUE_DOWN_ARROW: "blue-down-arrow",
    ORDER_SUCCESS: "order-success",

    ARROW_UP: "up-arrow",
    BLUE_UP_ARROW: "blue-up-arrow",
    PRODUCT_LIST_FILTERS: "product-list-filters",
    PRODUCT_LIST_SEARCH: "product-list-search",
    PRODUCT_LIST_VIEW: "product-list-view",
    PRODUCT_CARD_VIEW: "product-card-view",
    EDIT_ICON_IN_ACTIVE: "edit-icon-in-active",
    EDIT_ICON_ACTIVE: "edit-icon-active",

    NO_INVITATIONS: "no-invitations",

    LOGOUT: "logout",
    REFRESH: "refresh",

    ARROW_RIGHT_FILL: "arrow-right-fill",

    CLOSE: "close",
    NO_NOTIFICATION: "no-notification",
    CHECKBOX_INACTIVE: "checkbox-inactive",
    CHECKBOX_ACTIVE: "checkbox-active",

    RIGHT_ARROW_BLUE: "right-arrow-blue",
    UPLOAD_IMAGE: "upload-image",
    LOADING: "loading",
    REMOVE_ICON: "remove-icon",
    POPUP_CLOSE: "popup-close",
    CROSS_MARK: "cross-mark",
    GREEN_TICK_NEW: "green-tick-new",
    NO_PRODUCT: "no-product",

    IMAGE_SWIPE_LEFT: "image-swipe-left",
    IMAGE_SWIPE_RIGHT: "image-swipe-right",
    FAVORATE: "favorate",
    LOCK_ACTIVE: "lock-active",
    DELETE: "delete",
    DELETE_ACTIVE: "delete-active",
    CART_ICON: "cart-icon",

    ORDER_YELLOW: "order-yellow",
    SALES: "sales",
    DOLLER_GREEN: "doller-green",
    DOLLER_BLUE: "doller-blue",

    STAFF_LOGIN: "staff-login",
    SALES_ACTIVITY: "sales-activity",

    ORDER_BLUE: "order-blue",
    ORDER_RED: "order-red",

    DEFAULT_PRODUCT: "default-product",
    LOGIN_BACKGROUND: 'login-background',

    INVOICE_NEW: "invoice_new",
    INVOICE_PENDING: "invoice_pending",
    INOVICE_PAST_DUE: "inovice_past_due",
    INVOICE_COMPLETED: "invoice_completed",

    FILE_PLUS: "file-plus",

    PAID: "paid",
    PARTIAL_PAID: "partial-paid",

    PAYMENT: "payment",
    RADIO_YELLOW: "radio-yellow",
    INFO: "info",

    BANK: "bank",
    QUESTION: "question",

    MEMBERSHIP: "membership",
    MEMBERSHIP_CARD: "membership_card",
    
    TAG: "tag",

    EMAIL_BOX: "email-box",

    CROSS_RED: "cross-red",
    CROSS_GRAY: "cross-gray",
    ADD: "add",
    ARROW_UP_RIGHT_FROM_SQUARE_GREEN: "arrow-up-right-from-square-green",
    ARROW_UP_RIGHT_FROM_SQUARE_RED: "arrow-up-right-from-square-red",
    PLUS_BLACK: 'plus-black.png',
    MY_NETWORK: 'my-network.png',
    USER_GROUP: 'user-group.png'
}

export default ImageConstants