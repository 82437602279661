import React from 'react';
import "./payment-history.scss";
import Utils from '../../../utility/common-utilities'
import Constants from '../../../constants/constants';

class PaymentHistory extends React.Component {
    state = {
        invoice: this.props.invoice,
        histories: this.props.histories || [],
    }

    componentDidMount() {
    }


    getStatusBadge(status) {
        if (status == Constants.PAYMENT_STATUS.SUCCESS) return "payment-success"
        if (status == Constants.PAYMENT_STATUS.FAILED) return "payment-failed"

        return ""
    }

    render() {

        return (
            <div className="payment-history">
                <div>
                    <div id="table-scoll" className="table-responsive pt-2">
                        <table className="table mb-0 align-items-center bg-white">
                            <thead>
                                <tr>
                                    <th className=''>Transaction ID</th>
                                    {/* <th className=''>Organisation</th> */}
                                    <th className=''>Date Processed </th>
                                    <th className=''>Description </th>
                                    <th className=''>Status </th>
                                    <th className='text-right'>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.histories?.length ? this.state.histories.map((item, index) => (

                                        <tr key={item._id}>
                                            <td className="">{ item?.transactionId || ""} </td>
                                            {/* <td className=" semi-bold-text"> {item?.organization} </td> */}
                                            <td className=" created-date"> {Utils.datePipe(item.createdDate, Constants.DATE_FORMAT_1)} </td>
                                            <td className=" medium-text"> {item.description || "-"}</td>
                                            <td className=""><span className={`position-relative invoice-status-badge ${this.getStatusBadge(item.status)}`}><div className="d-inline-block draw-dot ">&nbsp;</div> <span className="pl-2">{Constants.PAYMENT_STATUS_TEXT[item?.status]}</span></span> </td>
                                            <td className="text-right bold-text font-14 pr-4">{this.state?.invoice?.currency?.symbol} {Utils.formatAmountStringStrict(item?.amount || 0)} </td>
                                        </tr>
                                    ))
                                        : <tr><td colSpan="10" align='center' className='text-gray'>No Records found !</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div >
        );
    }

}

export default PaymentHistory;