import HttpClient from './http-client';
import URL from './url';

export default class CommonApi {


    static getState(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.STATE + "?" + params.join('&') : URL.STATE;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

}
