import React from 'react';
import "./customers.scss";
import customerApi from '../../apis/customer'
import BranchApi from '../../apis/branch'

import Loading from '../common/loading/loading';
import SharedStore from '../../shared-store/shared-store';
import StaticText from '../../shared-store/static-text-store';
import AppConfirmModal from '../common/app-confirm/app-confirm'

import ImageConstants from '../../constants/image-constants';
import { getImageSource } from '../../utility/image-source'
import PriceSetup from "./price-setup/price-setup"

import SessionCache from '../routes/session-cache';
import ActionConstant from '../../constants/action-constant';
import Constants from '../../constants/constants';
import CustomerDetail from './customer-detail';

import { Tooltip } from 'reactstrap';
import CommonUtils from '../../utility/common-utilities';
import MyNetwork from '../../../layout/header/my-network/my-network';

class Customers extends React.Component {
    state = {
        viewCustomerDetail: false
    }
    accessRights = {}
    componentDidMount() {
        this.getCustomer()

        this.user = SessionCache.getUser()
        this.accessRights = this.user.accessRights || {}

        let staticText = StaticText.getStaticText("customers")
        this.setState({ staticText })
        document.addEventListener('mousedown', this.handleClickEvent, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }

    handleClickEvent = (event) => {
        if (this.containerRef && !this.containerRef.contains(event.target)) {
            if (!this.state.myNetworkAddPopUpVisible) this.closeMyNetWork()
        }
    }

    getCustomer(payload = {}) {
        this.setState({ isLoading: true })
        payload.type = [Constants.USER_TYPE.BOTH, Constants.USER_TYPE.CUSTOMER].join(",")
        payload.showAll = true
        customerApi.getCustomers(payload).then((response) => {
            this.setState({ customers: response.response || [], isLoading: false })
        }).catch((err) => {
            this.setState({ customers: [], isLoading: false })
        })
    }

    onClickAdd = () => {
        if (CommonUtils.checkTrialAccess(ActionConstant.ADD_CUSTOMER)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            let store = SharedStore.getStore("header")
            if (store.onClickAdd) store.onClickAdd()
        }
    }

    onClickDelete(event, item, index) {
        event.stopPropagation();
        this.setState({ deleteItem: item, showDeleteConfirm: true, deleteIndex: index })
    }

    handleCloseConfirm = () => {
        this.setState({ deleteItem: null, showDeleteConfirm: false })
    }

    onDelete = (id) => {

        let customers = this.state.customers
        customers = customers.filter(item => {
            return item._id !== id
        })
        this.setState({ customers })

    }

    onClickPriceSetup = (event, item, index) => {
        event.stopPropagation();

        this.setState({ selectedCustomer: item, showPriceSetup: true })
    }

    closePriceSetup = () => {
        this.setState({ showPriceSetup: false }, () => {
        })
    }

    makeActiveEdit = (value, index) => {
        let customers = this.state.customers
        customers[index].isActive = value
        this.setState({ customers })
    }

    onStatusUpdate(event, item, status) {
        event.stopPropagation();
        if (CommonUtils.checkTrialAccess(ActionConstant.CANCEL_INVITATION)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            let payload = {
                invitationId: item.invitationId,
                status
            }

            BranchApi.updateInvite(payload).then((response) => {
                this.getCustomer()
                if (CommonUtils.checkTrialAccess(ActionConstant.ACCEPT_INVITATION) || CommonUtils.checkTrialAccess(ActionConstant.REJECT_INVITATION)) {
                    SharedStore.getStore("subscription").showSubscriptionModal()
                } else {
                    if (status == "ACCEPTED" || status == "REJECTED") {
                        let store = SharedStore.getStore("header")
                        if (store.reduceInvitationBadgeCount) store.reduceInvitationBadgeCount(-1)
                    }

                }
            }).catch((err) => {
            })
        }

    }

    onShowProduct(item, index) {
        // if (CommonUtils.checkTrialAccess(ActionConstant.SHOW_PRODUCTS)) {
        //     SharedStore.getStore("subscription").showSubscriptionModal()
        //     return
        // } else if (CommonUtils.checkRights(ActionConstant.SHOW_PRODUCTS)) {

        let payload = {
            invitationId: item.invitationId,
            showProducts: !item.showProducts,
            showProductsKey: `${item.showProductsKey}.showProducts`
        }

        BranchApi.updateInvite(payload).then((response) => {
            let customers = this.state.customers
            customers[index].showProducts = payload.showProducts
            this.setState({ customers })
        }).catch((err) => {
        })
        // } else return ""

    }

    toggle = () => {
        this.setState({ tooltipOpen: !this.state.tooltipOpen });
    }

    showCustomerDetail = (event, item) => {
        if (CommonUtils.checkTrialAccess(ActionConstant.VIEW_CUSTOMER_DETAILS)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else if (CommonUtils.checkRights(ActionConstant.VIEW_CUSTOMER_DETAILS)) {
            this.setState({ customerDetail: item, viewCustomerDetail: true })
        } else return ""
    }

    handleBack = (id, refresh) => {

        if (refresh) {
            this.getCustomer()
        } else if (id) {
            this.onDelete(id)
        }
        this.setState({ viewCustomerDetail: false })
    }

    showMyNetworkPopUp = () => {
        this.setState({ showMyNetworkPopUp: true })
    }

    closeMyNetWork = () => {
        this.setState({ showMyNetworkPopUp: false })
    }


    render() {

        return (
            <div className="customers">


                {this.state.isLoading && <Loading />}
                {this.state.viewCustomerDetail && < CustomerDetail back={this.handleBack}
                    onDelete={this.onClickDelete}
                    businessUnit={this.state.customerDetail} />}

                {!this.state.viewCustomerDetail && <div>
                    <div className="">
                        <div className='row'>
                            <div className="col">
                                <strong className="active-title">My Network</strong>
                            </div>
                            <div className="col text-right">
                                <div className="d-inline-block position-relative">
                                    {CommonUtils.checkRights(ActionConstant.ADD_CUSTOMER) ? <button className="app-button-primary mr-2" onClick={this.showMyNetworkPopUp}>
                                        <span className="">+ ADD</span>
                                    </button> : ""}
                                    <div className="pt-1 text-left">
                                        {this.state.showMyNetworkPopUp ? <div ref={ref => this.containerRef = ref}><MyNetwork {...this.props} isHideMyNetwork close={this.closeMyNetWork} onModalHandler={(value) => this.setState({ myNetworkAddPopUpVisible: value })}  /></div> : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div id="table-scoll" className="table-responsive pt-2">
                            <table className="table mb-0 align-items-center  bg-white">
                                <thead>
                                    <tr>
                                        <th className='border-top-none text-center slno-max-width'>{this.state.staticText?.snoText}</th>
                                        <th className='border-top-none '>{this.state.staticText?.staffNameText}</th>
                                        <th className='border-top-none '>{this.state.staticText?.emailText}</th>
                                        <th className='border-top-none '>{this.state.staticText?.mobileText}</th>
                                        <th className='border-top-none '>{this.state.staticText?.locationText}</th>
                                        <th className='border-top-none text-center'>
                                            <span id="tool-tip">{this.state.staticText?.showProductsText}</span>
                                            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="tool-tip" toggle={this.toggle}>
                                                {this.state.staticText?.showProductsTooltipText || "Selecting this option will show your products and their prices in your buyer's shopping page"}
                                            </Tooltip>
                                        </th>
                                        <th className='border-top-none'>More</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.customers?.length ? this.state.customers.map((item, index) => (

                                            <tr key={item._id} onClick={(event) => this.showCustomerDetail(event, item)} className="staff-row">
                                                <td className="semi-bold-text text-center">{index + 1}</td>
                                                <td className="cursor-pointer">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <div className="staff-name-max-height font-14 bold-text vertical-line-clamp-2 ">{item.businessName}</div>
                                                            <div className='staff-code'>{item.cubeId || "-"}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="staff-code font-14">{item.email || "-"}</td>
                                                <td className="semi-bold-text font-14">{item.mobileNumber && item.countryCode ? <span>{item.countryCode} {item.mobileNumber}</span> : "-"}</td>
                                                <td className="semi-bold-text font-14">{item?.address?.city || "-"}</td>
                                                <td className="semi-bold-text font-14">
                                                    {
                                                        item.status !== "PENDING" ?
                                                            <div onClick={(event) => { event.stopPropagation(); event.preventDefault(); this.onShowProduct(item, index) }} class={`custom-control custom-switch custom-control-switch text-center`}>
                                                                <input type="checkbox" class="custom-control-input" id={`isProductShow_${index}`} checked={!!item.showProducts} />
                                                                <label class="custom-control-label" for={`isProductShow_${index}`}></label>
                                                            </div>
                                                            : ""
                                                    }
                                                </td>
                                                {/* <td className=''>
                                                    {
                                                        item.status == "PENDING" ?
                                                            <>
                                                                {
                                                                    item.invitedByBranchId == this.user.branchId ?
                                                                        <span> {CommonUtils.checkRights(ActionConstant.CANCEL_INVITATION) ?
                                                                            <span className='app-button-red-small mr-2' onClick={(event) =>
                                                                                this.onStatusUpdate(event, item, "CANCELLED")
                                                                            }>
                                                                                <span className='btn-text'>{this.state?.staticText.cancelText}</span>
                                                                            </span>
                                                                            : ""}
                                                                        </span>
                                                                        :
                                                                        <span className="text-center">

                                                                            {
                                                                                CommonUtils.checkRights(ActionConstant.REJECT_INVITATION) ?
                                                                                    <span className='app-button-secondary-small mr-2'
                                                                                        onClick={(event) => this.onStatusUpdate(event, item, "REJECTED")}>
                                                                                        <span className='btn-text'>{this.state?.staticText?.rejectText || "Reject"}
                                                                                        </span>
                                                                                    </span> : ""
                                                                            }

                                                                            {
                                                                                CommonUtils.checkRights(ActionConstant.ACCEPT_INVITATION) ?
                                                                                    <span className='app-button-primary-small'
                                                                                        onClick={(event) => this.onStatusUpdate(event, item, "ACCEPTED")}>
                                                                                        <span className='btn-text'>{this.state?.staticText?.acceptText || "Accept"}
                                                                                        </span>
                                                                                    </span> : ""
                                                                            }
                                                                        </span>
                                                                }
                                                            </>
                                                            :
                                                            ""
                                                    }

                                                </td> */}
                                                <td>
                                                    <img className="icon-10" alt="" src={getImageSource(ImageConstants.ARROW_RIGHT)} />
                                                </td>
                                            </tr>
                                        ))
                                            : <tr><td colSpan="10" align='center' className='text-gray'>{this.state?.staticText?.noRecordText}</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>}

                {
                    this.state.showDeleteConfirm ? <AppConfirmModal
                        img={getImageSource(ImageConstants.CLOSE_RED)}
                        okButtonText={this.state.staticText?.deleteText}
                        okButtonStyle="reject-text"
                        title={this.state.staticText?.deleteTitle}
                        close={this.handleCloseConfirm}
                        onOk={this.onDelete}
                    /> : ""
                }
                {this.state.showPriceSetup ? <PriceSetup cancel={() => this.closePriceSetup()} customer={this.state.selectedCustomer} /> : <></>}

            </div>
        );
    }

}

export default Customers;