import React from 'react';
import "./vendors.scss";
import customerApi from '../../../apis/customer'
import BranchApi from '../../../apis/branch'

import Loading from '../../common/loading/loading';
import SharedStore from '../../../shared-store/shared-store';
import StaticText from '../../../shared-store/static-text-store';
import AppConfirmModal from '../../common/app-confirm/app-confirm'

import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../../utility/image-source'

import SessionCache from '../../routes/session-cache';
import ActionConstant from '../../../constants/action-constant';
import Constants from '../../../constants/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, } from '@fortawesome/free-solid-svg-icons';
import CommonUtils from '../../../utility/common-utilities';
import MyNetwork from '../../../../layout/header/my-network/my-network';
import AcceptInvitation from '../accept-invitation/accept-invitation';

class Vendors extends React.Component {
    state = {
    }

    accessRights = {}

    componentDidMount() {
        this.getCustomer()

        this.user = SessionCache.getUser()
        this.accessRights = this.user.accessRights || {}

        let staticText = StaticText.getStaticText("vendors")
        this.setState({ staticText })
        document.addEventListener('mousedown', this.handleClickEvent, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }

    handleClickEvent = (event) => {
        if (this.containerRef && !this.containerRef.contains(event.target)) {
            if (!this.state.myNetworkAddPopUpVisible) this.closeMyNetWork()
        }
    }

    getCustomer(payload = {}) {
        this.setState({ isLoading: true })
        payload.type = [Constants.USER_TYPE.BOTH, Constants.USER_TYPE.VENDOR].join(",")
        payload.showAll = true
        customerApi.getCustomers(payload).then((response) => {
            this.setState({ customers: response.response || [], isLoading: false })
        }).catch((err) => {
            this.setState({ customers: [], isLoading: false })
        })
    }

    onClickAdd = () => {
        let store = SharedStore.getStore("header")
        if (store.onClickAdd) store.onClickAdd()
    }

    onClickDelete(item, index) {
        this.setState({ deleteItem: item, showDeleteConfirm: true, deleteIndex: index })
    }

    handleCloseConfirm = () => {
        this.setState({ deleteItem: null, showDeleteConfirm: false })
    }

    onDelete = () => {
        let payload = {
            id: this.state.deleteItem?._id,
        }
        customerApi.removeCustomer(payload).then((response) => {
            this.handleCloseConfirm()
            let customers = this.state.customers
            customers.splice(this.state.deleteIndex, 1)
            this.setState({ customers })
        }).catch((err) => {
            alert(err);
        })
    }

    onStatusUpdate(item, status, acceptedAs) {
        let payload = {
            invitationId: item.invitationId,
            status,
            acceptedAs
        }

        BranchApi.updateInvite(payload).then((response) => {
            this.getCustomer()
            if (status == "ACCEPTED" || status == "REJECTED") {
                let store = SharedStore.getStore("header")
                if (store.reduceInvitationBadgeCount) store.reduceInvitationBadgeCount(-1)
            }
        }).catch((err) => {
        })
    }


    showMyNetworkPopUp = () => {
        this.setState({ showMyNetworkPopUp: true })
    }

    closeMyNetWork = () => {
        this.setState({ showMyNetworkPopUp: false })
    }

    onClickAccept(item, index) {

        this.setState({ selectedItem: item, acceptInvitationIndex: index })
    }

    onConfirmAccept = (type) => {
        this.onStatusUpdate(this.state.selectedItem, "ACCEPTED", type)
    }

    onCloseInvitation = () => {
        this.setState({ acceptInvitationIndex: -1, selectedItem: null })

    }


    render() {

        return (
            <div className="vendors">

                {this.state.isLoading && <Loading />}
                <div className="">
                    <div className='row'>
                        <div className="col">
                            <strong className="active-title">My Network</strong>
                        </div>
                        <div className="col text-right">
                            <div className="d-inline-block position-relative">
                                {CommonUtils.checkRights(ActionConstant.ADD_CUSTOMER) ? <button className="app-button-primary mr-2" onClick={this.showMyNetworkPopUp}>
                                    <span className="">+ ADD</span>
                                </button> : ""}
                                <div className="pt-1 text-left">
                                    {this.state.showMyNetworkPopUp ? <div ref={ref => this.containerRef = ref}><MyNetwork {...this.props} isHideMyNetwork close={this.closeMyNetWork} onModalHandler={(value) => this.setState({ myNetworkAddPopUpVisible: value })} /></div> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div id="table-scoll" className="table-responsive pt-2">
                        <table className="table mb-0 align-items-center  bg-white">
                            <thead>
                                <tr>
                                    <th className='border-top-none text-center slno-max-width'>{this.state.staticText?.snoText}</th>
                                    <th className='border-top-none '>{this.state.staticText?.staffNameText}</th>
                                    <th className='border-top-none '>{this.state.staticText?.emailText}</th>
                                    <th className='border-top-none '>{this.state.staticText?.mobileText}</th>
                                    <th className='border-top-none '>{this.state.staticText?.locationText}</th>
                                    <th className='border-top-none action-button-width'>{this.state.staticText?.actionText}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.customers?.length ? this.state.customers.map((item, index) => (

                                        <tr key={item._id} className="staff-row">
                                            <td className="semi-bold-text text-center">{index + 1}</td>
                                            <td className="cursor-pointer">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="staff-name-max-height font-14 bold-text vertical-line-clamp-2 ">{item.businessName}</div>
                                                        <div className='staff-code'>{item.cubeId || "-"}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="staff-code font-14">{item.email || "-"}</td>
                                            <td className="font-14">{item.mobileNumber && item.countryCode ? <span>{item.countryCode} {item.mobileNumber}</span> : "-"}</td>
                                            <td className="font-14">{item?.address?.city || "-"}</td>
                                            <td className='action-td'>

                                                {
                                                    item.status == "PENDING" ?
                                                        <div>
                                                            {
                                                                item.invitedByBranchId == this.user.branchId ?
                                                                    <span> {CommonUtils.checkRights(ActionConstant.CANCEL_INVITATION) ?
                                                                        <span className='app-button-red-small mr-2' onClick={() => this.onStatusUpdate(item, "CANCELLED")}>
                                                                            <span className='btn-text'>{this.state?.staticText.cancelText}</span>
                                                                        </span>
                                                                        : ""}
                                                                    </span>
                                                                    :
                                                                    <div className="">
                                                                        {
                                                                            CommonUtils.checkRights(ActionConstant.REJECT_INVITATION) ?
                                                                                <div className="d-inline-block">
                                                                                    <span className='app-button-secondary-small mr-2'
                                                                                        onClick={() => this.onStatusUpdate(item, "REJECTED")}>
                                                                                        <span className='btn-text'>{this.state?.staticText?.rejectText || "Reject"}
                                                                                        </span>
                                                                                    </span>
                                                                                </div> : ""
                                                                        }

                                                                        {
                                                                            CommonUtils.checkRights(ActionConstant.ACCEPT_INVITATION) ?
                                                                                <div className="d-inline-block">
                                                                                    <span className='app-button-primary-small'
                                                                                        onClick={() => this.onClickAccept(item, index)}>
                                                                                        <span className='btn-text'>{this.state?.staticText?.acceptText}
                                                                                        </span>
                                                                                    </span>

                                                                                    {this.state.acceptInvitationIndex === index ?
                                                                                        <div className="accept-wrapper text-left">
                                                                                            <AcceptInvitation name={item.businessName} close={this.onCloseInvitation} onAccept={this.onConfirmAccept} />
                                                                                        </div>
                                                                                        : ""}
                                                                                </div> : ""
                                                                        }
                                                                    </div>
                                                            }
                                                        </div>
                                                        :
                                                        CommonUtils.checkRights(ActionConstant.REMOVE_CUSTOMER) ?
                                                            <span className='app-button-secondary-small mr-2' onClick={(event) => this.onClickDelete(item, index)}>
                                                                <span className='btn-text'>{this.state?.staticText.deleteText}</span>
                                                            </span>
                                                            : ""
                                                }
                                            </td>
                                        </tr>
                                    ))
                                        : <tr><td colSpan="10" align='center' className='text-gray'>No Records found !</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
                {
                    this.state.showDeleteConfirm ? <AppConfirmModal
                        img={getImageSource(ImageConstants.CLOSE_RED)}
                        okButtonText={this.state.staticText?.deleteText}
                        okButtonStyle="reject-text"
                        title={this.state.staticText?.deleteTitle}
                        close={this.handleCloseConfirm}
                        onOk={this.onDelete}
                    /> : ""
                }
            </div>
        );
    }

}

export default Vendors;