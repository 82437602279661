import React from 'react';
import { Redirect } from 'react-router-dom';
import "./login.scss";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import AuthenticateAPI from '../../apis/authenticate'
import SessionCache from '../routes/session-cache';
import Path from '../routes/routes-path';
import ReCAPTCHA from "react-google-recaptcha";
import UpdateTenant from "./update-tenant/update-tenant"

import Constants from '../../constants/constants';
import ImageConstants from "../../constants/image-constants"

import { getImageSource } from '../../utility/image-source';

import Utils from '../../utility/common-utilities';
import StaticText from '../../shared-store/static-text-store';

import { gapi } from 'gapi-script';
import { GoogleSignIn } from './google';

class Login extends React.Component {
    state = {
        staticText: {}
    }

    start() {
        gapi.client.init({
            client_id: process.env.REACT_APP_ENV === 'production' ? Constants.GOOGLE_LOGIN_KEY : Constants.DEV_GOOGLE_LOGIN_KEY,     /* CHG000001*/
            scope: 'email',
        });
    }


    componentDidMount() {
        let staticText = StaticText.getStaticText("login")
        this.setState({ staticText })

        gapi.load('client:auth2', this.start); //it's use for updated google login
    }

    redirectHomePage(userDetails) {
        if (userDetails?.totalBranch) {
            this.props.history.replace(Path.DASHBOARD)
        } else {
            this.props.history.replace(Path.TENANT_WORKFLOW)
        }
    }

    onSuccessGoogleLogin = (res) => {
        let payload = {
            from: "google",
            token: res.tokenId,
            id: res.googleId
        }

        AuthenticateAPI.loginUsingClient(payload).then((response) => {
            let userDetails = response.data
            if (!userDetails.email) alert("Login Success, Email Id is missing so you won't receive welcome email");
            SessionCache.setUser(userDetails)
            this.redirectHomePage(userDetails)
        }).catch((err) => {
            this.setState({ dot: "", })
            alert(err, "Login Faild");
        })
    }

    onFailureGoogleLogin = (res) => {
        console.log("google failed:", res)

    }


    onChangeHandler(event, key) {
        this.setState({ [key]: event?.target?.value }, () => {
            this.validatePayload()
        })
    }

    validatePayload(showError) {

        let isValidForm = true
        if (!this.state.email) {
            showError && this.setState({ isInvalidEmail: true })
            isValidForm = false
        } else if (showError && !Utils.validateEmail(this.state.email)) {
            this.setState({ isInvalidEmail: true })
            return
        } else this.setState({ isInvalidEmail: false })


        if (!this.state.password) {
            showError && this.setState({ isInvalidPassword: true })
            isValidForm = false
        } else this.setState({ isInvalidPassword: false })

        if (!this.state.isCaptchaVerified) {
            this.setState({ errMessage: "Captcha not Verified" })
            isValidForm = false
        }

        this.setState({ isValidForm, errMessage: "" })
        return isValidForm
    }

    onClickFbLogin = (res) => {
        console.log(res)

    }

    handleFbLogin = (res) => {
        let payload = {
            from: "facebook",
            token: res?.accessToken,
            id: res?.id
        }

        if (!payload?.token) {
            alert("Login Faild");
            return
        }

        AuthenticateAPI.loginUsingClient(payload).then((response) => {
            let userDetails = response.data
            SessionCache.setUser(userDetails)
            this.redirectHomePage(userDetails)
        }).catch((err) => {
            this.setState({ dot: "", })
            alert(err, "Login Faild");
        })
    }

    onClickLogin = () => {
        if (this.state.isSubmit) return
        let payload = {
            email: this.state.email,
            password: this.state.password,
            token: this.state.token
        };

        let validation = this.validatePayload(true)
        if (!validation) return

        this.setState({ isSubmit: true });

        AuthenticateAPI.login(payload).then((response) => {
            let userDetails = response.data
            SessionCache.setUser(userDetails)

            if (userDetails.isBusinessCaptured) {
                this.redirectHomePage(userDetails)
            } else {
                this.setState({ showProfileUpdate: true, isSubmit: false })
            }

        }).catch((err) => {
            this.setState({ isSubmit: false });
            alert("Invalid credentials");
        })

    }


    changeToSignup = () => {
        this.setState({ showSignupView: true })
    }

    changeToLogin = () => {
        this.setState({ showSignupView: false })
    }

    onClickForgotPassword = () => {
        this.props.history.push(Path.FORGOT_PASSWORD)
    }

    onChangeCaptcha = (value) => {
        this.setState({ isCaptchaVerified: !!value, token: value }, () => {
            this.validatePayload()
        })

    }


    renderGoogleLogin() {
        return (
            <div className="">
                <GoogleSignIn onSocialLogin={this.onSuccessGoogleLogin} />
                <div className="mt-2 d-flex justify-content-center">
         {/*           <FacebookLogin
                        autoLoad={false}
                        appId={Constants.FB_LOGIN_KEY}
                        fields="name,email"
                        callback={this.handleFbLogin}
                        render={this.fbButton}
        />   Pramod Disabled FB login 7/28/23                 */}
                </div>
            </div>)
    }

    fbButton = (renderProps) => {
        return (
            <div className="fb-login text-center my-auto">
                <img alt="" src={getImageSource(ImageConstants.FACEBOOK)} />
                <div className="ml-2 my-auto d-inline-block" onClick={renderProps.onClick}>{this.state.staticText.fbLoginText}</div>
            </div>
        )
    }

    onSaveProfile = () => {
        this.props.history.replace(Path.BRANCH)
    }

    renderProfileUpdate = () => {
        return (
            <div className="app-card singup-card p-4">
                <div className="text-center header-text">
                    Update Profile
                </div>
                <div>
                    <UpdateTenant onSave={this.onSaveProfile} />
                </div>
            </div>
        )
    }

    onClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }

    onClickOption(path) {
        this.setState({ navigatePath: path })
    }

    openTerms(path) {
        let url = `${window.location.origin}${path}`
        window.open(url)
    }

    renderLoginCard() {

        return (
            <div className="login-card">
                <div className="p-4">
                    <div className="text-center header-text pt-4">
                        {this.state.staticText?.loginHeaderTitle}
                    </div>
                    <div className='text-center mt-2 sub-header-text'>
                        Don't have an account? <span className='click-here bold-text' onClick={() => this.onClickOption(Path.SIGN_UP)}>Create One</span>
                    </div>

                    <div className="pt-4">

                        <div className={`bw-spaces-input ${this.state.isInvalidEmail ? "invalid-input-container" : ""}`}>
                            <div className='app-input-label'>Email <span className='star'>*</span></div>
                            <input
                                id="login-email" className={`app-input`}
                                placeholder='Enter Email'
                                value={this.state.email}
                                onChange={(e) => this.onChangeHandler(e, 'email')}
                                onBlur={(e) => this.validatePayload()}
                            />
                        </div>

                        <div className={`bw-spaces-input ${this.state.isInvalidPassword ? "invalid-input-container" : ""} position-relative`}>
                            <div className='app-input-label'>Password <span className='star'>*</span></div>
                            <input id="login-password" className={`app-input`} placeholder='Enter Password'
                                value={this.state.password}
                                type={this.state.showPassword ? "" : "password"}
                                onChange={(e) => this.onChangeHandler(e, 'password')} />
                            <span className="password-icon">
                                <img className="cursor-pointer icon-20" alt=""
                                    src={getImageSource(this.state.showPassword ? ImageConstants.EYE_OPEN : ImageConstants.EYE_CLOSE)}
                                    onClick={this.onClickShowPassword}
                                />
                            </span>
                        </div>


                        <div className='bw-spaces-input text-right'>
                            <span className='forgot cursor-pointer' onClick={this.onClickForgotPassword}>{this.state.staticText?.forgotPasswordTitle}</span>
                        </div>
                    </div>

                    <div className="p-4 text-center">
                        <div className="d-inline-block">
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_ENV === 'production' ? Constants.CAPTCHA_KEY : Constants.DEV_CAPTCHA_KEY} /*CHG000001 */
                                onChange={this.onChangeCaptcha}
                            />
                        </div>
                    </div>
                    <div className="pt-4 text-center">
                        <button className={`app-button-primary w-100 ${this.state.isValidForm ? "" : "app-button-disabled"}`} onClick={this.onClickLogin}>
                            {this.state.staticText.loginTitle}
                        </button>
                    </div>
                    <div className="text-center or">
                        <span className="line">OR</span>
                    </div>
                    <div className="pt-4">
                        {this.renderGoogleLogin()}
                    </div>

                    <div className='mt-3 text-center'>
                        <span className="tc cursor-pointer" onClick={() => this.openTerms(Path.SERVICES_AGREEMENT)}>Terms</span>
                        {" & "}
                        <span className="tc cursor-pointer" onClick={() => this.openTerms(Path.PRIVACY_POLICY)}>Privacy policy</span>
                    </div>
                </div>
            </div>
        )
    }

    render() {

        return (
            <>
                {this.state.navigatePath && <Redirect to={this.state.navigatePath} />}
                <div className="login">
                    <div className="employee-login" onClick={() => this.onClickOption(Path.STAFF_LOGIN)}>
                        <span>Employee Login</span>
                    </div>
                    <div className='left-image'>
                        <img className="login-image" alt="" src={getImageSource(ImageConstants.LOGIN_BACKGROUND)} />
                    </div>
                    <div className="right-content">
                        <div>
                            <div className="col">
                                <div>{this.renderLoginCard()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default Login;