import React from 'react';
import "./invoice-detail.scss";
import InvoiceApi from '../../../../apis/invoice'

import Payment from '../../../../apis/payment';
import ToasterComponent from '../../../common/toaster/toaster';
import StaticText from '../../../../shared-store/static-text-store';
import Utils from '../../../../utility/common-utilities';
import Constants from '../../../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Path from '../../../routes/routes-path'

import ImageConstants from '../../../../constants/image-constants';
import { getImageSource } from '../../../../utility/image-source'
import PaymentHistory from '../payment-history/payment-history';

class InvoiceDetail extends React.Component {

    state = {
        product: {},
        productList: [],
        activeItemDiscount: "PERCENTAGE",
        paymentTermsDropdownItems: [],
        currentDate: new Date(),
        selectedTab: 0
    }

    constructor(props) {
        super(props)
        this.staticText = StaticText.getStaticText("invoice")

        if (this.props.invoice) {
            this.state.invoice = this.props.invoice
            this.state.activeItemDiscount = this.props.invoice?.items?.length ? this.props.invoice?.items[0]?.discount.type : ""
            this.state.activeGloableDiscountSwitch = this.props.invoice?.discount?.type
        }
    }

    totalPages = 1;
    page = 1


    componentDidMount() {
        this.getInvoices()
        this.getVendorPaymentStatus()
    }

    getInvoices(payload = {}) {
        payload.invoiceId = this.state.invoice?._id

        InvoiceApi.getInvoices(payload).then((response) => {
            this.setState({ invoice: response.response[0], isLoading: false })

        }).catch((err) => {
            this.setState({ isLoading: false })

        })
    }

    getVendorPaymentStatus(payload = {}) {
        payload.branchId = this.state.invoice?.vendor?.id || ""
        InvoiceApi.getPaymentStatus(payload).then((response) => {
            this.setState({ paymentStatus: response?.status, isLoading: false })
        }).catch((err) => {
            this.setState({ isLoading: false })
        })
    }

    onClickPay = () => {

        this.setState({ payButtonLoading: true })
        const payload = {
            invoiceId: this.state.invoice?._id,
            successUrl: window.location.origin + Path.PAYMENT_RESPONSE,
            cancelUrl: window.location.origin + Path.PAYMENT_RESPONSE,
        }
        Payment.createPaymentSession(payload).then(({ data }) => {
            // this.setState({ payButtonLoading: false })
            window.location.assign(data.url)
        }).catch((err) => {
            this.setState({ payButtonLoading: false })
            if (err?.response?.data?.code == 904) {
                this.showToaster("error", "Vendor did not enable the payment")
            } else {
                this.showToaster("error", "Something went wrong")
            }
            console.error(err)
        })
    }

    onClickTab(selectedTab) {
        this.setState({ selectedTab })
    }

    showToaster(type, message) {
        this.setState({
            showToaster: true,
            toaster: {
                type,
                message,
            }
        }, () => {
            setTimeout(() => this.hideToaster(), 3000)
        })
    }

    hideToaster() {
        this.setState({ showToaster: false })
    }

    renderNameBadge(name, customeClass) {
        if (!name) return ""
        let splitName = name.split(" ")
        let tag = ""
        if (splitName.length > 1) {
            tag = `${splitName[0][0] || ""}${splitName[1][0] || ""}`
        } else if (splitName) {
            tag = `${splitName[0][0]}`
        }
        tag = tag ? tag.toUpperCase() : ""
        return (<div className={`d-inline-block name-badge ${customeClass || ""}`}><span className="">{tag || ""}</span></div>)

    }

    getUserAddress(address) {
        let str = [address.addressLine1]

        if (address.addressLine2) str.push(address.addressLine2)
        if (address.city) str.push(address.city)
        if (address.state) str.push(address.state)
        if (address.country) str.push(address.country)
        if (address.code) str.push(address.code)
        return str.join(", ")
    }


    renderInvoiceDetail() {
        let vendor = this.state.invoice?.vendor
        let buyer = this.state.invoice?.buyer
        return (

            <div className="invoice-detail-wrapper">
                <div>{this.staticText.invoiceDetails}</div>
                <div className="pt-4">
                    <div className="p-4">
                        <div className="row">

                            <div className="col">
                                <div className="row no-gutters">
                                    <div className="d-inline-block mr-4">
                                        {this.renderNameBadge(vendor?.name || "")}
                                    </div>
                                    <div className="d-inline-block">
                                        <div className="address">{this.staticText.invoiceFrom} </div>
                                        <div className="user-name"> {vendor?.name || ""} </div>
                                        <div className="address">{this.getUserAddress(vendor?.address || {})} </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col text-right">
                                <div className="d-inline-block">
                                    <div className="user-name">{this.staticText.invoiceNumber} {this.state.invoice?.invoiceNumber || ""} </div>
                                    <div className="address">
                                        <div>{this.staticText.issuedDate} : {this.state.invoice?.createdDate ? Utils.datePipe(this.state.invoice?.createdDate, Constants.DATE_FORMAT_1) : "-"}</div>
                                        <div>{this.staticText.dueDate} : {this.state.invoice?.dueDate ? Utils.datePipe(this.state.invoice?.dueDate, Constants.DATE_FORMAT_1) : "-"}</div>
                                        <div>{this.staticText.paymentTerms} : {this.state.invoice?.paymentTerms?.title ? this.state.invoice?.paymentTerms?.title : "-"}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        )
    }

    renderItemDetail() {
        let products = this.state.invoice?.items
        return (

            <div className="invoice-detail-wrapper">
                <div>{this.staticText.itemDetails}</div>

                <div className="ordered-product pt-4">
                    <div id="table-scoll" className="table-responsive pt-2">
                        <table className="table mb-0 align-items-center bg-white">
                            <thead>
                                <tr>
                                    <th className='th-product-max-width'>Item</th>
                                    <th className='th-max-width text-center'>Invoiced Qty</th>
                                    <th className='th-max-width text-center'>Unit Price</th>
                                    <th className='th-max-width text-center'>Discount {products && products[0]?.discount?.type === "PERCENTAGE" ? this.staticText.percentage : this.staticText.dollar}</th>
                                    <th className='th-max-width text-right'> Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    products?.length ? products?.map((item, index) => (

                                        <tr key={item.code} >
                                            <td className="cursor-pointer">
                                                <div className={`user-name`}>{item.product?.name}</div>
                                            </td>
                                            <td className=" sub-text text-center">{item.quantity}</td>
                                            <td className=" sub-text text-center"> {item?.product?.currency?.symbol} {Utils.formatAmountStringStrict(+item?.product.price)} </td>
                                            <td className={`cursor-pointer sub-text text-center ${this.state.onFocusInputIndex === index ? "p-0" : ""}`}>
                                                {this.getDiscountText(item?.discount)}
                                            </td>
                                            <td className=" sub-text text-right">{item?.product?.currency?.symbol} {Utils.formatAmountStringStrict(item.billingAmount >= 0 ? item.billingAmount : item.grossAmount)}</td>

                                        </tr>
                                    ))
                                        : <tr><td colSpan="6" align='center' className='text-gray'>No Records found !</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>

        )
    }


    getDiscountText(discount) {

        if (discount?.value >= 0 && discount?.type) {

            if (discount?.type == "PERCENTAGE") {
                return `${discount?.value}%`
            } else {
                return `${this.state.invoice?.currency?.symbol}${discount?.value}`
            }
        }
    }

    getProductGrossAmount(products) {
        let amount = 0
        products.forEach(element => {
            amount += element.billingAmount
        })

        return amount
    }

    renderDiscountDetail() {
        let invoice = this.state.invoice
        return (
            <div className="invoice-detail-wrapper">
                <div className="row">
                    <div className="col">
                        <div className="">
                            <div className="add-notes-wrapper">
                                <span className="notes">{this.staticText?.notes}</span>
                                <span className="pl-2 note">{invoice?.notes || "-"}</span>
                            </div>
                        </div>
                    </div>

                    <div className="col text-right">

                        {
                            invoice?.status === Constants.INVOICE_STATUS.PARTIALLY_PAID || invoice?.status === Constants.INVOICE_STATUS.FULLY_PAID ?
                                <div className="paid-image">
                                    <img alt="" src={getImageSource(invoice?.status === Constants.INVOICE_STATUS.FULLY_PAID ? ImageConstants.PAID : ImageConstants.PARTIAL_PAID)} />
                                </div>
                                : ""
                        }

                        <div className="">
                            <span className="discount-text pr-4">{this.staticText?.totalAmount} </span>
                            <div className="d-inline-block min-width-sm discount-text pr-4">{invoice?.currency?.symbol} {Utils.formatAmountStringStrict(this.getProductGrossAmount(invoice?.items || []))}</div>
                        </div>

                        <div className="pt-4">
                            <span className="discount-text pr-4">{this.staticText.discountText} ({this.getDiscountText(invoice?.discount)}) </span>
                            <div className="d-inline-block min-width-sm discount-text pr-4"> {invoice?.discount.value ? `- ${invoice?.currency?.symbol}` : ""}{Utils.formatAmountStringStrict(invoice?.discount.calculatedAmount || 0)}</div>
                        </div>

                        <div className="d-flex pt-4 pr-3">
                            <div className='flex-1'>
                                <span className="discount-text">{this.staticText.deliveryCharges} ({invoice?.currency?.symbol}{invoice?.charges?.additionalCharges?.value || 0})</span>
                                <br /><span className='pallet-text discount-text'>{this.staticText.palletCharges}</span>
                            </div>
                            <div className='flex-2 min-width-sm p-0 my-auto'>
                                <span className=" discount-text pr-2"> {invoice?.charges?.additionalCharges?.value ? invoice?.currency?.symbol : ""}{Utils.formatAmountStringStrict(invoice?.charges?.additionalCharges?.value)}</span>
                            </div>
                        </div>

                        <div className="pt-4">
                            <span className="discount-text pr-4">{this.staticText.salesTax} ({invoice?.charges?.tax?.value || 0}%)</span>
                            <div className="d-inline-block min-width-sm discount-text pr-4"> {invoice?.charges?.tax?.value ? invoice?.currency?.symbol : ""}{Utils.formatAmountStringStrict(invoice?.charges?.tax?.calculatedAmount || 0)}</div>
                        </div>
                    </div>
                </div>

                <div className="pt-4">
                    <div className="grand-total-wrapper">
                        <span className="user-name">{this.staticText.invoiceAmount}</span>
                        <span className="user-name float-right">{this.state?.invoice?.currency?.symbol} {Utils.formatAmountStringStrict(this.state?.invoice?.billingAmount)}</span>
                    </div>
                </div>

                {
                    invoice.status === Constants.INVOICE_STATUS.PARTIALLY_PAID ?
                        <div>
                            <div className="pt-4 text-right ">
                                <span className="discount-text pr-4">{this.staticText.paidAmount} </span>
                                <div className="d-inline-block min-width-sm discount-text pr-4"> {invoice?.currency?.symbol}{Utils.formatAmountStringStrict(invoice?.paidAmount)}</div>
                            </div>

                            <div className="pt-4 text-right ">
                                <span className="discount-text pr-4">{this.staticText.pendingAmount} </span>
                                <div className="d-inline-block min-width-sm discount-text pr-4"> {invoice?.currency?.symbol}{Utils.formatAmountStringStrict(invoice?.billingAmount - (invoice.paidAmount || 0))}</div>
                            </div>
                        </div>
                        : ""

                }

            </div>
        )
    }

    renderInvoice() {


        return (
            <div className='invoice-detail-wrapper-card p-4'>
                <div className=''>
                    <div>
                        {this.renderInvoiceDetail()}
                    </div>
                    <div className="pt-4">
                        {this.renderItemDetail()}
                    </div>
                    <div className="pt-4">
                        {this.renderDiscountDetail()}
                    </div>
                </div>

                <div className="custom-footer py-4 mt-4">
                    {this.state.paymentStatus !== "ACTIVE" ? <div className='payment-status float-right'>
                        <img className='image-info icon-20' alt='' src={getImageSource(ImageConstants.INFO)} />
                        {this.state.invoice?.vendor.name} has not enabled online
                        payments.Please contact {this.state.invoice?.vendor.name} so you can pay online.
                    </div> : (

                        this.state.invoice?.isReviewed && this.state.invoice?.status != Constants.INVOICE_STATUS.FULLY_PAID ?
                            <button disabled={this.state.payButtonLoading} className="app-button-success float-right mr-2" onClick={this.onClickPay}>
                                {this.state.payButtonLoading ? <FontAwesomeIcon icon={faSyncAlt} spin /> : <span className="pl-2">{this.staticText?.payButtonText}</span>}
                            </button>
                            : "")

                    }

                </div>
            </div>
        )
    }

    renderPaymentHistory() {
        return <PaymentHistory invoice={this.state.invoice} histories={this.state.invoice?.payments || []} />
    }

    render() {

        return (
            <>
                <div className='app-container customer-invoice-detail'>

                    <div className="position-relative">
                        <div className="d-inline-block back-icon" onClick={this.props.back}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </div>
                        <div className="user-name">{this.staticText.invoiceNumber} {this.state.invoice?.invoiceNumber || ""} </div>
                        <div className="address">{this.staticText.orderNumber} {this.state.invoice?.orderNumber || ""}</div>
                    </div>

                    <div className="pt-4 tab-wrapper">
                        <span className={`tab mr-4 ${this.state.selectedTab === 0 ? "active-tab" : ""}`} onClick={() => this.onClickTab(0)}>{this.staticText?.tab1}</span>
                        <span className={`tab ${this.state.selectedTab === 1 ? "active-tab" : ""}`} onClick={() => this.onClickTab(1)}>{this.staticText?.tab2}</span>
                    </div>

                    <div className="pt-4">
                        {
                            this.state.selectedTab === 0 ? this.renderInvoice() : this.renderPaymentHistory()
                        }
                    </div>
                </div>
                {this.state.showToaster ? <ToasterComponent type={this.state.toaster?.type} message={this.state.toaster?.message} /> : ""}
            </>
        );
    }
}

export default InvoiceDetail;


