//New Module for Inventory INV000001

import React from 'react';
import "./inventory-product-mapping.scss";

import inventoryApi from '../../../apis/inventory';                        // INV000001
import productApi from '../../../apis/product';

import ImageConstants from '../../../constants/image-constants';
import Constants from '../../../constants/constants';
import ActionConstant from '../../../constants/action-constant';
import { getImageSource } from '../../../utility/image-source'
import StaticText from '../../../shared-store/static-text-store';

import { Modal, ModalBody } from "reactstrap";
import ItemTextInput from './item-input';
import HandlingUnitInput from './handling-unit-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../common/loading/loading'
import ToasterComponent from '../../common/toaster/toaster';
import SessionCache from '../../routes/session-cache';
import ImagePreview from '../../common/image-preview'
import CommonUtils from '../../../utility/common-utilities';
import SharedStore from '../../../shared-store/shared-store'
import ImportExport from '../import-export/import-export';
import { UncontrolledTooltip } from "reactstrap"


class InventoryProductMapping extends React.Component {
    constructor(props) {
        super(props)

        this.getCategories()
        this.getBrands()
        this.staticText = StaticText.getStaticText("inventoryProductMapping")
        this.getProducts()

        if (this.props.product) {

            let { productName, packageQuantity, images, currentInventory, productCategory, productBrand, productId } = this.props.product

            this.state.name = productName
            this.state.packageQuantity = packageQuantity
            this.state.images = images
            this.state.invQty = currentInventory
            this.state.selectedCategory = productCategory
            this.state.code = productId
            this.state.brand = productBrand
        }
    }
    state = {

        categoryDropdown: [],
        brandDropdown: [],
        isSubmit: false,
        images: [],
        showToaster: false,
        recentlyAdded: [],
        showCategoryDropdown: true,
        showBrandDropdown: true,
        selectedPreview: '',
        products:[],
        invProducts:[],
    }

    productHash = {}
    totalPages = 1
    page = 1
    filter = {}

    imageTyes = ["image/jpeg", "image/png", "image/bmp", "image/gif", "	image/x-icon"]
    audioTypes = ["audio/mpeg", "audio/ogg", "audio/wav"]

    componentDidMount() {
        
    }

    onChangeHandler(event, key, max) {
        let value = event?.target?.value || ""
        max = max || Constants.INPUT_MAX_LENGTH_1
        if (max < value.length) return
        this.setState({ [key]: value })

        if (key === "code") {
            this.setState({ isDuplicateCode: false })
        }
    }

    onClickShowPreview = (item, index) => {
        this.setState({ selectedProduct: item, showProductPreview: true, selectedIndex: index })
    }

    onClickLoadMore = () => {
        this.page += 1
        this.getProducts(this.filter || {}, true)
    }

    getProducts = (payload = {}, append) => {

        if (!append)
            this.page = 1

        payload.page = this.page;
        
        this.setState({ isLoading: true })
        inventoryApi.getProductsByCustomer(payload).then((response) => {

            this.totalPages = response?.totalPages || 0

            let products = response.response || []
            this.prepareProductList(products, append)
        }).catch((err) => {
            this.setState({ isLoading: false })
        })

    }

    prepareProductList(products, append) {

          products.forEach(product => {
            product.mappedStatus = Boolean(product.mappedProductName);
            product.showSaveButton = false;
            product.showCancelButton = Boolean(product.mappedProductName);
          });

        this.setState({ products: append ? this.state.products.concat(products) : products })

    }

    getCategories = () => {

        let payload = {}

        inventoryApi.getCategories(payload).then((response) => {

            let categoryDropdown = response.response;
            this.setState({ categoryDropdown }, () => {
                this.categoryReload()
            });

        }).catch((err) => {
            alert(err);
        })

    }

    categoryReload = () => {
        this.setState({ showCategoryDropdown: false }, () => this.setState({ showCategoryDropdown: true }))
    }

    brandReload = () => {
        this.setState({ showBrandDropdown: false }, () => this.setState({ showBrandDropdown: true }))
    }

    onFocusInput(index, type) {
        
        if (index != this.state.onFocusInputIndex){
            let invProducts = []
            this.setState({ invProducts })
        }

        this.setState({ onFocusInputIndex: index, onFocusInputType: type })

    }

    onBlurInput = () => {
        this.setState({ onFocusInputIndex: -1 })
    }

    onChangeInput(value, index) {

        let products = this.state.products
        products[index].mappedProductName = value
        products[index].showSaveButton = false
        products[index].showCancelButton = false
        this.setState({products})

        if (!value) this.setState({ invProducts: [] })
        if (value.length >= 1) this.getInventoryProduct(value)
    }

    onChangeQuantity(value, index) {

        let products = this.state.products
        if (products[index].mappedStatus && products[index].productHandlingUnit !== value) products[index].showSaveButton = true
        products[index].productHandlingUnit = value
        this.setState({products})

    }

    onSelectProduct(item, index) {
        
        let products = this.state.products
        products[index].mappedProductId = item.productId
        products[index].mappedProductName = item.productName
        products[index].mappedProductBrand = item.productBrand
        if (products[index].productHandlingUnit == 0)   products[index].productHandlingUnit = 1
        products[index].showSaveButton = true
        products[index].showCancelButton = true
        this.setState({products}) 
        this.setState({ invProducts: [] })       
    }

    onClickSaveItem(index){

        let vendorProducts = this.state.products

        vendorProducts[index].showSaveButton = false
        vendorProducts[index].showCancelButton = false


        this.setState({ loading: true })

        let payload = {
            mappedProduct: {
                productId: vendorProducts[index].code,
                productName: vendorProducts[index].name,
                productCategory: vendorProducts[index].category.title,
                productBrand: vendorProducts[index].brand.title,
                vendorSellQty: vendorProducts[index].sellQuantity.value,
                productVendorBranchName: vendorProducts[index].branch.businessName,
                productVendorTenantId: vendorProducts[index].tenantId,
                productVendorBranchId: vendorProducts[index].branchId,
                mappedProductId: vendorProducts[index].mappedProductId,
                mappedProductName: vendorProducts[index].mappedProductName,
                mappedProductBrand: vendorProducts[index].mappedProductBrand,
                productHandlingUnit: vendorProducts[index].productHandlingUnit,
            },
            objectId: vendorProducts[index].objectId,
        }

        if (!vendorProducts[index].mappedStatus){

            inventoryApi.createMappedInventoryProduct(payload).then((response) => {
                this.setState({ loading: false })
                vendorProducts[index].mappedStatus = true
                vendorProducts[index].showCancelButton = true
                this.showToaster("Success", "Product mapping created successfully.")
            }).catch((err) => {
                this.setState({ loading: false })
                if (err?.response?.data?.code === 11000) {
                    this.setState({ isDuplicateCode: true })
                    this.showToaster("Error", err?.response?.data?.message)
                }
                else {
                    this.showToaster("Error", "Oops, Something went wrong :-(")
                }
            }) 


        } else{
            if (vendorProducts[index].mappedProductName === ""){
                inventoryApi.deleteMappedInventoryProduct(payload).then((response) => {
                    this.setState({ loading: false })
                    vendorProducts[index].mappedStatus = false
                    this.showToaster("Success", "Product mapping deleted successfully.")
                }).catch((err) => {
                    this.setState({ loading: false })
                    if (err?.response?.data?.code === 11000) {
                        this.setState({ isDuplicateCode: true })
                        this.showToaster("Error", err?.response?.data?.message)
                    }
                    else {
                        this.showToaster("Error", "Oops, Something went wrong :-(")
                    }
                }) 
            }else{
                inventoryApi.updateMappedInventoryProduct(payload).then((response) => {
                    this.setState({ loading: false })
                    vendorProducts[index].mappedStatus = true
                    vendorProducts[index].showCancelButton = true
                    this.showToaster("Success", "Product mapping updated successfully.")
                }).catch((err) => {
                    this.setState({ loading: false })
                    if (err?.response?.data?.code === 11000) {
                        this.setState({ isDuplicateCode: true })
                        this.showToaster("Error", err?.response?.data?.message)
                    }
                    else {
                        this.showToaster("Error", "Oops, Something went wrong :-(")
                    }
                }) 
            }
            

        }

        this.setState({ products: vendorProducts });


    }

    onClickRemoveItem(index){

        let products = this.state.products

        products[index].showSaveButton = false
        products[index].showCancelButton = false
        products[index].mappedProductName = ''
        products[index].mappedProductId = ''
        products[index].mappedProductBrand = ''
        products[index].productHandlingUnit = ''
        if (products[index].mappedStatus)   products[index].showSaveButton = true

        this.setState({products})

    }

    renderProductDropdown(index) {
        return (
            <div className="product-drop-down-popover">
                {
                    this.state.invProducts?.map(item => (
                        <div className={`cursor-pointer product-title-wrapper`} onClick={() => this.onSelectProduct(item, index)}>
                            <div className="d-flex align-items-center status-name">
                                <span className={`text-left`}>{item.productName}</span>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    getInventoryProduct(search) {


        let payload = {
            search,
            status: Constants.PRODUCT_STATUS.ACTIVE
        }

        this.setState({ loading: true })
        inventoryApi.getInventoryProducts(payload).then((response) => {
            let invProducts = response?.response || []
            this.setState({ invProducts })
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }

    showToaster(type, message) {
        this.setState({
            showToaster: true,
            toaster: {
                type,
                message,
            }
        }, () => {
            setTimeout(() => this.hideToaster(), 3000)
        })
    }

    hideToaster() {
        this.setState({ showToaster: false })
    }



    getBrands = () => {

        let payload = {
        }

        inventoryApi.getBrands(payload).then((response) => {
            this.setState({ brandDropdown: response.response }, () => {
                this.brandReload()
            })
        }).catch((err) => {
            alert(err);
        })
    }

    uploadFile = (signedUrl, file, callback) => {
        let type = "IMAGE"
        if (file.type.includes('video')) type = "VIDEO"
        else if (file.type.includes('audio')) type = "AUDIO"

        inventoryApi.uploadFileAwsS3(signedUrl, file,
            (progress) => {
                let progressPercentage = ((progress.loaded / progress.total) * 100).toFixed(2);
                this.setProgress(progressPercentage);
            })
            .then(response => {
                let splittedSignedUrl = signedUrl.split("?");
                callback && callback(null, splittedSignedUrl[0])
            })
            .catch(error => {
                callback && callback(error, null)
                console.log(error);
            });
    };

    getSignedURL(file) {

        let payload = {
            fileName: file.name,
            type: Constants.PRODUCTS
        };

        this.setState({ uploadLoading: true })
        inventoryApi.getSignedUrl(payload).then((response) => {

            this.uploadFile(response.signedUrl, file, (error, res) => {

                if (error) {
                    this.setState({ uploadLoading: false })
                    return
                }

                console.log(res)
                let images = this.state.images;
                images.push({ url: res })
                this.setState({ images, uploadLoading: false })
            })
        }).catch((err) => {
        })
    }

    onDrop = event => {
        const { type } = event.dataTransfer.files[0];
        const reader = new FileReader();
        if (type.includes('image')) {
            reader.onload = e => this.setPreview(e.target.result);
        }

        reader.readAsDataURL(event.dataTransfer.files[0]);
        const payload = new FormData();
        payload.append('file', event.dataTransfer.files[0]);
        let file = event.dataTransfer.files[0];
        this.getSignedURL(file);
        //}
        event.preventDefault();
    };

    onDragOver = event => {
        if (this.state.enableDragDrop) {
            this.setState({ status: 'Drop' });
        }
        event.preventDefault();
    };

    onDragLeave = event => {
        if (this.state.enableDragDrop) {
            this.setState({ status: 'Drop Here' });
        }
        event.preventDefault();
    };

    setProgress(percentage) {
        this.setState({ percentage: percentage });
    }

    showProgressBar = (status) => {
        this.setState({
            showProgress: status,
        });
    }

    reset = () => {
        this.setState({
            percentage: 0,
        });
    }

    onDragEnter = event => {
        if (this.state.enableDragDrop) {
            this.setState({ status: 'Image Selected' });
        }
        event.stopPropagation();
        event.preventDefault();
    };

    setPreview = (data) => {
        this.setState({ preview: data });
    }

    validateInputs = () => {

        if (!this.state.name)
            return false

        if (!this.state.code)
            return false

        if (!this.state.packageQuantity)
            return false

        if (!this.state.invQty)
            return false

        if (!this.state.selectedCategory)
            return false

        if (!this.state.brand)
            return false

        if (this.state.uploadLoading) return false

        return true

    }

    onClickCreate = () => {

        this.setState({ isSubmit: false })

        let isValid = this.validateInputs()

        if (!isValid) {
            this.setState({ isSubmit: true })
            return
        }

        if (this.props.product) {

            let payload = {
                productObjId: this.props.product._id,
                productName: this.state.name,
                packageQuantity: this.state.packageQuantity,
                images: this.state.images,
                currentInventory: this.state.invQty,
                productCategory: this.state.selectedCategory.value,
                productBrand: this.state.brand,
                productId: this.state.code ? this.state.code.trim() : ""
            }

            this.setState({ loading: true })
            inventoryApi.updateProductInventory(payload).then((response) => {
                this.setState({ loading: false })
                this.showToaster("success", "Product updated successfully.")
            }).catch((err) => {
                this.setState({ loading: false })
                if (err?.response?.data?.code === 11000) {
                    this.setState({ isDuplicateCode: true })
                    this.showToaster("error", err?.response?.data?.message)
                }
                else {
                    this.showToaster("error", "Oops somthing went wrong...")
                }
            })

        } else {
            let payload = {
                products: {
                    productName: this.state.name,
                    packageQuantity: this.state.packageQuantity,
                    images: this.state.images || [],
                    currentInventory: this.state.invQty,
                    productCategory: this.state.selectedCategory.value,
                    productBrand: this.state.brand,
                    productId: this.state.code
                }
            }

            this.setState({ loading: true })
            inventoryApi.createProductInventory(payload).then((response) => {
                this.setState({ loading: false })
                let recentlyAdded = this.state.recentlyAdded;
                recentlyAdded = payload.products
                this.setState({ recentlyAdded }, () => {
                    this.resetCreateInventoryProduct()
                })
                this.showToaster("Success", "Product created successfully.")
            }).catch((err) => {
                this.setState({ loading: false })
                if (err?.response?.data?.code === 11000) {
                    this.setState({ isDuplicateCode: true })
                    this.showToaster("Error", err?.response?.data?.message)
                }
                else {
                    this.showToaster("Error", "Oops, Something went wrong :-(")
                }
            })
        }
    }

    onClickRemove = (index) => {

        let images = this.state.images
        images.splice(index, 1)
        this.setState({ images })
    }

    resetCreateInventoryProduct = () => {
        this.setState({
            name: "",
            packageQuantity: "",
            images: [],
            invQty: 0,
            selectedCategory: "",
            code: "",
            brand: ""
        })

        this.brandReload()
        this.categoryReload()
    }

    onClickCancel = () => {
        this.props.cancel && this.props.cancel()
    }

    renderInventoryProductMapping() {

        return (
            <Modal className="product-mapping-modal" isOpen={true} toggle={this.hideModal} size="lg">
                <ModalBody>
                    <div className='modal-container-product'>
                        <div className='product-mapping-header'>
                            <div className='product-container'>
                                <div className='row vetrical-center m-0'>
                                    <div className='col'>
                                        <span className='add-product-text'>{this.staticText.title}</span>
                                    </div>
                                    <div className='col text-right pr-0'>
                                        <img src={getImageSource(ImageConstants.POPUP_CLOSE)} className='popup-close-icon cursor-pointer' onClick={() => this.onClickCancel()}></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='app-container'>
                            <div>
                            <div id="table-scroll" className="table-responsive pt-2 product-mapping-table-wrapper">
                                <table className="table mb-0 align-items-center product-mapping-table">
                                    <thead>

                                    <tr>
                                            <th className={'text-center product-code-border large-font'} colSpan='6'>
                                                <div>{this.staticText.leftTitle}</div>
                                            </th>
                                            <th className={'text-center large-font'} colSpan='3'>
                                                <div>{this.staticText.rightTitle}</div>
                                            </th>
                                    </tr>

                                        <tr>
                                            <th className='description-max-width'>
                                                <div>{this.staticText.productName}</div>
                                            </th>
                                            <th className='category-max-width'>
                                                <div>{this.staticText.brandTitle}</div>
                                            </th>
                                            <th className='category-max-width'>
                                                <div>{this.staticText.category}</div>
                                            </th>
                                            <th className='category-max-width'>
                                                <div>{this.staticText.vendorTitle}</div>
                                            </th>
                                            <th className='category-max-width text-right'>
                                                <div>{this.staticText.packageQuantity}</div>
                                            </th>
                                            <th className='slno-max-width text-right product-code-border'>
                                                <div>{this.staticText.productUnit}</div>
                                            </th>
                                            <th className='quantity-max-width text-left'>
                                                <div>{this.staticText.productName}</div>
                                            </th>
                                            <th className='price-max-width text-left'>
                                                <div>{this.staticText.brandTitle}</div>
                                            </th>
                                            <th className='quantity-max-width text-right'>
                                                <div>{this.staticText.mappedProductUnit}</div>
                                            </th>
                                            <th className='slno-max-width'>
                                                <div></div>
                                            </th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {
                                            this.state.products?.length ? this.state.products.map((item, index) => (

                                                <tr key={item._id} className="tr-fixed-5" onClick={() => this.onClickShowPreview(item, index)}>
                                                    <td className="">
                                                        <div className="d-flex align-items-center">
                                                            <div className="pr-4">
                                                                <div className='contains-preview-image'>
                                                                    <div className="image-circle-wrapper-50">
                                                                        <img alt="" src={item?.images[0]?.url || getImageSource(ImageConstants.DEFAULT_PRODUCT)} />
                                                                    </div>
                                                                    <ImagePreview isLast={index === this.state.products?.length - 1} src={item?.images[0]?.url || getImageSource(ImageConstants.DEFAULT_PRODUCT)} />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className='pl-2'>
                                                                <div className='product-code'>{item.name}</div>
                                                                <div className='product-code'>{item.code ? '#' + item.code : item.code}</div>
                                                            </div>
                                                            
                                                        </div>
                                                    </td>
                                                    <td className='light-text'>{item.brand?.title} </td>
                                                    <td className='light-text'>{item.category?.title} </td>
                                                    <td className='light-text'>{item.branch?.businessName} </td>
                                                    <td className='light-text text-right'>{item?.sellQuantity?.value}</td>
                                                    <td className="light-text text-right product-code-border">{1}</td>
                                                    
                                                    
                                                    <td className={`product-item sub-text ${this.state.onFocusInputIndex === index && this.state.onFocusInputType === "product" ? "" : ""}`}>
                                                        <ItemTextInput isFocused={this.state.onFocusInputIndex === index && this.state.onFocusInputType === "product"} value={item.mappedProductName} onFocus={() => this.onFocusInput(index, "product")} item={item} onChangeInput={(value) => this.onChangeInput(value, index)} />
                                                        {this.state.onFocusInputIndex === index && this.state.onFocusInputType === "product" ? this.renderProductDropdown(index) : ""}
                                                        <div>{item?.mappedProductId ? '#' + item.mappedProductId : ""}</div>
                                                    </td>

                                                    <td className='light-text text-left'>{item.mappedProductBrand} </td>

                                                    <td className={`sub-text text-center ${this.state.onFocusInputIndex === index && this.state.onFocusInputType === "handlingUnit" ? "" : ""}`}>
                                                    <HandlingUnitInput isFocused={this.state.onFocusInputIndex === index && this.state.onFocusInputType === "handlingUnit"} type={"AMOUNT"} value={item.productHandlingUnit} onFocus={() => this.onFocusInput(index, "handlingUnit")} onBlur={this.onBlurInput} item={item} onChangeItemDiscount={(value) => this.onChangeQuantity(value, index)} />
                                                    </td>

                                                    <td className="sub-text text-left">
                                                        { item?.showSaveButton && (
                                                            <span className="action-icon">     
                                                                    <>
                                                                        <img id={`save-item-${index}`} className="icon-20 " alt="" src={getImageSource(ImageConstants.GREEN_TICK_FILLED)} onClick={() => this.onClickSaveItem(index)} />
                                                                        <UncontrolledTooltip placement="left" target={`save-item-${index}`}>
                                                                            Save Changes
                                                                        </UncontrolledTooltip>
                                                                    </>
                                                            </span>
                                                        ) }
                                                        &nbsp;
                                                        { item?.showCancelButton && (
                                                            <span className="action-icon">     
                                                                    <>
                                                                        <img id={`remove-item-${index}`} className="icon-20 " alt="" src={getImageSource(ImageConstants.DELETE)} onClick={() => this.onClickRemoveItem(index)}/>
                                                                        <UncontrolledTooltip placement="left" target={`remove-item-${index}`}>
                                                                            Discard
                                                                        </UncontrolledTooltip>
                                                                    </>
                                                            </span>
                                                        ) }
                                                    </td>

                                                </tr>
                                            ))
                                                : <tr><td colSpan="6" align='center' className='text-gray product-code-border'>No vendor products found !</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {this.totalPages > this.page ? <div className='text-center mt-3 cursor-pointer' onClick={this.onClickLoadMore} style={{ marginBottom: '20px' }}><span>{this.staticText.loadMoreText}</span></div> : ""}
                            </div>
                            <div></div>
                        </div>
                    </div>

                </ModalBody>
            </Modal >
        )
    }

    render() {

        return (
            <div className='inventory-prod-mapping'>
                {this.renderInventoryProductMapping()}
                {this.state.showToaster ? <ToasterComponent type={this.state.toaster?.type} message={this.state.toaster?.message} /> : ""}
            </div>
        );
    }
}

export default InventoryProductMapping;