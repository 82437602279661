import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal, ModalBody } from "reactstrap";
import productApi from '../../../apis/product';
import Constants from '../../../constants/constants';
import ImageConstants from '../../../constants/image-constants';
import StaticText from '../../../shared-store/static-text-store';
import { getImageSource } from '../../../utility/image-source';
import ExcelToJson from '../../common/excel-to-json/excel-to-json';
import "./import-export.scss";

const actionDropDownItems = {
   export: 'Export (Download) Products to an Excel File',
   import: 'Import (Upload) Products from an Excel File',
   inactive: 'Inactivate Products'
}

const headerStaticKeys = {
   "Product Name": true,
   "Product ID": true,
   "Product Desc": true,
   "Sell Unit": true,
   "Price": true,
   "Category": true,
   "Brand": true,
   "Status": true
}
let fileLengthmax = 1000
class ImportExport extends React.Component {

   state = {
      loading: false,
      showActionDropDown: false,
      selectedAction: "",
      categories: [],
      brands: [],
      backupCategories: [],
      backupBrands: [],
      isActiveStatusSelected: true,
      isInActiveStatusSelected: false,
      categoryHash: {}
   }

   totalPages = 1;
   page = 1

   constructor(props) {
      super(props)
      this.staticText = StaticText.getStaticText("productList")
      this.getCategories()
      this.getVendorCategories()
   }


   componentDidMount() {
      document.addEventListener('mousedown', this.handleClickEvent, false);
   }

   componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickEvent, false);
   }

   getCategories = () => {

      let payload = {}

      productApi.getCategories(payload).then((response) => {

         let categories = response?.response || []

         let categoryHash = {}

         if (categories) {
            categories.forEach(element => {
               categoryHash[element.title] = element
            });

         }

         this.setState({ categoryHash })

      }).catch((err) => {
         alert(err);
      })

   }

   getVendorCategories = () => {

      let payload = {}

      productApi.getVendorCategories(payload).then((response) => {

         let { categories, brands } = response

         if (categories?.length) {
            categories = [{ title: "Select All", value: "SELECT_ALL" }, ...categories]
         }

         if (brands?.length) {
            brands = [{ title: "Select All", value: "SELECT_ALL" }, ...brands]
         }

         this.setState({
            backupCategories: categories || [],
            categories: categories || [],
            backupBrands: brands || [],
            brands: brands || [],
         })

      }).catch((err) => {
         alert(err);
      })

   }


   handleClickEvent = (event) => {
      if (this.actionDropdownRef && !this.actionDropdownRef.contains(event.target)) {
         this.setState({ showActionDropDown: false })
      }
   }

   showToaster(type, message) {
      this.props.showToaster(type, message)
   }

   onClickDownloadProducts() {
      const payload = {
         categories: this.state.selectedCategories,
         brands: this.state.selectedBrands,
         statuses: []
      }
      if (this.state.isActiveStatusSelected) {
         payload.statuses.push(Constants.PRODUCT_STATUS.ACTIVE)
      }
      if (this.state.isInActiveStatusSelected) {
         payload.statuses.push(Constants.PRODUCT_STATUS.INACTIVE)
      }

      this.setState({ loading: true })
      productApi.exportProducts(payload).then((response) => {
         this.setState({ loading: false })
         this.downloadCsv(response.data.downloadUrl)
      }).catch((err) => {
         console.error(err.response.data.message)
         this.showToaster('error', err?.response?.data?.message || err?.response?.toString() || err?.toString())
         this.setState({ loading: false })
      })
   }

   onClickInactivateProducts() {
      const payload = {
         categories: this.state.selectedCategories,
         brands: this.state.selectedBrands,
         statuses: []
      }
      if (this.state.isActiveStatusSelected) {
         payload.statuses.push(Constants.PRODUCT_STATUS.ACTIVE)
      }

      //if ((!payload.categories || !payload.categories.some(item => item.active)) && (!payload.brands || !payload.brands.some(item => item.active))) { //INV000001 (not inventory related, but a bug fix)
      if (!payload.categories && !payload.brands) {//INV000001 (not inventory related, but a bug fix)
         this.setState({ errorMessage: "Please select category or brand" })
         return
      }

      this.setState({ loading: true })
      productApi.bulkInactive(payload).then((response) => {
         this.setState({ loading: false })
         this.showToaster("success", "Product Inactivated successfully")
      }).catch((err) => {
         this.showToaster('error', "Something went wrong")
         this.setState({ loading: false })
      })
   }

   downloadCsv = (url) => {
      const link = document.createElement('a');
      link.href = url
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   }

   showActionDropDown = () => {
      this.setState({ showActionDropDown: true })
   }

   hideActionDropDown = () => {
      this.setState({ showActionDropDown: false })
   }

   onClickActionItem = (selectedAction) => {
      this.setState({ selectedAction, showActionDropDown: false, errorMessage: "" }, () => {
         if (this.state.selectedAction === actionDropDownItems.export) {
            this.onClickCategories(0)
            this.onClickBrands(0)
         } else {

            let brands = this.state.brands;
            let categories = this.state.categories;
            brands.forEach(element => element.active = false)
            categories.forEach(element => element.active = false)
            this.setState({ brands, categories })
         }
      })
   }

   onCategorySearch = (e) => {

      let value = e.target.value

      let backupCategories = this.state.backupCategories || []

      if (value) {
         let options = backupCategories.filter(option => option?.title?.toLowerCase().includes(value?.toLowerCase()));
         this.setState({ categories: options, categorySearch: value });
      } else {
         this.setState({ categories: backupCategories, categorySearch: value });
      }
   }

   onBrandSearch = (e) => {

      let value = e.target.value
      let backupBrands = this.state.backupBrands || []
      if (value) {
         let options = backupBrands.filter(option => option.title?.toLowerCase().includes(value?.toLowerCase()));
         this.setState({ brands: options, brandSearch: value });
      } else {
         this.setState({ brands: backupBrands, brandSearch: value });
      }

   }

   onClickCategories = (i) => {
      let categories = this.state.categories;

      if (!categories?.length) return
      categories[i].active = !categories[i].active
      let selectedCategories = this.state.selectedCategories || []

      let filterBadgeCount = this.state.filterBadgeCount || 0

      if (categories[i].value === "SELECT_ALL") {

         selectedCategories = []
         categories.forEach(element => {
            element.active = categories[i].active
            if (element.active && element.value !== "SELECT_ALL") selectedCategories.push(element.value)
         })
         filterBadgeCount = selectedCategories?.length
      } else {

         if (categories[i].active) {
            filterBadgeCount += 1
            selectedCategories.push(categories[i].value)
         }
         else {
            filterBadgeCount -= 1
            let data = selectedCategories.filter(item => item !== categories[i].value)
            selectedCategories = data
         }
      }

      this.setState({ categories, filterBadgeCount, selectedCategories }, () => {
         let [selectAll, ...restAll] = categories
         if (restAll) {
            categories[0].active = restAll.every(item => item.active)
            this.setState({ categories })
         }
      })
   }


   onClickBrands = (i) => {
      let brands = this.state.brands;
      if (!brands?.length) return

      brands[i].active = !brands[i].active

      let filterBadgeCount = this.state.filterBadgeCount || 0
      let selectedBrands = this.state.selectedBrands || []

      if (brands[i].value === "SELECT_ALL") {

         selectedBrands = []
         brands.forEach(element => {
            element.active = brands[i].active
            if (element.active && element.value !== "SELECT_ALL") selectedBrands.push(element.value)
         })
         filterBadgeCount = selectedBrands?.length
      } else {

         if (brands[i].active) {
            filterBadgeCount += 1
            selectedBrands.push(brands[i].value)
         }
         else {
            filterBadgeCount -= 1
            let data = selectedBrands = selectedBrands.filter(item => item !== brands[i].value)
            selectedBrands = data
         }
      }

      this.setState({ brands, filterBadgeCount, selectedBrands }, () => {
         let [selectAll, ...restAll] = brands
         if (restAll) {
            brands[0].active = restAll.every(item => item.active)
            this.setState({ brands })
         }
      })
   }

   renderCategories = () => {

      let elements = []

      for (let i = 0; i < this.state.categories.length; i++) {

         let item = this.state.categories[i]
         elements.push(<div className='cursor-pointer mb-3' onClick={() => { this.onClickCategories(i) }}>
            <div className="d-flex align-items-center">
               <img alt="" src={getImageSource(item.active ? ImageConstants.CHECKBOX_ACTIVE : ImageConstants.CHECKBOX_INACTIVE)} className='checkbox'></img>
               <span className={`categories-name pl-2 ${item.active ? 'categories-name-active' : ""}`}>{item.title}</span>
            </div>
         </div>)
      }

      return elements
   }

   renderBrands = () => {

      let elements = []

      for (let i = 0; i < this.state.brands.length; i++) {

         let item = this.state.brands[i]
         elements.push(<div className='cursor-pointer mb-3' onClick={() => { this.onClickBrands(i) }}>
            <div className="d-flex align-items-center">
               <img src={getImageSource(item.active ? ImageConstants.CHECKBOX_ACTIVE : ImageConstants.CHECKBOX_INACTIVE)} className='checkbox'></img>
               <span className={`categories-name pl-2 ${item.active ? 'categories-name-active' : ""}`}>{item.title}</span>
            </div>
         </div>)
      }

      return elements
   }

   onChangeActiveStatus = () => {
      if (this.state.isActiveStatusSelected) {
         this.setState({ isActiveStatusSelected: false, isInActiveStatusSelected: true })
         return
      }
      this.setState({ isActiveStatusSelected: true })
   }

   onChangeInActiveStatus = () => {
      if (this.state.isInActiveStatusSelected) {
         this.setState({ isInActiveStatusSelected: false, isActiveStatusSelected: true })
         return
      }
      this.setState({ isInActiveStatusSelected: true })
   }

   renderFilters = (hideInactive) => {

      return (
         <div className='filter-popover app-box-shadow' ref={ref => this.filterRef = ref}>
            <div className="arrow-up"></div>

            <div className='filter-header'>
               {/* <span className="pr-2">{this.staticText.filterText}</span> */}
               <div className='cursor-pointer mb-3' onClick={this.onChangeActiveStatus}>
                  <div className="d-flex align-items-center">
                     <img alt="" src={getImageSource(this.state.isActiveStatusSelected ? ImageConstants.CHECKBOX_ACTIVE : ImageConstants.CHECKBOX_INACTIVE)} className='checkbox'></img>
                     <span className={`categories-name pl-2 ${this.state.isActiveStatusSelected ? 'categories-name-active' : ""}`}>{'Active Products'}</span>
                  </div>
               </div>
               {
                  !hideInactive ?
                     <div className='cursor-pointer mb-3 ml-3' onClick={this.onChangeInActiveStatus}>
                        <div className="d-flex align-items-center">
                           <img alt="" src={getImageSource(this.state.isInActiveStatusSelected ? ImageConstants.CHECKBOX_ACTIVE : ImageConstants.CHECKBOX_INACTIVE)} className='checkbox'></img>
                           <span className={`categories-name pl-2 ${this.state.isInActiveStatusSelected ? 'categories-name-active' : ""}`}>{'Inactive Products'}</span>
                        </div>
                     </div> : ""
               }

            </div>
            <div className='row m-0'>

               <div className='col filter-left'>
                  <div className='title-pop-over'>{this.staticText.categoryTitle}</div>
                  <div>
                     <div className='filter-search-wrapper mt-1 my-2 p-2'>
                        <img alt="" src={getImageSource(ImageConstants.PRODUCT_LIST_SEARCH)} className='icon-15'></img>
                        <input className='filter-search' value={this.state.categorySearch} placeholder={'Search...'} onChange={this.onCategorySearch} />
                     </div>
                     <div className="category-list">
                        {this.renderCategories()}
                     </div>
                  </div>
               </div>
               {/* <div className='col filter-center'>
                   <div className='title-pop-over'>{this.staticText.status}</div>
                   {this.renderStatus()}
               </div> */}
               <div className='col filter-right'>
                  <div className='title-pop-over'>{this.staticText.brandTitle}</div>

                  <div className='filter-search-wrapper mt-1 my-2 p-2'>
                     <img alt="" src={getImageSource(ImageConstants.PRODUCT_LIST_SEARCH)} className='icon-15'></img>
                     <input className='filter-search' placeholder={'Search...'} value={this.state.brandSearch} onChange={this.onBrandSearch} />
                  </div>
                  <div className="category-list">
                     {this.renderBrands()}
                  </div>
               </div>
            </div>
         </div>)
   }

   renderActionDropdown() {
      return <div className='action-dropdown-container'>
         <div className='action-dropdown' onClick={this.showActionDropDown}>
            {this.state.selectedAction || 'Select Action'}
            <img className='blue-down-arrow' src={getImageSource(ImageConstants.BLUE_DOWN_ARROW)}></img>
         </div>

         {this.state.showActionDropDown ? <div className='action-dropdown-items' ref={ref => this.actionDropdownRef = ref}>
            {Object.keys(actionDropDownItems).map(
               key => <div
                  className={`action-dropdown-item ${actionDropDownItems[key] === this.state.selectedAction ? 'active' : ''}`}
                  onClick={() => this.onClickActionItem(actionDropDownItems[key])}
               >
                  {actionDropDownItems[key]}
               </div>
            )}
         </div> : ""}
      </div>
   }

   renderSelectCriteria = (hideHeader) => {
      return <div className='select-criteria-container'>
         <div className='select-criteria-title'>Select Criteria</div>
         <div className='filters'>
            {this.renderFilters(hideHeader)}
         </div>
      </div>
   }

   renderExportSteps = () => {
      return <>
         <div className='row mt-4'>
            <div className='col-md-1'>2.</div>
            <div className='col-md-11'>
               {this.renderSelectCriteria()}
            </div>
         </div>

         <div className='row mt-4'>
            <div className='col-md-1 align-self-center'></div>
            <div className='col-md-11'>
               <div className="text-center mt-3">
                  {this.state.errorMessage ? <div className="text-danger pb-4 px-4"><strong>{this.state.errorMessage} </strong></div> : ""}
                  <button className="app-button-primary" onClick={() => this.onClickDownloadProducts()}>
                     {!this.state.loading ? "Download" : <span><FontAwesomeIcon icon={faSpinner} spin /> </span>}
                  </button>
               </div>
            </div>
         </div>
      </>
   }

   renderInactivateSteps = () => {
      return <>
         <div className='row mt-4'>
            <div className='col-md-1'>2.</div>
            <div className='col-md-11'>
               {this.renderSelectCriteria(true)}
            </div>
         </div>

         <div className='row mt-4'>
            <div className='col-md-1 align-self-center'></div>
            <div className='col-md-11'>
               <div className="text-center mt-3">
                  {this.state.errorMessage ? <div className="text-danger pb-4 px-4"><strong>{this.state.errorMessage} </strong></div> : ""}
                  <button className="app-button-primary" onClick={() => this.onClickInactivateProducts()}>
                     {!this.state.loading ? "Inactivate" : <span><FontAwesomeIcon icon={faSpinner} spin /> </span>}
                  </button>
               </div>
            </div>
         </div>
      </>
   }

   removeExcelFile() {
      this.setState({ excelFileName: "", bulkProducts: [] })
   }

   onSuccessUploadExcel = (res) => {

      if (res.data) {
         let products = this.extractFromExcel(res.data)
         if (products) {
            this.setState({
               excelFileName: res?.fileName || "",
               bulkProducts: products,
               errorMessage: ""
            })
            return
         }
      }

      if (res.error) {
         this.setState({ errorMessage: "Excel upload error" })
      }
   }

   extractFromExcel(data) {
      let productList = []

      let sampleData = data[0]

      let sampleKeys = Object.keys(sampleData)

      for (let i = 0; i < sampleKeys.length; i++) {
         if (!headerStaticKeys[sampleKeys[i]]) {
            this.setState({ errorMessage: `Column headers don’t match with the template. Please download the template and make sure that the columns match` })
            return
         }
      }


      for (let i = 0; i < data.length; i++) {
         let obj = data[i]
         let product = {
            name: obj["Product Name"] ? obj["Product Name"].trim() : "",
            code: obj["Product ID"] ? obj["Product ID"].trim() : "",
            description: obj["Product Desc"] ? obj["Product Desc"].trim() : "",
            sellQuantity: obj["Sell Unit"] ? obj["Sell Unit"].trim() : "",
            price: obj["Price"] ? this.getPriceFromExcel(obj["Price"]) : 0,
            category: obj["Category"] ? obj["Category"].trim() : "",
            brand: obj["Brand"] ? obj["Brand"].trim() : "",
            status: obj["Status"] ? obj["Status"].trim() : "ACTIVE",
         }

         productList.push(product)
      }

      return productList
   }

   getPriceFromExcel(price) {
      let [currency, priceString] = price.split("$")
      priceString = priceString.trim()
      if (!priceString || priceString <= 0) return 0
      priceString = priceString.split(",").join("")
      return +priceString
   }

   onSaveBulkProducts = () => {

      let bulkProducts = this.state.bulkProducts || [];


      if (!bulkProducts?.length) {
         this.setState({ errorMessage: "Please select valid excel file" })
         return
      }

      if (bulkProducts.length > fileLengthmax) {
         this.setState({ errorMessage: `Upload Failed: More rows than max allowed ${fileLengthmax} in one file` })
         return
      }


      let buildPayload = [], productCodeHash = {}


      for (let i = 0; i < bulkProducts.length; i++) {

         let product = bulkProducts[i]

         if (productCodeHash[product.code]) {
            this.setState({ errorMessage: `Upload Failed: Product code duplicate at ROW no. ${productCodeHash[product.code].index + 2} and ${i + 2}` })
            return
         } else {
            productCodeHash[product.code] = { index: i }
         }

         let validation = this.validatePayload(product, i)

         if (!validation.success) {
            this.setState({ errorMessage: `${validation.message}` })
            return
         } else {
            this.setState({ errorMessage: "" })
         }

         let productBuild = {
            name: product.name,
            code: product.code,
            description: product.description,
            sellQuantity: {
               value: product.sellQuantity,
            },
            price: product.price,
            currency: {
               code: product.currencyCode || "USD",
               symbol: product.currencySymbol || "$"
            },
            category: {
               title: product.category,
               value: product.category
            },
            brand: {
               title: product.brand,
               value: product.brand
            },
            status: product.status
         }

         buildPayload.push(productBuild)
      }
      this.setState({ uploadLoading: true })
      productApi.bulkUploadProduct({ products: buildPayload }).then((response) => {
         this.setState({ showBulkUpload: false }, () => {
            this.showToaster("success", "Product uploaded successfully.")
            this.setState({ uploadLoading: false })

            setTimeout(() => {
               this.props.closeImportExport()
            }, 2000)
         })
      }).catch((err) => {
         this.setState({ uploadLoading: false })

         if (err?.response?.data?.code === 900) {
            this.showToaster("error", err?.response?.data?.message)
         } else {
            this.showToaster("error", "Oops somthing went wrong...")
         }
      })
   }

   validatePayload = (payload, i) => {
      let result = { success: false, message: "" }
      if (!payload.name) {
         result.message = `Upload Failed: Invalid product name at Row no. ${i + 2} `
         return result
      }

      if (!payload.code) {
         result.message = `Upload Failed: Invalid product id at Row no. ${i + 2} `
         return result
      }

      if (!payload.sellQuantity) {
         result.message = `Upload Failed: Invalid product sell unit at Row no. ${i + 2} `
         return result
      }

      if (!payload.category) {
         result.message = `Upload Failed: Invalid product category at Row no. ${i + 2} `
         return result
      }

      if (payload.price <= 0) {
         result.message = `Upload Failed: Invalid product price at Row no. ${i + 2} `
         return result
      }

      if (!this.state?.categoryHash[payload.category]) {
         result.message = `Upload Failed: Provided category not available in our system. Row no. ${i + 2} `
         return result
      }

      if (!payload.brand) {
         result.message = `Upload Failed: Invalid product brand at Row no. ${i + 2} `
         return result
      }
      if (!payload.status || (payload.status != "ACTIVE" && payload.status != "INACTIVE")) {
         result.message = `Upload Failed: Invalid status at Row no. ${i + 2}. status should be ACTIVE or INACTIVE `
         return result
      }
      result = {
         success: true,
         message: ""
      }
      return result
   }

   showExcelFormat = () => {
      const link = document.createElement('a');
      link.href = Constants.PRODUCT_UPLOAD_FORMAT
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   }


   renderImportSteps = () => {
      return <>
         <div className='row mt-4'>
            <div className='col-md-1'>2.</div>
            <div className='col-md-11'>
               <div className="import-excel-div ">
                  <label className="file-name py-2 px-4 m-0">
                     {this.state?.excelFileName || ""}
                  </label>
                  {
                     this.state.excelFileName ?
                        <label className="excel float-right text-danger py-2 px-4 m-0" onClick={() => this.removeExcelFile()}>
                           REMOVE EXCEL
                        </label> :
                        <label className="excel float-right text-success py-2 px-4 m-0">
                           <ExcelToJson headerStartFrom={0} onSuccess={this.onSuccessUploadExcel} />
                           ADD EXCEL
                        </label>
                  }
               </div>

               <div className="text-right check-format pt-2">
                  <span className="p-2 cursor-pointer" onClick={this.showExcelFormat}>Download Template Here</span>
               </div>
               <div className='sub-text pt-3'>
                  <ol>
                     <li className="pt-2">Please make sure that the spreadsheet follows the specified template. Otherwise the products will not upload correctly.</li>
                     <li className="pt-2">If an already existing product is uploaded again, it will update the product attributes in the system. (This will be helpful if would like to update price, qty, description etc)​.</li>
                     <li className="pt-2">If a brand new product is uploaded it will create a new product in the system</li>
                  </ol>
               </div>
            </div>
         </div>

         <div className='row mt-4'>
            <div className='col-md-1 align-self-center'>3.</div>
            <div className='col-md-11'>
               <div className="text-center mt-3" >
                  <button className="app-button-primary" onClick={this.onSaveBulkProducts} disabled={this.state.uploadLoading}>
                     {!this.state.uploadLoading ? "Upload Spreadsheet" : <span><FontAwesomeIcon icon={faSpinner} spin /> </span>}
                  </button>
               </div>
            </div>
         </div>
         <div className="text-center mt-4">
            {this.state.errorMessage ? <div className="error-message"> <img className="icon-30" alt="" src={getImageSource(ImageConstants.CLOSE_RED)} /> <strong className="px-2">{this.state.errorMessage} </strong></div> : ""}
         </div>
      </>
   }

   render() {

      return (
         <Modal className="custom-modal-import-export-product" isOpen={true} toggle={this.props.closeImportExport} size={'lg'}>

            <ModalBody >
               <div className="import-export-modal-body">
                  <div className="row">
                     <div className="col text-right">
                        <FontAwesomeIcon className="cursor-pointer" onClick={this.props.closeImportExport} icon={faTimes} />
                     </div>
                  </div>

                  <div className="header-text">
                     <span>{this.staticText?.importExportButtonTitle || 'Import / Export / Mass Update'}</span>
                  </div>

                  <div className='content'>
                     <div className='row'>
                        <div className='col-md-1 align-self-center'>1.</div>
                        <div className='col-md-11'>
                           {this.renderActionDropdown()}
                        </div>
                     </div>

                     {this.state.selectedAction === actionDropDownItems.export ? this.renderExportSteps() : ''}
                     {this.state.selectedAction === actionDropDownItems.import ? this.renderImportSteps() : ''}
                     {this.state.selectedAction === actionDropDownItems.inactive ? this.renderInactivateSteps() : ''}

                  </div>

               </div>
            </ModalBody>

         </Modal >
      );
   }
}

export default ImportExport;


