import React, { Suspense, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import PaymentPage from './payment-response'
import Path from '../routes/routes-path'
import SellerSetupRefresh from './seller/setup-refresh'
import SellerSetupSuccess from './seller/setup-success'

const PaymentRoutes = () => {

  return (
    <Suspense>
      <Switch>
        <Route exact path={Path.PAYMENT.pay} component={PaymentPage} />
        <Route exact path={Path.PAYMENT.sellerSetupRefresh} component={SellerSetupRefresh} />
        <Route exact path={Path.PAYMENT.sellerSetupSuccess} component={SellerSetupSuccess} />
        <Redirect to={Path.PAYMENT.pay} />
      </Switch>
    </Suspense>
  )
}

export default PaymentRoutes
