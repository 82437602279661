import React from 'react';
import "./price-setup.scss";
import productApi from '../../../apis/product'
import CustomerApi from '../../../apis/customer'
import { Modal, ModalBody } from "reactstrap";
import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../../utility/image-source'
import ToasterComponent from '../../common/toaster/toaster';
import StaticText from '../../../shared-store/static-text-store';


class CustomerPriceSetup extends React.Component {


    state = {
        productList: [],
        currency: {
            //CHG000002  PB-7                   code: "DOLLER",
            code: "USD",                     //CHG000002  PB-7
            symbol: "$"
        }
    }

    constructor(props) {

        super(props)
        this.staticText = StaticText.getStaticText("priceSetup")

        if (this.props.customer) {
            this.state.customer = this.props.customer
            this.state.product = {
                images: [{}]
            }
            this.getAlreadyAddedProduct()
        }
    }

    totalPages = 1;
    page = 1

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickEvent, false);

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }


    onChangeHandler(event, key) {
        this.setState({ [key]: event?.target?.value }, () => {
        })
    }

    showToaster(type, message) {
        this.setState({
            showToaster: true,
            toaster: {
                type,
                message,
            }
        }, () => {
            setTimeout(() => this.hideToaster(), 3000)
        })
    }

    hideToaster() {
        this.setState({ showToaster: false })
    }


    onClickCancel = () => {
        this.props.cancel && this.props.cancel()
    }

    onFocusSearch(value) {
        this.setState({ isFocusSearch: value })
        // this.getProduct()
    }

    onFocusCustomerSearch(value) {
        this.setState({ isFocusCustomerSearch: value })
        // this.getNewCustomers()
    }


    getNewCustomers = (payload = {}) => {

        if (this.state.customerSearch) {
            payload.search = this.state.customerSearch
        }

        CustomerApi.getCustomers({}).then((response) => {
            this.setState({ customersList: response.response || [] })
        }).catch((err) => {
        })
    }

    onChangeCustomerSearch = (event) => {
        this.setState({ customerSearch: event?.target.value }, () => {

            if (this.state.customerSearch && this.state.customerSearch.length > 3) {
                this.getNewCustomers()
            } else if (!this.state.customerSearch) {
                this.setState({ customersList: [] })
            }
        })
    }


    getProduct(payload = {}) {

        if (this.state.search) {
            payload.search = this.state.search
        }

        productApi.getProducts(payload).then((response) => {
            this.setState({ productList: response.response || [] })
        }).catch((err) => {
        })
    }

    getAlreadyAddedProduct = (append) => {
        let payload = {
            customerId: this.state.customer._id,
        }

        payload.page = this.page;

        productApi.getCustomerProductPrice(payload).then((response) => {

            if (response.totalPages)
                this.totalPages = response.totalPages;

            this.setState({ products: append ? this.state.products.concat(response.response || []) : response.response || [] })
        }).catch((err) => {
        })
    }

    onChangeSearch = (event) => {
        this.setState({ search: event?.target.value }, () => {

            if (this.state.search && this.state.search.length > 3)
                this.getProduct()
            else if (!this.state.search) {
                this.setState({ productList: [] })
            }
        })
    }

    onChangeCustomerPrice = (e) => {

        let selectedProduct = this.state.selectedProduct
        selectedProduct.price = e.target.value
        this.setState({ selectedProduct })
    }

    onSelectProduct(e, product) {
        console.log("PRODUCT", product)
        this.setState({ isFocusSearch: false, search: "", selectedProduct: product })

    }

    onSelectCustomer = (e, customer) => {

        this.setState({ customer, isFocusCustomerSearch: false, customerSearch: "" }, () => {
            this.getAlreadyAddedProduct()
        })
    }

    onPriceUpdate(item, index, isRowUpdate) {

        this.setState({ isSubmit: true })

        if (!item.price) {
            return
        }

        let payload = {
            productId: item._id,
            price: Number(item.price),
            currency: this.state.currency,
            customerBranchId: this.state.customer._id
        }
        productApi.setCustomPrice(payload).then((response) => {

            if (isRowUpdate) {
                let products = this.state.products
                products[index].isEdit = false;
                this.setState({ products })
            } else {
                this.setState({ selectedProduct: "" }, () => {
                    this.getAlreadyAddedProduct()
                })
            }
        }).catch((err) => {
            alert("ERROR")

        })
    }

    onClickLoadMore = () => {
        this.page += 1
        this.getAlreadyAddedCustomer(true)
    }

    onChangeRowPrice = (e, index) => {

        let products = this.state.products
        products[index] = {
            ...products[index],
            oldPrice: products[index].price,
            price: e.target.value
        }

        this.setState({ products })
    }


    handleClickEvent = (event) => {

        if (this.customerSearchRef && !this.customerSearchRef.contains(event.target)) {
            this.setState({ isFocusCustomerSearch: false })
        }


        if (this.productSearchRef && !this.productSearchRef.contains(event.target)) {
            this.setState({ isFocusSearch: false })
        }

    }

    onRowMouseEnter = (item, index, value) => {
        let products = this.state.products
        products[index].focus = value
        this.setState({ products })
    }

    onClickRemovePrice = (item, index) => {
        let payload = {
            productId: item._id,
            customerBranchId: this.state.customer?._id
        }
        productApi.removeCustomPrice(payload).then((response) => {
            this.getAlreadyAddedProduct()
        }).catch((err) => {
            alert("ERROR")

        })
    }

    onClickEdit = (item, index) => {
        let products = this.state.products
        products[index].isEdit = true;
        this.setState({ products })
    }


    renderNameBadge(name) {

        if (!name) return
        let splitName = name.split(" ")
        let tag = ""
        if (splitName.length > 1) {
            tag = `${splitName[0][0] || ""}${splitName[1][0] || ""}`
        } else if (splitName) {
            tag = `${splitName[0][0]}`
        }
        tag = tag ? tag.toUpperCase() : ""
        return (<div className="d-inline-block name-badge p-3 "><span className="">{tag || ""}</span></div>)

    }


    renderPriceSetup() {

        return (
            <Modal className="customer-setup-modal" isOpen={true} toggle={this.hideModal} size="lg">
                <ModalBody className="overflow-auto">
                    <div className='create-product-header'>
                        <div className='price-setup-container full-height'>
                            <div className='row align-center'>
                                <div className='col'>
                                    <span className='add-product-text'>{this.staticText.title}</span>
                                </div>
                                <div className='col text-right'>
                                    <img alt="" src={getImageSource(ImageConstants.POPUP_CLOSE)} className='popup-close-icon cursor-pointer' onClick={() => this.onClickCancel()}></img>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='app-container'>
                        <div className='price-setup-container'>
                            <div className='row  body-container'>
                                <div className='col-3'>

                                    <div className='app-search-wrapper-container' ref={ref => this.customerSearchRef = ref}>
                                        <div className={`${!this.state.isFocusCustomerSearch ? "app-search-input-gray" : "app-search-input"} ${this.state.isFocusSearch ? "border-radius-none" : ""} `}>
                                            <div className='search-icon-wrapper'>
                                                <img alt="" src={getImageSource(ImageConstants.SEARCH)} className='search-icon'></img>
                                            </div>
                                            <input
                                                ref={ref => this.inputRef = ref}
                                                className={`${!this.state.isFocusCustomerSearch ? "search-input-box-gray" : "search-input-box"}`}
                                                placeholder={this.staticText?.customerSearchPlaceHolder}
                                                value={this.state.customerSearch}
                                                onFocus={() => this.onFocusCustomerSearch(true)}
                                                onChange={(e) => this.onChangeCustomerSearch(e)}

                                            />
                                        </div>

                                        {this.state.isFocusCustomerSearch ? <div className='custom-list'>
                                            {this.state.customersList && this.state.customersList.length ? this.state.customersList.map((item, index) =>
                                                <div className={`product-name ${index < this.state.customersList.length - 1 ? "product-name-border " : ""}`} onClick={(e) => this.onSelectCustomer(e, item)}>{item.businessName}</div>
                                            ) : <div className='text-center pt-2'>No Buyers found</div>}

                                        </div> : ""}
                                    </div>

                                    <div className='product'>
                                        <div className='product-container-card'>
                                            <div className='setup-product-image-left'>
                                                {this.renderNameBadge(this.state?.customer?.businessName)}
                                            </div>
                                            <div className='setup-product-image-right'>
                                                <div className='product-name-setup-card'>{this.state.customer?.businessName}</div>
                                            </div>
                                        </div>

                                        <div className='row product-bg m-0'>
                                            <div className='col-3'><span className='actual-price'>{this.staticText.email}</span></div>
                                            <div className='col footer-pirce text-right'>{this.state.customer?.email}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-9'>

                                    <div className='app-search-wrapper-container customer-search' ref={ref => this.productSearchRef = ref}>
                                        <div className={`${!this.state.isFocusSearch ? "app-search-input-gray" : "app-search-input"} ${this.state.isFocusCustomerSearch ? "border-radius-none" : ""} `}>
                                            <div className='search-icon-wrapper-customer'>
                                                <img alt="" src={getImageSource(ImageConstants.SEARCH)} className='search-icon'></img>
                                            </div>
                                            <input
                                                ref={ref => this.inputRef = ref}
                                                className={`${!this.state.isFocusSearch ? "search-input-box-gray" : "search-input-box"}`}
                                                placeholder={this.staticText?.searchPlaceHolder}
                                                value={this.state.search}
                                                onFocus={() => this.onFocusSearch(true)}
                                                onChange={(e) => this.onChangeSearch(e)}
                                            />
                                        </div>

                                        {this.state.isFocusSearch ? <div className='custom-list product-custom'>
                                            {this.state.productList.length ? this.state.productList.map((item, index) =>
                                                <div className={`product-name ${index < this.state.productList.length - 1 ? "product-name-border " : ""}`} onClick={(e) => this.onSelectProduct(e, item)}>{item.name}</div>
                                            ) : <div className='text-center pt-2'><small>No Products found</small></div>}
                                        </div> : ""}
                                    </div>

                                    <div className='customer-container'>
                                        <div className='customer-title'>{this.staticText.productTitle} ({this.state.products?.length || 0})</div>

                                        {this.state.selectedProduct ? <div className='selected-customer row mt-3'>
                                            <div className='col-6'>
                                                <img alt="" src={getImageSource(ImageConstants.CROSS_MARK)} className='cross-mark' onClick={() => this.setState({ selectedProduct: null })}></img>
                                                <span className='selected-name ml-3'>{this.state.selectedProduct.name}</span>
                                            </div>

                                            <div className='col-6 text-right'>
                                                <span className='price-title'>{this.staticText.priceText}</span>
                                                <div className='price-card ml-3'>
                                                    <span className='pl-2 pr-2'>{this.state.currency.symbol}</span>
                                                    <input className='price-card-input' value={this.state.selectedProduct.price} type='number' onChange={(e) => this.onChangeCustomerPrice(e)}></input>
                                                </div>

                                                <div className='add-button ml-3 cursor-pointer' onClick={() => this.onPriceUpdate(this.state.selectedProduct)}>{this.staticText.addButton}</div>
                                            </div>
                                        </div> : ""}

                                        <div className='customer-list'>
                                            {this.state.products && this.state.products.length ? this.state.products.map((item, index) => (
                                                <div className={`row-wrapper ${this.state.products.length - 1 > index ? "border-bottom-row" : ""} row ${item.focus ? "customer-focus" : ""}`} onMouseEnter={() => this.onRowMouseEnter(item, index, true)} onMouseLeave={() => this.onRowMouseEnter(item, index, false)}>
                                                    <div className='col-1'>
                                                        <span className='sl-no'>{index + 1 < 10 ? '0' + (index + 1) : index + 1}</span>
                                                    </div>
                                                    <div className='col-3'>
                                                        <span className='customer-name'>{item.name}</span>
                                                    </div>
                                                    <div className='col text-right'>
                                                        {item.focus && !item.isEdit ? <img alt="" src={getImageSource(ImageConstants.CROSS_MARK)} className='cross-mark mr-3' onClick={() => this.onClickRemovePrice(item, index)}></img> : ""}
                                                        {item.focus && !item.isEdit ? <img alt="" src={getImageSource(ImageConstants.EDIT_ICON_ACTIVE)} className='cross-mark mr-5' onClick={() => this.onClickEdit(item, index)}></img> : ""}
                                                        {item.isEdit ? <div className='selected-customer p-0'>
                                                            <span className='price-title'>{this.staticText.priceText}</span>
                                                            <div className='price-card ml-3'>
                                                                <span className='pl-2 pr-2'>{item.currency.symbol}</span>
                                                                <input className='price-card-input' value={item.price} type='number' onChange={(e) => this.onChangeRowPrice(e, index)}></input>
                                                            </div>

                                                            <div className='add-button ml-3 cursor-pointer' onClick={() => this.onPriceUpdate(item, index, true)}>{this.staticText.updateButton}</div>

                                                        </div> : <span className='customer-price'>{item?.currency.symbol}{item.price}</span>}
                                                    </div>
                                                </div>
                                            )) : <div className='text-center p-2'>No products found</div>}

                                        </div>

                                        {this.totalPages > this.page ? <div className='mt-3 text-center mb-3 cursor-pointer' onClick={() => this.onClickLoadMore()}><small>{this.staticText.loadMore}</small></div> : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal >
        )
    }

    render() {

        return (
            <div className=''>
                {this.renderPriceSetup()}
                {this.state.showToaster ? <ToasterComponent type={this.state.toaster?.type} message={this.state.toaster?.message} /> : ""}
            </div>
        );
    }
}

export default CustomerPriceSetup;