import React from 'react';
import "./staff.scss";
import AuthenticateAPI from '../../apis/authenticate'
import StaffApi from '../../apis/staff'
import { Modal, ModalBody } from "reactstrap";

import ImageConstants from '../../constants/image-constants';
import { getImageSource } from '../../utility/image-source'
import Loading from '../common/loading/loading';
import StaticText from '../../shared-store/static-text-store';
import { Tooltip } from "reactstrap"

import CreateStaff from './create-staff/create-staff';
import AppConfirmModal from '../common/app-confirm/app-confirm'

import SessionCache from '../routes/session-cache';
import ActionConstant from '../../constants/action-constant';
import CommonUtils from '../../utility/common-utilities';
import SharedStore from '../../shared-store/shared-store';

class Staffs extends React.Component {
    state = {
        staticText: {},
        staff: [
        ]
    }
    accessRights = {}
    componentDidMount() {

        this.user = SessionCache.getUser()
        this.accessRights = this.user.accessRights || {}

        let staticText = StaticText.getStaticText("staffs")
        this.setState({ staticText })
        this.getStaff()
    }

    getStaff(payload = {}) {
        StaffApi.getStaffs(payload).then((response) => {
            this.setState({ staff: response.response || [] })
        }).catch((err) => {
        })
    }

    onClickMenu = (item) => {
        this.props.history.push(item.path)
    }

    onCreateStaff = () => {
        if (CommonUtils.checkTrialAccess(ActionConstant.ADD_STAFF)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ showCreateModal: true, mode: "CREATE" })
        }
    }

    hideModal = () => {
        this.setState({
            showCreateModal: false,
            showChangePassword: false
        })
    }

    onChangeHandler(event, key) {
        this.setState({ [key]: event?.target?.value })
    }

    onDelete = () => {
        let payload = {
            id: this.state.deleteItem?._id,
            isActive: false,
        }
        StaffApi.updateStaff(payload).then((response) => {
            this.handleCloseConfirm()
            let staff = this.state.staff
            staff.splice(this.state.deleteIndex, 1)
            this.setState({ staff })
        }).catch((err) => {
            alert(err);
        })
    }

    onClickDelete(item, index) {

        if (CommonUtils.checkTrialAccess(ActionConstant.REMOVE_STAFF)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ deleteItem: item, showDeleteConfirm: true, deleteIndex: index })
        }
    }

    handleCloseConfirm = () => {
        this.setState({ deleteItem: null, showDeleteConfirm: false })
    }

    onClickEdit(item) {

        if (CommonUtils.checkTrialAccess(ActionConstant.UPDATE_STAFF)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({
                selectedStaff: item,
                showCreateModal: true,
                mode: "EDIT"
            })
        }
    }

    onClickChangePassword(staff) {

        if (CommonUtils.checkTrialAccess(ActionConstant.STAFF_CHANGE_PASSWORD)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ selectedStaff: staff, showChangePassword: true })
        }
    }

    hideChangePassword = () => {
        this.setState({
            showChangePassword: false,
            selectedStaff: null,
            password: "",
            reEnterPassword: "",
            isInvalidNewPassword: false,
            isInvalidReEnterPassword: false,
            reEnterPasswordErrorMessage: ""

        })
    }

    onSelecDropdown = (option) => {
        this.setState({ type: option?.value })
    }

    onChangePassword = () => {
        let payload = {
            staffId: this.state.selectedStaff?._id,
            password: this.state.password,
        }

        let validation = this.validatePayload(true)

        if (!validation) return

        AuthenticateAPI.changePassword(payload).then((response) => {
            this.hideChangePassword()
        }).catch((err) => {
            alert(err);
        })
    }

    validatePayload(showError) {

        let isValidForm = true

        if (this.state.password && this.state.reEnterPassword) {
            if ((this.state.password !== this.state.reEnterPassword)) {
                showError && this.setState({ isInvalidReEnterPassword: true, isInvalidNewPassword: false, reEnterPasswordErrorMessage: this.state.staticText.misMatchPasswordErrorMessage })
                isValidForm = false
            } else this.setState({ isInvalidReEnterPassword: false, reEnterPasswordErrorMessage: "", isInvalidNewPassword: false })
        } else {
            if (!this.state.password) {
                showError && this.setState({ isInvalidNewPassword: true, newPasswordErrorMessage: this.state.staticText.newPasswordErrorMessage })
                isValidForm = false
            } else this.setState({ isInvalidNewPassword: false, newPasswordErrorMessage: "" })

            if (!this.state.reEnterPassword) {
                showError && this.setState({ isInvalidReEnterPassword: true, reEnterPasswordErrorMessage: this.state.staticText.reEnterPasswordErrorMessage })
                isValidForm = false
            } else this.setState({ isInvalidReEnterPassword: false, reEnterPasswordErrorMessage: "" })

        }


        this.setState({ isValidForm, errMessage: "" })
        return isValidForm
    }

    onClickShowPassword = (key) => {
        this.setState({ [key]: !this.state[key] })
    }

    renderChangePassword() {
        return (
            <Modal className="app-staff-custom-modal" isOpen={true} size="md">
                <ModalBody>

                    <div className="staff-password-change">
                        <div className='bold-text font-16 p-4 border-bottom'>
                            {this.state?.staticText?.changePasswordHeaderText}
                        </div>


                        <div className="px-4">

                            <div className={`bw-spaces-input ${this.state.isInvalidNewPassword ? "invalid-input-container" : ""} position-relative`}>
                                <div className='app-input-label'>{this.state.staticText?.passwordText}<span id="new-password-tooltip" className='star'>*</span></div>
                                <input id="new-password" className={`app-input pr-5`} placeholder={this.state.staticText?.passwordPlaceholder}
                                    value={this.state.password}
                                    type={this.state.showNewPassword ? "" : "password"}
                                    onChange={(e) => this.onChangeHandler(e, 'password')} />
                                <span className="password-icon">
                                    <img className="cursor-pointer icon-20" alt=""
                                        src={getImageSource(this.state.showNewPassword ? ImageConstants.EYE_OPEN : ImageConstants.EYE_CLOSE)}
                                        onClick={() => this.onClickShowPassword("showNewPassword")}
                                    />
                                </span>

                                <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isInvalidNewPassword} target="new-password-tooltip">
                                    {this.state.newPasswordErrorMessage}
                                </Tooltip>
                            </div>

                            <div className={`bw-spaces-input ${this.state.isInvalidReEnterPassword ? "invalid-input-container" : ""} position-relative`}>
                                <div className='app-input-label' >{this.state.staticText?.reEnterPasswordText}<span className='star' id="reenter-password-tooltip">*</span></div>
                                <input id="new-password" className={`app-input pr-5`} placeholder={this.state.staticText?.reEnterPasswordPlaceholder}
                                    value={this.state.reEnterPassword}
                                    type={this.state.showReEnterPassword ? "" : "password"}
                                    onChange={(e) => this.onChangeHandler(e, 'reEnterPassword')} />
                                <span className="password-icon">
                                    <img className="cursor-pointer icon-20" alt=""
                                        src={getImageSource(this.state.showReEnterPassword ? ImageConstants.EYE_OPEN : ImageConstants.EYE_CLOSE)}
                                        onClick={() => this.onClickShowPassword("showReEnterPassword")}
                                    />
                                </span>

                                <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isInvalidReEnterPassword} target="reenter-password-tooltip">
                                    {this.state.reEnterPasswordErrorMessage}
                                </Tooltip>
                            </div>

                            <div className='text-right py-4'>
                                <button className='app-button-default mr-2' onClick={this.hideChangePassword}>Cancel</button>
                                <button className='app-button-primary' onClick={this.onChangePassword}>{this.state.staticText?.changePasswordText}</button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal >
        )
    }

    onSaveStaff = () => {
        this.setState({ showCreateModal: false })
        this.getStaff()
    }

    renderStaffCreate() {

        return (
            <Modal className="app-staff-custom-modal" isOpen={true} size="lg">
                <ModalBody className="" >
                    <div>
                        <CreateStaff mode={this.state.mode} staff={this.state.selectedStaff} onSave={this.onSaveStaff} close={this.hideModal} />
                    </div>
                </ModalBody>

            </Modal >)
    }

    getRolesName(roles) {
        let names = []
        roles.forEach(element => {
            if (element.role != "Trial") names.push(element.role)
        })
        return names.join(", ")
    }

    makeActiveEdit = (value, index, key) => {
        let staff = this.state.staff
        staff[index][key] = value
        this.setState({ staff })
    }

    render() {

        return (
            <div className="staff">
                {this.state.isLoading && <Loading />}
                <div className="">
                    <div className='row'>
                        <div className="col">
                            <strong className="active-title">{this.state.staticText?.title}</strong>
                        </div>
                        <div className="col text-right">

                            {
                                CommonUtils.checkRights(ActionConstant.ADD_STAFF) ?
                                    <button className="app-button-primary mr-2" onClick={this.onCreateStaff}>
                                        <span className="pl-2">{this.state.staticText?.addStaffText} </span>
                                    </button> : ""
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <div id="table-scoll" className="table-responsive pt-2">
                        <table className="table mb-0 align-items-center  bg-white">
                            <thead>
                                <tr>
                                    <th className='border-top-none text-center slno-max-width'>S.No</th>
                                    <th className='border-top-none name-max-width'>Employee Name</th>
                                    <th className='border-top-none role-max-width'>Role</th>
                                    <th className='border-top-none action-button-width'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.staff?.length ? this.state.staff.map((item, index) => (

                                        <tr key={item._id} className="staff-row">
                                            <td className="semi-bold-text text-center">{index + 1}</td>
                                            <td className="cursor-pointer">
                                                <div className="d-flex align-items-center">
                                                    {
                                                        item?.image ?
                                                            <div className="pr-4">
                                                                <div className="image-circle-wrapper-50">
                                                                    <img alt="" src={item?.image} />
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }
                                                    <div>
                                                        <div className="staff-name-max-height font-14 bold-text vertical-line-clamp-2 ">{item.name}</div>
                                                        <div className='staff-code'>username {item.username || "-"}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="staff-code font-14">{this.getRolesName(item.roles)}</td>
                                            <td className='action-button-width'>
                                                {
                                                    CommonUtils.checkRights(ActionConstant.STAFF_CHANGE_PASSWORD) ?
                                                        <span className='app-button-secondary-small mr-2'
                                                            onMouseEnter={() => { this.makeActiveEdit(true, index, "isChangePasswordActive") }}
                                                            onMouseLeave={() => this.makeActiveEdit(false, index, "isChangePasswordActive")}
                                                            onClick={(event) => this.onClickChangePassword(item, index)}>
                                                            <img alt="" className='icon-15' src={getImageSource(item.isChangePasswordActive ? ImageConstants.LOCK_ACTIVE : ImageConstants.LOCK)} />
                                                            <span className='btn-text ml-3'>{this.state?.staticText.changePasswordText}</span>
                                                        </span> : ""
                                                }
                                                {
                                                    CommonUtils.checkRights(ActionConstant.REMOVE_STAFF) ?
                                                        <span className='app-button-secondary-small mr-2'
                                                            onMouseEnter={() => { this.makeActiveEdit(true, index, "isDeleteActive") }}
                                                            onMouseLeave={() => this.makeActiveEdit(false, index, "isDeleteActive")}
                                                            onClick={(event) => this.onClickDelete(item, index)}>
                                                            <img alt="" className='icon-15' src={getImageSource(item.isDeleteActive ? ImageConstants.DELETE_ACTIVE : ImageConstants.DELETE)} />
                                                            <span className='btn-text ml-3'>{this.state?.staticText.deleteText}</span>
                                                        </span> : ""
                                                }
                                                {
                                                    CommonUtils.checkRights(ActionConstant.UPDATE_STAFF) ?
                                                        <span className='app-button-secondary-small mr-2'
                                                            onMouseEnter={() => { this.makeActiveEdit(true, index, "isEditActive") }}
                                                            onMouseLeave={() => this.makeActiveEdit(false, index, "isEditActive")}
                                                            onClick={() => this.onClickEdit(item)}>
                                                            <img alt="" className='icon-15' src={getImageSource(item.isEditActive ? ImageConstants.EDIT_ICON_ACTIVE : ImageConstants.EDIT_ICON_IN_ACTIVE)} />
                                                            <span className='btn-text ml-3'>{this.state?.staticText.editText}</span>
                                                        </span> : ""
                                                }
                                            </td>
                                        </tr>
                                    ))
                                        : <tr><td colSpan="10" align='center' className='text-gray'>No Records found !</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
                {this.state.showCreateModal ? this.renderStaffCreate() : ""}
                {this.state.showChangePassword ? this.renderChangePassword() : ""}
                {
                    this.state.showDeleteConfirm ? <AppConfirmModal
                        img={getImageSource(ImageConstants.CLOSE_RED)}
                        okButtonText={this.state.staticText?.deleteText}
                        okButtonStyle="reject-text"
                        title={this.state.staticText?.deleteTitle}
                        close={this.handleCloseConfirm} onOk={this.onDelete}
                    /> : ""
                }
            </div>
        );
    }

}

export default Staffs;