import React from 'react';
import "./style.scss";

import ImageConstants from "../../constants/image-constants"
import { getImageSource } from '../../utility/image-source';
import StaticText from '../../shared-store/static-text-store';
import Path from '../routes/routes-path';
import InvoiceApi from '../../apis/invoice'
import Loading from '../common/loading/loading';


class PaymentPage extends React.Component {
   state = {
      staticText: {},
      isLoading: true,
      data: []
   }

   constructor(props) {
      super(props)
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let status = params.get('status');
      let id = params.get('id');

      this.state.isSuccess = status === "success"
      this.state.transactionId = id
   }

   componentDidMount() {

      let staticText = StaticText.getStaticText("paymentResponse")
      this.setState({ staticText })
      this.updatePaymentResponse()
   }

   getPaymentResponse = () => {
      const payload = {
         id: this.state.transactionId
      }
      InvoiceApi.getPaymentResponse(payload).then(response => {
         this.setState({ loading: false, data: response.data })
      }).catch((err) => {
         this.setState({ loading: false })
      })
   }

   updatePaymentResponse() {
      const payload = {
         transactionId: this.state.transactionId
      }
      this.setState({ isLoading: true })

      InvoiceApi.updatePaymentResponse(payload).then(response => {
         this.setState({ isLoading: false, data: response?.data?.data || [] })
      }).catch((err) => {
         this.setState({ isLoading: false })
      })
   }

   onClickContinue = () => {
      this.props.history.push(Path.INVOICE)
   }


   render() {

      if (this.state.isLoading) return <Loading />
      return (
         <div className="app-container">
            <div className="payment-response">
               <div className="container">
                  <div className="row  d-flex justify-content-center">
                     <div className="col payment-response-wrapper">
                        <div className=" complete">

                           <div className="text-center">
                              <img className="icon-80 animate-image-1" alt="" src={getImageSource(this.state.isSuccess ? ImageConstants.GREEN_TICK : ImageConstants.CLOSE_RED)} />
                           </div>

                           <div className="pt-4 text-center"> {this.state.isSuccess ? this.state.staticText?.successText : this.state.staticText?.failedText} </div>

                           <div className="row mt-5 border">
                              {
                                 this.state.data.map((item, index) => (
                                    <>
                                       <div className={`col-4 text-1 py-2 py-auto ${index != 0 ? "border-top-1" : ""}`}>
                                          <span className={`${item?.class || ""}`}>{item?.key}</span>
                                       </div>
                                       <div className={`col-8 text-2 py-2 py-auto ${index != 0 ? "border-top-1" : ""}`}> <span className={`${item?.class || ""}`}>{item?.value}</span>  </div>
                                    </>

                                 ))
                              }

                           </div>

                           <div className="mt-5 text-center">
                              <button className={`app-button-primary `} onClick={this.onClickContinue}>
                                 {this.state.staticText?.continueButtonText}
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div >
      )
   }
}

export default PaymentPage;