import React, { Component } from 'react';
import EmailNotification from '../../apis/email-notification';
import ImageConstants from '../../constants/image-constants';
import { getImageSource } from '../../utility/image-source';
import './email-notification-setting.scss';
import StaticText from '../../shared-store/static-text-store';  //CHG000002 - PB-9


export default class Email extends Component {
    
    /* BEGIN CHG000002 PB-9 */
    constructor(props) {
        super(props)
        this.staticText = StaticText.getStaticText("emailNotification")
    }
    /* END CHG000002 PB-9 */
    
    state = {
        config: []
    }

    componentDidMount() {
        this.getEmailNotification()
    }

    getEmailNotification(payload = {}) {
        EmailNotification.getEmailNotification(payload).then((response) => {
            this.setState({ config: response.payload.config || {} })
        }).catch((err) => {
        })
    }

    handleBack = () => {
        this.props.history.goBack()
    }

    updateEmailNotfication = (type) => {
        if (this.loading) return
        this.loading = true
        let config = this.state.config
        config[type] = !config[type]
        let payload = {
            [type]: config[type]
        }
        EmailNotification.updateEmailNotfication(payload).then((response) => {
            this.setState({ config })
            this.loading = false

        })
    }
    render() {
        return (

            <div className='email-container'>
                <div className='row'>
                    <div className='col-12'>
                        <img src={getImageSource(ImageConstants.FULL_ARROW_LEFT)} alt="" className='arrow-left cursor-pointer' onClick={() => this.handleBack()}></img>
                        <b className='header-text pl-4'>{this.staticText.pageHeader}</b>
                    </div>
                </div>

                <div className='email-container-inside'>
                    <div className='email-notification-header'>
                        <img src={getImageSource(ImageConstants.EMAIL_BOX)} alt="" className='emailbox' />
                        <b className='header-text  pl-4'>{this.staticText.pageTitle}</b>
                    </div>
                    <div className="p-4">
                        <div className='row px-5'>
                            <div className='col-6 py-4 px-5' onClick={() => this.updateEmailNotfication("invitationReceived")}>
                                <div className="action-flex">
                                    <b>{this.staticText.connectionRequest}</b>
                                    <span className="text-right px-2 flex-2">
                                        <div class={`custom-control custom-switch custom-control-switch text-center`}>
                                            <input type="checkbox" class="custom-control-input" id={`invitation_email}`} checked={this.state.config.invitationReceived} />
                                            <label class="custom-control-label" for={`invitation_email}`} ></label>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div className='col-6 py-4 px-5' onClick={() => this.updateEmailNotfication("invoiceReceived")}>
                                <div className="action-flex">
                                    <b>{this.staticText.newInvoice}</b>
                                    <span className="text-right px-2 flex-2">
                                        <div class={`custom-control custom-switch custom-control-switch text-center switch`}>
                                            <input type="checkbox" class="custom-control-input" id="invoiceReceived_email" checked={this.state.config.invoiceReceived} />
                                            <label class="custom-control-label" for="invoiceReceived_email"></label>
                                        </div>
                                    </span>
                                </div>
                            </div>

                            <div className='col-6 py-4 px-5' onClick={() => this.updateEmailNotfication("orderReceived")}>
                                <div className="action-flex">
                                    <b>{this.staticText.newOrder}</b>
                                    <span className="text-right px-2  flex-2">
                                        <div class={`custom-control custom-switch custom-control-switch text-center switch`}>
                                            <input type="checkbox" class="custom-control-input" id={`orderReceived_email`} checked={this.state.config.orderReceived} />
                                            <label class="custom-control-label" for={`orderReceived_email`}></label>
                                        </div>
                                    </span>
                                </div>

                            </div>
                            <div className='col-6 py-4 px-5' onClick={() => this.updateEmailNotfication("paymentReceived")}>
                                <div className="action-flex">
                                    <b>{this.staticText.newPayment}</b>
                                    <span className="text-right px-2  flex-2">
                                        <div class={`custom-control custom-switch custom-control-switch text-center switch `}>
                                            <input type="checkbox" class="custom-control-input" id={`paymentReceived_email`} checked={this.state.config.paymentReceived} />
                                            <label class="custom-control-label" for={`paymentReceived_email`}></label>
                                        </div>
                                    </span>
                                </div>
                            </div>

                            {/* Begin CHG000002 PB-9 Email Notification changes  */}
                            <div className='col-6 py-4 px-5' onClick={() => this.updateEmailNotfication("orderUpdates")}>
                                <div className="action-flex">
                                    <b>{this.staticText.orderUpdates}</b>
                                    <span className="text-right px-2  flex-2">
                                        <div class={`custom-control custom-switch custom-control-switch text-center switch`}>
                                            <input type="checkbox" class="custom-control-input" id={`orderUpdates_email`} checked={this.state.config.orderUpdates} />
                                            <label class="custom-control-label" for={`orderUpdates_email`}></label>
                                        </div>
                                    </span>
                                </div>

                            </div>
                            <div className='col-6 py-4 px-5' onClick={() => this.updateEmailNotfication("invoiceUpdates")}>
                                <div className="action-flex">
                                    <b>{this.staticText.invoiceUpdates}</b>
                                    <span className="text-right px-2  flex-2">
                                        <div class={`custom-control custom-switch custom-control-switch text-center switch `}>
                                            <input type="checkbox" class="custom-control-input" id={`invoiceUpdates_email`} checked={this.state.config.invoiceUpdates} />
                                            <label class="custom-control-label" for={`invoiceUpdates_email`}></label>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            {/* End CHG000002 PB-9 Email Notification changes  */}


                        </div>
                    </div>
                </div>
            </div >


        )
    }
}
