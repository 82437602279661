import React from 'react';
import "./app-confirm.scss";
import { Modal, ModalBody } from "reactstrap";
import { getImageSource } from '../../../utility/image-source';
import ImageConstants from '../../../constants/image-constants';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import Constants from '../../../constants/constants';

class ConfirmModal extends React.Component {
    state = {
    }

    onChangeHandler(event, key, max) {
        let value = event?.target?.value || ""
        max = max || Constants.INPUT_MAX_LENGTH_1
        if (max < value.length) return
        this.setState({ [key]: value })
    }

    onOk = () => {
        if (this.props.isCaptureInput && !this.state.reason) {
            this.setState({ isSubmit: true })
            return
        }
        this.props.onOk(this.state.reason)
    }

    render() {

        return (

            <Modal className={`app-confirm-custom-modal ${this.props.className}`} isOpen={true} toggle={this.props.close}>
                <ModalBody className="" >
                    <div className="app-confirm">
                        <div>
                            <img className="icon-20 close-icn cursor-pointer" alt="" src={getImageSource(ImageConstants.CLOSE_GRAY)} onClick={this.props.close} />
                        </div>
                        {this.props.img ? <div className="text-center pt-4">
                            <img className="icon-80 animate-image-1" alt="" src={this.props.img} />
                        </div> : ""}
                        <div className="text-center pt-2 px-4">
                            <div className="header-text">{this.props.headerText || "Confirmation"}</div>
                            <div className='description-text'>{this.props.title || "Are you sure do you want to do this Action?"}</div>
                        </div>
                        {
                            this.props.isCaptureInput ?
                                <div className={`bw-spaces-input ${this.state.isSubmit && !this.state.reason ? "invalid-input-container" : ""}`}>
                                    <textarea className={`app-input-text-area`} placeholder='Reason'
                                        value={this.state.description}
                                        onChange={(e) => this.onChangeHandler(e, 'reason')} />
                                </div> : ""
                        }
                    </div>
                </ModalBody>

                <ModalFooter>
                    <div className="row m-0 cursor-pointer">
                        <div className="col text-center border-right p-3 cancel-button" onClick={this.props.close}>
                            {this.props.cancelButtonText || "Cancel"}
                        </div>
                        <div className={`col text-center p-3 ok-button ${this.props.okButtonStyle || "text-primary"}`} onClick={this.onOk}>
                            {this.props.okButtonText || "Okay"}
                        </div>
                    </div>

                </ModalFooter>

            </Modal>
        );
    }

}

export default ConfirmModal;