import React from 'react';
import "./product-preview.scss";
import AuthenticateAPI from '../../../apis/authenticate'
// import SessionCache from '../routes/session-cache';
import { Modal, ModalBody } from "reactstrap";

import ImageConstants from '../../../constants/image-constants';
import { getImageSource } from '../../../utility/image-source';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import StaticText from '../../../shared-store/static-text-store';
import AddCartItem from '../../common/add-cart-items/add-cart-items'
import CommonUtils from '../../../utility/common-utilities';


class ProductPreview extends React.Component {

    constructor(props) {
        super(props)
        console.log(props)

        if (this.props.product)
            this.state.quantity = this.props.product.quantity || 0

        this.staticText = StaticText.getStaticText("productPreview")

    }
    state = {
        staticText: {},
        imageIndex: 0

    }

    componentDidMount() {
    }

    nextImage = () => {
        this.setState({ imageIndex: this.state.imageIndex + 1 })
    }

    previousImage = () => {
        this.setState({ imageIndex: this.state.imageIndex - 1 })
    }


    renderImages = () => {

        let images = this.props?.product?.images;
        let elements = []

        if (images && images.length) {
            elements.push(<div className='image'>
                {images.length > 1 && this.state.imageIndex != 0 ? <img src={getImageSource(ImageConstants.IMAGE_SWIPE_LEFT)} className='image-swipe-left' onClick={() => this.previousImage()}></img> : ""}
                <img src={images[this.state.imageIndex].url} className='product-images'></img>
                {images.length > 1 && this.state.imageIndex < images.length - 1 ? <img src={getImageSource(ImageConstants.IMAGE_SWIPE_RIGHT)} className='image-swipe-right' onClick={() => this.nextImage()}></img> : ""}
            </div>)
        } else {
            return <div className='image'><img alt="" src={getImageSource(ImageConstants.DEFAULT_PRODUCT)} className='product-images'></img> </div>
        }

        return elements;
    }

    onClickAdd = (nonHide) => {
        if (+this.state.quantity < 0)
            return

        this.props.onAdd && this.props.onAdd(this.state.quantity || 0, nonHide)
    }

    onChangeQuantity = (e) => {
        this.setState({ quantity: e.target.value })
    }

    onClickMinus = () => {

        let value = this.state.quantity ? +(this.state.quantity - 1) : "";

        if (!value) {
            value = ""
        }

        this.setState({ quantity: value }, () => {
            this.onClickAdd(true)
        })
    }

    onClickPlus = () => {
        let value = (this.state.quantity || 0) + 1
        this.setState({ quantity: value }, () => {
            this.onClickAdd(true)
        })
    }

    render() {

        let product = this.props.product;

        return (

            <Modal className="product-preview-modal" isOpen={true} toggle={this.props.close} size="md">
                <ModalBody className="p-0">
                    <div className='product-preview'>
                        {this.renderImages()}
                        <div className='product-name-preview mt-3'>{product.name}</div>
                        <div className='row border-bottom m-0 pb-3'>
                            <div className='col-10 p-0'>
                                <div className='vendor-name-preview d-inline-block mr-3'>{product?.branch?.name}</div>
                                <div>
                                    <div className='gray-dot'></div>
                                    <div className='vendor-name-preview d-inline-block ml-2'>{product?.category?.title}</div>
                                </div>
                                <div>
                                    <div className='gray-dot'></div>
                                    <div className='vendor-name-preview d-inline-block ml-2'>Brand - {product?.brand?.title}</div>
                                </div>
                            </div>
                            <div className='col-2 p-0 text-right'>
                                <img src={getImageSource(ImageConstants.FAVORATE)} className='favorate-image' />
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div className='description-title'>{this.staticText.descriptionText}</div>
                            <div className='description mt-1'>{product.description}</div>
                        </div>

                        <div className='mt-4'>
                            <div className='gray-dot mr-2'></div>
                            <span className='price-unit'>{product.currency.symbol}{CommonUtils.formatAmountStringStrict(product.price)}/ {product.sellQuantity.value} {product.sellQuantity.unit}</span>
                        </div>

                        <div className='mt-4'>
                            <AddCartItem onSubmit={this.onClickAdd} onChange={this.onChangeQuantity} onClickMinus={() => this.onClickMinus()} value={this.state.quantity} onClickAdd={() => this.onClickPlus()} />
                        </div>

                        {/* <div className='input-container mt-4'>
                            <div className='input-left pr-0'>
                                <input className='app-input' type={'number'} value={this.state.quantity} placeholder={'Enter quantity'} onChange={this.onChangeQuantity} />
                                <span className='unit-text'>{product?.sellQuantity?.unit}</span>
                            </div>
                            <div className='input-right'>
                                <div className='app-button-primary d-inline-block' onClick={() => this.onClickAdd()}>{this.staticText.addButton}</div>
                            </div>
                        </div> */}


                    </div>
                </ModalBody>
            </Modal >
        );
    }

}

export default ProductPreview;