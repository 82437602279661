// INV000001 - New file for Inventory module

import React from 'react';
import "./inventory.scss";
import inventoryApi from '../../apis/inventory';                        // INV000001
import ImageConstants from '../../constants/image-constants';
import Constants from '../../constants/constants';
import ActionConstant from '../../constants/action-constant';
import { getImageSource } from '../../utility/image-source'
import StaticText from '../../shared-store/static-text-store';
import CreateInventoryProduct from './create-inventory-products/create-inventory-products'
import InventoryProductMapping from './inventory-product-mapping/inventory-product-mapping'

import ExcelToJson from '../common/excel-to-json/excel-to-json';
import { Modal, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Loading from '../common/loading/loading'
import ToasterComponent from '../common/toaster/toaster';
import SessionCache from '../routes/session-cache';
import ImagePreview from '../common/image-preview'
import CommonUtils from '../../utility/common-utilities';
import SharedStore from '../../shared-store/shared-store'
import ImportExport from './import-export/import-export';

class Inventory extends React.Component {

    accessRights = {}
    user = {}
    isStaff = false
    filter = {}

    constructor(props) {
        super(props)
        this.user = SessionCache.getUser()
        this.accessRights = this.user.accessRights || {}
        this.isStaff = this.user.loginAs === Constants.LOGIN_AS.STAFF

        this.staticText = StaticText.getStaticText("inventoryProductList")
        this.getProduct()
        this.getCategories()
    }

    state = {
        products: [],
        showFilters: false,
        categories: [],
        status: [
            { title: "Inactive" },
            { title: "Active" }
        ],
        brands: [],
        selectedProduct: null,
        searchText: "",
        selectedTab: 0,
    }

    totalPages = 1
    page = 1

    componentDidMount() {
        this.getProduct()
        document.addEventListener('mousedown', this.handleClickEvent, false);
    }


    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickEvent, false);
    }

    onClickLoadMore = () => {
        this.page += 1
        this.getProduct(this.filter || {}, true)
    }

    onClickProductEdit = (product, index) => {
        if (CommonUtils.checkTrialAccess(ActionConstant.UPDATE_PRODUCT)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else if (CommonUtils.checkRights(ActionConstant.UPDATE_PRODUCT)) {
            this.setState({ selectedProduct: product, showCreateInventory: true })
            return
        }
    }

    onSearch = (e) => {
        this.setState({ searchText: e.target.value }, () => {
            this.getProduct()
        })
    }

    onClickEdit = () => {
       /* let products = this.state.products
        products[index].isEdit = true;
        this.setState({ products })*/
    }

    onChangeHandler(event, key) {
        /*this.setState({ [key]: event?.target?.value }, () => {
            this.searchInvoice(key)
        })*/
    }

    getCurrentFormattedDateForInput() {
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + 1); // Set to one day ahead
        const year = futureDate.getFullYear().toString();
        const month = (futureDate.getMonth() + 1).toString().padStart(2, '0');
        const day = futureDate.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    getProduct(payload = {}, append) {

        payload.page = this.page;

        if (this.state.searchText) {
            payload.search = this.state.searchText
        }

        payload.status = this.state.selectedTab === 0 ? Constants.PRODUCT_STATUS.ACTIVE : Constants.PRODUCT_STATUS.INACTIVE

        this.setState({ loading: true })
        inventoryApi.getInventoryProducts(payload).then((response) => {
            this.setState({ loading: false })
            if (response.totalPages)
                this.totalPages = response.totalPages

            this.setState({ products: append ? this.state.products.concat(response.response || []) : response.response || [] })
        }).catch((err) => {
            this.setState({ loading: false })
        })
    }


    makeActiveEdit = (value, index) => {
        let products = this.state.products
        products[index].isActive = value
        this.setState({ products })
    }

    onClickReset = () => {
        this.page = 1
        this.filter = {}
        this.getProduct()
        let categories = this.state.backupCategories
        let brands = this.state.backupBrands

        categories.forEach(element => {
            element.active = false
        });
        brands.forEach(element => {
            element.active = false
        });
        this.setState({ showFilters: false, filterBadgeCount: 0, categories, brands, selectedCategories: [], selectedBrands: [] })
    }

    onClickApplyFilter = () => {
        this.page = 1
        let payload = {}

        if (this.state.selectedCategories?.length) {
            payload.categories = this.state.selectedCategories?.join(",")
        }

        if (this.state.selectedBrands?.length) {
            payload.brands = this.state.selectedBrands?.join(",")
        }
        this.filter = payload
        this.getProduct(payload)
        this.setState({ showFilters: false })
    }

    onClickShowFilter = () => {
        //this.getCategories()
        // this.getBrands()
    }

    showProductExpand = () => {
        this.setState({ showProductExpandView: !this.state.showProductExpandView })
    }

    onChangeProductStatus = (item, index) => {
        let payload = {
            productObjId: item._id,
            productId: item.productId,
            productStatus: item.productStatus === Constants.INVENTORY_PRODUCT_STATUS.ACTIVE ? Constants.INVENTORY_PRODUCT_STATUS.INACTIVE : Constants.INVENTORY_PRODUCT_STATUS.ACTIVE
        }

        let products = this.state.products
        item.productStatus = payload.productStatus
        products[index] = item

        this.setState({ loading: true, products })

        inventoryApi.updateProductInventory(payload).then((response) => {
            this.setState({ loading: false })
        }).catch((err) => {
            item.productStatus = item.productStatus === Constants.INVENTORY_PRODUCT_STATUS.ACTIVE ? Constants.INVENTORY_PRODUCT_STATUS.INACTIVE : Constants.INVENTORY_PRODUCT_STATUS.ACTIVE
            products[index] = item
            this.setState({ loading: false, products })
            if (err?.response?.data?.code === 11000) {
                this.setState({ isDuplicateCode: true })
                this.showToaster("error", err?.response?.data?.message)
            }
            else {
                this.showToaster("error", "Oops somthing went wrong...")
            }
        })
    }


    renderInventoryProductsListView = () => {

        return (<div>
            <div id="table-scoll" className="table-responsive pt-2 product-list-table-wrapper">
                <table className="table mb-0 align-items-center product-list-table">
                    <thead>
                        <tr>
                            <th className='slno-max-width text-center'>
                                <div>{this.staticText.serialNo}</div>
                                <div>&nbsp;</div>
                            </th>
                            <th className='product-max-width'>
                                <div>{this.staticText.productName}</div>
                                <div>&nbsp;</div>
                            </th>
                            <th className='category-max-width'>
                                <div>{this.staticText.brandTitle}</div>
                                <div>&nbsp;</div>
                            </th>
                            <th className='category-max-width'>
                                <div>{this.staticText.categoryTitle}</div>
                                <div>&nbsp;</div>
                            </th>
                            <th className='category-max-width text-right'>
                                <div className='text-right'>
                                    {this.staticText.onHandLine1} &nbsp;
                                    <img alt="" src={getImageSource(ImageConstants.EDIT_ICON_ACTIVE)} width='16px' height='16px' class = 'edit-icon-opac' onClick={() => this.onClickEdit()}/>
                                </div>
                                <div className='text-right'>
                                    {this.staticText.onHandLine2}&nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                            </th>
                            <th className='description-max-width text-right'>
                                <div>{this.staticText.buyerOrdersLine1}</div>
                                <div>{this.staticText.buyerOrdersLine2}</div>
                            </th>
                            <th className='quantity-max-width text-right text-right'>
                                <div>{this.staticText.onOrderLine1}</div>
                                <div>{this.staticText.onOrderLine2}</div>
                            </th>
                            <th className='price-max-width text-right text-right'>
                                <div>{this.staticText.netLine1}</div>
                                <div>{this.staticText.netLine2}</div>
                            </th>
                            <th className='inventory-15-width text-right'>
                                <div>
                                    {/*this.staticText.netAsOf*/ this.staticText.status} &nbsp;
                                    {/* <input      // Do not delete. Code for Net As Of date for inventory
                                        type="date"
                                        className="app-input-small"
                                        defaultValue={this.getCurrentFormattedDateForInput()}
                                        min={new Date().toISOString().split('T')[0]}
                                        onChange={(e) => this.onChangeHandler(e, 'invoiceNumberSearch')} /> */}
                                </div>
                                
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.products?.length ? this.state.products.map((item, index) => (

                                <tr key={item._id} className='products-row' onClick={(e) => { e.stopPropagation(); this.onClickProductEdit(item, index) }}>
                                    <td className="semi-bold-text S.No text-center">{index + 1}</td>
                                    <td className="cursor-pointer">
                                        <div className='d-flex name-center'>
                                            <div className='contains-preview-image'>
                                                <div className=''>
                                                    <img alt="" className="product-image" src={item.images[0]?.url || getImageSource(ImageConstants.DEFAULT_PRODUCT)} />
                                                </div>
                                                <ImagePreview isLast={index === this.state.products?.length - 1} src={item?.images[0]?.url || getImageSource(ImageConstants.DEFAULT_PRODUCT)} />
                                            </div>
                                            <div className='pl-2'>
                                                <div className='product-name-list'>{item.productName}</div>
                                                <div className='product-code'>{item.productId ? '#' + item.productId : item.productId}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className='light-text name-center'>{item?.productBrand} </td>
                                    <td className='light-text name-center'>{item?.productCategory} </td>
                                    <td className='semi-bold-text text-right'>{item?.currentInventory} </td>
                                    <td className='light-text text-right'>{item.outgoingInventory ? item.outgoingInventory : 0}</td>
                                    <td className="light-text text-right">{item.incomingInventory ? item.incomingInventory : 0}</td>
                                    <td className='light-text text-right'>{
                                        ((+item.currentInventory ?? 0)   +
                                        (item.incomingInventory ?? 0)   -
                                        (item.outgoingInventory ?? 0))
                                    }</td>
                                    <td className="text-right">
                                        <div class={`custom-control custom-switch custom-control-switch d-inline-block`} onClick={(event) => {
                                            event.stopPropagation()
                                            this.onChangeProductStatus(item, index)
                                        }}>
                                            <input type="checkbox" class="custom-control-input" id="product-status-switch" checked={item.productStatus == Constants.INVENTORY_PRODUCT_STATUS.ACTIVE} />
                                            <label class="custom-control-label" for="product-status-switch"></label>
                                        </div>
                                    </td>
                                </tr>
                            ))
                                : <tr><td colSpan="10" align='center' className='text-gray'>No Records found !</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            {this.totalPages > this.page ? <div className='text-center mt-3 cursor-pointer' onClick={this.onClickLoadMore}><span>{this.staticText.loadMoreText}</span></div> : ""}
        </div>)
    }

    onClickHeaderNavigation = (item) => {

        this.setState({ showProductExpandView: false })

        if (item.isActive || !item.path) {
            return
        }

        this.props.history.push(item.path)
    }


    renderInventoryHeaders = () => {

        return (
            <div className='row'>
                <div className='col-4 my-auto position-relative' onClick={() => this.showProductExpand()}>
                    <div className="tab-wrapper">
                        <span className={`tab mr-4 ${this.state.selectedTab === 0 ? "active-tab" : ""}`} onClick={() => this.onClickTab(0)}>{this.staticText?.tab1 || "Active"}</span>
                        <span className={`tab ${this.state.selectedTab === 1 ? "active-tab" : ""}`} onClick={() => this.onClickTab(1)}>{this.staticText?.tab2 || "Inactive"}</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {this.user?.currentUseType === Constants.USER_TYPE.CUSTOMER && CommonUtils.checkRights(ActionConstant.ADD_INVENTORY) ? 
                            (<div className='app-button-primary add-product-button' onClick={() =>
                            CommonUtils.checkTrialAccess(ActionConstant.ADD_INVENTORY) ? SharedStore.getStore("subscription").showSubscriptionModal() :
                            this.setState({ showProductMapping: true })
                        }>{this.staticText.productMappingButtonTitle}</div>) : ""}
                    
                    
                    </div>

                   

                </div>

                <div className='col-8 text-right header-right-wrapper'>
                    {
                        this.state.products?.length || this.state.searchText ?
                            <>
                                <div className='search-input'>
                                    <img alt="" src={getImageSource(ImageConstants.PRODUCT_LIST_SEARCH)} className='product-list-search ml-3'></img>
                                    <input className='search-input-input' placeholder={this.staticText.searchProducts} onChange={(e) => this.onSearch(e)} />
                                </div>

                                <div className='d-inline-block mr-4'>
                                    <img alt="" src={getImageSource(ImageConstants.PRODUCT_LIST_FILTERS)} className='cursor-pointer product-list-filters cursor-pointer' onClick={() => { this.onClickShowFilter(); this.setState({ showFilters: !this.state.showFilters }) }}></img>
                                    {this.state.filterBadgeCount ? <span className="position-absolute top-0"><div className="badge-red"><i>{this.state.filterBadgeCount}</i></div></span> : ""}
                                    {this.state.showFilters ? this.renderFilters() : <></>}
                                </div>

                                <div className='d-inline-block header-devider mr-4'></div>

                                <div className='d-inline-block mr-3'>{/*this.renderListSwitch()*/}</div>
                            </> : ""
                    }

                    {CommonUtils.checkRights(ActionConstant.ADD_INVENTORY) ? <div className='app-button-primary add-product-button' onClick={() =>
                        CommonUtils.checkTrialAccess(ActionConstant.ADD_INVENTORY) ? SharedStore.getStore("subscription").showSubscriptionModal() :
                            this.setState({ showCreateInventory: true, selectedProduct: null })
                    }>{this.staticText.buttonTitle}</div> : ""}
                    {CommonUtils.checkRights(ActionConstant.IMPORT_PRODUCT) ? <div className='app-button-primary add-product-button ml-2' onClick={this.onClickImportExport}>{this.staticText?.importExportButtonTitle || 'Import / Export Inventory'}</div> : ""}
                </div>

            </div>)
    }

    renderCategories = () => {

        let elements = []

        for (let i = 0; i < this.state.categories.length; i++) {

            let item = this.state.categories[i]
            elements.push(<div className='cursor-pointer mb-3' onClick={() => { this.onClickCategories(i) }}>
                <div className="d-flex align-items-center">
                    <img alt="" src={getImageSource(item.active ? ImageConstants.CHECKBOX_ACTIVE : ImageConstants.CHECKBOX_INACTIVE)} className='checkbox'></img>
                    <span className={`categories-name pl-2 ${item.active ? 'categories-name-active' : ""}`}>{item.title}</span>
                </div>
            </div>)
        }

        return elements
    }

    handleClickEvent = (event) => {

        if (this.invitaionsRef && !this.invitaionsRef.contains(event.target)) {
            this.setState({ showProductExpandView: false })
        }

        if (this.filterRef && !this.filterRef.contains(event.target)) {
            this.setState({ showFilters: false })
        }
    }


    onClickCategories = (i) => {
        let categories = this.state.categories;
        categories[i].active = !categories[i].active
        let selectedCategories = this.state.selectedCategories || []

        let filterBadgeCount = this.state.filterBadgeCount || 0

        if (categories[i].active) {
            filterBadgeCount += 1
            selectedCategories.push(categories[i].title)
        }
        else {
            filterBadgeCount -= 1
            let data = selectedCategories.filter(item => item !== categories[i].title)
            selectedCategories = data
        }

        this.setState({ categories, filterBadgeCount, selectedCategories })
    }


    onClickBrands = (i) => {
        let brands = this.state.brands;
        brands[i].active = !brands[i].active

        let filterBadgeCount = this.state.filterBadgeCount || 0
        let selectedBrands = this.state.selectedBrands || []

        if (brands[i].active) {
            filterBadgeCount += 1
            selectedBrands.push(brands[i].title)
        }
        else {
            filterBadgeCount -= 1
            let data = selectedBrands = selectedBrands.filter(item => item !== brands[i].title)
            selectedBrands = data
        }

        this.setState({ brands, filterBadgeCount, selectedBrands })
    }

    renderStatus = () => {

        let elements = []

        for (let i = 0; i < this.state.status.length; i++) {

            let item = this.state.status[i]
            elements.push(<div className='cursor-pointer mb-1' onClick={() => { this.onClickStatus(i) }}>
                <img src={getImageSource(item.active ? ImageConstants.CHECKBOX_ACTIVE : ImageConstants.CHECKBOX_INACTIVE)} className='checkbox'></img>
                <span className={`categories-name pl-2 ${item.active ? 'categories-name-active' : ""}`}>{item.title}</span>
            </div>)
        }

        return elements
    }

    onClickStatus = (i) => {
        let status = this.state.status;
        status[i].active = !status[i].active
        this.setState({ status })
    }

    getCategories = () => {

        let payload = {}

        inventoryApi.getInvCategoriesAndBrand(payload).then((response) => {

            let { categories, brands } = response
            this.setState({
                backupCategories: categories || [],
                categories: categories || [],
                backupBrands: brands || [],
                brands: brands || [],
            })

        }).catch((err) => {
            alert(err);
        })

    }

    renderBrands = () => {

        let elements = []

        for (let i = 0; i < this.state.brands.length; i++) {

            let item = this.state.brands[i]
            elements.push(<div className='cursor-pointer mb-3' onClick={() => { this.onClickBrands(i) }}>
                <div className="d-flex align-items-center">
                    <img src={getImageSource(item.active ? ImageConstants.CHECKBOX_ACTIVE : ImageConstants.CHECKBOX_INACTIVE)} className='checkbox'></img>
                    <span className={`categories-name pl-2 ${item.active ? 'categories-name-active' : ""}`}>{item.title}</span>
                </div>
            </div>)
        }

        return elements
    }


    closeCreateProduct = () => {
        this.setState({ products: [] })
        this.page = 1
        this.setState({ showCreateInventory: false }, () => {
            this.getProduct()
            this.getCategories()
        })
    }

    closeInventoryProductMapping = () => {
        this.setState({ products: [] })
        this.page = 1
        this.setState({ showProductMapping: false }, () => {
            this.getProduct()
            this.getCategories()
        })
    }

    onCategorySearch = (e) => {

        let value = e.target.value

        let backupCategories = this.state.backupCategories || []

        if (value) {
            let options = backupCategories.filter(option => option.title.toLowerCase().includes(value.toLowerCase()));
            this.setState({ categories: options, categorySearch: value });
        } else {
            this.setState({ categories: backupCategories, categorySearch: value });
        }
    }

    onBrandSearch = (e) => {

        let value = e.target.value
        let backupBrands = this.state.backupBrands || []
        if (value) {
            let options = backupBrands.filter(option => option.title.toLowerCase().includes(value.toLowerCase()));
            this.setState({ brands: options, brandSearch: value });
        } else {
            this.setState({ brands: backupBrands, brandSearch: value });
        }

    }

    renderFilters = () => {

        return (
            <div className='filterpop-over app-box-shadow' ref={ref => this.filterRef = ref}>
                <div className="arrow-up"></div>
                <div className='filter-header'><span className="pr-2">{this.staticText.filterText}</span> {this.state.filterBadgeCount ? <div className="badge-red position-absolute"><i>{this.state.filterBadgeCount}</i></div> : ""}</div>

                <div className='row m-0'>

                    <div className='col filter-left'>
                        <div className='title-pop-over'>{this.staticText.categoryTitle}</div>
                        <div>
                            <div className='filter-search-wrapper mt-1 my-2 p-2'>
                                <img alt="" src={getImageSource(ImageConstants.PRODUCT_LIST_SEARCH)} className='icon-15'></img>
                                <input className='filter-search' value={this.state.categorySearch} placeholder={'Search...'} onChange={this.onCategorySearch} />
                            </div>
                            <div className="category-list">
                                {this.renderCategories()}
                            </div>
                        </div>
                    </div>
                    <div className='col filter-right'>
                        <div className='title-pop-over'>{this.staticText.brandTitle}</div>

                        <div className='filter-search-wrapper mt-1 my-2 p-2'>
                            <img alt="" src={getImageSource(ImageConstants.PRODUCT_LIST_SEARCH)} className='icon-15'></img>
                            <input className='filter-search' placeholder={'Search...'} value={this.state.brandSearch} onChange={this.onBrandSearch} />
                        </div>
                        <div className="category-list">
                            {this.renderBrands()}
                        </div>
                    </div>
                </div>

                <div className='row button-wrapper py-2'>
                    <div className='reset-button' onClick={() => this.onClickReset()}>
                        Reset
                    </div>
                    <div className='apply-button' onClick={() => this.onClickApplyFilter()}>
                        Apply Filter
                    </div>
                </div>
            </div>)
    }

    ////Prdocut Upload Dummy 

    onClickBulk = () => {

        if (CommonUtils.checkTrialAccess(ActionConstant.IMPORT_PRODUCT)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ showBulkUpload: true })
        }
    }

    closeBulkUpload = () => {
        this.setState({ showBulkUpload: false })
    }

    onClickImportExport = () => {

        if (CommonUtils.checkTrialAccess(ActionConstant.IMPORT_PRODUCT)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ showImportExport: true })
        }
    }

    closeImportExport = (toastType, toastMsg) => {
        this.setState({ showImportExport: false })
        if (toastType && toastMsg) {
            this.showToaster(toastType, toastMsg)
        }
    }

    onSuccessUploadExcel = (res) => {

        if (res.data) {
            let products = this.extractFromExcel(res.data)
            this.setState({
                excelFileName: res?.fileName || "",
                bulkProducts: products
            })
            return
        }

        if (res.error) {
            this.setState({ errorMessage: "Excel upload error" })
        }
    }

    extractFromExcel(data) {
        let productList = []

        for (let i = 0; i < data.length; i++) {
            let obj = data[i]
            let product = {
                name: obj["PRODUCT"] ? obj["PRODUCT"].trim() : "",
                code: obj["PRODUCT ID"] ? obj["PRODUCT ID"].trim() : "",
                description: obj["PRODUCT DESCRIPTION"] ? obj["PRODUCT DESCRIPTION"].trim() : "",
                sellQuantity: obj["SELL UNIT"] ? obj["SELL UNIT"].trim() : "",
                images: obj["images"] ? obj["images"].trim() : "",
                price: obj["PRICE"] ? obj["PRICE"].trim() : "",
                currencyCode: obj["CURRENCY"] ? obj["CURRENCY"].trim() : "",
                currencySymbol: obj["CURRENCY SYMBOL"] ? obj["CURRENCY SYMBOL"].trim() : "$",
                category: obj["CATEGORY"] ? obj["CATEGORY"].trim() : "",
                brand: obj["BRAND"] ? obj["BRAND"].trim() : ""
            }

            productList.push(product)
        }

        return productList
    }

    showToaster(type, message) {
        this.setState({
            showToaster: true,
            toaster: {
                type,
                message,
            }
        }, () => {
            setTimeout(() => this.hideToaster(), 3000)
        })
    }

    hideToaster() {
        this.setState({ showToaster: false })
    }


    removeExcelFile() {
        this.setState({ excelFileName: "", bulkProducts: [] })
    }

    onSaveBulkProducts = () => {

        let bulkProducts = this.state.bulkProducts;

        let buildPayload = []

        for (let i = 0; i < bulkProducts.length; i++) {

            let product = bulkProducts[i]

            if (!this.validatePayload(product)) {
                alert(`Invalid product (ROW (${i + 1}))`)
                return
            }

            let productBuild = {
                name: product.name,
                code: product.code,
                description: product.description,
                sellQuantity: {
                    value: product.sellQuantity,
                },
                images: product.images ? [{ url: product.images }] : [],
                price: product.price,
                currency: {
                    code: product.currencyCode,
                    symbol: product.currencySymbol
                },
                category: {
                    title: product.category,
                    value: product.category
                },
                brand: {
                    title: product.brand,
                    value: product.brand
                },
            }

            buildPayload.push(productBuild)
        }

        inventoryApi.CreateInventoryProduct({ products: buildPayload }).then((response) => {
            this.setState({ showBulkUpload: false }, () => {
                this.showToaster("success", "Product created successfully.")
                this.getProduct()
            })
        }).catch((err) => {
            this.showToaster("error", "Failed to create product")

        })
    }

    validatePayload = (payload) => {

        if (!payload.name) {
            return false
        }
        if (!payload.code) {
            return false
        }

        if (!payload.sellQuantity) {
            return false
        }


        if (!payload.currencyCode) {
            return false
        }

        if (!payload.currencySymbol) {
            return false
        }

        if (!payload.category) {
            return false
        }

        if (!payload.brand) {
            return false
        }

        return true
    }

    showExcelFormat = () => {
        const link = document.createElement('a');
        link.href = Constants.INVENTORY_UPLOAD_FORMAT
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    renderUploadModal() {

        return (
            <Modal isOpen={true} toggle={this.closeBulkUpload} size={'md'}>

                <ModalBody >
                    <div className="">
                        <div className="row">
                            <div className="col text-right">
                                <FontAwesomeIcon className="cursor-pointer" onClick={this.closeBulkUpload} icon={faTimes} />
                            </div>
                        </div>

                        <div className="product-bulk-import">
                            <div className="header-text">
                                <span>Add Product</span>
                            </div>

                            <div className='bw-spaces-input py-5'>
                                <div className="import-excel-div ">
                                    <label className="file-name py-2 px-4 m-0">
                                        {this.state?.excelFileName || ""}
                                    </label>
                                    {
                                        this.state.excelFileName ?
                                            <label className="excel float-right text-danger py-2 px-4 m-0" onClick={() => this.removeExcelFile()}>
                                                REMOVE EXCEL
                                            </label> :
                                            <label className="excel float-right text-success py-2 px-4 m-0">
                                                <ExcelToJson headerStartFrom={1} onSuccess={this.onSuccessUploadExcel} />
                                                ADD EXCEL
                                            </label>
                                    }
                                </div>
                                <div className="text-right check-format pt-2">
                                    <span className="p-2 cursor-pointer" onClick={this.showExcelFormat}>Download Template Here</span>
                                </div>
                                <div className='sub-text pt-3'>
                                    <ol>
                                        <li className="pt-2">Please make sure that the spreadsheet follows the specified template. Otherwise the products will not upload correctly</li>
                                        <li className="pt-2">Please make sure that you are not uploading the products that already exist in the system. Otherwise duplicate entries will be created and you may need to delete them manually, one at a time.</li>
                                    </ol>
                                </div>
                            </div>

                            <div className="text-center">
                                {this.state.errorMessage ? <div className="text-danger pb-4 px-4"><strong>{this.state.errorMessage} </strong></div> : ""}
                                <button className="app-button-primary" onClick={() => this.onSaveBulkProducts()}>
                                    Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalBody>

            </Modal >
        );
    }

    onClickTab(selectedTab) {
        this.page = 1
        this.setState({ selectedTab, }, () => {
            this.getProduct()
        })
    }

    render() {

        return (
            <>
                <div className='inventory-s1'>

                    <div className="">
                        {this.renderInventoryHeaders()}
                    </div>

                    {this.state.products && this.state.products.length ? <div>
                        {this.state.loading ? <Loading></Loading> : <div className='mt-3'>
                            {this.renderInventoryProductsListView()}
                        </div>}
                    </div> :
                        this.state.loading ? <Loading></Loading> : <div className='no-records-wrapper'>
                            <img alt="" src={getImageSource(ImageConstants.NO_PRODUCT)} className='no-product-image' />
                            <div className='no-product-text mt-2'>{this.state.selectedTab === 1 ? this.staticText.noInactiveProductsText : this.staticText.noProductsText}</div>
                            {this.state.selectedTab === 0 && 
                                    (<div className='app-button-primary mt-3' onClick={() => 
                                        CommonUtils.checkTrialAccess(ActionConstant.ADD_INVENTORY) ? SharedStore.getStore("subscription").showSubscriptionModal() :
                                        this.setState({ showCreateInventory: true, selectedProduct: null })}>
                                        {this.staticText.buttonTitle}
                                    </div>)
                            }
                    </div>
                    }
                </div>
                {
                    this.state.showProductMapping ? <InventoryProductMapping cancel={() => this.closeInventoryProductMapping()} /> : <></>
                }
                
                {
                    this.state.showCreateInventory ? <CreateInventoryProduct cancel={() => this.closeCreateProduct()} product={this.state.selectedProduct} /> : <></>
                }
                {
                    this.state.showBulkUpload ? this.renderUploadModal() : "" //Product Upload Dummy 
                }
                {
                    this.state.showImportExport ? <ImportExport closeImportExport={() => this.closeImportExport()} showToaster={(type, message) => this.showToaster(type, message)} {...this.props} /> : "" 
                }

                {this.state.showToaster ? <ToasterComponent type={this.state.toaster?.type} message={this.state.toaster?.message} /> : ""}

            </>
        );
    }

}

export default Inventory;