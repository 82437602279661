import React, { useState } from 'react';
import './App.scss';
import Routes from './home/components/routes/routes';
import AuthenticateAPI from "./home/apis/authenticate"
import { getStaticText } from './home/shared-store/static-text-modal';

import SharedStaticText from "./home/shared-store/static-text-store"

function App() {
  const [isLoading, handleLoading] = useState(true)

  getStaticTextApi((err, data) => {
    handleLoading(false)
  })

  if (isLoading) return ""
  return (
    <div id="app-main-class" className="app-main-class">
      <Routes />
    </div>
  );
}

function getStaticTextApi(callback) {

  AuthenticateAPI.getStaticText({ type: "STATIC_TEXT" }).then((data) => {
    let details = data.response[0].screen
    let contructedData = getStaticText(details);
    SharedStaticText.setStaticText(contructedData);
    callback(null, true)
  }).catch((err) => {
    callback(null, true)

  })
}

export default App;
