import HttpClient from './http-client';
import URL from './url';

export default class productApi {

    static getInvoices(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.INVOICE + "?" + params.join('&') : URL.INVOICE;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static getPaymentStatus(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.PAYMENT_STATUS + "?" + params.join('&') : URL.PAYMENT_STATUS;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getPaymentResponse(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }

        let paramsList = params.length ? URL.PAYMENT_RESPONSE + "?" + params.join('&') : URL.PAYMENT_RESPONSE;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);

    }

    static updateInvoice(payload) {
        return HttpClient.put(URL.BASE_URL + URL.INVOICE, null, payload);
    }

    static getPaymentTermsDate(payload) {
        return HttpClient.post(URL.BASE_URL + URL.PAYMENT_TERMS, null, payload);
    }

    static sendInvoice(payload) {
        return HttpClient.put(URL.BASE_URL + URL.SEND_INVOICE, null, payload);
    }

    static markAsPaidInvoice(payload) {
        return HttpClient.put(URL.BASE_URL + URL.MARK_AS_PAID_INVOICE, null, payload);
    }

    static updatePaymentResponse(payload) {
        return HttpClient.post(URL.BASE_URL + URL.UPDATE_PAYMENT_RESPONSE, null, payload);
    }

    static createManualInvoice(payload) {
        return HttpClient.post(URL.BASE_URL + URL.MANUAL_INVOICE, null, payload);

    }

    static exportInvoices(payload) {
        return HttpClient.post(URL.BASE_URL + URL.EXPORT_INVOICES, null, payload);
    }
}
