import React from "react";
import "./subscription-management.scss";

import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaymentSubscriptions from "../../apis/payment-subscriptions";
import ImageConstants from "../../constants/image-constants";
import StaticText from "../../shared-store/static-text-store";
import { getImageSource } from "../../utility/image-source";
import ToasterComponent from "../common/toaster/toaster";
import Path from "../routes/routes-path";

class SubscriptionManagement extends React.Component {
   state = {
      staticText: {},
      loading: true,
      existingSubscription: {},
   };

   componentDidMount() {
      let staticText = StaticText.getStaticText("paymentResponse");
      this.setState({ staticText });
      this.getExistingSubscription();
   }

   getExistingSubscription() {
      this.setState({ loading: true });
      PaymentSubscriptions.getTenantSubscription({})
         .then((response) => {
            this.setState({ loading: false, existingSubscription: response });
         })
         .catch((err) => {
            this.setState({ loading: false });
         });
   }

   showToaster(type, message) {
      this.setState(
         {
            showToaster: true,
            toaster: {
               type,
               message,
            },
         },
         () => {
            setTimeout(() => this.hideToaster(), 3000);
         }
      );
   }

   hideToaster() {
      this.setState({ showToaster: false });
   }

   getStripeAccountAction() {
      if (this.state?.loading) {
         return (
            <div className="d-inline-block ">
               <span className="position-relative dot-wrapper ">
                  <span className="active-text pl-2">
                     <FontAwesomeIcon icon={faSyncAlt} spin />{" "}
                     {this.state.staticText?.loadingText || " Fetching subscription info"}
                  </span>
               </span>
            </div>
         );
      }

      if (this.state.existingSubscription) {
         return (
            <div className="d-inline-block cursor-pointer">
               <span
                  className="continue-button"
                  disabled={this.state.loading}
                  onClick={this.onClickManageSubscription}
               >
                  <span className="px-2">Manage Subscription</span>
                  <img
                     alt=""
                     className="arrow-right"
                     src={getImageSource(ImageConstants.RIGHT_ARROW_BLUE)}
                  />
               </span>
            </div>
         );
      }

      return (
         <button
            className="app-button-primary mr-2 cursor-pointer"
            disabled={this.state.loading}
            onClick={() => this.onClickSubscribe()}
         >
            <span className="pl-2">Subscribe</span>
         </button>
      );
   }

   onClickSubscribe = () => {
      this.setState({ loading: true });
      const payload = {
         successUrl: window.location.origin + Path.SUBSCRIPTION.setupResponse,
         cancelUrl: window.location.origin + Path.SUBSCRIPTION.setupResponse,
      };
      PaymentSubscriptions.createSessionForPaymentSetup(payload)
         .then(({ data }) => {
            window.location.assign(data.url);
         })
         .catch((err) => {
            this.setState({ loading: false });
            this.showToaster(
               "error",
               err.response?.data?.message || "Something went wrong"
            );
            console.error(err);
         });
   };

   onClickManageSubscription = () => {
      this.setState({ loading: true })
      const payload = {
         returnUrl: window.location.origin + Path.PROFILE,
      };
      PaymentSubscriptions.getStripeCustomerPortalSession(payload)
         .then(({ data }) => {
            this.setState({ loading: false });
            window.location.assign(data.url);
         })
         .catch((err) => {
            this.setState({ loading: false });
         });
   }

   onClickMembershipAndBilling = () => {
      console.log(this.props);
      this.props.history.push(Path.SUBSCRIPTION.membershipAndBilling)
   }

   render() {
      return (
         <div className="payment-card">
            <div className="row">
               <div className="col-1 pr-0 fit-content">
                  <div className="d-inline-block pr-2">
                     <div className="image-wrapper">
                        <img
                           className="icon-30"
                           alt=""
                           src={getImageSource(ImageConstants.BANK)}
                        />
                     </div>
                  </div>
               </div>
               <div className="col-7 my-auto">
                  <div className="d-inline-block">
                     <div className="payment-title">
                        Subscribe to activate pro features
                     </div>
                     {/* <div className='bank-account-number-container text-right'>
                        <span className='bank-account-number-text bank-account-number-text-star'>
                           ****
                        </span>
                        <span className='bank-account-number-text'>
                           1234
                        </span>
                     </div> */}
                  </div>
               </div>
               <div className="col-4 text-right my-auto">
                  {this.getStripeAccountAction()}
                  {/* <button className="app-button-primary mr-2 cursor-pointer" disabled={this.state.loading} onClick={() => this.onClickSubscribe()}>
                     <span className="pl-2">Subscribe</span>
                  </button> */}
                  {/* <div className="d-inline-block cursor-pointer">
                     <span className="continue-button" disabled={this.state.loading} onClick={this.onClickSubscribe}>
                        <span className="px-2">{this.state.staticText?.completeStripeOnboardingText || 'Subscribe'}</span>
                        <img alt="" className="arrow-right" src={getImageSource(ImageConstants.RIGHT_ARROW_BLUE)} />
                     </span>
                  </div> */}

                  {/* <div>
                     {this.state.showLinkAccount ? this.renderLinkAccount() : ""}
                  </div> */}
               </div>
            </div>
            {this.state.showToaster ? (
               <ToasterComponent
                  type={this.state.toaster?.type}
                  message={this.state.toaster?.message}
               />
            ) : (
               ""
            )}
         </div>
      );
   }
}

export default SubscriptionManagement;
