import React from 'react';
import AuthenticateAPI from '../../home/apis/authenticate';
import BranchApi from '../../home/apis/branch';
import SessionCache from '../../home/components/routes/session-cache';
import "./profile.scss";

import Loading from '../../home/components/common/loading/loading';
import CreateBranchModal from '../../home/components/create-branch/create-branch';
import Path from '../../home/components/routes/routes-path';
import ActionConstant from '../../home/constants/action-constant';
import Constants from '../../home/constants/constants';
import ImageConstants from '../../home/constants/image-constants';
import SharedStore from '../../home/shared-store/shared-store';
import StaticText from '../../home/shared-store/static-text-store';
import CommonUtils from '../../home/utility/common-utilities';
import { getImageSource } from '../../home/utility/image-source';
import ChangePassword from '../change-password/change-password';
import PaymentSetting from './payment-setting';
import Email from '../../home/components/email-notification-setting/email-notification-setting';

class Profile extends React.Component {
    state = {
        staticText: {},
        branches: []
    }

    user = {}
    accessRights = {}

    componentDidMount() {

        let staticText = StaticText.getStaticText("profile")
        this.user = SessionCache.getUser()

        let activeTab = ""
        if (this.user?.type == Constants.USER_TYPE.BOTH) {
            if (this.user.currentUseType) {
                activeTab = this.user?.currentUseType
            } else {
                activeTab = Constants.USER_TYPE.VENDOR
            }
        } else {
            activeTab = this.user?.type
        }

        this.setState({ staticText, activeTab })
        this.getBranch()
    }

    getBranch(payload = {}) {
        BranchApi.getBranches(payload).then((response) => {
            this.setState({ branches: response?.response || [] })
        }).catch((err) => {
        })
    }

    renderNameBadge(name, customeClass) {
        if (!name) return ""
        let splitName = name.split(" ")
        let tag = ""
        if (splitName.length > 1) {
            tag = `${splitName[0][0] || ""}${splitName[1][0] || ""}`
        } else if (splitName) {
            tag = `${splitName[0][0]}`
        }
        tag = tag ? tag.toUpperCase() : ""
        return (<div className={`d-inline-block name-badge ${customeClass || ""}`}><span className="">{tag || ""}</span></div>)

    }

    onPressLogout = () => {

        AuthenticateAPI.logout({}).then((response) => {
            SessionCache.setUser({})
            if (response?.data?.isAdmin) {
                window.open(window.location.origin + "/admin/dashboard", "_self")
            } else {
                this.props.history.replace(Path.LOGIN)
            }
        }).catch((err) => {
        })

    }

    onClickChangePassword = () => {

        if (CommonUtils.checkTrialAccess(ActionConstant.CHANGE_PASSWORD)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ showChangePassword: true })
        }
    }

    hideChangePassword = () => {
        this.setState({ showChangePassword: false })
    }

    onClickEmailNotification = () => {
        this.props.history.push(Path.EMAIL_NOTIFICATION)
    }

    onClickCreateBranch = () => {

        if (CommonUtils.checkTrialAccess(ActionConstant.ADD_BRANCH)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ showCreateBranch: true, selectedBranch: null })
        }
    }

    hideCreateBranch = () => {
        this.setState({ showCreateBranch: false })
    }

    onBranchCreateSuccess = () => {
        this.getBranch()
        this.hideCreateBranch()
    }

    onClickBranchEdit = (item) => {
        if (CommonUtils.checkTrialAccess(ActionConstant.UPDATE_BRANCH)) {
            SharedStore.getStore("subscription").showSubscriptionModal()
        } else {
            this.setState({ selectedBranch: item })
            this.setState({ showCreateBranch: true })
        }
    }

    onClickMembershipAndBilling = () => {
        console.log(this.props);
        this.props.history.push(Path.SUBSCRIPTION.membershipAndBilling)
    }

    render() {
        return (
            <div className="profile">
                <div className="container">
                    {this.state.isLoading && <Loading />}
                    <div className='row'>
                        <div className="col">
                            <strong className="header-text">{this.state.staticText?.title}</strong>
                        </div>
                    </div>

                    <div className="card mt-4">
                        <div className="d-flex">
                            <div className="flex-1 text-center  py-4 border-right-gray">
                                <div className="d-inline-block">{this.renderNameBadge(this.user?.firstName, "profile-icon")}</div>
                                <div className="bold-text font-16 pt-2"> {this.user?.firstName}</div>
                                <div className="bold-text font-14 logout-text pt-2 cursor-pointer"> <span onClick={this.onPressLogout}>{this.state.staticText?.logoutTitle}</span></div>
                            </div>

                            <div className="py-4 px-5">
                                <div className="">
                                    <div className="lable-text font-14"> {this.state.staticText?.businessNameLableText}</div>
                                    <div className="font-20 bold-text pt-2">{this.user?.businessName ? this.user.businessName : this.user.firstName}</div>
                                </div>

                                <div className="pt-4">
                                    <div className="lable-text font-14"> {this.state.staticText?.emailLableText}</div>
                                    <div className="font-20 bold-text pt-2"> {this.user?.email}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        CommonUtils.checkRights(ActionConstant.CHANGE_PASSWORD) ?
                            <div className="mt-4 card cursor-pointer" onClick={this.onClickChangePassword}>
                                <div className="p-4 d-flex align-items-center">
                                    <div className="cp-flex-1 text-center">
                                        <img className="icon-20" alt="" src={getImageSource(ImageConstants.LOCK)} />
                                    </div>
                                    <div className="cp-flex-2">
                                        <span className="pl-4 semi-bold-text">{this.state.staticText?.changePasswordText} </span>
                                    </div>
                                    <div className="cp-flex-3 px-4">
                                        <div className="float-right"><img className="icon-10" alt="" src={getImageSource(ImageConstants.RIGHT_ARROW_BLUE)} /></div>
                                    </div>
                                </div>
                            </div> : ""
                    }

                    {
                        CommonUtils.checkRights(ActionConstant.SETUP_PAYMENT) && this.state.activeTab !== "CUSTOMER" ?
                            <PaymentSetting />
                            : ""
                    }
                    {CommonUtils.checkRights(ActionConstant.SETUP_PAYMENT) ?
                        <div className="mt-4 card cursor-pointer" onClick={this.onClickMembershipAndBilling}>
                            <div className="p-4 d-flex align-items-center">
                                <div className="cp-flex-1 text-center">
                                    <img className="icon-20" alt="" src={getImageSource(ImageConstants.MEMBERSHIP_CARD)} />
                                </div>
                                <div className="cp-flex-2">
                                    <span className="pl-4 semi-bold-text">{this.state.staticText?.membershipAndBilling} </span>
                                </div>
                                <div className="cp-flex-3 px-4">
                                    <div className="float-right"><img className="icon-10" alt="" src={getImageSource(ImageConstants.RIGHT_ARROW_BLUE)} /></div>
                                </div>
                            </div>
                        </div>
                        : ""}
                    {
                        <div className="mt-4 card cursor-pointer" onClick={this.onClickEmailNotification}>
                            <div className="p-4 d-flex align-items-center">
                                <div className="cp-flex-1 text-center">
                                    <img className="icon-20" alt="" src={getImageSource(ImageConstants.EMAIL_BOX)} />
                                </div>
                                <div className="cp-flex-2">
                                    <span className="pl-4 semi-bold-text">Notification Settings</span>
                                </div>
                                <div className="cp-flex-3 px-4">
                                    <div className="float-right"><img className="icon-10" alt="" src={getImageSource(ImageConstants.RIGHT_ARROW_BLUE)} /></div>
                                </div>
                            </div>
                        </div>
                    }

                    {

                        CommonUtils.checkRights(ActionConstant.BRANCH_LIST) && this.state.branches?.length ?
                            <div className="mt-4">
                                <div className='row'>
                                    <div className='col'>
                                        <div className="bold-text d-inline-block font-16">{this.state.staticText?.branchTitle}</div>
                                    </div>
                                    <div className='col'>
                                        {
                                            CommonUtils.checkRights(ActionConstant.ADD_BRANCH) ?
                                                <div className='app-button-primary ml-3 d-inline-block float-right' onClick={() => { this.onClickCreateBranch() }}>{this.state.staticText?.createBranch}</div>
                                                : ""
                                        }
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <div className="row">
                                        {
                                            this.state.branches.map(item => (
                                                <div className="col-6 mt-2 no-gutters" key={item?.cubeId}>
                                                    <div className="card branch-card p-4">
                                                        <div className="d-flex">
                                                            <div className="branch-flex-1">
                                                                {this.renderNameBadge(item.businessName)}
                                                            </div>
                                                            <div className="branch-flex-2">
                                                                <div className="bold-text font-16">{item.businessName}</div>
                                                                <div className="lable-text font-14">{item?.address?.state}</div>

                                                                <div className="pt-2 font-14">{this.state.staticText?.zipCodeText} {item?.address?.code}</div>
                                                            </div>
                                                            {
                                                                CommonUtils.checkRights(ActionConstant.UPDATE_BRANCH) ?
                                                                    <div className="branch-flex-3 text-right">
                                                                        <img alt="" className="icon-20 cursor-pointer" src={getImageSource(ImageConstants.EDIT_ICON_IN_ACTIVE)} onClick={() => this.onClickBranchEdit(item)} />
                                                                    </div> : ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>

                                </div>
                            </div>
                            :
                            ""
                    }
                </div>
                {
                    this.state.showChangePassword ? <ChangePassword close={this.hideChangePassword} /> : ""
                }
                {
                    this.state.showEmailNotification ? <Email close={this.hideEmailNotification} /> : ""
                }
                {this.state.showCreateBranch ? <CreateBranchModal onPressCreate={() => this.onBranchCreateSuccess()} onCancel={() => this.hideCreateBranch()} branch={this.state.selectedBranch} /> : <></>}
            </div >
        );
    }

}

export default Profile;