import HttpClient from './http-client';
import URL from './url';

export default class EmailNotification {

    static getEmailNotification(payload) {
        let params = [];
        if (payload) {
            Object.keys(payload).forEach(key => {
                params.push(`${key}=${payload[key]}`);
            });
        }
        let paramsList = params.length ? URL.EMAIL_NOTIFICATION + "?" + params.join('&') : URL.EMAIL_NOTIFICATION;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static updateEmailNotfication(payload) {
        return HttpClient.put(URL.BASE_URL + URL.UPDATE_EMAIL_NOTIFICATION, null, payload);
    }
}