import React from "react";
import Loading from "../common/loading/loading";

class SubscriptionMobileAppRedirect extends React.Component {
   state = {
      isLoading: true,
   }

   componentDidMount() {
      if (window?.ReactNativeWebView) {
         window.ReactNativeWebView.postMessage("hideWebView")
         return
      }
   }

   render() {
      return <Loading />
   }
}

export default SubscriptionMobileAppRedirect