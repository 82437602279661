import React from 'react';
import AuthenticateAPI from '../../apis/authenticate';
import { privacyPolicy } from './const';
import './style.scss'

class PrivacyPolicy extends React.Component {
   state = {
      loading: true
   }

   componentDidMount() {
      const appMainClass = document.getElementById('app-main-class')
      appMainClass.className = ""
      this.getPrivacyPolicy()
   }

   componentWillUnmount() {
      const appMainClass = document.getElementById('app-main-class')
      appMainClass.className = "app-main-class"
   }

   setPrivacyPolicy(content) {
      document.getElementById('privacy-policy-content').innerHTML = content
      this.setState({ loading: false })
   }

   getPrivacyPolicy() {
      AuthenticateAPI.getStaticText({ type: "PRIVACY_POLICY" }).then((data) => {
         const response = data.response || []
         this.setPrivacyPolicy(response[0]?.htmlContent || privacyPolicy)
      }).catch((err) => {
      })
   }

   render() {
      return (
         <div >
            <div className='row' style={{ margin: 10 }}>
               <div className='col-md-1 col-sm-0'></div>
               <div className='col-md-10 col-sm-12 mt-4'>
                  {this.state.loading && <p className='text-center'>Loading...</p>}
                  <div id="privacy-policy-content"></div>
               </div>
            </div>
         </div>
      )
   }
}

export default PrivacyPolicy
