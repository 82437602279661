const ROOT = '';
const Path = {
    BASE: "",
    LOGIN: "/login",
    SIGN_UP: ROOT + '/signup',
    DASHBOARD: ROOT + '/dashboard',
    BRANCH: ROOT + '/branch',
    STAFF: ROOT + '/staff',
    PRODUCT: ROOT + '/my-products',
    MY_SHOP: ROOT + '/my-shop',
    CUSTOMER: ROOT + '/customer',
    STAFF_LOGIN: ROOT + '/staff-login',
    CART: ROOT + '/cart',
    ORDER: ROOT + '/orders',
    FORGOT_PASSWORD: ROOT + '/forgot-password',
    TENANT_WORKFLOW: ROOT + '/tenant-workflow',
    CALENDAR: ROOT + '/calendar',
    MY_ORDERS: ROOT + '/my-orders',
    PROFILE: ROOT + '/profile',
    VENDORS: ROOT + '/vendors',
    PRIVACY_POLICY: ROOT + '/privacy-policy',
    SERVICES_AGREEMENT: ROOT + '/services-agreement',
    INVOICE: ROOT + "/invoice",
    PAYMENT_RESPONSE: ROOT + '/payment/response',
    EMAIL_NOTIFICATION: ROOT + '/email/notification',
    INVENTORY: ROOT + '/inventory',                     //INV000001

    PAYMENT: {
        baseUrl: ROOT + '/payment',
        pay: ROOT + '/payment/pay',
        sellerSetupRefresh: ROOT + '/payment/seller/setup-refresh',
        sellerSetupSuccess: ROOT + '/payment/seller/setup-success',
    },

    SUBSCRIPTION: {
        baseUrl: ROOT + '/subscription',
        subscribe: ROOT + '/subscription/subscribe',
        setupResponse: ROOT + '/subscription/setup-response',
        mobileAppRedirect: ROOT + '/subscription/mobile-app-redirect',
        membershipAndBilling: ROOT + '/subscription/membership-and-billing',
        billSummary: ROOT + '/subscription/bill-summary',
    }
};

export default Path;