
export let getStaticText = (input) => {

    let staticText = {};

    if (input && input.login) {
        staticText.login = {
            loginTitle: input.login.loginTitle,
            loginHeaderTitle: input.login.loginHeaderTitle,
            forgotPasswordTitle: input.login.forgotPasswordTitle,
            leftCartTitle: input.login.leftCartTitle,
            leftCartSlogan: input.login.leftCartSlogan,
            googleLoginText: input.login.googleLoginText,
            fbLoginText: input.login.fbLoginText
        }
    } else { //DEFAULT
        staticText.login = {
            loginTitle: "Sign In",
            loginHeaderTitle: "Sign in to the MyBiZSquare",
            forgotPasswordTitle: "Forgot Password ?",
            leftCartTitle: "MyBiZSquare",   /* pramod CHG000001 */
            leftCartSlogan: "House of new age MarketPlace",
            googleLoginText: "GOOGLE",
            fbLoginText: "FACEBOOK"
        }
    }

    if (input && input.staffLogin) {
        staticText.staffLogin = {
            loginTitle: input.staffLogin.loginTitle,
            loginHeaderTitle: input.staffLogin.loginHeaderTitle,
            userNamePlaceHolder: input.staffLogin.userNamePlaceHolder,
            passwordPlaceHolder: input.staffLogin.passwordPlaceHolder,
            cubeIdPlaceHolder: input.staffLogin.cubeIdPlaceHolder,
            nextText: input.staffLogin.nextText,
            continueText: input.staffLogin.continueText,
            invalidCubeId: input.staffLogin.invalidCubeId


        }
    } else {
        staticText.staffLogin = {
            loginTitle: "Sign In",
            loginHeaderTitle: "Sign in to the MyBiZSquare",
            userNamePlaceHolder: "Enter user name",
            passwordPlaceHolder: "Enter password",
            cubeIdPlaceHolder: "Enter MyBiZSquare ID",
            nextText: "Next",
            continueText: "Continue",
            invalidCubeId: "Invalid MyBiZSquare ID"
        }
    }

    if (input && input.signup) {
        staticText.signup = {
            signupTitle: input.signup.signupTitle,
            signupHeaderTitle: input.signup.signupHeaderTitle,
            leftCartSlogan: input.signup.leftCartSlogan,
            existingUserText: input.signup.existingUserText,
            signinHereText: input.signup.signinHereText,
            invalidBusinessName: input.signup.invalidBusinessName,
            invalidEmail: input.signup.invalidEmail,
            invalidFirstName: input.signup.invalidFirstName,
            invalidLastName: input.signup.invalidLastName,
            invalidPassword: input.signup.invalidPassword,
            invalidPasswordLength: input.signup.invalidPasswordLength,
            sentOtpMessage: input.signup.sentOtpMessage,
            invalidOtp: input.signup.invalidOtp,
            otpButtonText: input.signup.otpButtonText,
            otpTitle: input.signup.otpTitle,
            cancelButtonText: input.signup.cancelButtonText,
            resendButtonText: input.signup.resendButtonText,
            continueButtonText: input.signup.continueButtonText,
            congratulationsText: input.signup.congratulationsText,
            successText: input.signup.successText,
        }
    } else {
        staticText.signup = {
            signupTitle: "Sign Up now",
            signupHeaderTitle: "MyBiZSquare",  /* pramod CHG000001 */
            leftCartSlogan: "House of new age Market Place",
            existingUserText: "Are you an Existing User?",
            signinHereText: "Sign in here",
            invalidBusinessName: "* Please enter details (Mandatory)",
            invalidEmail: "* Please enter details (Mandatory)",
            invalidFirstName: "* Please enter details (Mandatory)",
            invalidLastName: "* Please enter details (Mandatory)",
            invalidPassword: "* Please enter details (Mandatory)",
            invalidPasswordLength: "Password must be at least 8 characters, and should include letters, numbers and special characters",
            sentOtpMessage: "OTP has been sent to your email.",
            invalidOtp: "You’ve entered wrong OTP, Please enter correct",
            otpButtonText: "Validate OTP",
            otpTitle: "Enter your 6 Digit OTP",
            cancelButtonText: "Cancel",
            resendButtonText: "Resend OTP",
            continueButtonText: "Continue",
            congratulationsText: "Congratulations",
            successText: "Your Account has been created successfully."
        }
    }

    if (input && input.forgotPassword) {
        staticText.forgotPassword = {
            forgotPasswordTitle: input.forgotPassword.forgotPasswordTitle,
            emailTitle: input.forgotPassword.emailTitle,
            sendOtpButtonText: input.forgotPassword.sendOtpButtonText,
            validateOtpTitle: input.forgotPassword.validateOtpTitle,
            invalidOtp: input.forgotPassword.invalidOtp,
            otpInputTitle: input.forgotPassword.otpInputTitle,
            otpButtonText: input.forgotPassword.otpButtonText,
            cancelButtonText: input.forgotPassword.cancelButtonText,
            resendButtonText: input.forgotPassword.resendButtonText,
            continueButtonText: input.forgotPassword.continueButtonText,
            congratulationsText: input.forgotPassword.congratulationsText,
            successText: input.forgotPassword.successText,
            changePasswordText: input.forgotPassword.changePasswordText,
            invalidPasswordLength: input.forgotPassword.invalidPasswordLength,
        }
    } else {
        staticText.forgotPassword = {
            forgotPasswordTitle: "FORGOT PASSWORD",
            emailTitle: "Please Enter your registered email",
            sendOtpButtonText: "Send OTP",
            validateOtpTitle: "OTP has been sent to your email.",
            invalidOtp: "You’ve entered wrong OTP, Please enter correct",
            otpInputTitle: "Enter your 6 Digit OTP",
            otpButtonText: "Validate OTP",
            cancelButtonText: "Cancel",
            resendButtonText: "Resend OTP",
            continueButtonText: "Continue",
            congratulationsText: "Congratulations",
            successText: "Your password reset successfully.",
            changePasswordText: "Change Password",
            invalidPasswordLength: "Password must be at least 8 characters, and should include letters, numbers and special characters",
        }
    }

    if (input && input.myShop) {
        staticText.myShop = {
            title: input.myShop.title,
            addToCartButtonText: input.myShop.addToCartButtonText,
            addQuantityButtonText: input.myShop.addQuantityButtonText,
            filterText: input.myShop.filterText,
            characterForSearch: input.myShop.characterForSearch,
            categoryTitle: input.myShop.categoryTitle,
            vendor: input.myShop.vendor,
            brandTitle: input.myShop.brandTitle,
            filterText: input.myShop.filterText,
        }
    } else {
        staticText.myShop = {
            title: "My Marketplace",
            addToCartButtonText: "+ Add To Cart",
            addQuantityButtonText: "+Add",
            characterForSearch: 3,
            categoryTitle: "Category",
            vendor: "Vendor",
            brandTitle: "Brand",
            filterText: "FILTERS",
            viewVendorPriceDiscount: "View Vendor Price Discounts",
            viewVendorPriceDiscountTitle: "Price Discounts",
            viewVendorPriceDiscountTitleSub: "(Already applied to the prices below)",
            thVendor: "Vendor",
            thDiscount: "Discount"
        }
    }

    if (input && input.myCart) {
        staticText.myCart = {
            title: input.myCart.title,
            itemAdded: input.myCart.itemAdded,
            quantityText: input.myCart.quantityText,
            goToCartText: input.myCart.goToCartText,
        }
    } else {
        staticText.myCart = {
            title: "My Cart",
            itemAdded: "Items Added",
            quantityText: 'Qty:',
            goToCartText: "Go to Cart",

        }
    }

    if (input && input.orders) {
        staticText.orders = {
            title: input.orders.title,
            filterByText: input.orders.filterByText,
            loadMoreText: input.orders.loadMoreText,
            applyText: input.orders.applyText,
            calanderHeaderText: input.orders.calanderHeaderText,
        }
    } else {
        staticText.orders = {
            title: "Orders",
            filterByText: "Filter By",
            loadMoreText: "+ LOAD MORE",
            applyText: "Apply Filter",
            calanderHeaderText: "Order Calendar"

        }
    }

    if (input && input.orderDetail) {
        staticText.orderDetail = {
            deliveryDateText: input.orderDetail.deliveryDateText,
            cencelText: input.orderDetail.cencelText,
            cencelOrderText: input.orderDetail.cencelOrderText,
            noText: input.orderDetail.noText,
            confirmText: input.orderDetail.confirmText,
            rejectText: input.orderDetail.rejectText,
            acceptText: input.orderDetail.acceptText,
            closeText: input.orderDetail.closeText,
            closeAlertButtonText: input.orderDetail.closeAlertButtonText,
            completeText: input.orderDetail.completeText,
            updatePartialText: input.orderDetail.updatePartialText,
            priceSummary: input.orderDetail.priceSummary,
            priceText: input.orderDetail.priceText,
            grandTotalText: input.orderDetail.grandTotalText,
            orderDetailText: input.orderDetail.orderDetailText,
            mobileText: input.orderDetail.mobileText,
            typeText: input.orderDetail.typeText,
            importButtonTitle: input.orderDetail.importButtonTitle,
            rejectTitle: input.orderDetail.rejectTitle,
            cancelTitle: input.orderDetail.cancelTitle,
            closeAlertText: input.orderDetail.closeAlertText,
            yesText: input.orderDetail.yesText,
            deliveredOnText: input.orderDetail.deliveryDateText,
            addressText: input.orderDetail.addressText,
            updateOrderText: input.orderDetail.updateOrderText,
            customerAddressText: input.orderDetail.customerAddressText
        }
    } else {
        staticText.orderDetail = {
            deliveryDateText: "Delivery Date",
            cencelText: "Cancel",
            cencelOrderText: "Cancel This Order",
            noText: "No",
            confirmText: "Confirm",
            rejectText: "Reject Order",
            acceptText: "Accept Order",
            closeText: "Mark The Order As Closed",
            closeAlertButtonText: "Close the Order",
            completeText: "Mark As Completely Delivered",
            updatePartialText: "Mark As Partially Delivered",
            updateOrderText: "Update Order",
            priceSummary: "Price Summary",
            priceText: "Price",
            grandTotalText: "Grand Total",
            orderDetailText: "OTHER DETAILS",
            mobileText: "Mobile",
            typeText: "Type",
            rejectTitle: "Are you sure do you want to Reject the order?",
            cancelTitle: "Are you sure do you want to Cancel the order?",
            closeAlertText: "Are you sure do you want to Close the order?",
            yesText: "Yes",
            deliveredOnText: "Delivered on:",
            addressText: "Address",
            customerAddressText: "Customer Address"

        }
    }

    if (input && input.header) {
        staticText.header = {
            noSearchRecords: input.header.noSearchRecords,
            searchPlaceHolder: input.header.searchPlaceHolder,
            noInvitationSentText: input.header.noInvitationSentText,
            noInvitationReceivedText: input.header.noInvitationReceivedText,
            addButtonText: input.header.addButtonText,
            swichBranchText: input.header.swichBranchText,
            logoutText: input.header.logoutText,
            addBranchText: input.header.addBranchText,
            changePasswordText: input.header.changePasswordText,
            sendInviteButtonText: input.header.sendInviteButtonText,
            invitedText: input.header.invitedText,
            invitedReceivedText: input.header.invitedReceivedText,
            acceptedText: input.header.acceptedText,
            profileText: input.header.profileText,
            notificationTitle: input.header.notificationTitle,
            noNotificationText: input.header.noNotificationText,
            cubeIdText: input.header.cubeIdText,
            backText: input.header.backText,
            sendRegisterRequestText: input.header.sendRegisterRequestText,
            registerRequestText: input.header.registerRequestText,
            searchPlaceHolderBusiness: input.header.searchPlaceHolderBusiness
        }
    } else {
        staticText.header = {
            noSearchRecords: "No search results",
            searchPlaceHolder: "Search with MyBiZSquare ID / Email...",
            noInvitationSentText: "Currently there is no invite has sent",
            noInvitationReceivedText: "There are no pending connection requests",
            addButtonText: "+ Add ",
            swichBranchText: "Switch Business Unit",
            logoutText: "Logout",
            addBranchText: "Add Business Unit",
            changePasswordText: "Change Password",
            sendInviteButtonText: "Send Invite",
            invitedText: "Invited",
            invitedReceivedText: "Invite Received",
            acceptedText: "Connected",
            cubeIdText: "MyBiZSquare ID : ",
            profileText: "Profile & Settings",
            notificationTitle: "Notifications",
            noNotificationText: "Currently there is no notifications",
            backText: "Back",
            sendRegisterRequestText: "Send Register Request",
            registerRequestText: "Enter the email ID below to invite your buyer/vendor to join MyBiZSquare!",
            searchPlaceHolderBusiness: "Search with Business Name"

        }
    }

    if (input && input.changePassword) {
        staticText.changePassword = {
            title: input.changePassword.title,
            oldPasswordLableText: input.changePassword.oldPasswordLableText,
            oldPasswordPlaceHolder: input.changePassword.oldPasswordPlaceHolder,
            changePasswordButtonTitle: input.changePassword.changePasswordButtonTitle,
            newPasswordLableText: input.changePassword.newPasswordLableText,
            newPasswordPlaceHolder: input.changePassword.newPasswordPlaceHolder,
            reEnterPasswordLableText: input.changePassword.reEnterPasswordLableText,
            reEnterPasswordPlaceHolder: input.changePassword.reEnterPasswordPlaceHolder,
            inValidOldPasswordErrorMessage: input.changePassword.inValidOldPasswordErrorMessage,
            oldPasswordErrorMessage: input.changePassword.oldPasswordErrorMessage,
            newPasswordErrorMessage: input.changePassword.newPasswordErrorMessage,
            reEnterPasswordErrorMessage: input.changePassword.reEnterPasswordErrorMessage,
            misMatchPasswordErrorMessage: input.changePassword.misMatchPasswordErrorMessage,
            cancelButtonText: input.changePassword.cancelButtonText,
            invalidPasswordLength: input.changePassword.invalidPasswordLength,
        }
    } else {
        staticText.changePassword = {
            title: "Change Password",
            oldPasswordLableText: "Old Password",
            oldPasswordPlaceHolder: "Enter your old Password",
            changePasswordButtonTitle: "Change Password",
            newPasswordLableText: "New Password",
            newPasswordPlaceHolder: "Enter your New Password",
            reEnterPasswordLableText: "Re Enter Password",
            reEnterPasswordPlaceHolder: "Enter your password again",
            inValidOldPasswordErrorMessage: "Invalid Old Password",
            oldPasswordErrorMessage: "* Please enter details (Mandatory)",
            newPasswordErrorMessage: "* Please enter details (Mandatory)",
            reEnterPasswordErrorMessage: "* Please enter details (Mandatory)",
            misMatchPasswordErrorMessage: "Password mismatch",
            cancelButtonText: "Cancel",
            invalidPasswordLength: "Password must be at least 8 characters, and should include letters, numbers and special characters",
        }
    }

    if (input && input.priceSetup) {
        staticText.priceSetup = {
            title: input.priceSetup.title,
            actualPrice: input.priceSetup.actualPrice,
            searchPlaceHolder: input.priceSetup.searchPlaceHolder,
            customerSearchPlaceHolder: input.priceSetup.customerSearchPlaceHolder,
            customerTitle: input.priceSetup.customerTitle,
            priceText: input.priceSetup.priceText,
            addButton: input.priceSetup.addButton,
            updateButton: input.priceSetup.updateButton,
            loadMore: input.priceSetup.loadMore,
            email: input.priceSetup.email,
            productTitle: input.priceSetup.productTitle,
        }
    } else {
        staticText.priceSetup = {
            title: "Advanced Price Setup",
            actualPrice: "Actual Price:",
            searchPlaceHolder: "Look for the products...",
            customerTitle: "CUSTOMERS",
            customerSearchPlaceHolder: "Look for the customer...",
            priceText: "PRICE:",
            addButton: "Add",
            updateButton: "Update",
            loadMore: "+LOADMORE",
            email: "Email",
            productTitle: "PRODUCTS"

        }
    }

    if (input && input.productList) {
        staticText.productList = { ...input.productList }
    } else {
        staticText.productList = {
            title: "My Products",
            buttonTitle: "+Add",
            loadMoreText: "+Load More",
            serialNo: "S.No",
            productName: "Product Name",
            category: "Category",
            description: "Description",
            quantity: "Qty",
            price: "Price",
            priceSetup: "Advanced Price Setup",
            filterText: "FILTERS",
            categoryTitle: "Category",
            searchProducts: "Search Products...",
            status: "Status",
            brandTitle: "Brand",
            noProductsText: "You don’t have any Products under your Business Unit, You can add using the below button.",
            importButtonTitle: "Import from a spreadsheet",
            tab1: "Active",
            tab2: "Inactive",
            importExportButtonTitle: "Import / Export / Mass Update"
        }
    }

    if (input && input.profile) {
        staticText.profile = { ...input.profile }
    } else {
        staticText.profile = {
            title: "My Profile",
            contactNumberLableText: "Primary Contact No.",
            businessNameLableText: "Business Name",
            logoutTitle: "Logout",
            emailLableText: "Email",
            changePasswordText: "Change Current Password",
            branchTitle: "My Business Unit",
            zipCodeText: "Zip Code :",
            createBranch: "Add",
            paymentTitle: "Payment Mode",
            paymentDescription: "Link your account now",
            linkAccount: "Link Account",
            linkAccountTitle: "Do you want to use the account linked to <%branchName%>",
            addNewAccountText: "Add New Account",
            removeText: "Remove",
            removeTitle: "Are you sure want to remove account?",
            subscriptionAmount: "Subscription Amount",
            currency: "$ ",
            "membershipAndBilling": "Membership and Billing"
        }
    }

    if (input && input.productPreview) {
        staticText.productPreview = {
            descriptionText: input.productPreview.descriptionText,
            addButton: input.productPreview.addButton
        }
    } else {
        staticText.productPreview = {
            descriptionText: "DESCRIPTION",
            addButton: "+Add"
        }
    }


    if (input && input.staffs) {
        staticText.staffs = {
            title: input.staffs.title,
            addStaffText: input.staffs.addStaffText,
            addStaffModalHeaderText: input.staffs.addStaffModalHeaderText,
            editStaffModalHeaderText: input.staffs.editStaffModalHeaderText,
            changePasswordText: input.staffs.changePasswordText,
            deleteText: input.staffs.deleteText,
            editText: input.staffs.editText,
            employeeNameText: input.staffs.employeeNameText,
            employeeNamePlaceHolder: input.staffs.employeeNamePlaceHolder,
            employeeCodeText: input.staffs.employeeCodeText,
            employeeCodePlaceHolder: input.staffs.employeeCodePlaceHolder,
            phoneNumberText: input.staffs.phoneNumberText,
            mobileNumberPlaceHolder: input.staffs.mobileNumberPlaceHolder,
            emailText: input.staffs.emailText,
            emailPlaceHolder: input.staffs.emailPlaceHolder,
            emailErrorMessage: input.staffs.emailErrorMessage,
            branchText: input.staffs.branchText,
            branchPlaceHolder: input.staffs.branchPlaceHolder,
            roleText: input.staffs.roleText,
            rolePlaceHolder: input.staffs.rolePlaceHolder,
            passwordText: input.staffs.passwordText,
            passwordPlaceholder: input.staffs.passwordPlaceholder,

            updateButtonText: input.staffs.updateButtonText,
            createButtonText: input.staffs.createButtonText,
            changePasswordHeaderText: input.staffs.changePasswordHeaderText,

            reEnterPasswordText: input.staffs.reEnterPasswordText,
            reEnterPasswordPlaceholder: input.staffs.reEnterPasswordPlaceholder,
            newPasswordErrorMessage: input.staffs.newPasswordErrorMessage,
            reEnterPasswordErrorMessage: input.staffs.reEnterPasswordErrorMessage,
            misMatchPasswordErrorMessage: input.staffs.misMatchPasswordErrorMessage,
            cancelText: input.staffs.cancelText,
            deleteTitle: input.staffs.deleteTitle,

            nameErrorMessage: input.staffs.nameErrorMessage,
            usernameErrorMessage: input.staffs.usernameErrorMessage,
            mobileNumberErrorMessage: input.staffs.mobileNumberErrorMessage,
            branchErrorMessage: input.staffs.branchErrorMessage,
            passwordErrorMessage: input.staffs.passwordErrorMessage,
            roleErrorMessage: input.staffs.roleErrorMessage,
            usernameExistsErrorMessage: input.staffs.usernameExistsErrorMessage,
            invalidUserNameErrorMessage: input.staffs.invalidUserNameErrorMessage,
        }
    } else {
        staticText.staffs = {
            title: "Employees",
            addStaffText: "+ Add Employee",
            addStaffModalHeaderText: "Add Employee",
            editStaffModalHeaderText: "Edit Employee details",
            changePasswordText: "Change Password",
            deleteText: "Delete",
            editText: "Edit",
            employeeNameText: "Employee Name ",
            employeeNamePlaceHolder: 'Enter employee name',
            employeeCodeText: "Employee Code / User name",
            employeeCodePlaceHolder: 'Enter employee code / user name',
            phoneNumberText: "Phone number",
            mobileNumberPlaceHolder: "Enter Mobile number",
            emailText: "Email",
            emailPlaceHolder: "Enter email",
            emailErrorMessage: "Invalid email",
            branchText: "Select Business Unit",
            branchPlaceHolder: "Select Business Unit",
            roleText: "Role Assigned ",
            rolePlaceHolder: "Select Role",
            passwordText: "Password",
            passwordPlaceholder: 'Enter Password',
            updateButtonText: "Update",
            createButtonText: "Add Employee",
            changePasswordHeaderText: "Change Password",
            reEnterPasswordText: "Re Enter Password",
            reEnterPasswordPlaceholder: "Enter your password again",
            newPasswordErrorMessage: "* Please enter details (Mandatory)",
            reEnterPasswordErrorMessage: "* Please enter details (Mandatory)",
            misMatchPasswordErrorMessage: "Password mismatch",
            cancelText: "Cancel",
            deleteTitle: "Are you sure do you want to delete the employees?",

            nameErrorMessage: "* Please enter details (Mandatory)",
            usernameErrorMessage: "* Please enter details (Mandatory)",
            mobileNumberErrorMessage: "* Please enter details (Mandatory)",
            branchErrorMessage: "* Please enter details (Mandatory)",
            passwordErrorMessage: "* Please enter details (Mandatory)",
            roleErrorMessage: "* Please enter details (Mandatory)",
            usernameExistsErrorMessage: "User name already exists",
            invalidUserNameErrorMessage: "Invalid user name "

        }
    }
    if (input && input.dashboard) {
        staticText.dashboard = {
            title: input.dashboard.title,
            staticticsText: input.dashboard.staticticsText,
            salesText: input.dashboard.salesText,
            orderText: input.dashboard.orderText,
            avgSalesText: input.dashboard.avgSalesText,
            noSalesTitle: input.dashboard.noSalesTitle,
            noSalesDescription: input.dashboard.noSalesDescription,
            overAllSalesText: input.dashboard.overAllSalesText,
            avgPurchaseText: input.dashboard.avgPurchaseText,
            purchaseText: input.dashboard.purchaseText,

            weekText: input.dashboard.weekText,
            monthText: input.dashboard.monthText,
            yearText: input.dashboard.yearText,

            deliveredText: input.dashboard.deliveredText,
            cancelledText: input.dashboard.cancelledText,
            inProgressText: input.dashboard.inProgressText,

            totalOrderText: input.dashboard.totalOrderText,

            noVendorTitle: input.dashboard.noVendorTitle,
        }
    } else {
        staticText.dashboard = {
            title: "Dashboard",
            staticticsText: "STATISTICS",
            salesText: "Sales",
            orderText: "No: Orders",
            avgSalesText: "Average Sales",
            noSalesTitle: "You’ve no data, Start your sales",
            noSalesDescription: "Currently there is no sales, so your Average sales report is not available.",
            overAllSalesText: "Overall Sales",

            avgPurchaseText: "Average Purchase",
            purchaseText: "Purchases",

            weekText: "WEEK",
            monthText: "MONTH",
            yearText: "YEAR",

            deliveredText: "Delivered Successfully",
            cancelledText: "Cancelled Orders",
            inProgressText: "In Progress",

            totalOrderText: "Total Orders"
        }
    }

    if (input && input.invitation) {
        staticText.invitation = {
            title: input.invitation.title,
            rejectText: input.invitation.rejectText,
            acceptText: input.invitation.acceptText,
            addButtonText: input.invitation.addButtonText,
            noInvitationReceivedText: input.invitation.noInvitationReceivedText,
            requestText: input.invitation.requestText,

        }
    } else {
        staticText.invitation = {
            title: "Connection Request",
            rejectText: "Reject",
            acceptText: "Accept",
            addButtonText: "+ Add",
            noInvitationReceivedText: "There are no pending connection requests",
            requestText: "Request"
        }
    }

    if (input && input.topCustomers) {
        staticText.topCustomers = {
            title: input.topCustomers.title,
            currentYearText: input.topCustomers.currentYearText,
            lastYearText: input.topCustomers.lastYearText,
            addButtonText: input.topCustomers.addButtonText,
            noCustomerText: input.topCustomers.noCustomerText,
            noCustomerDescription: input.topCustomers.noCustomerDescription,

        }
    } else {
        staticText.topCustomers = {
            title: "Top Buyers",
            currentYearText: "This Yr",
            lastYearText: "Last Yr",
            addButtonText: "+ Add",
            noCustomerText: "You don’t have any buyers",
            noCustomerDescription: "Currently there is no buyers, so your Buyer list is not available.",
        }
    }

    if (input && input.topSales) {
        staticText.topSales = {
            title: input.topSales.title,
            salesText: input.topSales.salesText,
            unitText: input.topSales.unitText,
            addButtonText: input.topSales.addButtonText,
            noRecordText: input.topSales.noRecordText,
            noRecordDescription: input.topSales.noRecordDescription,


        }
    } else {
        staticText.topSales = {
            title: "Top 5 Items",
            salesText: "Sales",
            unitText: "Unit",
            addButtonText: "+ Add",
            noRecordText: "You don’t have any Products",
            noRecordDescription: "Currently there is no products, so your products list is not available.",
        }
    }

    if (input && input.topVendors) {
        staticText.topVendors = {
            title: input.topVendors.title,
            currentYearText: input.topVendors.currentYearText,
            lastYearText: input.topVendors.lastYearText,
            addButtonText: input.topVendors.addButtonText,
            noCustomerText: input.topVendors.noCustomerText,
            noCustomerDescription: input.topVendors.noCustomerDescription,

        }
    } else {
        staticText.topVendors = {
            title: "Top Vendors",
            currentYearText: "This Yr",
            lastYearText: "Last Yr",
            addButtonText: "+ Add",
            noCustomerText: "You don’t have any vendors",
            noCustomerDescription: "Currently there is no vendors, so your Vendors list is not available.",
        }
    }

    if (input && input.topProducts) {
        staticText.topProducts = {
            title: input.topProducts.title,
            salesText: input.topProducts.salesText,
            unitText: input.topProducts.unitText,
            addButtonText: input.topProducts.addButtonText,
            noRecordText: input.topProducts.noRecordText,
            noRecordDescription: input.topProducts.noRecordDescription,

        }
    } else {
        staticText.topProducts = {
            title: "Top Product Purchased",
            salesText: "Sales",
            unitText: "Unit",
            addButtonText: "+ Add",
            noRecordText: "You don’t have any purchased products",
            noRecordDescription: "Currently there is no products, so your purchased products list is not available.",
        }
    }

    if (input && input.customers) {
        staticText.customers = {
            ...input.customers
        }
    } else {
        staticText.customers = {
            title: "My Buyers",
            addCustomerText: "+ Add Buyer",
            snoText: "S.No",
            staffNameText: "Name & ID",
            emailText: "Email",
            mobileText: "Mobile",
            locationText: "Location",
            actionText: "Action",
            deleteText: "Remove",
            cancelText: "Cancel",
            acceptText: "Accept",
            rejectText: "Reject",
            deleteTitle: "Are you sure do you want to remove the buyer?",
            priceSetupText: "Advanced Price Setup",
            noRecordText: "No Records found !",
            showProductsText: "Show Products",
            showProductsTooltipText: "Selecting this option will show your products and their prices in your buyer's shopping page"
        }
    }

    if (input && input.vendors) {
        staticText.vendors = {
            ...input.vendors
        }
    } else {
        staticText.vendors = {
            title: "My Vendors",
            addVendorText: "+ Add Vendor",
            snoText: "S.No",
            staffNameText: "Name & ID",
            emailText: "Email",
            mobileText: "Mobile",
            locationText: "Location",
            actionText: "Action",
            deleteText: "Remove",
            cancelText: "Cancel",
            acceptText: "Accept",
            rejectText: "Reject",
            deleteTitle: "Are you sure do you want to Remove the Vendor?",
            noRecordText: "No Records found !"
        }

    }

    if (input && input.invoice) {
        staticText.invoice = {
            ...input.invoice
        }
    } else {
        staticText.invoice = {
            title: "My Invoices",
            editInvoice: "Edit Invoice",
            invoiceDetails: "Invoice Details",
            invoiceNumber: "Invoice No: ",
            orderNumber: "Order No: ",
            invoiceTo: "Invoice To",
            invoiceFrom: "Invoice From",
            issuedDate: "Invoice Date",
            dueDate: "Due Date",
            itemDetails: "Item Details",
            salesTax: "Sales Tax",
            deliveryCharges: "Additional Charges",
            discountText: "Discount",
            invoiceAmount: "Invoice Amount",
            pendingAmount: "Remaining Amount",
            paidAmount: "Paid Amount",
            markAsPaidButtonText: "Mark as Paid",
            payButtonText: "Pay Now",
            previewButtonText: "Preview Invoice",
            updateInvoiceButtonText: "Update Invoice",
            sendInvoiceButtonText: "Send Invoice",
            editButtonText: "Edit Invoice",
            notePlaceHolder: "Add Notes",
            paymentTerms: "Payment Terms",
            totalAmount: "Total Amount",
            notes: "Notes: ",
            percentage: "(%)",
            currency: "($)",
            payButtonNotEnabled: "This feature will be enabled soon",
            sendText: "Send",
            sendInvoiceAlertTitle: "Are you sure you want to send the invoice?",
            paidText: "Mark as Paid",
            markAsPaidAlertTitle: "Do you want to mark the invoice as paid?",
            applyText: "Apply Filter",
            loadMoreText: "+ LOAD MORE",
            filterByText: "Filter By",
            paymentTitle: "You haven’t linked your payment method yet.",
            paymentDescription: "Link your account now",
            linkAccount: "Link Account",
            tab1: "Invoice Details",
            tab2: "Payment History",
            characterForSearch: 3,
            selectPaymentTerms: "Select Payment Terms",
            palletCharges: "(Pallet Charges,Delivery Charges etc)",
            createdDate: "Invoice Date :"
        }
    }

    if (input && input.paymentResponse) {
        staticText.paymentResponse = {
            ...input.paymentResponse
        }
    } else {
        staticText.paymentResponse = {
            successText: "Payment Successful",
            continueButtonText: "Continue",
            failedText: "Payment Failed"
        }
    }

    if (input && input.branchConfig) {
        staticText.branchConfig = {
            ...input.branchConfig
        }
    } else {
        staticText.branchConfig = {
            paymentTerms: "Payment Terms",
            selectPaymentTerms: "Select Payment Terms",
            deleteText: "Remove",
            priceSetupText: "Advance Price Setup",
            deleteTitle: "Are you sure do you want to Remove the Buyer?",
            cancelText: "Cancel",
            discountOrMarkup: "Discount / Markup",
            discount: "Discount",
            markup: "Markup",
        }
    }

    if (input && input.membershipAndBilling) {
        staticText.membershipAndBilling = {
            ...input.membershipAndBilling
        }
    } else {
        staticText.membershipAndBilling = {
            pageTitle: "Membership and Billing",
            currentMembershipTitle: "Current Membership Tier",
            upgradeMembershipTitle: "Upgrade",
            silverMembership: "Silver Membership",
            platinumMembership: "Platinum Membership",
            freeMembership: "Free Membership",
            premiumMembership: "Premium Membership",
            membershipBenefitsHint: "* For Membership Benefits and Pricing Info please refer to our ",
            membershipBenefitsHintLinkText: "Pricing Page",

            billSummary: "Monthly Bill Summary",
            thUsageMonth: "Usage Month",
            thBillingDate: "Billing Date",
            thUsagePeriod: "Usage Period",
            thMembershipFee: "Membership Fee",

            membershipCancelConfirmation: "Confirmation",
            membershipCancelWarning: "Your outstanding membership fee is $<%outstandingAmount%>, for the current month. Once you confirm cancellation, your card on file will be charged and the membership will be cancelled.\n\n Are you sure you want to cancel your Platinum membership?",
            membershipCancelYes: "Yes, Cancel",
            membershipCancelNo: "No, Don’t Cancel",
        }
    }

    if (input && input.billSummary) {
        staticText.billSummary = {
            ...input.billSummary
        }
    } else {
        staticText.billSummary = {
            pageTitle: "Bill Summary",
            billNo: "Bill No:",
            issuedOn: "Issued on:",
            dueDate: "Due Date:",
            membershipFee: "Membership Fee *",
            paymentMethod: "Payment Method",
            autoPay: "Auto Pay",
            cardEndText: "Credit Card Ending in <%last4Number%>",

            grossTitle: "Gross Received Order Value",
            receivedOrderValue: "Received Order Value",
            rejectedOrderValue: 'Rejected Order Value',
            cancelledOrderValue: "Cancelled Order Value",
            grov: "Gross Received Order Value (GROV)",

            feeCalculationHint: "Membership fee calculated as the greater of $50 or 0.5% of Gross Received Order Value (GROV) for the billing period",

            billDetails: "Bill Details",
            thBusinessUnit: "Business Unit",
            thOrder: "Order #",
            thBuyer: "Buyer",
            thOrderDate: "Order Date",
            thOrderTotal: "Order Total",

            salesTax: "Sales Tax",
            totalMonthlyPayment: "Total Monthly Payment",
        }
    }


    if (input && input.manualInvoice) {
        staticText.manualInvoice = {
            ...input.manualInvoice
        }
    } else {
        staticText.manualInvoice = {
            title: "My Invoices",
            createInvoice: "Create Manual Invoice",
            invoiceNumber: "Invoice No: ",
            selectCustomer: "Select Customer",
            orderNumber: "Order No: ",
            invoiceTo: "Invoice To",
            invoiceFrom: "Invoice From",
            issuedDate: "Invoice Date",
            dueDate: "Due Date",
            itemDetails: "Item Details",
            salesTax: "Sales Tax",
            deliveryCharges: "Additional Charges",
            discountText: "Discount",
            invoiceAmount: "Invoice Amount",
            pendingAmount: "Remaining Amount",
            paidAmount: "Paid Amount",
            markAsPaidButtonText: "Mark as Paid",
            payButtonText: "Pay Now",
            previewButtonText: "Preview Invoice",
            createInvoiceButtonText: "Create Invoice",
            sendInvoiceButtonText: "Send Invoice",
            editButtonText: "Edit Invoice",
            notePlaceHolder: "Add Notes",
            paymentTerms: "Payment Terms",
            totalAmount: "Total Amount",
            notes: "Notes: ",
            percentage: "(%)",
            currency: "($)",
            payButtonNotEnabled: "This feature will be enabled soon",
            sendText: "Send",
            sendInvoiceAlertTitle: "Are you sure you want to send the invoice?",
            paidText: "Mark as Paid",
            markAsPaidAlertTitle: "Do you want to mark the invoice as paid?",
            applyText: "Apply Filter",
            loadMoreText: "+ LOAD MORE",
            filterByText: "Filter By",
            paymentTitle: "You haven’t linked your payment method yet.",
            paymentDescription: "Link your account now",
            linkAccount: "Link Account",
            tab1: "Invoice Details",
            tab2: "Payment History",
            characterForSearch: 3,
            selectPaymentTerms: "Select Payment Terms",
            palletCharges: "(Pallet Charges,Delivery Charges etc)",
            createdDate: "Invoice Date :",
            refOrderNo: "Ref Order No : ",
            refOrderNumberPlaceHolder: "",
        }
    }

    /* INV000001 BEGIN */
    if (input && input.inventoryProductList) {
        staticText.inventoryProductList = {
            ...input.inventoryProductList
        }
    } else {
        staticText.inventoryProductList = {
            title: "My Inventory", 
            buttonTitle: "+Add", 
            loadMoreText: "+Load More", 
            serialNo: "S.No", 
            productName: "Product Name", 
            category: "Category", 
            description: "Description", 
            packageQuantity: "Package Qty", 
            searchProducts: "Search Products", 
            status: "Status", 
            filterText: "FILTERS",
            categoryTitle: "Category",
            brandTitle: "Brand", 
            noProductsText: "You don’t have any products setup for Inventory. Please add using the below button.", 
            tab1: "Active", 
            tab2: "Inactive", 
            importExportButtonTitle: "Import / Export Inventory", 
            productMappingButtonTitle: "Product Mapping",
    	    onHandLine1: "On Hand", 
    	    onHandLine2: "(A)", 
    	    buyerOrdersLine1: "Buyer Orders", 
    	    buyerOrdersLine2: "(B - Outgoing)", 
    	    onOrderLine1: "On Order", 
    	    onOrderLine2: "(C - Incoming)", 
    	    netLine1: "Net", 
    	    netLine2: "(A + C - B)", 
    	    netAsOf: "Net (As of)", 
    	    today: "Today", 
        }
    }

    if (input && input.inventoryProductMapping) {
        staticText.inventoryProductMapping = {
            ...input.inventoryProductMapping
        }
    } else {
        staticText.inventoryProductMapping = {
            title: "Product Mapping", 
            leftTitle: "Vendor Products", 
            rightTitle: "My Products",
            loadMoreText: "+Load More", 
            productName: "Product Name", 
            category: "Category", 
            packageQuantity: "Package", 
            brandTitle: "Brand", 
            productUnit: "Unit",
            vendorTitle:"Vendor",
            mappedProductUnit: "Handling/Selling Unit",
            noProductsText: "You don’t have any products setup for Inventory. Please add using the below button.", 
        }
    }

/* INV000001 END */

/* CHG000002 PB-9 BEGIN */
if (input && input.emailNotification) {
    staticText.emailNotification = {
        ...input.emailNotification
    }
} else {
    staticText.emailNotification = {
        pageHeader: "Notification Settings",
        pageTitle: "Email Notification Settings", 
        connectionRequest: "Connection Requests From Vendors/Buyers", 
        newInvoice: "New Invoice Received", 
        newOrder: "New Order Received", 
        newPayment: "New Payment Received", 
        orderUpdates: "Updates To Existing Orders", 
        invoiceUpdates: "Updates To Existing Invoices", 
    }
}

/* CHG000002 PB-9 END */

    return staticText;
}
