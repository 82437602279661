import React from 'react';
import "./tenant-workflow.scss";
import AuthenticateAPI from '../../apis/authenticate'
import BranchApi from '../../apis/branch'
import SessionCache from '../routes/session-cache';
import { Modal, ModalBody } from "reactstrap";
import Dropdown from '../common/dropdown-v2/dropdown'
import Path from '../routes/routes-path';
import { ImageConstants } from '../../constants/constants';
import { getImageSource } from '../../utility/image-source'
import Header from '../common/header/header-component';
import Footer from '../common/footer/footer-component';
import CreateBranch from './create-branch/create-branch';
import CreateStaff from './create-staff/create-staff'
import AddVendors from './add-vendors/add-vendors'


class TenantWorkflow extends React.Component {

    constructor(props) {
        super(props)
        this.userInfo = SessionCache.getUser()
    }

    state = {
        steps: [
            {
                title: "Add Your Business Units",
                activeIcon: getImageSource(ImageConstants.HOME_ACTIVE),
                inactiveIcon: "",
                status: "ACTIVE",
                height: "20px",
                width: "17.5px",
                type: "BRANCH"
            },
            {
                title: "Add Your Employees",
                activeIcon: getImageSource(ImageConstants.ADD_USER_ACTIVE),
                inactiveIcon: getImageSource(ImageConstants.ADD_USER_IN_ACTIVE),
                status: "IN_ACTIVE",
                width: "21.39px",
                height: "18px",
                type: "EMPLOYEE"
            },
            {
                title: "Add Your Vendors/Buyers",
                activeIcon: getImageSource(ImageConstants.ADD_VENDOR_ACTIVE),
                inactiveIcon: getImageSource(ImageConstants.ADD_VENDOR_IN_ACTIVE),
                status: "IN_ACTIVE",
                width: "21.39px",
                height: "18px",
                type: "VENDOR"
            }
        ],
        activeStepIndex: 0
    }

    componentDidMount() {
    }

    onPressBranchCreate = (branchId) => {

        let steps = this.state.steps;

        steps[0].status = "COMPLETED"
        steps[1].status = "ACTIVE"

        this.setState({ steps, activeStepIndex: 1, branchId })
    }

    onPressStaffCreate = () => {

        let steps = this.state.steps;

        steps[1].status = "COMPLETED"
        steps[2].status = "ACTIVE"

        this.setState({ steps, activeStepIndex: 2 })

    }

    onClickContinue = () => {
        // this.props.history.push(Path.DASHBOARD)
        window.location.replace(Path.DASHBOARD);

    }

    onPressAddVendor = () => {

        let steps = this.state.steps;
        steps[2].status = "COMPLETED"

        this.setState({ steps, isCompleted: true })

    }


    renderStepCompoenent = () => {

        if (this.state.activeStepIndex == 0) {
            return <CreateBranch onPressCreate={this.onPressBranchCreate}></CreateBranch>
        }

        if (this.state.activeStepIndex == 1) {
            return <CreateStaff onPressCreate={this.onPressStaffCreate} branchId={this.state.branchId}></CreateStaff>
        }

        if (this.state.activeStepIndex == 2) {
            return <AddVendors onPressCreate={this.onPressAddVendor}></AddVendors>
        }
    }

    renderTitles = () => {
        if (this.state.activeStepIndex == 0) {
            return '1. Complete your business unit creation'
        }

        if (this.state.activeStepIndex == 1) {
            return '2. Add your employees to the business unit'
        }

        if (this.state.activeStepIndex == 2) {
            return '3. Add your Vendors/Buyers'
        }
    }

    renderSteps = () => {

        let elements = [];

        for (let i = 0; i < this.state.steps.length; i++) {

            let step = this.state.steps[i]

            if (step.status == "COMPLETED") {
                elements.push(<div className='row spacing'>
                    <div className='col-3'>
                        <div className='green-line-circle'>
                            <img src={getImageSource(ImageConstants.GREEN_TICK_FILLED)} className={`green-tick-filled`}></img>
                            {i < this.state.steps.length - 1 ? <div className='green-line'></div> : <></>}
                        </div>
                    </div>
                    <div className='col-9'>
                        <div className='step-index'>Step {i + 1}</div>
                        <div className={`step-title ${step.status == 'ACTIVE' ? 'step-title-active' : ""}`}>{step.title}</div>
                    </div>

                </div>)
            } else if (step.status == "ACTIVE") {
                elements.push(<div className='row spacing'>
                    <div className='col-3'>
                        <div className={`step-icon-wrapper ${step.status == 'ACTIVE' ? 'active-step' : ""}`}>
                            <img src={step.activeIcon} className={`step-icon`} style={{ height: step.height, width: step.width }}></img>

                            {i < this.state.steps.length - 1 ? <img src={getImageSource(ImageConstants.DOWN_ARROW)} className='down-arrow'></img> : <></>}

                        </div>
                    </div>
                    <div className='col-9'>
                        <div className='step-index'>Step {i + 1}</div>
                        <div className={`step-title ${step.status == 'ACTIVE' ? 'step-title-active' : ""}`}>{step.title}</div>
                    </div>

                </div>)
            } else {
                elements.push(<div className='row spacing'>
                    <div className='col-3'>
                        <div className={`step-icon-wrapper`}>
                            <img src={step.activeIcon} className={`step-icon`} style={{ height: step.height, width: step.width }}></img>

                            {i < this.state.steps.length - 1 ? <img src={getImageSource(ImageConstants.DOWN_ARROW)} className='down-arrow'></img> : <></>}
                        </div>
                    </div>
                    <div className='col-9'>
                        <div className='step-index'>Step {i + 1}</div>
                        <div className={`step-title`}>{step.title}</div>
                    </div>
                </div>)
            }
        }

        return <div className='step-container'>{elements}</div>
    }

    render() {
        return (
            <>
                <div className="tenant-workflow">
                    <Header from={'DASHBOARD'} {...this.props}></Header>
                    {!this.state.isCompleted ? <div className="onboarding-container">
                        <div className="gray-bg"></div>
                        <div className='app-container'>
                            <div className='onboarding-card'>

                                <div className='header'>
                                    <div className='sub-text-wrapper'><span className='sub-text'>START WITH BASICS</span></div>
                                    <div className='row m-0 mt-2'>
                                        <div className='col-8 p-0'>
                                            <span className='tenant-workflow-title'>{this.renderTitles()}</span>
                                        </div>
                                        <div className='col-4 p-0'>
                                            <div className='sub-text'>{Math.round((this.state.activeStepIndex) * (100 / 3)) + '%'} COMPLETED</div>
                                            <div className='progress-bar mt-3'>
                                                <div className={`progress-fill ${this.state.activeStepIndex > 1 ? 'progress-bar-yellow' : ""}`} style={{ width: (this.state.activeStepIndex) * (100 / 3) + '%' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='body'>
                                    <div className='mist-left-card'>
                                        {this.renderSteps()}
                                    </div>

                                    <div className='mist-right-card'>
                                        {this.renderStepCompoenent()}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> : <div className="row display-grid-center tenant-workflow-final">
                        <div className="col complete text-center">
                            <div>
                                <img className="icon-80 animate-image-1" alt="" src={getImageSource(ImageConstants.GREEN_TICK)} />
                            </div>
                            <div className="pt-4">
                                <div>Congratulations {this.userInfo.firstName + ' ' + this.userInfo.lastName} !</div>
                                <div>You’ve successfully created your Business Unit.</div>
                            </div>

                            <div className="pt-4 text-center">
                                <button className={`app-button-primary `} onClick={this.onClickContinue}>
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>}
                    {/* <Footer></Footer> */}
                </div>
            </>

        );
    }

}

export default TenantWorkflow;
