import React from "react";
import StaticText from "../../../../shared-store/static-text-store";

class SilverMembership extends React.Component {
   state = {
      staticText: {},
      loading: true,
      existingSubscription: {},
   };

   componentDidMount() {
      let staticText = StaticText.getStaticText("membershipAndBilling");
      console.log({staticText});
      this.setState({ staticText });
   }

   renderNameBadge(name, customClass) {
      if (!name) return "";
      let splitName = name.split(" ");
      let tag = "";
      if (splitName.length > 1) {
         tag = `${splitName[0][0] || ""}${splitName[1][0] || ""}`;
      } else if (splitName) {
         tag = `${splitName[0][0]}`;
      }
      tag = tag ? tag.toUpperCase() : "";
      return (
         <div className={`d-inline-block name-badge ${customClass || ""}`}>
            <span className="">{tag || ""}</span>
         </div>
      );
   }

   render() {
      return (
         <div className="membership-section">
            <p className="section-title">{this.state.staticText.currentMembershipTitle}</p>
            <div className="membership-card">
               <div className="profile-info">
                  <div className="pr-2">
                     {this.renderNameBadge(this.props.profileLine1)}
                  </div>
                  <div>
                     <p className="line1">{this.props.profileLine1}</p>
                     <p className="line2">{this.props.profileLine2}</p>
                     <p className="line3">{this.props.profileLine3}</p>
                  </div>
               </div>

               <p className="membership-name">{this.state.staticText.silverMembership}</p>
               <p className="membership-type">{this.state.staticText.freeMembership}</p>
            </div>
         </div>
      );
   }
}

export default SilverMembership;
