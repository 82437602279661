import React from 'react';
import "./forgot-password.scss";
import AuthenticateAPI from '../../apis/authenticate'

import ImageConstants from "../../constants/image-constants"

import { getImageSource } from '../../utility/image-source';

import Utils from '../../utility/common-utilities';
import Header from '../common/header/header-component';
import Footer from '../common/footer/footer-component';
import ToasterComponent from '../common/toaster/toaster';
import OtpInput from '../common/otp-input/otp-input';
import { Tooltip } from "reactstrap"
import Path from '../routes/routes-path';
import StaticText from '../../shared-store/static-text-store';
import CommonUtils from '../../utility/common-utilities';

class ForgotPassword extends React.Component {
    state = {
        isForgotOtpSend: false,
        isValidateOtp: false,
        isValidUpdatePassword: false,
        isUpdatePassword: false,
        isInvalidOtp: false,
        email: "",
        isComplete: false,
        staticText: {}
    }

    componentDidMount() {
        let staticText = StaticText.getStaticText("forgotPassword")
        this.setState({ staticText })
    }

    showToaster(type, message) {
        this.setState({
            showToaster: true,
            toaster: {
                type,
                message,
            }
        }, () => {
            setTimeout(() => this.hideToaster(), 3000)
        })
    }

    hideToaster() {
        this.setState({ showToaster: false })
    }

    onChangeHandler(event, key) {
        this.setState({ [key]: event?.target?.value }, () => {
            this.validateSendOtpPayload()
        })
    }

    onChangePasswordHandler(event, key) {
        this.setState({ [key]: event?.target?.value }, () => {
            this.validateUpdatePayload()
        })
    }

    onClickSendForgotOtp = () => {

        let payload = { email: this.state.email };

        let validate = this.validateSendOtpPayload(true)
        if (!validate) return

        AuthenticateAPI.forgortPassword(payload).then((response) => {
            this.setState({ isForgotOtpSend: true, invalidOtp: false, isValidateOtp: false }, () => {
                this.setState({ isValidateOtp: true })
            })
            this.showToaster("success", "OTP has been sent successfully.")

        }).catch((err) => {
            this.showToaster("error", "User not registered")
        })
    }

    validateForgotOtp = () => {
        const email = this.state.email
        if (this.state.otp?.length != 6) {
            return;
        }

        let payload = { email, otp: this.state.otp?.join("") };

        AuthenticateAPI.validateForgortPassword(payload).then((response) => {
            this.setState({ isValidateOtp: false, isUpdatePassword: true })
        }).catch((err) => {
            this.setState({ invalidOtp: true, otp: "", refreshOtp: true }, () => {
                this.setState({ refreshOtp: false })
            })

        })
    }

    validateForgotPassword = () => {

        let payload = {
            email: this.state.email,
            otp: this.state.otp?.join(""),
            password: this.state.password
        };

        let validation = this.validateUpdatePayload(true)
        if (!validation) return

        AuthenticateAPI.validateForgortPasswordAndUpdate(payload).then((response) => {
            this.setState({ isUpdatePassword: false, isComplete: true, name: response?.data?.name })
        }).catch((err) => {
            alert("Something went wrong")
        })
    }

    validateSendOtpPayload(showError) {

        let isValidForm = true
        if (!this.state.email || !Utils.validateEmail(this.state.email)) {
            showError && this.setState({ isInvalidEmail: true })
            isValidForm = false
        } else this.setState({ isInvalidEmail: false })

        this.setState({ isValidForm })
        return isValidForm
    }

    validateUpdatePayload(showError) {

        let isValidUpdatePassword = true
        if (!this.state.password) {
            showError && this.setState({ inValidPassword: true })
            isValidUpdatePassword = false
        } else {
            if (!CommonUtils.validatePassword(this.state.password)) {
                showError && this.setState({ isInvalidPasswordLength: true })
                return false
            } else this.setState({ isInvalidPassword: false, isInvalidPasswordLength: false })
        } this.setState({ inValidPassword: false })

        if (!this.state.reEnterPassword) {
            showError && this.setState({ inValidReEnterPassword: true })
            isValidUpdatePassword = false
        } else this.setState({ inValidReEnterPassword: false })

        if (this.state.password && this.state.password != this.state.reEnterPassword) {
            if (showError) {
                this.setState({ inValidPassword: true, inValidReEnterPassword: true })
            }
            isValidUpdatePassword = false
        } else this.setState({ inValidPassword: false, inValidReEnterPassword: false })

        this.setState({ isValidUpdatePassword })
        return isValidUpdatePassword
    }

    onEnterOtp = (value) => {
        let isInvalidOtp = value?.length !== 6
        this.setState({ otp: value, isInvalidOtp })
    }

    onClickCancel = () => {
        this.setState({
            isForgotOtpSend: false,
            isValidateOtp: false,
            isUpdatePassword: false,
            password: "",
            reEnterPassword: "",
            otp: []
        })
    }

    onClickContinue = () => {
        this.props.history.replace(Path.LOGIN)
    }

    onClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }

    onClickShowReEnterPassword = () => {
        this.setState({ showReEnterPassword: !this.state.showReEnterPassword })
    }

    rednerChangePassword() {
        return (
            <>
                <div className="sub-title d-inline-block">
                    You can set your new password now.
                </div>

                <div className="pt-4">

                    <div className={`position-relative bw-spaces-input ${this.state.inValidPassword || this.state.isInvalidPasswordLength ? "invalid-input-container" : ""}`}>
                        <input id="password-tooltip" className={`app-input`} placeholder='Enter Your new Password'
                            value={this.state.password}
                            type={this.state.showPassword ? "" : "password"}
                            onChange={(e) => this.onChangePasswordHandler(e, 'password')}
                        />
                        <span className="password-icon">
                            <img className="cursor-pointer icon-20" alt=""
                                src={getImageSource(this.state.showPassword ? ImageConstants.EYE_OPEN : ImageConstants.EYE_CLOSE)}
                                onClick={this.onClickShowPassword}
                            />
                        </span>

                        {this.state.isInvalidPasswordLength ? <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.isInvalidPasswordLength} target="password-tooltip">
                            {this.state.staticText?.invalidPasswordLength}
                        </Tooltip> : ""}
                    </div>

                    <div className={`position-relative bw-spaces-input ${this.state.inValidReEnterPassword ? "invalid-input-container" : ""}`}>
                        <input className={`app-input`} placeholder='Re Enter Password'
                            value={this.state.reEnterPassword}
                            type={this.state.showReEnterPassword ? "" : "password"}
                            onChange={(e) => this.onChangePasswordHandler(e, 'reEnterPassword')}
                        />
                        <span className="password-icon">
                            <img className="cursor-pointer icon-20" alt=""
                                src={getImageSource(this.state.showReEnterPassword ? ImageConstants.EYE_OPEN : ImageConstants.EYE_CLOSE)}
                                onClick={this.onClickShowReEnterPassword}
                            />
                        </span>
                    </div>
                </div>

                <div className="pt-4 text-center">
                    <button className={`app-button-primary w-100 ${!this.state.isValidUpdatePassword ? "app-button-disabled" : ""}`} onClick={this.validateForgotPassword}>
                        {this.state.staticText?.changePasswordText}
                    </button>
                </div>

                <div className="pt-4">
                    <div className="text-center">
                        <span className="cancel" onClick={this.onClickCancel}>{this.state.staticText?.cancelButtonText}</span>
                    </div>
                </div>
            </>
        )

    }

    renderValidateOtp() {
        return (
            <>
                <div className="sub-title d-inline-block">
                    {this.state.staticText?.validateOtpTitle}
                </div>

                <div className="pt-4">
                    <div className='bw-spaces-input'>
                        <div className='otp-input-label'>
                            {this.state.staticText?.otpInputTitle}
                        </div>
                        <div id="otp-tooltip" className={`${this.state.invalidOtp ? "invalid-otp" : ""}`}>
                            <OtpInput resetOtp={this.state.refreshOtp} onChange={this.onEnterOtp} />
                        </div>
                        {
                            !this.state.refreshOtp ? <Tooltip className="custom-error-tooltip" placement="right" isOpen={this.state.invalidOtp} target="otp-tooltip">
                                {this.state.staticText?.invalidOtp}
                            </Tooltip> : ""
                        }


                    </div>
                </div>

                <div className="pt-4 text-center">
                    <button className={`app-button-primary w-100 ${this.state.isInvalidOtp ? "app-button-disabled" : ""}`} onClick={this.validateForgotOtp}>
                        {this.state.staticText?.otpButtonText}
                    </button>
                </div>

                <div className="pt-4">
                    <div>
                        <span className="cancel" onClick={this.onClickCancel}>{this.state.staticText?.cancelButtonText}</span>
                        <span className="resend float-right" onClick={this.onClickSendForgotOtp}>{this.state.staticText?.resendButtonText}</span>
                    </div>
                </div>
            </>
        )
    }

    renderSendOtp() {
        return (
            <>
                <div className="sub-title d-inline-block">
                    {this.state.staticText?.emailTitle}
                </div>

                <div className="pt-4">
                    <div className={`bw-spaces-input ${this.state.isInvalidEmail ? "invalid-input-container" : ""}`}>
                        <input className={`app-input`} placeholder='Enter Email'
                            value={this.state.email}
                            onChange={(e) => this.onChangeHandler(e, 'email')}
                        />
                    </div>
                </div>
                <div className="pt-4 text-center">
                    <button className={`app-button-primary w-100  ${(!this.state.email || this.state.isInvalidEmail) ? "app-button-disabled" : ""}`} onClick={this.onClickSendForgotOtp}>
                        {this.state.staticText?.sendOtpButtonText}
                    </button>
                </div>

                <div className="pt-4">
                    <div className="text-center">
                        <span className="cancel" onClick={this.onClickContinue}>{this.state.staticText?.cancelButtonText}</span>
                    </div>
                </div>
            </>
        )
    }

    renderSteps() {
        return !this.state.isForgotOtpSend ?
            this.renderSendOtp() :
            this.state.isValidateOtp ?
                this.renderValidateOtp() :
                this.state.isUpdatePassword ?
                    this.rednerChangePassword() :
                    ""
    }

    render() {

        return (
            <>
                <Header from="LOGIN" />
                <div className="app-container">
                    <div className="forgot-password">
                        <div className="container">
                            <div className="row  d-flex justify-content-center">

                                <div className="col forgot-password-wrapper">
                                    {
                                        !this.state.isComplete ?
                                            <div>
                                                <div>
                                                    <span className="lock-image"> <img className="icon-20" alt="" src={getImageSource(ImageConstants.LOCK)} /> </span>
                                                </div>
                                                <div className="title pt-5">
                                                    {this.state.staticText?.forgotPasswordTitle}
                                                </div>
                                                <div className="pt-4"> {this.renderSteps()}</div>
                                            </div>
                                            :
                                            <div className="text-center complete">
                                                <div>
                                                    <img className="icon-80 animate-image-1" alt="" src={getImageSource(ImageConstants.GREEN_TICK)} />
                                                </div>
                                                <div className="pt-4">
                                                    <div> {this.state.staticText?.congratulationsText} {this.state.name} !</div>
                                                    <div>{this.state.staticText?.successText}</div>
                                                </div>

                                                <div className="pt-4 text-center">
                                                    <button className={`app-button-primary `} onClick={this.onClickContinue}>
                                                        {this.state.staticText?.continueButtonText}
                                                    </button>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                {
                    this.state.showToaster ? <ToasterComponent type={this.state.toaster?.type} message={this.state.toaster?.message} /> : ""
                }
            </>
        );
    }

}

export default ForgotPassword;