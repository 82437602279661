import React from 'react';
import AuthenticateAPI from '../../apis/authenticate';
import Urls from '../../apis/url';
import { Route, Redirect } from 'react-router-dom';
import SessionCache from './session-cache';
import Path from './routes-path';

import Loading from '../common/loading/loading';
export default class AuthenticateRoute extends React.Component {

    state = {
        isLoading: true
    }

    constructor(props) {
        super(props)
        this.isAuthenticated();
    }

    isAuthenticated() {
        if (SessionCache.isAuthenticated() == null) {
            AuthenticateAPI.isLogin({}).then((response) => {

                if (response) {
                    SessionCache.setUser(response);
                    this.setState({
                        ...response,
                        isLoading: false
                    });

                } else {
                    this.setState({ isLoading: false })
                }
            }).catch((err) => {
                this.setState({ isLoading: false })
            })
        }
        else {

            SessionCache.setUser(null);
            this.setState({
                isLoading: false
            });
        }
    }

    componentDidMount() {
    }

    render() {
        let isAuthenticated = SessionCache.isAuthenticated()

        return (

            <div>
                {this.state.isLoading && <Loading></Loading>}
                {!this.state.isLoading && isAuthenticated && this.props.path == Path.LOGIN && <Redirect to={Path.BASE} />}
                {/* {!this.state.isLoading && isAuthenticated && this.props.path == Path.TENANT_WORKFLOW && this.state.totalBranch && <Redirect to={Path.BASE} />} */}
                {!this.state.isLoading && isAuthenticated && !this.state.totalBranch && <Redirect to={Path.TENANT_WORKFLOW} />}
                {/* {!this.state.isLoading && isAuthenticated && this.props.path == "*" && <Redirect to={Path.DASHBOARD} />} */}
                {!this.state.isLoading && isAuthenticated && <Route {...this.props} component={this.props.component} />}
                {!this.state.isLoading && !isAuthenticated && <Redirect to={Path.LOGIN} />}

            </div>
        );
    }

}