import React from 'react';
import "./add-cart-items.scss";


class AddCartItem extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (<div className='cart-item-add'>
            <div className='minus-symbol' onClick={(e) => { e.stopPropagation(); this.props.onClickMinus && this.props.onClickMinus() }}>-</div>
            <div className='quantity-input'><input className='app-input' value={this.props.value} type={'number'} onChange={this.props.onChange} onBlur={this.props.onSubmit} onClick={(e) => { e.stopPropagation() }}></input></div>
            <div className='plus-symbol' onClick={(e) => { e.stopPropagation(); this.props.onClickAdd && this.props.onClickAdd() }}>+</div>
        </div>)
    }
}

export default AddCartItem;