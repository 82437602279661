import Constants from "../../../constants/constants"

const tabs = {
   1: "All Active",
   2: "Pending",
   3: "Past Due",
   4: "Closed"
}
const invoiceStatuses = Constants.INVOICE_STATUS
const invoiceStatusText = Constants.INVOICE_STATUS_TEXT
const filterStatuses = {
   [invoiceStatuses.PENDING_PAYMENT]: {
      title: invoiceStatusText.PENDING_PAYMENT,
      value: invoiceStatuses.PENDING_PAYMENT,
      active: true
   },
   [invoiceStatuses.PENDING_PAYMENT]: {
      title: invoiceStatusText.PENDING_PAYMENT,
      value: invoiceStatuses.PENDING_PAYMENT,
      active: true
   },
   [invoiceStatuses.PAST_DUE]: {
      title: invoiceStatusText.PAST_DUE,
      value: invoiceStatuses.PAST_DUE,
      active: true
   },
   [invoiceStatuses.PARTIALLY_PAID]: {
      title: invoiceStatusText.PARTIALLY_PAID,
      value: invoiceStatuses.PARTIALLY_PAID,
      active: true
   },
   [invoiceStatuses.FULLY_PAID]: {
      title: invoiceStatusText.FULLY_PAID,
      value: invoiceStatuses.FULLY_PAID,
      active: true
   },
}
const tabFilters = {
   [tabs[1]]: [filterStatuses[invoiceStatuses.PENDING_PAYMENT], filterStatuses[invoiceStatuses.PAST_DUE], filterStatuses[invoiceStatuses.PARTIALLY_PAID]],
   [tabs[2]]: [filterStatuses[invoiceStatuses.PENDING_PAYMENT]],
   [tabs[3]]: [filterStatuses[invoiceStatuses.PAST_DUE]],
   [tabs[4]]: [filterStatuses[invoiceStatuses.FULLY_PAID]]
}

export default {
   tabs,
   tabFilters,
}
