import React from "react";
import SilverMembership from "./silver-membership";
import "./membership.scss";
import PlatinumMembership from "./platinum-membership";
import StaticText from "../../../../shared-store/static-text-store";
import PaymentSubscriptions from "../../../../apis/payment-subscriptions";
import Loading from "../../../common/loading/loading";
import AppConfirmModal from '../../../../../home/components/common/app-confirm/app-confirm';
import { getImageSource } from "../../../../utility/image-source";
import ImageConstants from "../../../../constants/image-constants";
import CommonUtils from "../../../../utility/common-utilities";
import SessionCache from "../../../routes/session-cache";

class Membership extends React.Component {
   state = {
      staticText: {},
      loading: true,
      existingSubscription: {},
      outstandingAmount: 0
   };
   user = {}

   constructor(props) {
      super(props)
      this.user = SessionCache.getUser()
      console.log(this.user)
   }

   componentDidMount() {
      let staticText = StaticText.getStaticText("membershipAndBilling");
      this.setState({ staticText });

      this.getExistingSubscription();
   }

   getExistingSubscription() {
      this.setState({ loading: true });
      PaymentSubscriptions.getTenantSubscription({})
         .then((response) => {
            this.setState({ loading: false, existingSubscription: response });
         })
         .catch((err) => {
            this.setState({ loading: false });
         });
   }

   showToaster(type, message) {
      this.setState(
         {
            showToaster: true,
            toaster: {
               type,
               message,
            },
         },
         () => {
            setTimeout(() => this.hideToaster(), 3000);
         }
      );
   }

   hideToaster() {
      this.setState({ showToaster: false });
   }
   onClickCancel = () => {
      this.getSubscriptionUsageAmountForCancel()
   }

   getSubscriptionUsageAmountForCancel() {
      this.setState({ loading: true });
      PaymentSubscriptions.getSubscriptionUsageAmountForCancel({})
         .then((response) => {
            this.setState({ loading: false, showCancelConfirm: true, outstandingAmount: response.amount || 0 });
         })
         .catch((err) => {
            this.setState({ loading: false });
         });
   }

   hideCancelConfirm = () => {
      this.setState({ showCancelConfirm: false })
   }

   cancelMembership = () => {
      const payload = {
         stripeSubscriptionId: this.state.existingSubscription.subscription.id,
         outstandingAmount: this.state.outstandingAmount
      }
      this.setState({ loading: true });
      PaymentSubscriptions.cancelSubscription(payload).then((response) => {
         window.location.reload()
         this.setState({ loading: false, existingSubscription: {}, showCancelConfirm: false });

      })
         .catch((err) => {
            this.setState({ loading: false });
         });
   }
   render() {
      const silverMembershipTexts = {
         profileLine1: this.user.businessName,
         profileLine2: this.user.email,
         profileLine3: '',
      }
      const platinumMembershipTexts = {
         profileLine1: this.user.businessName,
         profileLine2: this.user.email,
         profileLine3: '',
      }
      return (
         <div>
            {this.state.loading && <Loading />}
            <div className={`membership-container ${this.state.existingSubscription?._id ? 'justify-content-center' : ''}`}>
               {!this.state.existingSubscription?._id &&
                  <SilverMembership {...silverMembershipTexts} />
               }

               <div className="platinum-container">
                  <PlatinumMembership {...platinumMembershipTexts} existingSubscription={this.state.existingSubscription} />
                  {!this.state.existingSubscription?._id ?
                     <p className="platinum-hint">
                        {this.state.staticText.membershipBenefitsHint}
                        <a rel="noopener noreferrer" target="_blank" href="https://www.mybizsquare.com/pricing">{this.state.staticText.membershipBenefitsHintLinkText}</a>
                     </p>
                     : <div className='app-button-primary' onClick={this.onClickCancel}>{'Cancel Membership'}</div>
                  }
               </div>

               {
                  this.state.showCancelConfirm ?
                     <AppConfirmModal
                        className="membership-cancel-confirmation-modal"
                        img={getImageSource(ImageConstants.QUESTION)}
                        cancelButtonText={this.state.staticText?.membershipCancelNo}
                        okButtonText={this.state.staticText?.membershipCancelYes}
                        okButtonStyle="reject-text"
                        headerText={this.state.staticText?.membershipCancelConfirmation}
                        title={CommonUtils.simpleTemplateEngine(this.state.staticText?.membershipCancelWarning, { outstandingAmount: this.state.outstandingAmount })}
                        close={this.hideCancelConfirm}
                        onOk={this.cancelMembership} />
                     : ""
               }
            </div>
         </div>
      );
   }
}

export default Membership;
