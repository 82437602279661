import ImageConstants from '../constants/image-constants';

export function getImageSource(image) {

    let imageSrc;

    switch (image) {
        case ImageConstants.EMAIL_BOX:
            imageSrc = require("../assets/images/emailBox.png")
            break;
        case ImageConstants.FACEBOOK:
            imageSrc = require("../assets/images/facebook.png");
            break;

        case ImageConstants.USER:
            imageSrc = require("../assets/images/user.png");
            break;


        case ImageConstants.ADD_USER_IN_ACTIVE:
            imageSrc = require("../assets/images/add-user-inactive.png");
            break;

        case ImageConstants.ADD_USER_ACTIVE:
            imageSrc = require("../assets/images/add-user-active.png");
            break;


        case ImageConstants.ADD_VENDOR_IN_ACTIVE:
            imageSrc = require("../assets/images/add-vendor-inactive.png");
            break;

        case ImageConstants.ADD_VENDOR_ACTIVE:
            imageSrc = require("../assets/images/add-vendor-active.png");
            break;


        case ImageConstants.DOWN_ARROW:
            imageSrc = require("../assets/images/down-arrow.png");
            break;


        case ImageConstants.HOME_ACTIVE:
            imageSrc = require("../assets/images/home-active.png");
            break;

        case ImageConstants.VENDOR:
            imageSrc = require("../assets/images/vendor.png");
            break;


        case ImageConstants.LOCK:
            imageSrc = require("../assets/images/lock.png");
            break;

        case ImageConstants.GREEN_TICK:
            imageSrc = require("../assets/images/green-tick.png");
            break;


        case ImageConstants.NO_STAFF:
            imageSrc = require("../assets/images/no-staff.png");
            break;

        case ImageConstants.SIGNUP:
            imageSrc = require("../assets/images/singup.png");
            break;

        case ImageConstants.CHECKBOX:
            imageSrc = require("../assets/images/checkbox.png");
            break;

        case ImageConstants.CHECKBOX_TICK:
            imageSrc = require("../assets/images/checkbox-tick.png");
            break;

        case ImageConstants.NO_VENDOR:
            imageSrc = require("../assets/images/no-vendor.png");
            break;

        case ImageConstants.TICK_MARK:
            imageSrc = require("../assets/images/tick-mark.png");
            break;

        case ImageConstants.GREEN_TICK_FILLED:
            imageSrc = require("../assets/images/green-tick-filled.png");
            break;

        case ImageConstants.EYE_OPEN:
            imageSrc = require("../assets/images/eye-open.png");
            break;

        case ImageConstants.EYE_CLOSE:
            imageSrc = require("../assets/images/eye-close.png");
            break;

        case ImageConstants.SLIDER:
            imageSrc = require("../assets/images/sliders.png");
            break;

        case ImageConstants.ORDER_NEW:
            imageSrc = require("../assets/images/order-new.png");
            break;

        case ImageConstants.ORDER_PENDING:
            imageSrc = require("../assets/images/order-pending.png");
            break;

        case ImageConstants.ORDER_PAST_DUE:
            imageSrc = require("../assets/images/order-past-due.png");
            break;

        case ImageConstants.ORDER_COMPLETE:
            imageSrc = require("../assets/images/order-complete.png");
            break;

        case ImageConstants.ORDER_REJECT:
            imageSrc = require("../assets/images/order-reject.png");
            break;

        case ImageConstants.SORT:
            imageSrc = require("../assets/images/sort.png");
            break;

        case ImageConstants.CUBE_WHITE:
            imageSrc = require("../assets/images/cube-white.png");
            break;

        case ImageConstants.PHONE:
            imageSrc = require("../assets/images/phone.png");
            break;

        case ImageConstants.DELIVERY:
            imageSrc = require("../assets/images/delivery.png");
            break;

        case ImageConstants.CUBE_GREEN:
            imageSrc = require("../assets/images/cube-green.png");
            break;

        case ImageConstants.CUBE_RED:
            imageSrc = require("../assets/images/cube-red.png");
            break;

        case ImageConstants.CLOSE_RED:
            imageSrc = require("../assets/images/close-red.png");
            break;

        case ImageConstants.CLOSE_GRAY:
            imageSrc = require("../assets/images/close-gray.png");
            break;

        case ImageConstants.ARROW_LEFT:
            imageSrc = require("../assets/images/arrow-left.png");
            break;

        case ImageConstants.ARROW_RIGHT:
            imageSrc = require("../assets/images/arrow-right.png");
            break;

        case ImageConstants.CALENDAR_WHITE:
            imageSrc = require("../assets/images/calendar-white.png");
            break;

        case ImageConstants.CLOSE_WHITE:
            imageSrc = require("../assets/images/close-white.png");
            break;

        case ImageConstants.BELL:
            imageSrc = require("../assets/images/bell.png");
            break;

        case ImageConstants.FLAG:
            imageSrc = require("../assets/images/flag.png");
            break;


        case ImageConstants.SEARCH:
            imageSrc = require("../assets/images/search.png");
            break;

        case ImageConstants.SHOPPING_CART:
            imageSrc = require("../assets/images/shopping-cart.png");
            break;

        case ImageConstants.CROSS_ICON:
            imageSrc = require("../assets/images/cross-icon.png");
            break;

        case ImageConstants.CROSS_ICON_WHITE:
            imageSrc = require("../assets/images/cross-icon-white.png");
            break;

        case ImageConstants.EDIT_ICON:
            imageSrc = require("../assets/images/edit-icon.png");
            break;

        case ImageConstants.FULL_ARROW_LEFT:
            imageSrc = require("../assets/images/full-arrow-left.png");
            break;

        case ImageConstants.LIST_VIEW:
            imageSrc = require("../assets/images/list-view.png");
            break;

        case ImageConstants.CARD_VIEW:
            imageSrc = require("../assets/images/card-view.png");
            break;

        case ImageConstants.BLUE_DOWN_ARROW:
            imageSrc = require("../assets/images/blue-down-arrow.png");
            break;

        case ImageConstants.ORDER_SUCCESS:
            imageSrc = require("../assets/images/order-success.png");
            break;

        case ImageConstants.ARROW_UP:
            imageSrc = require("../assets/images/up-arrow.png");
            break;

        case ImageConstants.BLUE_UP_ARROW:
            imageSrc = require("../assets/images/blue-up-arrow.png");
            break;

        case ImageConstants.PRODUCT_LIST_FILTERS:
            imageSrc = require("../assets/images/product-list-filters.png");
            break;

        case ImageConstants.PRODUCT_LIST_SEARCH:
            imageSrc = require("../assets/images/product-list-search.png");
            break;

        case ImageConstants.PRODUCT_LIST_VIEW:
            imageSrc = require("../assets/images/product-list-view.png");
            break;

        case ImageConstants.PRODUCT_CARD_VIEW:
            imageSrc = require("../assets/images/product-card-view.png");
            break;

        case ImageConstants.EDIT_ICON_IN_ACTIVE:
            imageSrc = require("../assets/images/edit-inactive.png");
            break;

        case ImageConstants.EDIT_ICON_ACTIVE:
            imageSrc = require("../assets/images/edit-active.png");
            break;

        case ImageConstants.NO_INVITATIONS:
            imageSrc = require("../assets/images/no-invitation.png");
            break;

        case ImageConstants.LOGOUT:
            imageSrc = require("../assets/images/logout.png");
            break;

        case ImageConstants.REFRESH:
            imageSrc = require("../assets/images/refresh.png");
            break;

        case ImageConstants.ARROW_RIGHT_FILL:
            imageSrc = require("../assets/images/arrow-right-fill.png");
            break;

        case ImageConstants.CLOSE:
            imageSrc = require("../assets/images/close.png");
            break;

        case ImageConstants.NO_NOTIFICATION:
            imageSrc = require("../assets/images/no-notification.png");
            break;

        case ImageConstants.CHECKBOX_INACTIVE:
            imageSrc = require("../assets/images/checkbox-inactive.png");
            break;

        case ImageConstants.CHECKBOX_ACTIVE:
            imageSrc = require("../assets/images/checkbox-active.png");
            break;

        case ImageConstants.RIGHT_ARROW_BLUE:
            imageSrc = require("../assets/images/arrow-right-blue.png");
            break;

        case ImageConstants.UPLOAD_IMAGE:
            imageSrc = require("../assets/images/upload-image.png");
            break;

        case ImageConstants.LOADING:
            imageSrc = require("../assets/images/loading.png");
            break;

        case ImageConstants.REMOVE_ICON:
            imageSrc = require("../assets/images/remove-icon.png");
            break;

        case ImageConstants.POPUP_CLOSE:
            imageSrc = require("../assets/images/popup-close.png");
            break;

        case ImageConstants.CROSS_MARK:
            imageSrc = require("../assets/images/x.png");
            break;

        case ImageConstants.GREEN_TICK_NEW:
            imageSrc = require("../assets/images/green-tick-new.png");
            break;

        case ImageConstants.NO_PRODUCT:
            imageSrc = require("../assets/images/no-product.png");
            break;

        case ImageConstants.IMAGE_SWIPE_LEFT:
            imageSrc = require("../assets/images/image-left-swipe.png");
            break;

        case ImageConstants.IMAGE_SWIPE_RIGHT:
            imageSrc = require("../assets/images/image-right-swipe.png");
            break;

        case ImageConstants.FAVORATE:
            imageSrc = require("../assets/images/favorate.png");
            break;

        case ImageConstants.LOCK_ACTIVE:
            imageSrc = require("../assets/images/lock-active.png");
            break;

        case ImageConstants.DELETE:
            imageSrc = require("../assets/images/delete.png");
            break;

        case ImageConstants.DELETE_ACTIVE:
            imageSrc = require("../assets/images/delete-active.png");
            break;

        case ImageConstants.DOLLER_BLUE:
            imageSrc = require("../assets/images/doller-blue.png");
            break;

        case ImageConstants.DOLLER_GREEN:
            imageSrc = require("../assets/images/doller-green.png");
            break;

        case ImageConstants.SALES:
            imageSrc = require("../assets/images/sales.png");
            break;

        case ImageConstants.ORDER_YELLOW:
            imageSrc = require("../assets/images/order-yellow.png");
            break;

        case ImageConstants.STAFF_LOGIN:
            imageSrc = require("../assets/images/staff-login.png");
            break;

        case ImageConstants.CART_ICON:
            imageSrc = require("../assets/images/cart-icon.png");
            break;

        case ImageConstants.SALES_ACTIVITY:
            imageSrc = require("../assets/images/sales-activity.png");
            break;

        case ImageConstants.ORDER_BLUE:
            imageSrc = require("../assets/images/order-blue.png");
            break;

        case ImageConstants.ORDER_RED:
            imageSrc = require("../assets/images/order-red.png");
            break;


        case ImageConstants.DEFAULT_PRODUCT:
            imageSrc = require("../assets/images/default-product.png");
            break;

        case ImageConstants.APP_DEFAULT_LOGO:
            imageSrc = require("../assets/images/my-biz-square.png");
            break;

        case ImageConstants.LOGIN_BACKGROUND:
            imageSrc = require("../assets/images/login-page-background.png");
            break;

        case ImageConstants.INVOICE_NEW:
            imageSrc = require("../assets/images/invoice-new.png");
            break;

        case ImageConstants.INVOICE_PENDING:
            imageSrc = require("../assets/images/invoice-pending.png");
            break;

        case ImageConstants.INOVICE_PAST_DUE:
            imageSrc = require("../assets/images/invoice-past-due.png");
            break;

        case ImageConstants.INVOICE_COMPLETED:
            imageSrc = require("../assets/images/invoice-completed.png");
            break;


        case ImageConstants.FILE_PLUS:
            imageSrc = require("../assets/images/file-plus.png");
            break;

        case ImageConstants.PAID:
            imageSrc = require("../assets/images/paid.png");
            break;

        case ImageConstants.PARTIAL_PAID:
            imageSrc = require("../assets/images/partial-paid.png");
            break;

        case ImageConstants.PAYMENT:
            imageSrc = require("../assets/images/payment.png");
            break;

        case ImageConstants.RADIO_YELLOW:
            imageSrc = require("../assets/images/radio-yellow.png");
            break;

        case ImageConstants.INFO:
            imageSrc = require("../assets/images/info.png");
            break;

        case ImageConstants.BANK:
            imageSrc = require("../assets/images/bank.png");
            break;

        case ImageConstants.ARROW_RIGHT_BLACK:
            imageSrc = require("../assets/images/arrow-right-black.png");
            break;

        case ImageConstants.QUESTION:
            imageSrc = require("../assets/images/question.png");
            break;

        case ImageConstants.MEMBERSHIP:
            imageSrc = require("../assets/images/membership.png");
            break;

        case ImageConstants.MEMBERSHIP_CARD:
            imageSrc = require("../assets/images/membership-card.png");
            break;

        case ImageConstants.CROSS_RED:
            imageSrc = require("../assets/images/cross-red.png");
            break;

        case ImageConstants.CROSS_GRAY:
            imageSrc = require("../assets/images/cross-gray.png");
            break;

        case ImageConstants.ADD:
            imageSrc = require("../assets/images/add.png");
            break;

        case ImageConstants.TAG:
            imageSrc = require("../assets/images/tag.png");
            break;

        case ImageConstants.EXPORT_ICON:
            imageSrc = require("../assets/images/export-icon.png");
            break;

        case ImageConstants.ARROW_UP_RIGHT_FROM_SQUARE_GREEN:
            imageSrc = require("../assets/images/arrow-up-right-from-square-green.png");
            break;

        case ImageConstants.ARROW_UP_RIGHT_FROM_SQUARE_RED:
            imageSrc = require("../assets/images/arrow-up-right-from-square-red.png");
            break;

        case ImageConstants.PLUS_BLACK:
            imageSrc = require("../assets/images/plus-black.png");
            break;

        case ImageConstants.MY_NETWORK:
            imageSrc = require("../assets/images/my-network.png");
            break;

        case ImageConstants.USER_GROUP:
            imageSrc = require("../assets/images/user-group.png");
            break;

    }
    return imageSrc;

}